import * as S from './styles';
import { Button } from 'components/Button';
import { useCameraDevices } from 'hooks/useCameraDevices';
import React, { useRef, useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const referenceSelf = require('assets/image/checkin/MaskGroup.png');
interface SelfieCaptureProps {
    onCapture: (imageSrc: string) => void;
    setIsOpenModal: (value: boolean) => void;
    isModalOpen: boolean;
    handleFileSelfie: (file: File) => void;
}

interface SelectedDevice {
    value?: string;
    label?: string;
}
interface SelfiePermissionProps {
    onPermissionCheck: (granted: boolean) => void;
    onCameraActivated?: () => void; // Função adicional para ativar a câmera
}

const SelfiePermission: React.FC<SelfiePermissionProps> = ({ onPermissionCheck, onCameraActivated }) => {
    const handlePermissionCamera = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true });
            onPermissionCheck(true);
            if (onCameraActivated) onCameraActivated(); // Chama a função para ativar a câmera
        } catch (err) {
            onPermissionCheck(false);
        }
    };

    return (
        <S.PermissionContainer>
            <h4>Permissão de câmera necessária para continuar</h4>
            <Button onClickButton={handlePermissionCamera} size='large' title='Permitir' type='primary' />
        </S.PermissionContainer>
    );
};

const stopCameraStream = (videoRef: React.RefObject<HTMLVideoElement>) => {
    if (videoRef.current?.srcObject) {
        const mediaStream: MediaStream = videoRef.current.srcObject as MediaStream;
        mediaStream.getTracks().forEach(track => track.stop());
        videoRef.current.srcObject = null; // Clear video source
    }
};

const SelfieCapture: React.FC<SelfieCaptureProps> = ({ onCapture, setIsOpenModal, isModalOpen, handleFileSelfie }) => {
    const [cameraPermission, setCameraPermission] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [selectedDeviceId, setSelectedDeviceId] = useState<SelectedDevice | undefined>(undefined);
    const cameraDevices = useCameraDevices();

    const { t } = useTranslation();

    const dataURLtoFile = (dataurl: string, filename: string): File => {
        const arr = dataurl.split(',');
        const mimeMatch = arr[0].match(/:(.*?);/);
        if (!mimeMatch) {
            throw new Error("Invalid dataURL format");
        }
        const mime = mimeMatch[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };


    useEffect(() => {
        const fetchDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');

            if (videoDevices.length > 0 && !selectedDeviceId) {
                const defaultDevice = videoDevices[0];
                setSelectedDeviceId({ value: defaultDevice.deviceId, label: defaultDevice.label });
                enableCamera(defaultDevice.deviceId);
            }
        };

        if (cameraPermission) {
            fetchDevices();
        }
        return () => {
            stopCameraStream(videoRef);
        };
    }, [cameraPermission, selectedDeviceId]);

    const enableCamera = async (deviceId: string) => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: { exact: deviceId } },
            });
            if (videoRef.current) {
                videoRef.current.srcObject = mediaStream;
            }
        } catch (err) {
            console.error('Error accessing the camera', err);
        }
    };

    const captureSelfie = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const context = canvas.getContext('2d')!;
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const imageSrc = canvas.toDataURL('image/png');
            const file = dataURLtoFile(imageSrc, 'selfie.png');
            onCapture(imageSrc);
            setIsOpenModal(false);

            // Passar o arquivo para o componente pai
            handleFileSelfie(file);

            stopCameraStream(videoRef);
        }
    };

    return (
        <S.ContainerSelfie>
            {cameraPermission ? (
                <>
                    <S.CaptureVideoContainer >
                        <video ref={videoRef} autoPlay playsInline></video>
                        <img
                            src={referenceSelf}
                            alt=""
                        />
                    </S.CaptureVideoContainer>

                    <Button title={t('checkin.selfieValidation.buttonTakeSelfie')} onClickButton={captureSelfie} size='large' type='primary' />
                </>
            ) : (
                <SelfiePermission onPermissionCheck={setCameraPermission} onCameraActivated={() => selectedDeviceId?.value && enableCamera(selectedDeviceId.value)} />
            )}
        </S.ContainerSelfie>
    );
};

export default SelfieCapture;
