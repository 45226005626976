import Skeleton from 'react-loading-skeleton';

export const SkeletonCustonStayCard = () => {
    return (
        <div>
            <Skeleton style={{
                width: '100px',
                height: '100px',
            }} width={496} height={576} />
        </div>
    )
}