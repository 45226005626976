import { useState, useRef, useEffect } from 'react'

import { CustomStayCard } from '../CustomStayCard'
import { Amenities, AmenitiesProps } from '../Amenities'
import * as S from './styles'
import { ProductCard } from '../../../../components/ProductCard'
import { Button } from '../../../../components/Button'
import { TabButtonComponent } from './components/TabButtons'
import useAlert from '../../../../components/Notification'
import fetchAxios from 'services/http'
import { usePaymentProvider } from 'context/paymentContext'
import { useAnyProvider } from 'hooks/Context'
import { useDateDifference } from 'hooks/useDateDifference'
import { CancellationPolicies } from 'components/CancellationPolicies'
import Skeleton from 'react-loading-skeleton'
import { useScreenWidth } from 'utils/useScreenWidth'
import { Tooltip } from 'components/Tooltip'
import { MobileModal } from 'components/MobileModal'
import { ReserveDetails } from 'pages/Payment/components/PaymentCard/types'
import useExpensesStore from 'hooks/useExpensesStorage'

interface IconProps {
    icon: string
    height?: string | number
    width?: string | number
}
export interface CompleteAmenities {
    amenitie_id: string,
    _mstitle: {
        pt_BR: string
    }
}
export interface AmenitiesCondominium {
    descriptionAmenity: string;
    name: string
}
export type AccommodationExpenses = {
    id: string,
    uniqueValue: number,
    condominiumValue: number,
    iptuValue: number,
    yoghaPackageTax: number,
    cleaningValue: number,
    monthlyValue: number,
    definedDailyValue: number,
    midStay: number,
    longStay: number,
    moreThanMidStay?: number
}

interface SessionItensProps {
    scrollContainerRef?: any
    visible?: boolean
    priceTab: boolean
    locationRef: React.RefObject<HTMLDivElement>
    imagesRef: React.RefObject<HTMLDivElement>
    basicInfo?: AmenitiesProps
    accomodationId: string | number | undefined,
    completeAmenities: CompleteAmenities[],
    amenitiesCondominium: AmenitiesCondominium[]
    reserveDetails: ReserveDetails
    houseRules: string
    buildingDescription: string
    accommodationDescription: string,
    isLoading: boolean
    garage?: boolean
    minimumStay: number
}

export const SessionItens = ({ scrollContainerRef, visible, priceTab, locationRef, imagesRef, basicInfo, accomodationId, completeAmenities, amenitiesCondominium, reserveDetails, houseRules, buildingDescription, accommodationDescription, isLoading, garage, minimumStay }: SessionItensProps) => {
    const [yoghaPackage, setYoghaPackage] = useState(false)
    const [apartmentModal, setApartmentModal] = useState(false)
    const [cupomModal, setCupomModal] = useState(false)
    const [cupomCode, setCupomCode] = useState('')
    const [erroCupom, setErroCupom] = useState(false)
    const [discount, setDiscount] = useState(false)

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const breakpoint = useScreenWidth()

    const [reserved, setReserved] = useState(false)

    const { date } = useAnyProvider()

    const { weeklyDifference: weekValue } = useDateDifference(date)

    const { totalValue } = usePaymentProvider()

    const { expensesData } = useExpensesStore();

    const geralRef = useRef<HTMLDivElement>(null);
    const amenitiesRef = useRef<HTMLDivElement>(null);

    const iconErrorCupom = require('assets/svg/product/WarningCircleError.svg').default

    const refArrays = [imagesRef, geralRef, amenitiesRef, locationRef]

    const validateCupom = () => {
        if (cupomCode === 'YOGHA') {
            setCupomModal(false)
            setDiscount(true)
            setErroCupom(false)
            showAlert({ active: true, title: 'Cupom aplicado com sucesso!', type: 'SUCCESS' });
        } else {
            setErroCupom(true)
        }
    }

    const { dayDifference: differenceDays, weeklyDifference: cleaningDay } = useDateDifference(date)

    const handleScroll = (id: string) => {
        const element = document.getElementById(id);
        if (element && scrollContainerRef.current) {
            const rect = element.getBoundingClientRect();
            const top = rect.top + scrollContainerRef.current.scrollTop - 96;
            scrollContainerRef.current.scrollTo({ top: top, behavior: 'smooth' });
        }
    }

    const check = require('assets/svg/product/Check.svg').default

    const warning = require('assets/svg/WarningCircle.svg').default

    const checkCondominium = require('assets/svg/product/CheckCondominium.svg').default

    const caretRight = require('assets/svg/product/CaretRight.svg').default

    const chat = require('assets/svg/product/Chat.svg').default

    const Icon = ({ icon, height, width }: IconProps) => {
        return <img style={{ width: width, height: height }} src={icon} alt="" />
    }

    useEffect(() => {
        const getDaysReserve = async () => {
            try {
                const { data } = await fetchAxios.get(`/accommodations/notFreeCalendar/${accomodationId}`);
                const filterData = data.map((item: any) => {
                    return item.date
                })
                setReserved(filterData);

            } catch (error) {

            }
        }
        getDaysReserve();
    }, [])


    const basicResources = ['Luz', 'Água', 'Internet', 'Suporte 24h']

    const apResources = ['Kit enxoval', 'Mobília', 'Utensílios de cozinha']

    const amenities = ['Wifi', 'Ferro de passar', 'Cozinha / Cozinha Compacta', 'Geladeira', 'Aquecimento a gás', 'Ar-condicionado', 'Utensílios de cozinha', 'Cafeteira', 'Tanque', 'Smart TV', 'Liquidificador', 'Cooktop', 'Elevador', 'Portaria']

    const selectedAmenitiesCollumnOne = amenities.filter(item => ['Wifi', 'Elevador', 'Portaria'].includes(item));

    const selectedAmenitiesCollumnTWo = amenities.filter(item => ['Cozinha / Cozinha Compacta', 'Aquecimento a gás', 'Geladeira'].includes(item));

    return (<>
        <S.SessionContainer  >
            <S.ContentSession >
                <S.InitialSession id='Geral' ref={geralRef}>
                    <Amenities isLoading={isLoading} garage={garage} {...basicInfo} />

                    {breakpoint && <S.ContainerReserveCard>
                        <CustomStayCard minimumStay={minimumStay} isFetching={isLoading} setReserveData={reserveDetails} accomodationId={accomodationId} reservedDays={reserved} discount={discount} onClickCupom={() => setCupomModal(true)} onClick={() => setYoghaPackage(true)} />
                    </S.ContainerReserveCard>}

                    {isLoading ? (
                        <div>
                            <Skeleton height={22} width={205} />
                            <Skeleton height={40} width={"100%"} style={{
                                marginTop: 8
                            }} />
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2,fit-content(100%))',
                                placeItems: 'center start',
                                gap: '14px 59px',
                                marginTop: 20
                            }}>
                                <Skeleton width={170} height={14} />
                                <Skeleton width={170} height={14} />
                                <Skeleton width={139} height={14} />
                                <Skeleton width={139} height={14} />
                                <Skeleton width={98} height={14} />
                                <Skeleton width={98} height={14} />
                            </div>
                            <Skeleton width={'100%'} height={14} style={{
                                marginTop: 58
                            }} />
                            <Skeleton width={439} height={14} style={{
                                marginTop: 8
                            }} />
                            <Skeleton width={326} height={16} style={{
                                marginTop: 42
                            }} />
                        </div>
                    ) : (
                        <S.Session>
                            <S.FirstRow>
                                <h1> Conheça o Pacote Yogha </h1>
                                <S.Text> Com a gente, você encontra tudo que precisa para ter a melhor experiência durante sua moradia. No Pacote Yogha desta unidade estão inclusas as taxas de: </S.Text>
                            </S.FirstRow>
                            <S.ItensRows>
                                <S.ItemCollumn>
                                    {basicResources.map((item: string) => {
                                        return (
                                            <S.Item key={item}>
                                                <Icon icon={check} /> <S.Text>{item}</S.Text>
                                            </S.Item>
                                        )
                                    })}
                                </S.ItemCollumn>
                                <S.ItemCollumn>
                                    {apResources.map((item: string) => {
                                        return (
                                            <S.Item key={item}>
                                                <Icon icon={check} /> <S.Text>{item}</S.Text>
                                            </S.Item>
                                        )
                                    })}
                                </S.ItemCollumn>
                            </S.ItensRows>
                            <S.Text>*Reposições de itens como toalhas, itens básicos de banheiro e afins podem ter custos adicionais.</S.Text>
                            <S.WarningContainer onClick={() => setYoghaPackage(true)}>
                                <S.WarningText>Entenda mais sobre o Pacote Yogha</S.WarningText> <Icon icon={warning} />
                            </S.WarningContainer>
                        </S.Session>
                    )}
                </S.InitialSession>
                <S.SessionAmenities id='Amenities' ref={amenitiesRef}>
                    {isLoading ? (
                        <div>
                            <Skeleton width={255} height={22} borderRadius={10} />
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2,fit-content(100%))',
                                placeItems: 'center start',
                                gap: '8px 58px',
                                marginTop: 20,
                                marginBottom: 36
                            }}>
                                <Skeleton width={170} height={20} borderRadius={10} />
                                <Skeleton width={170} height={20} borderRadius={10} />
                                <Skeleton width={68} height={20} borderRadius={10} />
                                <Skeleton width={68} height={20} borderRadius={10} />
                                <Skeleton width={119} height={20} borderRadius={10} />
                                <Skeleton width={119} height={20} borderRadius={10} />
                            </div>
                            <Skeleton width={281} height={22} borderRadius={10} />
                        </div>
                    ) : (
                        <S.FirstRow >
                            <h1>Comodidades do apartamento</h1>
                            <S.ItensRows>
                                <S.ItemCollumn>
                                    {selectedAmenitiesCollumnOne.map((item: string) => {
                                        return (
                                            <S.Item key={item}>
                                                <Icon icon={check} /> <S.Text>{item}</S.Text>
                                            </S.Item>
                                        )
                                    })}
                                </S.ItemCollumn>
                                <S.ItemCollumn>
                                    {selectedAmenitiesCollumnTWo.map((item: string) => {
                                        return (
                                            <S.Item key={item}>
                                                <Icon icon={check} /> <S.Text>{item}</S.Text>
                                            </S.Item>
                                        )
                                    })}
                                </S.ItemCollumn>
                            </S.ItensRows>
                            <S.WarningContainer onClick={() => setApartmentModal(true)}>
                                <S.WarningText> Mostrar todas as comodidades</S.WarningText> <Icon icon={caretRight} />

                            </S.WarningContainer>
                        </S.FirstRow>
                    )}
                    <S.FirstRow>
                        {isLoading ? (
                            <>
                                <Skeleton width={97} height={22} borderRadius={10} style={{
                                    marginBottom: -8
                                }} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <Skeleton width={'100%'} height={14} borderRadius={10} />
                                    <Skeleton width={'100%'} height={14} borderRadius={10} />
                                    <Skeleton width={'98%'} height={14} borderRadius={10} />
                                    <Skeleton width={426} height={14} borderRadius={10} />
                                </div>
                            </>
                        ) : (
                            <>
                                <h1>Sobre o apê</h1>
                                <S.Text dangerouslySetInnerHTML={{ __html: accommodationDescription }} />
                            </>
                        )}
                    </S.FirstRow>
                    {/* <S.FirstRow>
                        <h1>Onde você vai dormir</h1>
                        <S.CardAccommodations>
                            <S.CardAccommodationsIcons> <Icon icon={bedIcon} /> <Icon icon={singleBedIcon} /> <Icon icon={cradleIcon} /></S.CardAccommodationsIcons>
                            <h2>Dormitório</h2>
                            <S.CollumAcoomodations>
                                <S.Text>1 cama de casal</S.Text>
                                <S.Text>1 sofa-cama</S.Text>ß
                                <S.Text>1 berço</S.Text>
                            </S.CollumAcoomodations>
                        </S.CardAccommodations>
                    </S.FirstRow> */}
                </S.SessionAmenities>
                <S.FirstRow>
                    {isLoading ? (
                        <>
                            <Skeleton width={164} height={22} borderRadius={10} style={{
                                marginBottom: -8
                            }} />
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Skeleton width={'100%'} height={14} borderRadius={10} />
                                <Skeleton width={'100%'} height={14} borderRadius={10} />
                                <Skeleton width={'98%'} height={14} borderRadius={10} />
                                <Skeleton width={426} height={14} borderRadius={10} />
                            </div>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(2,fit-content(100%))',
                                    gap: '6px 207px',
                                    marginTop: 48
                                }}>
                                <Skeleton width={196} height={22} borderRadius={10} style={{
                                    marginBottom: 8
                                }} />
                                <Skeleton width={196} height={22} borderRadius={10} style={{
                                    marginBottom: 8
                                }} />
                                <Skeleton width={141} height={14} borderRadius={10} />
                                <Skeleton width={141} height={14} borderRadius={10} />
                                <Skeleton width={119} height={14} borderRadius={10} />
                                <Skeleton width={119} height={14} borderRadius={10} />
                            </div>
                        </>
                    ) : (
                        <>
                            <h1>Sobre o condomínio</h1>
                            <S.Text>
                                {buildingDescription}
                            </S.Text>
                            {amenitiesCondominium.length > 1 && <S.AmenitiesCondominium>
                                {amenitiesCondominium.map((item) => {
                                    return (
                                        <S.CondominiumItem key={item.name}>
                                            <S.TitleCondominiumItem>
                                                <Icon icon={checkCondominium} />
                                                <h1>{item.name}</h1>
                                            </S.TitleCondominiumItem>
                                            <S.ContentCondominiumItem dangerouslySetInnerHTML={{ __html: item.descriptionAmenity }} style={{
                                                whiteSpace: 'pre-line',
                                                fontSize: 14
                                            }}>

                                            </S.ContentCondominiumItem>
                                        </S.CondominiumItem>
                                    )
                                })}
                            </S.AmenitiesCondominium>}
                        </>
                    )}
                </S.FirstRow>
                <S.FirstRow>
                    {isLoading ? (
                        <>
                            <Skeleton width={164} height={22} borderRadius={10} style={{
                                marginBottom: -8
                            }} />
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Skeleton width={'100%'} height={14} borderRadius={10} />
                                <Skeleton width={'100%'} height={14} borderRadius={10} />
                                <Skeleton width={'98%'} height={14} borderRadius={10} />
                                <Skeleton width={426} height={14} borderRadius={10} />
                            </div>
                        </>
                    ) : (
                        <>
                            <h1>Regras da casa</h1>
                            <S.Text dangerouslySetInnerHTML={{ __html: houseRules }} />
                        </>
                    )}
                </S.FirstRow>
                {isLoading ? (
                    <Skeleton width={'100%'} height={172} borderRadius={10} />
                ) : (
                    <S.FooterContainer>
                        <S.FirstRow>
                            <h1>Ainda ficou com dúvida ?</h1>
                            <S.Text> Fale com um dos nossos atendentes da Yogha e tire todas as suas dúvidas sobre horário de check-in, serviços disponíveis, politica para animais, etc. </S.Text>
                        </S.FirstRow>
                        <S.StyledLink to={'https://api.whatsapp.com/send?phone=5541996941949'}>
                            <p>Falar com um atendente</p> <Icon icon={chat} />
                        </S.StyledLink>
                    </S.FooterContainer>
                )}
            </S.ContentSession >
            {!breakpoint && <CustomStayCard minimumStay={minimumStay} isFetching={isLoading} setReserveData={reserveDetails} accomodationId={accomodationId} reservedDays={reserved} discount={discount} onClickCupom={() => setCupomModal(true)} onClick={() => setYoghaPackage(true)} />}
            {breakpoint ? (
                <MobileModal bottomLine isOpen={yoghaPackage} width={breakpoint === true ? 'calc(100vw - 40px)' : undefined} setIsOpen={setYoghaPackage} title={date && date.length > 1 ? "Entenda os valores" : "Pacote Yogha"} >
                    {date && date?.length > 1 && date[0] !== null && date[1] !== null && <>
                        <S.PaymentContainer>
                            <S.Rows>
                                <p>{differenceDays > 26 ? 'Aluguel' : `${differenceDays} Noites`}</p>
                                <S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {differenceDays > 26 ? expensesData.midStay : expensesData.definedDailyValue.toFixed(0)},00
                                    </S.RowValues>
                                </S.ContainerValues>
                            </S.Rows>
                            {differenceDays > 26 ? <S.Rows>
                                <p>Condomínio</p>
                                <S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {expensesData.condominiumValue},00
                                    </S.RowValues>
                                </S.ContainerValues>
                            </S.Rows> : null}
                            {differenceDays > 26 ? <S.Rows>
                                <p>IPTU</p>
                                <S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {expensesData.iptuValue},00</S.RowValues>
                                </S.ContainerValues>
                            </S.Rows> : null}
                            <S.Rows>
                                <S.CleaningRow>
                                    <p>{weekValue} Limpeza{weekValue > 1 && 's'} </p> <Tooltip inModal={true} text='As limpezas serão feitas a cada 7 dias, sendo a primeira no sétimo dia e a última, após a saída do hóspede. Em estadias de até 7 dias, a limpeza ocorrerá após o checkout.' />
                                </S.CleaningRow>
                                <S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {expensesData.cleaningValue * cleaningDay},00</S.RowValues>
                                </S.ContainerValues>
                            </S.Rows>

                            <S.Rows>
                                <p>Pacote Yogha</p>
                                {differenceDays > 26 ? (<S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {expensesData.yoghaPackageTax},00</S.RowValues>
                                </S.ContainerValues>) : (
                                    <S.ContainerValues>
                                        <S.RowValues include>
                                            Incluso</S.RowValues>
                                    </S.ContainerValues>)}
                            </S.Rows>
                            <S.Rows>
                                <span>{differenceDays > 26 ? 'Total mensal' : 'Total'}</span>
                                <S.ContainerValues>
                                    <S.LabelTotal>R$</S.LabelTotal>
                                    <S.RowValuesTotal>
                                        {totalValue},00</S.RowValuesTotal>
                                </S.ContainerValues>
                            </S.Rows>
                        </S.PaymentContainer>
                    </>}
                    <S.PaymentContainer>
                        <S.ModalTitle>
                            No Pacote Yogha desta moradia
                            estão inclusas as taxas de:
                        </S.ModalTitle>
                        <S.ItensRows>
                            <S.ItemCollumn>
                                {basicResources.map((item: string) => {
                                    return (
                                        <S.Item key={item}>
                                            <Icon icon={check} /> <S.ItemUndestandValue>{item}</S.ItemUndestandValue>
                                        </S.Item>
                                    )
                                })}
                            </S.ItemCollumn>
                            <S.ItemCollumn>
                                {apResources.map((item: string) => {
                                    return (
                                        <S.Item key={item}>
                                            <Icon icon={check} /> <S.ItemUndestandValue>{item}</S.ItemUndestandValue>
                                        </S.Item>
                                    )
                                })}
                            </S.ItemCollumn>
                        </S.ItensRows>
                    </S.PaymentContainer>

                    {date && date?.length > 1 && date[0] !== null && date[1] !== null && <S.Separator />}
                    <S.FooterSession style={{
                        marginTop: date && date?.length === 0 ? 10 : 0
                    }}>
                        {date && date?.length > 1 && date[0] !== null && date[1] !== null && <>
                            <CancellationPolicies date={date} />
                        </>}
                        <S.ButtonContainer>
                            <Button widthSize={"100%"} disabled={false} icon='' size='large' onClickButton={() => setYoghaPackage(false)} type='primary' title='Tudo bem, eu entendi' />
                        </S.ButtonContainer>

                    </S.FooterSession>
                </MobileModal>
            ) : (
                <ProductCard bottomLine isOpen={yoghaPackage} setIsOpen={setYoghaPackage} title={date && date.length > 1 ? "Entenda os valores" : "Pacote Yogha"} reduceModal={date && date.length === 0 ? true : false} >
                    {date && date?.length > 1 && date[0] !== null && date[1] !== null && <>
                        <S.PaymentContainer>
                            <S.Rows>
                                <p>{differenceDays > 26 ? 'Aluguel' : `${differenceDays} Noites`}</p>
                                <S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {differenceDays > 26 ? expensesData.midStay : expensesData.definedDailyValue.toFixed(0)},00
                                    </S.RowValues>
                                </S.ContainerValues>
                            </S.Rows>
                            {differenceDays > 26 ? <S.Rows>
                                <p>Condomínio</p>
                                <S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {expensesData.condominiumValue},00
                                    </S.RowValues>
                                </S.ContainerValues>
                            </S.Rows> : null}
                            {differenceDays > 26 ? <S.Rows>
                                <p>IPTU</p>
                                <S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {expensesData.iptuValue},00</S.RowValues>
                                </S.ContainerValues>
                            </S.Rows> : null}
                            <S.Rows>
                                <S.CleaningRow>
                                    <p>{weekValue} Limpeza{weekValue > 1 && 's'} </p> <Tooltip inModal={true} text='As limpezas serão feitas a cada 7 dias, sendo a primeira no sétimo dia e a última, após a saída do hóspede. Em estadias de até 7 dias, a limpeza ocorrerá após o checkout.' />
                                </S.CleaningRow>
                                <S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {expensesData.cleaningValue * cleaningDay},00</S.RowValues>
                                </S.ContainerValues>
                            </S.Rows>

                            <S.Rows>
                                <p>Pacote Yogha</p>
                                {differenceDays > 26 ? (<S.ContainerValues>
                                    <label>R$</label>
                                    <S.RowValues>
                                        {expensesData.yoghaPackageTax},00</S.RowValues>
                                </S.ContainerValues>) : (
                                    <S.ContainerValues>
                                        <S.RowValues include>
                                            Incluso</S.RowValues>
                                    </S.ContainerValues>)}
                            </S.Rows>
                            <S.Rows>
                                <span>{differenceDays > 26 ? 'Total mensal' : 'Total'}</span>
                                <S.ContainerValues>
                                    <S.LabelTotal>R$</S.LabelTotal>
                                    <S.RowValuesTotal>
                                        {totalValue},00</S.RowValuesTotal>
                                </S.ContainerValues>
                            </S.Rows>
                        </S.PaymentContainer>
                    </>}
                    <S.PaymentContainer>
                        <S.ModalTitle>
                            No Pacote Yogha desta moradia
                            estão inclusas as taxas de:
                        </S.ModalTitle>
                        <S.ItensRows>
                            <S.ItemCollumn>
                                {basicResources.map((item: string) => {
                                    return (
                                        <S.Item key={item}>
                                            <Icon icon={check} /> <S.ItemUndestandValue>{item}</S.ItemUndestandValue>
                                        </S.Item>
                                    )
                                })}
                            </S.ItemCollumn>
                            <S.ItemCollumn>
                                {apResources.map((item: string) => {
                                    return (
                                        <S.Item key={item}>
                                            <Icon icon={check} /> <S.ItemUndestandValue>{item}</S.ItemUndestandValue>
                                        </S.Item>
                                    )
                                })}
                            </S.ItemCollumn>
                        </S.ItensRows>
                    </S.PaymentContainer>

                    {date && date?.length > 1 && date[0] !== null && date[1] !== null && <S.Separator />}
                    <S.FooterSession style={{
                        marginTop: (date && date?.length === 0) || (date && date?.length > 1 && date[0] === null && date[1] === null) ? 10 : 0,
                    }}>
                        {date && date?.length > 1 && date[0] !== null && date[1] !== null && <>
                            <CancellationPolicies date={date} />
                        </>}
                    </S.FooterSession>
                    <S.ButtonContainerPayment style={{
                        marginTop: (date && date?.length === 0) || (date && date?.length > 1 && date[0] === null && date[1] === null) ? -48 : 0,
                    }}>
                        <Button widthSize={"100%"} disabled={false} icon='' size='large' onClickButton={() => setYoghaPackage(false)} type='primary' title='Tudo bem, eu entendi' />
                    </S.ButtonContainerPayment>

                </ProductCard>
            )}
            <ProductCard title='Comodidades' width={breakpoint === true ? 'calc(100vw - 40px)' : undefined} isOpen={apartmentModal} setIsOpen={setApartmentModal} bottomLine={true}>
                <S.ComoditiesContainer>
                    <S.ModalTitle>
                        Esta moradia possui:
                    </S.ModalTitle>
                    <S.ItemCollumn modal>
                        {completeAmenities.map((item: CompleteAmenities) => {
                            return (
                                <S.Item key={item.amenitie_id}>
                                    <Icon icon={check} /> <S.Text>{item._mstitle.pt_BR}</S.Text>
                                </S.Item>
                            )
                        })}
                    </S.ItemCollumn>
                </S.ComoditiesContainer>
                <S.ComoditiesButtonContainer>
                    <Button widthSize={"100%"} disabled={false} icon='' size='large' onClickButton={() => setApartmentModal(false)} type='primary' title='Tudo bem, eu entendi' />

                </S.ComoditiesButtonContainer>
            </ProductCard>
            <ProductCard clickAnotherFunctions={true} arg={setErroCupom} title='Aplicar Cupom' transparent isOpen={cupomModal} setIsOpen={setCupomModal}  >
                <S.CupomCodeContainer error={erroCupom}>
                    <label htmlFor="cupom">Cupom</label>
                    <input id="cupom" type="text" value={cupomCode} onChange={(e) => setCupomCode(e.currentTarget.value)} />
                    {erroCupom && <S.CupomError><img src={iconErrorCupom} alt='' /><p>O código digitado não existe ou já expirou. </p></S.CupomError>}
                </S.CupomCodeContainer>
                <S.CupomButtonContainer>
                    <Button widthSize={"137px"} disabled={false} icon='' size='medium' onClickButton={() => setCupomModal(false)} type='secondary' title='Cancelar' />
                    <Button widthSize={"137px"} disabled={false} icon='' size='medium' onClickButton={() => validateCupom()} type='primary' title='Aplicar cupom' />
                </S.CupomButtonContainer>
            </ProductCard>
            <Alert />
        </S.SessionContainer >
        {visible && !breakpoint && <TabButtonComponent reserveDetails={reserveDetails} sectionRefs={refArrays} visible={visible} priceTab={priceTab} handleScroll={handleScroll} />
        }
    </>
    )
}

