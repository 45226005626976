import * as S from "./styles";
import { Outlet, useOutletContext } from "react-router-dom";
import { CheckinHeader } from "./components/CheckinHeader";
import useSession from "./hook/useSession";
import useScrollToTop from "hooks/useScrollToTop";
import useAdjustPaddingForNotch from "hooks/useAdjustPaddingForNotch";

export type PaymentTypeState = {
    type: string;
    cardId: string;
};

interface FetchingProps {
    isFetching: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useFetchingPayment() {
    return useOutletContext<FetchingProps>();
}

export const Checkin = () => {

    useSession()
    useScrollToTop('.main')
    useAdjustPaddingForNotch('#content');

    return (
        <>
            <S.Main className="main">
                <S.Content id='content' >
                    <CheckinHeader />
                    <Outlet />
                </S.Content>
            </S.Main>
        </>
    );
};
