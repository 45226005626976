import styled from "styled-components";

export const Forms = styled.form`
    display: grid;
    grid-template-columns: 380px 380px;

    place-content: center;
    place-items: center;

    gap: 32px;

 @media (max-width: 768px) {
    grid-template-columns: 1fr;
 }

`