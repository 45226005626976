import React, { useEffect, useState } from 'react';

interface DeviceInfo {
  label: string;
  value: string;
}

export const useCameraDevices = () => {
  const [devices, setDevices] = useState<DeviceInfo[]>([]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = mediaDevices.filter(device => device.kind === 'videoinput').map(device => ({
          label: device.label || `Camera ${device.deviceId}`,
          value: device.deviceId
        }));
        setDevices(videoDevices);
      } catch (error) {
        console.error("Error fetching devices", error);
      }
    };

    fetchDevices();
  }, []);

  return devices;
};
