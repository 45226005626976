import { FONT } from "constants/fontConstants";
import styled from "styled-components";

export const AddressContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;

    padding-bottom: 48px;

    h1{
        ${FONT.HEADLINES.H5}
        margin-bottom: 16px;
    }
    h4{
        ${FONT.BODY.BODY_SMALL[400]};
        margin-bottom: 40px;
    }

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        padding-bottom: 0px;
    }
`;

export const FormContainer = styled.div`
    display: grid;

    grid-template-columns: repeat(2, 332px);

    place-content: start start;
    place-items: start start;

    gap: 24px;

    margin-bottom: 40px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: 100%;
    }
`;

export const LeftColumn = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 24px;
`;

export const RigthColumn = styled(LeftColumn)`
`;

export const FormContainerInternational = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    place-content: start start;
    place-items: start start;

    gap: 24px;

    max-width: 413px;
    width: 100%;

    margin-bottom: 40px;


    & > div {
        grid-column: 1/3;
        width: 100%;
    }
`;