import styled, { css } from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "constants/styleConstants";
import { FONT } from "constants/fontConstants";
import { Link } from "react-router-dom";
import { ReservesProps } from "../Reservations";

const Triangle = require('assets/svg/spaceUser/TriangleTooltip.svg').default;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 582px 1fr ;

    width: 100%;

    gap:40px;

    @media (width < 768px) {
        grid-template-columns: 1fr ;

        gap:32px;
    }
`;

export const ReserveValidText = styled.p`
    ${FONT.BODY.BODY_SMALL[600]};
    color: ${STATUS_COLORS.SUCCESS};
    padding: 16px;
    padding-left: 0;
    `;

export const FirstCollumn = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;

    position: relative;

    @media (width < 768px) {
        row-gap: 32px;
        align-items: center;
    }
`;

export const SecondCollumn = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding-bottom: 140px;
    }
    `;

export const Text = styled.h3<ColorText>`
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    ${({ invalid }) => invalid && css`
        color: ${STATUS_COLORS.ERROR};
    `};
    ${({ free }) => free && css`
        ${FONT.BODY.BODY_SMALL[600]};
        color: ${STATUS_COLORS.SUCCESS};
    `};
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;

    position: relative;

    padding: 16px 0px;

    align-items: center;
    justify-content: space-between;

    ::before{
        content: '';
        position: absolute;

        width: 100%;
        height: 1px;

        top:0px;

        background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};
    };

    @media (width < 768px) {
        width: calc(100% - 20px);

        flex-direction: column;
        align-items: flex-start;

        row-gap: 16px;
    }

`;

export const Value = styled.div`
    display: flex;
    flex-direction: column;

`;

export const TotalRow = styled.div`
    display: flex;

    position: relative;

    width: 100%;

    padding-top:16px;

    ::before{
        content: '';
        position: absolute;

        width: 100%;
        height: 1px;

        top:0px;

        background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};
    };

    justify-content: space-between;

    @media (width < 768px) {
       width: calc(100% - 20px);
    }

`;

export const ValueRows = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding: 16px 0px;

    @media (width < 768px) {
  width: calc(100% - 20px);
    }
`;

export const Title = styled.h1`
    ${FONT.BODY.BODY_MEDIUM[600]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const FirstRow = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;

    align-items: flex-start;
    justify-content: space-between;

    row-gap: 6px;

    padding: 16px 0px;

    @media (width < 768px) {
        padding: 0px;

        ${Title}{
            display: none;
        }
        padding-bottom: 16px;
    }
`;

export const Address = styled.span`
    display: none;

    @media (width < 768px){
        display: block;

        ${FONT.BODY.BODY_SMALL[600]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const ButtonMap = styled.div`
    position: absolute;

    top:16px;
    right: 0px;

`;

export const RowDate = styled.div`
    display: flex;
    flex-direction: row;


    gap:16px;

    @media (width < 768px) {
        flex-direction: column;
        gap: 8px;
    }

`;

export const AdvertText = styled.p`
    display: none;

    @media (width < 768px) {
       display: block;

       ${FONT.BODY.BODY_SMALL[400]};
       padding-right: 20px;
    }
`;

export const Date = styled.div`
    display: flex;
    flex-direction: row;

    position: relative;

    width: 100%;

    gap: 64px;

    padding: 16px 0;

    ::before{
        content: '';
        position: absolute;

        width: 100%;
        height: 1px;

        top:0px;

        background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};
    };

    @media (width < 768px) {
        width: calc(100% - 20px);

        flex-direction: column;

        gap: 16px;
    }

`;

export const PaymentType = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-end;

    gap: 8px;

    padding: 2px 0px;

`;

export const Image = styled.img`
    width: 582px;
    height: 396px;

    object-fit: cover;

    border-radius: 16px;

    @media (width < 768px) {
        width: calc(100lvw - 6px);
        height: 238px;

        border-radius: 0px;

        margin-left: -20px;
    }
`;

export const TextAreaContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`;

export const ButtonSeeMore = styled.button`
    display: flex;
    flex-direction: row;

    background-color: transparent;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    align-items: center;
    justify-content: flex-start;

    padding: 8px 16px;

    gap:16px;

    p{
        ${FONT.BODY.BODY_SMALL[500]};
        color:${FONT.BODY.BODY_SMALL[500]};
    }

    cursor: pointer;
`;

export const Gadget = styled.div<Pick<ReservesProps, 'type'>>`
    display: flex;

    align-items: center;
    justify-items: center;

    border-radius: 10px;

    p{
        ${FONT.CAPTION.CAPTION_SEMI_BOLD};
    }

    width: fit-content;

    padding: 2px 8px;

    ${({ type }) => {
        switch (type) {
            case "RECEIVED":
                return css`
                    background-color: ${STATUS_COLORS.SUCCESS};
                    color: ${NEUTRAL_COLORS.WHITE};
                    `;
            case "PAID":
                return css`
                    background-color: ${STATUS_COLORS.SUCCESS};
                    color: ${NEUTRAL_COLORS.WHITE};
                    `;
            case "UNPAID":
                return css`
                    background-color: ${STATUS_COLORS.WARNING};
                    color: ${NEUTRAL_COLORS.HIGH_DARK};
                    `;
            case "CANCELLED":
                return css`
                    background-color: ${NEUTRAL_COLORS.DARK_GRAY};
                    color: ${NEUTRAL_COLORS.WHITE};
                    `;
            case "CONFIRMADA":
                return css`
                    background-color: ${STATUS_COLORS.INFO};
                    color: ${NEUTRAL_COLORS.WHITE};
                    `;
        }
    }};

    @media (width < 768px) {
        display: none;
    }
`;

export const GadgetInImage = styled(Gadget)`
    display: none;

        @media (width < 768px) {
            display: flex;
            position: absolute;

            top: 12px;
            left: -8px;

            padding: 2px 8px;

            ${FONT.CAPTION.CAPTION_SEMI_BOLD};

        }
`;



export const SubTitle = styled.h2`
    ${FONT.BODY.BODY_SMALL[600]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

interface ColorText {
    invalid?: boolean;
    free?: boolean;
}

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;

    gap:16px;

    margin-top: 16px;

    @media (width < 768px) {
        flex-direction: column;

        align-items: center;
    }
`;

export const LeftButtonColumn = styled.div`
    display: flex;

    width: 197px;

    @media (width < 768px) {
        width: calc(100vw - 40px);

        margin-left: -20px;
    }
`;

export const ButtonCancel = styled.button`
    display: flex;

    align-items: center;
    justify-content: center;

    background-color: ${NEUTRAL_COLORS.WHITE};

    padding: 12px 16px;

    border: none;

    p{
        ${FONT.BODY.BODY_SMALL[600]};
        color: ${STATUS_COLORS.ERROR};
    }

    :hover{
        cursor: pointer;
    }
`;

export const ModalCancel = styled.div`
    display: flex;
    flex-direction: column;

`;

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;

    gap:20px;

    img{
        width:86px;
        height: 76px;

        object-fit: cover;

        border-radius: 8px;
    }
`;

export const ModalRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 16px;

    width: 160px;

    justify-content: space-between;

`;

export const ModalHeaderRight = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;
`;

export const ModalCancelled = styled.div`
    display: flex;
    flex-direction: row;

    position: relative;

    gap:8px;
`;

export const CancelledSession = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;
`;

export const LinkTerms = styled(Link)`
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${PRIMARY_COLORS.MEDIUM};
    text-decoration: none;
    &:hover{
        color: ${PRIMARY_COLORS.DARK};
    }
`

export const Terms = styled.div`
    display: flex;
    flex-direction: row;

    padding-right: 124px;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }
`;


export const Tooltip = styled.div`
    display: flex;
    flex-direction: row;

    max-width: 395px;
    width: 100%;

    position: absolute;

    padding: 8px;

    top: 28px;
    left: 127px;

    border-radius: 8px;

    background-color: ${NEUTRAL_COLORS.DARK};

    ::after{
        content:url(${Triangle});
        position: absolute;

        top: -12px;
    }

    p{
     ${FONT.CAPTION.CAPTION_SEMI_REGULAR}   ;
     color: ${NEUTRAL_COLORS.WHITE}
    }
`;

export const TaxCancelled = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;
`;

export const TaxRow = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

`;

export const ModalButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: flex-end;

    gap: 24px;
`;

export const ConfirmCancelButton = styled.button`
    display: flex;

    align-items: center;
    justify-content: center;

    width: 214px;
    height: 44px;

    background-color: ${STATUS_COLORS.ERROR};

    border: none;
    border-radius: 10px;

p{
    ${FONT.BODY.BODY_SMALL[600]};
    color: ${NEUTRAL_COLORS.WHITE};
}

`;