import { useNavigate } from "react-router-dom"
import * as S from "./styles"

export interface SpaceCardProps {
    route: string
    icon: string
    title: string
    description: string
}

export const SpaceCard = ({...card}:SpaceCardProps) => {

    const navigate = useNavigate()

return(
    <S.Container onClick={() => navigate(card.route)}>
                    <img src={card.icon} alt="" />
                    <h1>{card.title}</h1>
                    <p>{card.description}</p>
                </S.Container>
)
}