import * as S from './styles'
import { TextAreaProps } from './types'


export const TextArea = ({label, name, value, disabled = false, placeholder, errorMessage, ...rest}:TextAreaProps) => {

    const iconError = require('assets/svg/error/WarningError.svg').default

    return (
        <S.Container>
            {label !== null && <S.Label htmlFor={name}>{label}</S.Label>}
            <S.TextArea errorMessage={errorMessage} disabled={disabled} {...rest} placeholder={placeholder} name={name} id={name} value={value} />
            { errorMessage && <S.Error>
                <img src={iconError} alt="" />
                <span>{errorMessage}</span>
            </S.Error>}
        </S.Container>
    )
}