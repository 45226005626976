// TABLE OF FONT SIZES

export const FONT_SIZE = {
  HUGE: '32px',
  LARGE: '24px',
  MEDIUM: '20px',
  SMALL: '16px',
  TINY: '14px',
  LITTLE: '12px',
} as const;

export const FONT_WEIGTH = {
  BOLD: '800',
  HUGE: '600',
  LARGE: '500',
  MEDIUM: '400',
  SMALL: '200',
  TINY: '100',
} as const

export const FONT = {
  HEADLINES: {
    H1:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": "64px",
      "line-height": "140%",
    },
    H2:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": "48px",
      "line-height": "140%",
    },
    H3:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": "40px",
      "line-height": "140%",
    },
    H4:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": FONT_SIZE.HUGE,
      "line-height": "140%",
    },
    H5:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": FONT_SIZE.LARGE,
      "line-height": "140%",
    },
},
  BODY: {
    BODY_LARGE:{
      600:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": FONT_SIZE.MEDIUM,
      "line-height": "140%",
    },
      500:{
      "font-weight": FONT_WEIGTH.LARGE,
      "font-size": FONT_SIZE.MEDIUM,
      "line-height": "140%",
    },
      400:{
      "font-weight": FONT_WEIGTH.MEDIUM,
      "font-size": FONT_SIZE.MEDIUM,
      "line-height": "140%",
    }
  },
    BODY_MEDIUM:{
      600:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": FONT_SIZE.SMALL,
      "line-height": "140%",
      },
      500:{
      "font-weight": FONT_WEIGTH.LARGE,
      "font-size": FONT_SIZE.SMALL,
      "line-height": "140%",
      },
      400:{
      "font-weight": FONT_WEIGTH.MEDIUM,
      "font-size": FONT_SIZE.SMALL,
      "line-height": "140%",
      }
    },
    BODY_SMALL:{
      600:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": FONT_SIZE.TINY,
      "line-height": "140%",
      },
      500:{
      "font-weight": FONT_WEIGTH.LARGE,
      "font-size": FONT_SIZE.TINY,
      "line-height": "140%",
      },
      400:{
      "font-weight": FONT_WEIGTH.MEDIUM,
      "font-size": FONT_SIZE.TINY,
      "line-height": "140%",
      }
    },
  },
  CAPTION: {
    CAPTION_SEMI_BOLD:{
      "font-weight": FONT_WEIGTH.HUGE,
      "font-size": FONT_SIZE.LITTLE,
      "line-height": "140%"
    },
    CAPTION_SEMI_REGULAR:{
      "font-weight": FONT_WEIGTH.MEDIUM,
      "font-size": FONT_SIZE.LITTLE,
      "line-height": "140%"
    }
    }
 } as const