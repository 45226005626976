import styled, { css } from "styled-components";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";
import { FONT } from "../../constants/fontConstants";

interface IModal {
  transparent: boolean;
  width?: string | number;
  reduceModal?: boolean;
  fill?: boolean;
}

export const Blur = styled.div<IModal>`
  display: flex;

  position: fixed;

  top: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  width: 100svw;
  height: 100dvh;

  backdrop-filter: ${({ transparent }) => transparent ? '' : 'blur(5px)'};
  z-index: 9999;
  `;

export const Container = styled.div<IModal>`
  display: flex;
  flex-direction: column;

  align-items: center;

  position: absolute;

  width: ${({transparent}) => transparent ? '302px' : '421px' };
  height: max-content;

  padding: 0px 24px;
  padding-bottom: 24px;

  background-color: ${NEUTRAL_COLORS.WHITE};

  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  ${({width}) => typeof width === 'number' ? css`width: ${width}px; ` : css`width: ${width}; `}

  @media (min-width: 1280px) and (max-width: 1768px) and (max-height: 650px) {
    height: ${({reduceModal}) => reduceModal ? 'max-content' : 'calc(100vh - 112px)'} ;
  }

  @media (max-width: 768px) {
    ${({fill}) => fill && css`
    width: calc(100% - 20px);
    height: 100%;
      h5{
        padding-left: 8px;
      }
    `};

  }
  `;

interface IHead {
  bottomLine?: boolean;
}

export const Head = styled.div<IHead>`
  display: flex;
  flex-direction: row;

  position: relative;

  width: 100%;

  /* padding: 10px 0px; */
  height: 60px;

  align-items: center;
  justify-content: space-between;


  ${({bottomLine}) => bottomLine ? css`::after {
    content: "";
    display: block;
    position: absolute;

    margin-top: 66px;

    width: calc(100% + 48px);

    margin-left: -24px;

    height: 1px;

    background-color: ${NEUTRAL_COLORS.GRAY};

    margin-bottom: 12px;
  }
  ` : ''}

  position: sticky;
  top: 0;

  z-index: 100;

  background-color: ${NEUTRAL_COLORS.WHITE};
`;

export const CloseButton = styled.button`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;


  background-color: transparent;

  border: none;
  outline: none;
  cursor: pointer;

  margin-top: -5px;

  height: 40px;

  /* &:hover {
    svg {
      fill: ${NEUTRAL_COLORS.DARK_GRAY};
    }
  } */

  margin-right: 8px;
`;

export const Title = styled.h5`
  ${FONT.BODY.BODY_MEDIUM[600]}
  color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

type ContentProps = {
  fill?: boolean;
  center?: boolean;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;

  position: relative;

  row-gap: 32px;

  width: 100%;
  @media (min-width: 1280px) and (max-width: 1768px) {
    padding: 0px 18px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media (max-width: 768px) {
    ${({fill,center}) => fill && center && css`
    width: calc(100% - 20px);
    height: 100%;
    align-items: center;
    justify-content: center;
    `};
  }

`;