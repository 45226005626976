import fetchAxios from "services/http";
import { ReservesProps } from "../Reservations";
import { formatDate } from "utils/dateReserveFormatter";

export const fetchReservations = async (token: string, idReserve: string): Promise<ReservesProps> => {
    const { data } = await fetchAxios.get('/reservers/listReserver', {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const newReservationObject: ReservesProps[] = data.map((item: any) => {

        return {
            id: item.idBooking,
            address: {
                street: item.address,
                number: item.street_number,
                area: item.areaBuilding,
                town: item.town,
                state: 'PR'
            },
            accommodation: item.accommodation,
            initialDate: formatDate(item.arrival_date),
            finalDate: item.departure_date,
            type: item.statusPayment,
            paymentType: item.type,
            image: 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/1280x1080/filters:quality(65)/' + item.featured_image,
            guests: item.adults_number,
            totalValue: item.value_total,
            title: item.name_ad,
            streetNumbe: item.street_number,
            ref_stays: item.accRefStays,
            idBuilding: item.building_yogha,
            dateEdit: item.dateReserver,
            checkinCode: item.token,
            statusCheckin: item.statusCheckin,
            paymentId: item.id_asaas_charge,
            createReserver: item.createReserver
        }
    })

    const [filteredReserve] = newReservationObject.filter((item) => item.id === idReserve)

    return filteredReserve;
};