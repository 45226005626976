import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

const background = require('assets/image/owner/sliderbackground.png');

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1366px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
    `;

export const PortraitCarousel = styled.div`
  position: relative;
  padding: 0px 80px;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-start;
`;

export const BorderPortrain = styled.div`
position: absolute;
width: 1200px;
outline: 8px solid white;
border-radius: 16px;
inset: 0;
pointer-events: none;
align-self: center;
justify-self: center;
`;

export const BorderRight = styled.div`
position: absolute;
width: 346px;
height: 100%;
top: 0;
right: calc(100% - 344px);
background-color: white;
pointer-events: none;
align-self: center;
justify-self: center;
margin:0px 64px;
margin-left: calc(64px + 16px);
pointer-events: none;

`;

export const BorderLeft = styled.div`
position: absolute;
width: 350px;
height: 100%;
top: 0;
right: calc(100% - 1434px);
background-color: white;
pointer-events: none;
pointer-events: none;
`;

export const CarouselContent = styled.div`
    display:flex;
    width: 100%;
    transition: all 1s ease-in-out;
    margin: 0;
    padding: 0;
    border-radius: 10px;
`;

export const CarouselItem = styled.div`
    display: flex;

    width: 1206px;

    align-items: center;
    justify-content: center;
`;

export const ButtonCarouselLeft = styled.button`
    display: flex;
    align-items: center;

    width: 48px;
    height: 48px;

    align-items: center;
    justify-content: center;

    border:none;
    border-radius: 50%;

    position: absolute;
    top:120px;
    left: 220px;

    cursor: pointer;
    background-color: ${NEUTRAL_COLORS.WHITE};
`;

export const ButtonCarouselRight = styled(ButtonCarouselLeft)`
    rotate: 180deg;
    left:unset;
    right: 220px;
`;

export const BackgroundContainer = styled.div`
    display: flex;

    flex-direction: column;

    width: calc(638px - 32px);
    justify-content: center;
    height: calc(242px - 32px);

    padding: 32px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${background});

    border-radius: 16px;

    h3{
        ${FONT.BODY.BODY_LARGE[400]};
        color: ${NEUTRAL_COLORS.WHITE};
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    strong{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.WHITE};
    }
    justify-content: space-between;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;

    gap: 8px;

    p{
        color: ${NEUTRAL_COLORS.WHITE} !important;
    }
`;


export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    padding-bottom: 4px;
    `;

export const ButtonLeft = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 69px;
  background: ${NEUTRAL_COLORS.WHITE};
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  `;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotate(180deg);
  `;

export const Bullet = styled.div`
width: 8px;
height: 8px;

margin-top: 16px;

border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    gap: 32px;
    `;