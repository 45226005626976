import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

const backgroundEcosystem = require('assets/image/developers/background/Background1.png');
const backgroundEnterprise = require('assets/image/developers/background/Background2.png');
const backgroundProjects = require('assets/image/developers/background/Background3.png');
const backgroundDevelopers = require('assets/image/developers/background/Background4.png');
const backgroundExpertise = require('assets/image/developers/background/Background5.png');
const backgroundDepositions = require('assets/image/developers/background/Background6.png');
const backgroundForms = require('assets/image/developers/background/Background7.png');

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    `;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1366px;

    margin: 0 auto;

    align-items: center;

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    @media (max-width: 768px) {
        max-width: calc(100% - 40px);
    }
`;

export const CardEcosystem = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    padding: 16px;

    width: calc(324px - 32px);
    height: calc(224px - 32px);

    gap:16px;

    border-radius: 16px;

    background: rgba(0, 0, 0, 0.40);

    backdrop-filter: blur(14px);

    text-align: center;

    img{
        width: 48px;
        height: 48px;
    }

    p{
        ${FONT.BODY.BODY_MEDIUM[500]};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    @media (max-width: 768px) {
        width: calc(100% - 84px);
        p{
        ${FONT.BODY.BODY_SMALL[500]};
    }
    }
`;

export const CardEcosystemContainer = styled.div`
    display: flex;

    gap: 40px;

    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const DevelopersContent = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    h2{
        ${FONT.HEADLINES.H4};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    span{
        color: ${PRIMARY_COLORS.MEDIUM};
    }

    max-width: 1366px;
    width: 100%;

    @media (max-width: 768px) {
        max-width: unset;

        h2{
            ${FONT.BODY.BODY_LARGE[600]};
        }
        z-index: 2;
    }
    `;

export const DevelopersHeader = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    margin-top: 38px;
    margin-bottom: 64px;

    h3{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    row-gap: 16px;
    @media (max-width: 768px) {
        width: calc(100% - 40px);
        text-align: center;
    }
    `;

export const EcossystemContent = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    max-width: 1366px;
    width: 100%;

    text-align: center;

    h1{
        max-width: 955px;
        ${FONT.HEADLINES.H3};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    p{
        max-width: 720px;
    }
    row-gap: 96px;

    @media (max-width: 768px) {
            row-gap: 40px;
    }
    `;

export const ExpertiseContent = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    max-width: 1366px;
    width: 100%;

    @media (max-width: 768px) {
        z-index: 2;
    }
`;


export const ExpertiseHeader = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    h1{
        ${FONT.HEADLINES.H4};
        color: ${NEUTRAL_COLORS.WHITE};
    }
    span{
        color: ${PRIMARY_COLORS.MEDIUM};
    }
    p{
        font-size: 20px;
    }

    row-gap: 16px;

    margin-bottom: 96px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        text-align: center;
        h1{
            ${FONT.BODY.BODY_LARGE[600]};
        }
        p{
            ${FONT.BODY.BODY_SMALL[400]};
        }
        margin-bottom: 40px;

    }
`;

export const Footerbaseboard = styled.ul`
    display: flex;
    flex-direction: column;

    margin-block: 32px 64px;

    list-style: circle;
    color: ${NEUTRAL_COLORS.WHITE};

    li{
        list-style: disc;
        ${FONT.BODY.BODY_SMALL[400]};
    color: ${NEUTRAL_COLORS.WHITE};

    }

    span{
        color: ${PRIMARY_COLORS.MEDIUM};
    }

    margin-left: 16px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        margin:0px;

        margin-right: -20px;
        margin-left: 12px;

        margin-block: 32px 64px;
    }
    `;

export const EcossystemHeader = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;



    align-items: center;

    @media (max-width: 768px) {
        width: calc(100% - 40px);

        h1{
            ${FONT.BODY.BODY_LARGE[600]};
        }
        p{
        ${FONT.BODY.BODY_SMALL[400]};
        }
    }
    `;

export const Ecossystem = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    width: calc(100vw - 6px);
    height: 100vh;

    background-image: url(${backgroundEcosystem});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center ;

    @media (max-width: 768px) {
    height: max-content;

    padding-top: 32px;
    padding-bottom: 80px;
    }

`;

export const Enterprise = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    width: calc(100vw - 6px);
    height: 100vh;

    background-image: url(${backgroundEnterprise});
    background-repeat: space;
    background-size: cover;
    background-position: bottom;



    @media (max-width: 768px) {
        width: 100%;
        padding: 0 20px;

        flex-direction: column;
        height: max-content;

        background-color: ${NEUTRAL_COLORS.HIGH_DARK};
        background-image: linear-gradient(
        to bottom,
        #2c1f44 0%,
        #2c1f4460 20%,
        #191919 100%
    );
        padding-bottom: 80px;
    }
`;

export const Projects = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: calc(100vw - 6px);
    height: 100vh;

    background-image: url(${backgroundProjects});
    background-repeat: space;
    background-size: cover;
    background-position: top;


    h5{
        font-size: 32px;
        color: ${NEUTRAL_COLORS.WHITE};
        margin-bottom: 16px;
    }
    span{
        color: ${PRIMARY_COLORS.MEDIUM};
        font-weight: 600;
    }

    row-gap: 48px;

    @media (max-width: 768px) {
    position: relative;
    height: max-content;

    h5{
        ${FONT.BODY.BODY_LARGE[600]};
        z-index: 2;
    }
    padding-bottom: 80px;
    }
`;

export const LinearGradient = styled.div`
    visibility: hidden;
    @media (max-width: 768px) {
    visibility: visible;
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100vw - 6px);
    height: 100vh;
    background-image: linear-gradient(
        to bottom,
        #191919 0%,
        #00000010 100%
    );
    }
`;

export const LinearGradientExpertise = styled.div`
    visibility: hidden;
    @media (max-width: 768px) {
    visibility: visible;
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100vw - 6px);
    height: 100%;
    background-image: linear-gradient(
        to top,
        #191919 0%,
        #00000010 100%
    );
    }
`;

export const LinearGradientDevelopers = styled.div`
    visibility: hidden;
    @media (max-width: 768px) {
    visibility: visible;
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100vw - 6px);
    height: 100%;
    background-image: linear-gradient(
        to bottom,
        #452a1e10 0%,
        #251c1b 100%
    );
    }
`;
export const Developers = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: calc(100vw - 6px);
    height: 100vh;

    background-image: url(${backgroundDevelopers});
    background-repeat: space;
    background-size: cover;
    background-position: top;

    @media (max-width: 768px) {
        position: relative;
        height: max-content;
        padding-top: 64px;
        padding-bottom: 64px;
    }
    `;

export const Expertise = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: calc(100vw - 6px);
    height: 100vh;

    background-image: url(${backgroundExpertise});
    background-repeat: space;
    background-size: cover;
    background-position: top;

    @media (max-width: 768px) {
    height: max-content;
    position: relative;

    padding-top: 80px;
    padding-bottom: 52px;

    background-position: right center;
    background-size: cover;
    }
    `;

export const Depositions = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: calc(100vw - 6px);
    height: 100vh;

    background-image: url(${backgroundDepositions});
    background-repeat: space;
    background-size: cover;
    background-position: top;
    @media (max-width: 768px) {
    height: unset;
    padding-top: 64px;
    }
`;

export const Forms = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: calc(100vw - 6px);
    height: 100vh;
    min-height: 960px;

    background-image: url(${backgroundForms});
    background-repeat: space;
    background-size: cover;
    background-position: top;

    @media (max-width: 768px) {
    width: calc(100%);
    height: max-content;

    padding: 80px 20px;
    }
`;

export const InputContainer = styled.div`
    width: 380px;
    @media (max-width: 768px) {
        width: calc(100% + 40px) ;
    }
`;

export const FormsContent = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 1366px;

    align-items: start;
    justify-content: center;

    @media (max-width: 768px) {
    width: calc(100% - 40px);
    max-width: unset;

                }
`;

export const FormsHeader = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 600px;

    h1{
        ${FONT.HEADLINES.H4};
        color: ${NEUTRAL_COLORS.WHITE};
    }
    span{
        color: ${PRIMARY_COLORS.MEDIUM};
    }

    margin-bottom: 80px;

    @media (max-width: 768px) {
        h1{
            ${FONT.BODY.BODY_LARGE[600]};
        }
    }
    `    ;

export const DepositionsHeader = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;
    text-align: center;

    h5{
        ${FONT.HEADLINES.H5};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    margin-bottom: 48px;

    @media (max-width: 768px) {
        h5{
            ${FONT.BODY.BODY_LARGE[600]};
        }
    }
    `;

export const EnterpriseCollumnImage = styled.div`
        margin-top: -28px;
        @media (max-width: 768px) {
            img{
                height: 335px;
                padding-bottom: 32px;
            }
        }
`;

export const EnterpriseCollumnText = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 56px;
    @media (max-width: 768px) {
        order: 1;
    }
`;

export const GridEnterprise = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 16px 32px;

    place-content: center;
    place-items: center;

`;

export const HeaderEnterprise = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 480px;

    row-gap: 16px;

    h1{
        ${FONT.HEADLINES.H5};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    @media (max-width: 768px) {
        text-align: center;
        h1{
            ${FONT.BODY.BODY_LARGE[600]};
        }
        p{
        ${FONT.BODY.BODY_SMALL[400]};
        }
    }
`;

export const ItensEnterprise = styled.div`
    display: flex;

    gap: 10px;

    max-width: 270px;
    width: 100%;

    align-items: center;

    padding-block: 10px;

    div{
        display: flex;

        align-items: center;
        justify-content: center;

        background-color:${PRIMARY_COLORS.MEDIUM};

        min-height: 40px;
        min-width: 40px;

        border-radius: 8px;
    }
    @media (max-width: 768px) {
        max-width: (159px - 20px);

        flex-direction: column;
        text-align: center;
        p{
            width: calc(100% - 32px);
        }
    }
`;



export const HeaderProject = styled.div`
    display: flex;
    flex-direction: column;

    text-align: center;

    h1{
        ${FONT.HEADLINES.H3};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    max-width: 852px;

    gap:16px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        max-width: unset;
        text-align: center;
        h1{
            ${FONT.BODY.BODY_LARGE[600]};
    }
    z-index: 2;
    }
`;

export const GridProjects = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap:32px;

    place-content: center;
    place-items: center;
    @media (max-width: 768px) {
    grid-template-columns: 1fr;

    gap:24px;

    z-index: 2;
    }
`;

export const CardProject = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    max-width: 373px;
    width: 100%;
    max-height: 152px;
    height: 100%;

    gap:16px;

    padding: 24px 54px;

    border-radius: 16px;

    background: rgba(22, 22, 22, 0.60);

    backdrop-filter: blur(6px);

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${NEUTRAL_COLORS.WHITE};
    }
    @media (max-width: 768px) {
    max-width: calc(100% - 32px - 40px);
    max-height: unset;
    height: 196px;

    padding: 16px 16px;

    }
`;

export const GridExpertise = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;

    gap: 60px;

    & > div:nth-child(2){
        position: relative;
        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: -29px;
            width: 1px;
            height: 100%;
            background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

        }
        &::after{
            content: "";
            position: absolute;
            top: 0;
            right: -29px;
            width: 1px;
            height: 100%;
            background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

        }

    }

    @media (max-width: 768px) {
    grid-template-columns: auto;

    gap: 64px;

    & > div:nth-child(2){
        position: relative;
        &::before{
            content: "";
            position: absolute;
            top: -16px;
            left: 0px;
            width: 100%;
            height: 1px;
            background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

        }
        &::after{
            content: "";
            position: absolute;
            top: calc(100% + 16px);
            right: 0px;
            width: 100%;
            height: 1px;
            background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

        }}


    }
`;

export const CardExpertise = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    width: 258px;
    height: 158px;

    gap: 12px;

    text-align: center;

    span{
        ${FONT.HEADLINES.H1}
        color: ${PRIMARY_COLORS.MEDIUM};
    }
    p{
        ${FONT.BODY.BODY_LARGE[400]};
    }
`;

export const ColumnDevelopers = styled.div`
    display: flex;
    flex-direction: column;

    gap: 24px;

    align-items: center;

    margin-bottom: 64px;

`;

export const RowDevelopers = styled.div`
    display: flex;

    gap: 16px;

    align-items: center;

    @media (max-width: 768px) {
        width: calc(100% - 20px);
        gap: 8px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        p{
            ${FONT.CAPTION.CAPTION_SEMI_REGULAR};
        }
    }
`;
