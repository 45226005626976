import { FONT } from "constants/fontConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    row-gap: 64px;

    @media (max-width: 768px) {
        width: 100%;
        row-gap: 32px;
        margin-top: -64px;
    }

`;

export const Title = styled.h1`
    ${FONT.HEADLINES.H4};

    @media (max-width: 768px) {
        ${FONT.BODY.BODY_LARGE[600]};
        text-align: center;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 32px;
`;
