import { useLocalStorageState } from 'hooks/useLocalStorageState';
import React, { createContext, useContext } from 'react';

export type PaymentContextType = {
    typePayment: string;
    setTypePayment: (newState: string) => void;
};

export const PaymentTypeContext = createContext<PaymentContextType | null>(null);

export const PaymentTypeProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [typePayment, setTypePayment] = useLocalStorageState('typePayment', '');

    const contextValue: PaymentContextType = {
        typePayment,
        setTypePayment,
    };

    return (
        <PaymentTypeContext.Provider value={contextValue}>
            {children}
        </PaymentTypeContext.Provider>
    );
};

export const usePaymentTypeProvider = (): PaymentContextType => {
    const context = useContext(PaymentTypeContext);
    if (!context) {
        throw new Error('useUserProvider must be used within a UserProvider');
    }
    return context;
};
