import styled, { css } from "styled-components";
import { NEUTRAL_COLORS } from "../../../../constants/styleConstants";

interface IProps {
    size: number;
}

export const Container = styled.div<IProps>`
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    place-content: center start;
    ${({size}) => size <= 3 ? css`
        grid-template-rows: repeat(1, 1fr);
        height: 58px;
        ` : css` grid-template-rows: repeat(2, 1fr);
                height: 132px;
                `

}
    gap: 14px;

    width: 651px;

    @media (max-width: 768px) {
        margin-top: 24px;
        grid-template-columns: repeat(2, calc(100% / 2));
        width: 100%;
        height: fit-content;
    }

    `;

export const Amenity = styled.div`
    display: flex;
    flex-direction: row ;

    max-width: 206px;
    height: 56px;

    padding: 0px 20px;

    gap: 16px;

    align-items: center;
    justify-content: flex-start;

    border: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    border-radius: 10px;

    white-space: nowrap;

    @media (max-width: 768px) {
        max-width: unset;
        width: fit-content(100%);
        padding: 0px 12px;
    }


`;

export const AmenityText = styled.div`
    display: flex;
    flex-direction: row;

    gap: 4px;
    `