import styled from "styled-components";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 713px;
  height: 452px;
  justify-content: center;
  align-items: center;
  justify-self: center;
  overflow: hidden;
  position: relative;
  background-color: #f6f4f4;
    `;



export const PortraitCarousel = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-start;
`;

export const BorderPortrain = styled.div`
position: absolute;
width: 713px;
height: 452px;
border-radius: 16px;
inset: 0;
pointer-events: none;
align-self: center;
justify-self: center;
`;

export const BorderRight = styled.div`
position: absolute;
width: 146px;
height: 490px;
top: 0;
right: calc(100% - 144px);
background-color:transparent;
pointer-events: none;
align-self: center;
justify-self: center;
margin:0px 64px;
margin-left: calc(64px + 16px);
pointer-events: none;
`;

export const BorderLeft = styled.div`
position: absolute;
width: 150px;
height: 490px;
top: 0;
right: calc(100% - 1430px);
background-color: transparent;
pointer-events: none;
align-self: center;
justify-self: center;
pointer-events: none;
`;

export const CarouselContent = styled.div`
    display:flex;
    width: 100%;
    height: 488px;
    transition: all 1s ease-in-out;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    img{
        pointer-events: none;
        position: relative;
        border-radius: 10px;
        height: 488px;
        object-fit: contain;
    }
`;


export const CaroselImage = styled.div`
    width: 1206px;
    /* @media (min-width: 1660px) {
        margin: 0px 13%;
    }
    @media (min-width: 1870px) {
        margin: 0px 17%;
    }
    @media (min-width: 2000px) {
        margin: 0px 24%;
    }
    @media (min-width: 2400px) {
        margin: 0px 29%;
    }
    &:first-child {
        margin-left: 0px;
    } */
    `;

export const ButtonLeft = styled.button`
  cursor: pointer;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #f6f4f4;
  border-radius: 29px;
  inset: 0;
  top: 238px;

  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  left: calc(0% - 0px); ;
  `;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotate(180deg);
  left: calc(100% - 40px);
  `;


export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    gap: 32px;
    `;

