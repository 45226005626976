import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import { ImageCarousel } from '../ImageCarousel';
import { Logo } from '../Logo';
import { NavBar } from '../NavBar';
import { User } from '../User';
import { useAnyProvider } from '../../hooks/Context';
import { useSpaProvider } from '../../hooks/spaRouterContext';
import { Blur, GlobalContainer, HeaderContainer, HidedContainer, LeftContainer, NavContainer, RightContainer } from './styles';

import Skeleton from 'react-loading-skeleton';
import { useHeader } from './useHeader';
import { AutoCompleteInput } from 'components/AutoCompletInput';
import { useAutoCompletInput } from 'components/AutoCompletInput/useAutoCompletInput';
import { useSessionStorage } from 'context/sessionStorage';
import { CalendarComponent } from 'components/Calendar';
import dayjs from 'dayjs';
import { GuestSelectorHeader } from './components/GuestSelectorHeader';
import { useGuestHeaderStore } from './components/useGuestHeaderStorage';

export interface Building {
  id: string
  name: string
  town: string
  area: string
  postal_code: string
  address: string
  street_number: string
  nearby_landmark: string,
  nearby_landmark_distance: string,
  payment: number
  // parking: string
  // welcome_message: string
  // parking_vagas: any
  active: boolean
  ref_stays: string
  property_type: string
  status_property: any
  latitude: string
  longitude: string
  description: string
  // common_area: any
  country_id: number
  state_id: string
  building_id: number
  // amenities: any
  // order: number
  link_doc: string
  minValueAccommodation: number
  building_yogha: number
  valor_mais_barato: number
}


interface HeaderProps {
  paddingHeader?: boolean;
  getProperties?: any;
  setShouldFocus?: (arg0: boolean) => void
  shouldFocus?: boolean
}

export const Header = ({ paddingHeader, getProperties, setShouldFocus, shouldFocus }: HeaderProps) => {
  const [isOpenAutoComplete, setIsOpenAutoComplete] = useState(false)
  const { removeFromSessionStorage } = useSessionStorage()
  const [inputSearchValue, setInputSearchValue] = useState<string>('')
  const [inputTypeSearchValue, setInputTypeSearchValue] = useState<string>('')
  const [date, setDate] = useState<Date[] | null>([])

  const { visible, setExpanded, expanded, setPageChosen, pageChosen } = useSpaProvider();

  const { setSearchValue, anyValue, setSearchType, setAutoCompletSearch } = useAnyProvider();

  const { totalGuests } = useGuestHeaderStore();

  const checkInput = (e: any) => {
    setInputSearchValue(e);
    if (e !== '') {
      setErrorSearch(false);
    } else {
      setErrorSearch(true);
    }
  };

  const { carouselTitle, array, buildings, closeCalendar, globalContainerRef, isFetching, isLoading, mounted, promotionalUnits, units, address, setErrorSearch } = useHeader({ getProperties });

  const searchIcon = require('../../assets/svg/MagnifyingGlass.svg').default;

  function handleCarousel() {
    switch (pageChosen) {
      case 0: {
        return <ImageCarousel type='default' hasFrom card='building' key={`carousel-${pageChosen}`} titleCarousel={carouselTitle} data={units} />;
      }
      case 1: {
        return <ImageCarousel type='default' hasFrom card='unit' key={`carousel-${pageChosen}`} titleCarousel={carouselTitle} data={promotionalUnits} />;
      }
      case 2: {
        return <ImageCarousel type='unit' hasFrom card='city' key={`carousel-${pageChosen}`} titleCarousel={carouselTitle} data={buildings} />;
      }
      // case 3: {
      //   return <ImageCarousel type='unit' hasFrom card='unit' key={`carousel-${pageChosen}`} titleCarousel={carouselTitle} data={buildings} />;
      // }
      default: {
        return null;
      }
    }
  }

  const navigate = useNavigate();

  const carousel = handleCarousel()

  const { pressEnterSelectOption } = useAutoCompletInput({ setInputSearchValue: (e: any) => checkInput(e), inputSearchValue, getProperties: getProperties, isOpen: true, setInputTypeSearchValue, date })

  const handleButtonClick = () => {
    if (inputSearchValue === '') {
      setSearchValue('Curitiba')
      setSearchType('city')
      if (date && date?.length > 0 && date[0] && date[1]) {
        navigate('/properties/?search=Curitiba&type=city&check_in=' + dayjs(date[0]).format('YYYY-MM-DD') + '&check_out=' + dayjs(date[1]).format('YYYY-MM-DD'))
        setAutoCompletSearch(dayjs(date[0]).format('YYYY-MM-DD') + dayjs(date[1]).format('YYYY-MM-DD') + totalGuests)
      } else {
        navigate('/properties/?search=Curitiba&type=city_building')
      }
      setExpanded(true);
    } else {
      setErrorSearch(false)
      pressEnterSelectOption(new MouseEvent("click"))
      setSearchType(inputTypeSearchValue)
      setSearchValue(inputSearchValue)
    }
  };

  const backToHome = () => {
    setDate([])
    removeFromSessionStorage('dateHeader')
    navigate('/')
  }

  return (
    <>
      {visible && <Blur expanded={expanded}>
        <GlobalContainer mounted={mounted} onClick={closeCalendar} ref={globalContainerRef} expanded={expanded}>
          <HeaderContainer expanded={expanded}>
            <LeftContainer pageResults={paddingHeader}>
              <div onClick={backToHome} style={{ marginTop: expanded ? '-4px' : '0px' }}>
                <Logo />
              </div>
              <div style={{ display: 'flex', marginLeft: '48px', gap: 16 }}>
                <div style={{ marginTop: expanded ? '0px' : '0px' }} onClick={() => setExpanded(false)}>
                  <AutoCompleteInput isOpen={isOpenAutoComplete} setIsOpen={setIsOpenAutoComplete} getProperties={getProperties} setShouldFocus={setShouldFocus} shouldFocus={shouldFocus} expanded={expanded} widthDrop={266} hasResult={'has'} onChange={() => { }} placeholder={'Busque por cidade, unidade, bairro...'} inputSearchValue={inputSearchValue} setInputSearchValue={(e: any) => checkInput(e)} options={address} setInputTypeSearchValue={setInputTypeSearchValue} />
                </div>
                <HidedContainer mounted={mounted} expanded={expanded}>
                  {!expanded && <CalendarComponent date={date} setDate={setDate} />}
                  {!expanded && <GuestSelectorHeader />}
                </HidedContainer>
                <div style={{ marginLeft: expanded ? '-14px' : '0px' }}>
                  <Button title='Buscar' icon={searchIcon} iconDirection='left' iconHeight={24} iconWidth={24} size='large' type='primary' disabled={false} onClickButton={() => handleButtonClick()} />
                </div>
              </div>
            </LeftContainer>
            {expanded && <RightContainer pageResults={paddingHeader}>
              <User />
            </RightContainer>}
          </HeaderContainer>

          {!expanded && (isFetching || isLoading) ? (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              maxWidth: 1208,
              justifyContent: 'flex-start',
              marginTop: 24,
              marginBottom: 40,
              overflow: 'hidden',
            }}>
              <div style={{
                display: 'flex',
                width: '100%',
                gap: 16,
              }}>
                <Skeleton width={120} height={20} borderRadius={10} />
                <Skeleton width={90} borderRadius={10} height={20} />
                <Skeleton width={80} borderRadius={10} height={20} />
              </div>
              <Skeleton width={310} borderRadius={10} height={28} style={{
                marginBlock: '34px 16px'
              }} />
              <div style={{
                display: 'flex',
                gap: 32
              }}>
                {
                  Array.from({ length: 4 }).map((_, index) => {
                    return (
                      <div key={index}>

                        <Skeleton width={310} borderRadius={10} height={192} />
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 14,
                          marginTop: 22
                        }}>
                          <Skeleton width={228} borderRadius={10} height={13} />
                          <Skeleton width={142} borderRadius={10} height={13} />
                        </div>
                        <div style={{
                          display: 'flex',
                          gap: 14,
                          flexDirection: 'column',
                          marginTop: 22
                        }}>
                          <Skeleton width={118} borderRadius={10} height={16} />
                          <Skeleton width={185} borderRadius={10} height={13} />
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          ) : <>
            {!expanded &&
              <NavContainer>
                <NavBar selected={pageChosen} setSelected={setPageChosen} />
              </NavContainer>
            }
            {
              !expanded && <div className='container'>
                <div >
                  {carousel}
                </div>
              </div>
            }
          </>}
        </GlobalContainer>
      </Blur>
      }
    </>
  );
};
