import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { useHistoryStack } from 'hooks/useHistory';
import { useSessionStorage } from 'context/sessionStorage';
import { useAnyProvider } from 'hooks/Context';

const logo = require('assets/image/logo.png');
const arrowLeft = require('assets/svg/buttons/ArrowLeft.svg').default;

export const HeaderAlt = () => {
  const navigate = useNavigate()
  const {  setDate } = useAnyProvider();

  const { popFromHistory, historyStack } = useHistoryStack()
  const {  removeFromSessionStorage } = useSessionStorage()

  const pathname = window.location.pathname.includes('payment')
  const handleBackButton = () => {
    const lastRoute = popFromHistory();
    if (lastRoute && !pathname) {
      navigate(lastRoute);
    } else {
      navigate(-1);
    }
  };
  const backToHome = () => {
    setDate([])
    removeFromSessionStorage('dateHeader')
    navigate('/')
  }
  return (
    <S.Header>
      <S.NavigateButton onClick={handleBackButton}>
        <img src={arrowLeft} alt="" />
      </S.NavigateButton>
      <S.LogoContainer onClick={backToHome}>
        <img src={logo} alt="" />
      </S.LogoContainer>
    </S.Header>
  )
}