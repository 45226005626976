import styled from "styled-components";
import { NEUTRAL_COLORS } from "../../../../constants/styleConstants";
import { FONT } from "../../../../constants/fontConstants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 11px;

    padding: 16px 0px;
    padding-left: 32px;
    padding-right: 12px;

    max-width:calc(380px - 32px - 12px);
    width: 100%;
    height: calc(132px - 32px);

    text-align: left;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    h1{
        ${FONT.BODY.BODY_SMALL[600]}
    }
    p{
        ${FONT.BODY.BODY_SMALL[400]}
    }

    img{
        width:24px;
        height: 24px;
    }

    cursor: pointer;

    @media (width < 768px) {
        width: calc(100% - 40px);
        max-width:100%;
        padding: 16px;
        height: max-content;
    }
`;