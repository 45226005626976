import Skeleton from "react-loading-skeleton"

export const HomeSkeleton = () => {
    return (<div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <Skeleton width={1208} borderRadius={16} height={490} />
        <Skeleton width={160} borderRadius={10} height={48} style={{
            marginBlock: '24px 52px'
        }} />
        <div style={{
            display: 'flex',
            gap: 32,
            marginBottom: 85
        }}>
            {Array.from({ length: 3 }).map((_, index) => {
                return <Skeleton key={index} borderRadius={10} width={380} height={165} />
            })}
        </div>
        <div style={{
            width: 1206
        }}>
            <Skeleton width={310} borderRadius={10} height={28} style={{
                marginBlock: '34px 16px',
                justifySelf: 'flex-start'
            }} />
        </div>

        <div style={{
            display: 'flex',
            gap: 32,
            maxWidth: 1208,
            overflow: 'hidden',
            marginBottom: 84
        }}>

            {
                Array.from({ length: 4 }).map((_, index) => {
                    return (
                        <div key={index}>

                            <Skeleton width={310} borderRadius={10} height={192} />
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 14,
                                marginTop: 22
                            }}>
                                <Skeleton width={228} borderRadius={10} height={13} />
                                <Skeleton width={142} borderRadius={10} height={13} />
                            </div>
                            <div style={{
                                display: 'flex',
                                gap: 14,
                                flexDirection: 'column',
                                marginTop: 22
                            }}>
                                <Skeleton width={118} borderRadius={10} height={16} />
                                <Skeleton width={185} borderRadius={10} height={13} />
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </div>)
}