import { extend } from 'leaflet';
import styled from 'styled-components';

export const Container = styled.div`
margin: 0;
`;

interface Props extends React.HTMLAttributes<HTMLImageElement>{}

export const Image = styled.img<Props>`
  width: ${({width}) => width ? `${width}px` :  '112px'} ;
  height: ${({height}) => height ? `${height}px` :  '40px'};
  cursor: pointer;
`;