import ReactDOM from 'react-dom/client';
import App from './App';
import { AnyProvider } from './hooks/Context';
import { SpaProvider } from './hooks/spaRouterContext';
import { UserProvider } from './hooks/userContext';
import GlobalStyle from './styles/style';
import './i18n';
import { SessionStorageProvider } from 'context/sessionStorage';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const helmetContext = {};


root.render(
  <div translate="no">
    <HelmetProvider context={helmetContext}>
      <SessionStorageProvider>
        <UserProvider>
          <AnyProvider>
            <SpaProvider>
              <GlobalStyle />
              <App />
            </SpaProvider>
          </AnyProvider>
        </UserProvider>
      </SessionStorageProvider>
    </HelmetProvider>
  </div>

);

