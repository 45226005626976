import styled from "styled-components";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "../../../../constants/styleConstants";
import { Link } from "react-router-dom";

export const SessionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 1206px;

    position: relative;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: calc(100% - 40px);
        padding: 0px 20px
    }

`;

export const Separator = styled.div`
    ::after{
        content: '';

        display: block;

        width: 100%;
        height: 1px;

        background-color: ${NEUTRAL_COLORS.GRAY};
        margin-top: -16px;
    }
`;

export const FooterSession = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: -64px;
    @media (max-width: 768px) {
        padding-bottom: 96px;
    }
`;

export const ButtonContainerPayment = styled.div`
    display: flex;
    width: 100%;

    position: sticky;
    bottom: 0px;
    background-color: ${NEUTRAL_COLORS.WHITE};
    margin-top: -16px;
    padding-top: 16px;
`;

export const TabNavSessions = styled.div<{visible:boolean}>`
    display: ${({visible}) => visible ? 'flex' : 'none'};
    flex-direction: row;

    width: 100%;
    height: 86px;

    align-items: center;
    justify-content:center;

    border-bottom: 1px solid ${NEUTRAL_COLORS.GRAY};

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    background-color: ${NEUTRAL_COLORS.WHITE};

    z-index: 2000;

    `;

export const TabNavContent = styled.div`
    display: flex;
    flex-direction: row;


    width: 100%;
    height: 100%;
    max-width: 1206px;

    align-items: center;
    justify-content: space-between;

    gap: 16px;

    margin: 0 auto;
    padding: 0 32px;

    @media (max-width: 768px) {
        max-width: unset;
        padding: 0px;
    }

    `;

export const TabContainerButtons = styled.div`
    display: flex;

    gap:16px;
`;

export const ButtonTab = styled.button`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.LARGE};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
    background-color: transparent;
    border: none;

    cursor: pointer;
    `;

interface CupomProps{
    error:boolean;
}

export const CupomCodeContainer = styled.div<CupomProps>`
    display: flex;
    flex-direction: column;

    width: 100%;

    align-items: flex-start;
    justify-content: flex-start;

    row-gap: 8px;

    label{
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.LARGE};
        line-height: 140%;
        color:${NEUTRAL_COLORS.HIGH_DARK};
    }

    input{

        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.MEDIUM};
        line-height: 140%;
        color:${NEUTRAL_COLORS.HIGH_DARK};

        width: calc(100% - 16px);

        padding: 16px 0px;
        padding-left: 16px;

        border: 1px solid ${({error}) => error ? STATUS_COLORS.ERROR : PRIMARY_COLORS.MEDIUM};
        border-radius: 8px;

        outline: none;
    }

`;

export const CupomError = styled.div`
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    justify-content: flex-start;

    gap: 8px;

    p{
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.MEDIUM};
        line-height: 140%;
        color:${STATUS_COLORS.ERROR};
    }

    img{
        width: 15px;
        height: 21px;
    }

`;

export const CupomButtonContainer = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: end;

    width: 100%;

    gap:4px;
`;

export const PriceTab = styled.div`
    display: flex;
    flex-direction: row;

    justify-self: self-end;

    align-items: center;
    justify-content: flex-start;

    gap:32px;

    h1{
        font-size: ${FONT_SIZE.SMALL};
        font-weight: ${FONT_WEIGTH.HUGE};
        line-height: 140%;
        color:${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const ContentSession = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    width: 690px;

    row-gap: 64px;

    justify-content: flex-start;


    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const InitialSession = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 64px;

    @media (max-width: 768px) {
        width: 100%;
        row-gap: 0px;
    }
`;

export const ContainerReserveCard  = styled.div`
 margin-top: -64px;
 padding-bottom: 128px;
`;

export const Session = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;
    `;

export const SessionAmenities = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 64px;
`;

export const FirstRow = styled.div `
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    h1{
        ${FONT.BODY.BODY_MEDIUM[600]}
        color:${NEUTRAL_COLORS.HIGH_DARK}
    }

    @media (max-width: 768px) {
        width: 100%;
        h1{
            white-space: normal;
        }
    }

`;

export const ItensRows = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

interface ItemCollumnProps{
    modal?:boolean;
}

export const ItemCollumn = styled.div<ItemCollumnProps>`
    display: flex;
    flex-direction: column;

    width: 227px;

    gap: 8px;

    white-space: nowrap;

    padding-bottom: ${({modal}) => modal ? '8px' : '0px'};

    @media (max-width: 768px) {
        width: 100%;
    }

    `;

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    img{
        width: 16px;
        height: 16px;
    }
`;

export const Text = styled.span`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;

export const ItemUndestandValue = styled.span`
    ${FONT.BODY.BODY_MEDIUM[400]}
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;

export const WarningContainer = styled.div`
    display: flex;
    flex-direction: row;

    padding: 16px 16px 0px 0px;

    gap: 10px;

    cursor: pointer;
`;

export const WarningText = styled.span`
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140%;
    color:${PRIMARY_COLORS.MEDIUM};

    cursor: pointer;
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    ${FONT.BODY.BODY_MEDIUM[600]}
    color:${PRIMARY_COLORS.MEDIUM};

    cursor: pointer;

    padding: 16px 16px 0px 0px;

    gap: 10px;

    display: flex;

    align-items: center;
`;

export const AmenitiesCondominium = styled.div`
    display: grid;
    grid-template-columns: auto 288px;

    place-content: space-between space-between;
    place-items: space-between;

    width: 100%;

    gap: 32px;

    margin-top: 30px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    `

export const CondominiumItem = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 12px;
`;

export const TitleCondominiumItem = styled.div`
    display: flex;
    flex-direction: row;

    gap:16px;

    `
export const ContentCondominiumItem = styled.div`
    display: flex;
    flex-direction: column;
    p{
        font-size: 14px;
    }
    `

export const CardAccommodations = styled.div`
    display: flex;
    flex-direction: column;

    width: 119px;
    height: 126px;

    padding: 16px 24px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    align-items: flex-start;
    justify-content: flex-start;

    row-gap: 8px;

    h2{
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.HUGE};
        line-height: 140%;
        color:${NEUTRAL_COLORS.HIGH_DARK};
    }

    @media (max-width: 768px) {
        width: 100%;

    }
`;

export const CardAccommodationsIcons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;`

export const CollumAcoomodations = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    align-items: flex-start;

    padding: 16px;
`;


export const MapContainer = styled.div`
    display: flex;
    flex-direction: column;


    width: 100%;
    height: 500px;

    row-gap: 16px;

    h1{
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
    }

`;

export const PaymentContainer = styled.div`
   display: flex;
   flex-direction: column;

   width: 100%;

   row-gap: 16px;

   max-height: 358px;

   @media (max-width: 768px) {
        min-height: unset;
        max-height: unset;
        row-gap: 8px;
    }
`;

export const ButtonContainer = styled.div`

width: 100%;

@media (max-width: 768px) {

    display: flex;


    position: fixed;
    width: calc(100% - 48px );

    bottom: 20px;
    left: 50%;

    transform: translateX(-50%);
}

    @media (min-width: 1280px) and (max-width: 1768px) {
        background-color: ${NEUTRAL_COLORS.WHITE};
        position: absolute
    }
`;

export const ComoditiesButtonContainer = styled.div`
    display: flex;
    width: 100%;


    @media (min-width: 1280px) and (max-width: 1768px) {
        align-self: flex-end;
        justify-self: flex-end;
    }
`;

export const CleaningRow = styled.div`
    display: flex;

    gap: 8px;

    align-items: center;
    position: relative;
`;

export const ComoditiesContainer = styled.div`
   display: flex;
   flex-direction: column;

   width: 100%;

   row-gap: 16px;

    max-height: 358px;

    overflow: auto;
    padding-bottom: 4px;

    @media (max-width: 768px) {
        min-height: 125vmin;
        row-gap: 4px;
        padding-bottom: 0px;
    }

    @media (min-width: 1280px) and (max-width: 1768px) {
        min-height: unset;
        max-height: unset;
        height: 100%;
    }
`;

export const ContainerValues = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

    `;

export const LabelTotal = styled.label`
    ${FONT.BODY.BODY_MEDIUM[600]};
`;

export const RowValuesTotal = styled.div`
    ${FONT.BODY.BODY_MEDIUM[600]};

    width:fit-content;

    text-align: end;


`;

export const RowValues = styled.div<{include?:boolean}>`
    width:fit-content;



    text-align: end;

    border: 0;
    outline: none;

    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    ${({include}) => include &&`
        color: ${STATUS_COLORS.SUCCESS};
        `}
`;

export const Rows = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    span{
        ${FONT.BODY.BODY_MEDIUM[600]}
    }

    :has(span){
        ::after{
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: ${NEUTRAL_COLORS.GRAY};
        margin-top: 38px;
    }
    }
    `;

export const ModalTitle = styled.span`
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;