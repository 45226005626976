import * as S from './styles'

type TagProps = {
    title: string;
}

export const Tag = ({ title }:TagProps) => {
    return (
        <S.Container>
            <p>{title}</p>
        </S.Container>
    )
}