import * as S from './styles'
import { Controller, useForm } from "react-hook-form"
import { FooterWithButtons } from "../../components/FooterWithButtons"
import { Input, Inputs } from "components/Input"
import { IMaskInput } from "react-imask"
import { NewInput } from "components/NewInput"
import { RadioButton } from "components/RadioButton"
import { t } from 'i18next'
import { usePersonalInfoSchema } from 'pages/Checkin/schemas/PersonalInfo'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate, useParams } from 'react-router'
import { useSessionStorage } from 'context/sessionStorage'
import { useEffect, useState } from 'react'

interface EditAccompanyingProps {
    birthDate: string;
    documentNumber: string
    documentType: string;
    email: string;
    name: string;
    phone: string;
    surname: string;

}

export const EditAccompanying = () => {

    const { id } = useParams()

    const { omitCountryYouLive } = usePersonalInfoSchema();

    const [otherAccompanying, setOtherAccompanying] = useState<EditAccompanyingProps[]>([])

    type FormData = z.infer<typeof omitCountryYouLive>;

    const navigate = useNavigate();

    const pathname = window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/"))
    const removeLastPathname = pathname.lastIndexOf("/", pathname.lastIndexOf("edit") - 1)
    const pathnameAccompanying = pathname.slice(0, removeLastPathname)

    const { saveToSessionStorage, getFromSessionStorage } = useSessionStorage()

    const { handleSubmit, formState: { errors }, setValue, getValues, control, trigger, watch } = useForm<FormData>({
        resolver: zodResolver(omitCountryYouLive),
        defaultValues: {
            documentType: 'CPF'
        }
    });

    useEffect(() => {
        const data = getFromSessionStorage('accompanying')
        const filterAccompanying = data.filter((item: EditAccompanyingProps, index: number) => index === Number(id) - 1)
        if (filterAccompanying.length > 0) {
            setValue('documentType', filterAccompanying[0].documentType)
            setValue('documentNumber', filterAccompanying[0].documentNumber)
            setValue('name', filterAccompanying[0].name)
            setValue('surname', filterAccompanying[0].surname)
            setValue('birthDate', filterAccompanying[0].birthDate)
            setValue('phone', filterAccompanying[0].phone)
            setValue('email', filterAccompanying[0].email)
        }
        if (data.length > 0) {
            setOtherAccompanying(data)
        }
    }, [])



    const onSubmit = (data: FormData) => {


        const filterOthersAccompanying = otherAccompanying.filter((item: EditAccompanyingProps, index: number) => index !== Number(id) - 1)
        saveToSessionStorage('accompanying', [...filterOthersAccompanying, data])
        navigate(`${pathnameAccompanying}/accompanying`, { replace: true })
    }

    const handleBackPage = () => {
        navigate(`${pathnameAccompanying}/accompanying`)
    }

    return (
        <S.ContainerAddAccompanying>
            <h1>{t('checkin.addAccompanying.title')}</h1>
            <h5>{t('checkin.addAccompanying.subtitle')}</h5>
            <S.FormContainer>
                <S.ContainerRadio className="document" id="document">
                    <strong>{t('checkin.personalInformation.forms.label.typeDocument')}</strong>
                    <S.ContainerRadioButtons >
                        <Controller
                            name="documentType"
                            control={control}
                            render={({ field }) => (
                                // <Input width={"100%"} id="documentType" onBlur={() => trigger('documentType')} htmlFor="documentType" errorText={errors.documentType?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.typeDocument')} value={value} />
                                <RadioButton {...field} control={control} label={t('checkin.personalInformation.forms.label.cpf')} value="CPF" />
                            )
                            }
                        />
                        <Controller
                            name="documentType"
                            control={control}
                            render={({ field }) => (
                                // <Input width={"100%"} id="documentType" onBlur={() => trigger('documentType')} htmlFor="documentType" errorText={errors.documentType?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.typeDocument')} value={value} />
                                <RadioButton {...field} control={control} label={t('checkin.personalInformation.forms.label.passport')} value={'PASSPORT'} />
                            )
                            }
                        />
                    </S.ContainerRadioButtons>
                </S.ContainerRadio>
                {watch('documentType') === 'PASSPORT' ? (
                    <Controller
                        name="documentNumber"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input width={"100%"} id="documentNumber" onBlur={() => trigger('documentNumber')} htmlFor="documentNumber" errorText={errors.documentNumber?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.passport')} value={value} />
                        )
                        }
                    />
                ) : (
                    <Controller
                        name="documentNumber"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <NewInput mask={''} width={'100%'} name="documentNumber" htmlFor="documentNumber" placeholder="" title={t('checkin.personalInformation.forms.label.cpf')} type="text" errorText={errors.documentNumber?.message}>
                                <IMaskInput
                                    width={'100%'}
                                    mask={[{ mask: "***.***.***-**" }]}
                                    radix="."
                                    unmask={true} // true|false|'typed'
                                    value={value}
                                    onAccept={(value, mask) => {
                                        onChange(value)
                                    }}
                                    placeholder=""
                                    onBlur={() => trigger('documentNumber')}
                                />
                            </NewInput>
                        )
                        }
                    />
                )}
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input width={"100%"} id="name" onBlur={() => trigger('name')} htmlFor="name" errorText={errors.name?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.name')} value={value} />
                    )
                    }
                />
                <Controller
                    name="surname"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input width={"100%"} id="surname" onBlur={() => trigger('surname')} htmlFor="surname" errorText={errors.surname?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.surname')} value={value} />
                    )
                    }
                />
                <Controller
                    name="birthDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <NewInput width={'100%'} mask={''} id="birthDate" name="birthDate" htmlFor="birthDate" placeholder={t('checkin.personalInformation.forms.placeholder.birthDate')} title={t('checkin.personalInformation.forms.label.birthDate')} type="text" errorText={errors.birthDate?.message}>
                            <IMaskInput
                                mask={'**/**/****'}
                                radix="."
                                unmask={true} // true|false|'typed'
                                value={value}
                                onAccept={(value, mask) => {
                                    onChange(value)
                                }}
                                placeholder={t('checkin.personalInformation.forms.placeholder.birthDate')}
                                onBlur={() => trigger('birthDate')}
                            />
                        </NewInput>
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                        <Inputs.Phone
                            width={'100%'}
                            onChangePhoneNumber={field.onChange}
                            htmlFor={field.name}
                            placeholder=""
                            title={t('checkin.addAccompanying.phone')}
                            type=""
                            {...field}
                            value={field.value}
                            errorText={errors.phone?.message}
                            onBlur={() => {
                                trigger('phone')
                            }}
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input width={"100%"} id="email" onBlur={() => trigger('email')} htmlFor="email" errorText={errors.email?.message} onChange={onChange} placeholder={''} title={t('checkin.addAccompanying.email')} value={value} />
                    )
                    }
                />
            </S.FormContainer>
            <FooterWithButtons handleBackStep={handleBackPage} handleNextStep={handleSubmit(onSubmit)} nextButtonTitle={t('checkin.addAccompanying.buttonRegister')} />
        </S.ContainerAddAccompanying>
    )
}