import styled from "styled-components";
import { ModalProps } from ".";

export const Container = styled.div<ModalProps>`
    `;

export const Overlay = styled.div<ModalProps>`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
    `;

export const CloseButton = styled.button<ModalProps>`
    `;

    export const Content = styled.div<ModalProps>`
    `;

export const Modal = styled.div`
`
