import { Input } from "components/Input";
import * as S from "./styles"

type FormsProps = {
    children: React.ReactNode;
}

export const Forms = ({children}:FormsProps) => {
    return (
    <S.Forms>
        {children}
    </S.Forms>
    );
}
