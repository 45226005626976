import { Plus } from 'assets/svg/guests-selector/Plus';
import * as S from './styles';
import { useGuestStore } from "./useGuestStorage";
import { Minus } from "assets/svg/guests-selector/Minus";
import { NEUTRAL_COLORS, STATUS_COLORS } from "constants/styleConstants";
import { useEffect, useRef } from 'react';
import { useSpaProvider } from 'hooks/spaRouterContext';

type GuestSelectorProps = {
    maxGuests?: number;
    fill?: boolean;
    isCard?: boolean;
}

const iconCaret = require('../../../assets/svg/guests-selector/Caret.svg').default;

export const GuestSelector = ({ maxGuests, fill, isCard }: GuestSelectorProps) => {

    const { adults, children, babies, isOpen, updateAdults, updateChildren, updateBabies, closeModal, openModal, totalGuests } = useGuestStore();

    const modalRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const limitGuests = totalGuests === maxGuests || totalGuests === 6;

    const { expanded, visible } = useSpaProvider();

    useEffect(() => {
        const handleClickOutside = (event: PointerEvent) => {
            const target = event.target as Node;
            // Verifica se o clique ocorreu fora tanto do modalRef quanto do contentRef
            if (
                modalRef.current &&
                contentRef.current &&
                !modalRef.current.contains(target) &&
                !contentRef.current.contains(target)
            ) {
                closeModal(); // Fechar modal
            }
        };

        if (isOpen) {
            document.addEventListener("pointerdown", handleClickOutside);
        }

        // Remove o listener ao desmontar ou quando o modal fechar
        return () => {
            document.removeEventListener("pointerdown", handleClickOutside);
        };
    }, [isOpen, closeModal]);

    // Fechar modal quando `expanded` for false
    useEffect(() => {
        if (!expanded) {
            closeModal();
        }
    }, [expanded, closeModal]);

    const formatGuests = (guests: number, children: number, babies: number) => {
        if (guests && children > 0 && babies > 0) {
            const total = guests + children;
            return `${total} Hóspedes, ${babies} Bebês`;
        }
        if (guests && babies > 0) {
            return `${guests} Hóspedes, ${babies} Bebês`;
        }
        if (guests && children > 0) {
            const total = guests + children;
            return `${total} Hóspedes`;
        }
        if (guests === 1) return "1 Hóspede";
        return `${guests} Hóspedes`;
    };

    // Função para alternar o modal
    const handleOpenModal = (event: React.MouseEvent) => {
        event.stopPropagation(); // Evita que o clique no modal dispare o evento de clique no contêiner
        if (isOpen) {
            closeModal();
        } else {
            openModal();
        }
    };

    return (
        <S.Container fill={fill} >
            <S.ContentContainer isCard={isCard} fill={fill} ref={contentRef} onClick={handleOpenModal} isOpen={isOpen}>
                <input type="text" readOnly placeholder="Hóspede" value={formatGuests(adults, children, babies)} />
                <img src={iconCaret} alt="" />
            </S.ContentContainer>
            {isOpen && (
                <S.ModalGuests isCard={isCard} fill={fill} ref={modalRef} isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
                    <S.GuestOption>
                        <S.GuestOptionColumnDetails>
                            <strong>
                                Adultos
                            </strong>
                            <p>
                                Acima de 12 anos
                            </p>
                        </S.GuestOptionColumnDetails>
                        <S.GuestOptionColumnButtons>
                            <S.GuestsButton disabled={adults === 1} onClick={() => updateAdults(- 1)}>
                                <Minus color={adults === 1 ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK} />
                            </S.GuestsButton>
                            <p>
                                {adults}
                            </p>
                            <S.GuestsButton disabled={limitGuests} onClick={() => updateAdults(1)}>
                                <Plus color={limitGuests ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK} />
                            </S.GuestsButton>
                        </S.GuestOptionColumnButtons>

                    </S.GuestOption>
                    <S.Divisor />
                    <S.GuestOption>
                        <S.GuestOptionColumnDetails>
                            <strong>
                                Crianças
                            </strong>
                            <p>
                                De 2 até 12 anos
                            </p>
                        </S.GuestOptionColumnDetails>
                        <S.GuestOptionColumnButtons>
                            <S.GuestsButton disabled={children === 0} onClick={() => updateChildren(-1)}>
                                <Minus color={children === 0 ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK} />
                            </S.GuestsButton>
                            <p>
                                {children}
                            </p>
                            <S.GuestsButton disabled={limitGuests} onClick={() => updateChildren(1)}>
                                <Plus color={limitGuests ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK} />
                            </S.GuestsButton>
                        </S.GuestOptionColumnButtons>

                    </S.GuestOption>
                    <S.Divisor />
                    <S.GuestOption>
                        <S.GuestOptionColumnDetails>
                            <strong>
                                Bebês
                            </strong>
                            <p>
                                Menos de 2 anos
                            </p>
                        </S.GuestOptionColumnDetails>
                        <S.GuestOptionColumnButtons>
                            <S.GuestsButton disabled={babies === 0} onClick={() => updateBabies(-1)}>
                                <Minus color={babies === 0 ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK} />
                            </S.GuestsButton>
                            <p>
                                {babies}
                            </p>
                            <S.GuestsButton disabled={babies === 2} onClick={() => updateBabies(1)}>
                                <Plus color={babies === 2 ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK} />
                            </S.GuestsButton>
                        </S.GuestOptionColumnButtons>

                    </S.GuestOption>
                </S.ModalGuests>
            )}
        </S.Container>
    )
}