import { FC } from 'react';
import * as S from './styles'
import { ButtonGradient } from '../ButtonGradient';
import { Logo } from 'components/Logo';
import { useScreenWidth } from 'utils/useScreenWidth';


export const Hero = ({ }) => {

    const smarthphone = require('assets/image/owner/Iphones.png')

    const handleButtonClick = () => {
        const element = document.getElementById('Forms');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const breakpoint = useScreenWidth()

    return (
        <S.Container>
            {breakpoint ?
                <S.MobileContainer>
                    <S.MobileHero>
                    </S.MobileHero>
                    <S.Header>
                        <S.LogoHeader>
                            <Logo height='37' width='108' />
                            <h3>Admin</h3>
                        </S.LogoHeader>
                        <h5>Conecte seu imóvel a um ecossistema inteligente</h5>
                        <span>Obtenha a máxima rentabilidade com a sua unidade sem sair de casa com o Yogha Admin.</span>
                        <ButtonGradient onClick={handleButtonClick} width='100%' title='Fale com um especialista' />
                    </S.Header>
                </S.MobileContainer>
                :
                <S.Hero>
                    <S.HeroContent>
                        <S.Header>
                            <S.LogoHeader>
                                <Logo height='54' width='156' />
                                <h3>Admin</h3>
                            </S.LogoHeader>
                            <h5>Conecte seu imóvel a um ecossistema inteligente</h5>
                            <span>Obtenha a máxima rentabilidade com a sua unidade sem sair de casa com o Yogha Admin.</span>
                            <ButtonGradient onClick={handleButtonClick} width='309px' title='Fale com um especialista' />
                        </S.Header>
                        {/* <S.Smarphone /> */}
                    </S.HeroContent>
                </S.Hero>}
        </S.Container>
    )
}