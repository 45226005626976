import { HeaderAlt } from "components/HeaderAlt"
import { useEffect, useRef, useState } from 'react';
import * as S from "./styles"
import { SessionArticles } from "./SessionArticles"
import { RegisterNewsletter } from "./RegisterNewsletter"
import { Footer } from "components/Footer"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { HeaderBlog } from "components/HeaderBlog";
import { Banner } from "components/Banner";
import { SlideBlog, SlideBlogProps } from "pages/Blog/components/SlideBlog";
import { fetchAxiosBlog } from "services/http";
import { parseISO } from "date-fns";
import { readingTime } from "utils/readingTime";
import { useScreenWidth } from "utils/useScreenWidth";
import { ContainerProvider } from "hooks/useBlogRef";
import MobileBlogHero from "components/MobileBlogHero";

export const BlogPage = () => {

    document.title = "Home | Blog da Yogha";

    const [data, setData] = useState()
    const [slidePost, setSlidePost] = useState<SlideBlogProps[]>([])

    const [loading, setLoading] = useState(false)

    const getArticles = async () => {
        setLoading(true);
        try {
            const { data } = await fetchAxiosBlog.get('api/articles?&populate=deep', {});
            const sortedData = data.data.sort((a: SlideBlogProps, b: SlideBlogProps) => {
                const dateA = parseISO(a.attributes.datePublication);
                const dateB = parseISO(b.attributes.datePublication);
                return dateB.getTime() - dateA.getTime();
            });
            const sortedDataWithReadingTime = sortedData.map((obj: SlideBlogProps) => {
                const sectionString = transformSections([obj])[0]; // Transforma a seção em string
                const readTime = readingTime(sectionString); // Calcula o tempo de leitura
                return {
                    ...obj,
                    readingTime: readTime, // Adiciona a chave 'readingTime' ao objeto
                };
            });
            setData(sortedDataWithReadingTime);
            setSlidePost(sortedDataWithReadingTime);
            setLoading(false);
        } catch (error) {
        }
    };

    useEffect(() => {
        getArticles()

    }, [])




    function transformSections(objects: any[]) {
        return objects.map((obj) => {
            let sectionString = '';

            if (obj.attributes && obj.attributes.section) {
                obj.attributes.section.forEach((section: any) => {
                    sectionString += section.title ? section.title + ' ' : '';
                    section.paragraph.forEach((paragraph: any) => {
                        sectionString += paragraph.text + ' ';
                    });
                });
            }

            return sectionString.trim();
        });
    }

    const location = useLocation();

    const isBlog = location.pathname === '/blog';

    useEffect(() => {
        scrollToTop(true)
    }, [isBlog])

    const containerRef = useRef<HTMLDivElement>(null);

    const arrowTop = require("assets/svg/buttons/ArrowTop.svg").default;

    const scrollToTop = (fast?: boolean) => {
        const container = containerRef.current;
        if (container) {
            container
                .scrollTo({
                    top: 0,
                    behavior: fast ? 'auto' : 'smooth'
                })
        };
    };

    const navigate = useNavigate()

    const breakpoint = useScreenWidth()

    const pathname = window.location.pathname.split('/')[2]
    const pathnameBlog = window.location.pathname.split('/')[1]

    const handleNavigateBlog = () => {
        if (pathnameBlog === 'blog' && pathname === undefined && containerRef && containerRef.current) {
            containerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
            navigate('/blog')
        }
    }

    return (
        <>
            <S.Container ref={containerRef}>

                <HeaderBlog onClick={handleNavigateBlog} />
                <S.Content>
                    {isBlog ? <>
                        {breakpoint ? <MobileBlogHero blogContent={slidePost.slice(0, 4)} /> : <SlideBlog blogContent={slidePost} />}
                        <SessionArticles articlesArray={data} setArticlesArray={setData} />
                        {/*  <BlogCarousel titleCarousel='Blog da Yogha' data={posts} /> */}
                        <S.NewsLetterContainer>
                            <RegisterNewsletter />
                        </S.NewsLetterContainer>
                    </>
                        :
                        <ContainerProvider containerRef={containerRef}>
                            <Outlet />
                        </ContainerProvider>}
                    {breakpoint && pathname === 'pesquisar' && <S.ScrollToTopButton onClick={() => scrollToTop(false)} >
                        <img src={arrowTop} alt="" />
                    </S.ScrollToTopButton>}
                    {!breakpoint && <S.ScrollToTopButton onClick={() => scrollToTop(false)} >
                        <img src={arrowTop} alt="" />
                    </S.ScrollToTopButton>}
                </S.Content>
                <Footer />
            </S.Container>

        </>
    )
}