import * as S from './styles';

type NavegationProps<T extends string | undefined> = T extends string
  ? {
    lastPage: T;
    linkLastPage: string;
    actualPage: string;
  }
  : {
    lastPage?: never;
    linkLastPage?: never;
    actualPage: string;
  };

export const Navegation = <T extends string | undefined>({
  lastPage,
  linkLastPage,
  actualPage,
}: NavegationProps<T>) => {
  const carretActualPageIcon = require('assets/svg/navegation/CaretRight.svg').default;

  return (
    <S.Container>
      <S.InitialLink to="/minha-conta">Minha conta</S.InitialLink>
      <img src={carretActualPageIcon} alt="Caret Right" />
      {lastPage && linkLastPage && (
        <>
          <S.InitialLink to={linkLastPage}>{lastPage}</S.InitialLink>
          <img src={carretActualPageIcon} alt="Caret Right" />
        </>
      )}
      <S.ActualPage>{actualPage}</S.ActualPage>
    </S.Container>
  );
};
