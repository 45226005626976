import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import * as S from './styles';
import { Button } from '../../../../../../components/Button';
import { useUserProvider } from 'hooks/userContext';
import { usePaymentProvider } from 'context/paymentContext';
import { useAnyProvider } from 'hooks/Context';
import useAlert from 'components/Notification';
import { useNavigate } from 'react-router-dom';
import { useDateDifference } from 'hooks/useDateDifference';
import { ReserveDetails } from 'pages/Payment/components/PaymentCard/types';
import useExpensesStore from 'hooks/useExpensesStorage';

interface TabButtonProps {
  visible: boolean;
  handleScroll: (target: string) => void;
  priceTab: boolean;
  sectionRefs: React.RefObject<HTMLDivElement>[]
  reserveDetails: ReserveDetails
  ;
}

export const TabButtonComponent: React.FC<TabButtonProps> = ({ visible, handleScroll, priceTab, sectionRefs, reserveDetails }) => {
  const items = ['Imagens', 'Geral', 'Amenities', 'locationRef'];

  const [selected, setSelected] = useState<number>(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [highlightStyle, setHighlightStyle] = useState<{ width?: number; x?: number }>({});

  const { userName } = useUserProvider()
  const { expensesData } = useExpensesStore();
  const { date } = useAnyProvider()

  const { dayDifference } = useDateDifference(date)

  const { showAlert, Alert } = useAlert({ type: 'ERROR', title: 'Erro', active: false })

  const navigate = useNavigate()

  const TabNames = (item: string) => {
    if (item === 'Geral') {
      return 'Visão Geral'
    }
    if (item === 'Amenities') {
      return 'Comodidades'
    }
    if (item === 'locationRef') {
      return 'Localização'
    }
    if (item === 'Imagens') {
      return 'Imagens'
    } else {
      return item
    }
  }



  const handleButtonClick = (index: number, target: string) => {
    setSelected(index);
    setIsScrolling(true);
    handleScroll(target);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isScrolling) {
            const targetIndex = sectionRefs.findIndex(ref => ref.current === entry.target);
            if (targetIndex !== -1) setSelected(targetIndex);
          }
        });
      },
      {
        rootMargin: '100px 0px -70% 0px',
        threshold: 0.25,
      }
    );

    sectionRefs.forEach(ref => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      sectionRefs.forEach(ref => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, [sectionRefs, isScrolling]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsScrolling(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [isScrolling]);

  useEffect(() => {
    const selectedItem = document.querySelector(`[data-selected='${selected}']`);
    if (selectedItem) {
      const rect = selectedItem.getBoundingClientRect();
      const container = document.querySelector('.container');
      const containerRect = container?.getBoundingClientRect();

      const containerLeft = containerRect?.left;
      const relativeX = rect.left - Number(containerLeft);

      setHighlightStyle({
        width: selectedItem.clientWidth,
        x: relativeX,
      });
    }
  }, [selected]);

  const handleReserve = () => {
    if ((date && date.length === 0) || (date && date[0] === null && date[1] === null)) {
      showAlert({ active: true, title: 'Selecione uma data para continuar a reserva', type: "ERROR" })
      return;
    }
    if (userName.length === 0) {
      navigate('/login')
    } else {
      if (date && date[0] && date[1]) {
        navigate('/payment')
      } else {
        showAlert({ active: true, title: 'Selecione uma data para continuar a reserva', type: "ERROR" })
      }
    }

  }

  return (
    <S.TabNavSessions visible={visible}>
      <S.TabNavContent>
        <S.TabContainerButtons className='container'>
          {items.map((item, index) => (
            <S.ButtonTab
              key={item}
              onClick={() => handleButtonClick(index, item)}
              data-selected={index}
            >
              {TabNames(item)}
            </S.ButtonTab>
          ))}
          <motion.div
            className="border-bottom"
            initial={false}
            animate={highlightStyle}
            transition={{ duration: 0.2, ease: 'easeOut' }}
          />
        </S.TabContainerButtons>
        {priceTab && <S.PriceTab>
          <h1>R$ {dayDifference >= 30 ? expensesData.midStay.toFixed(0) : expensesData.uniqueValue.toFixed(0)},00 <span>{dayDifference >= 30 ? '/mês' : '/noite'}</span> </h1>
          <Button widthSize={"137px"} disabled={false} icon='' size='large' onClickButton={handleReserve} type='primary' title='Reservar' />
        </S.PriceTab>}
      </S.TabNavContent>
      <Alert />
    </S.TabNavSessions>
  );
};
