import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 32px;

    justify-content: flex-start;

    width: 100%;
    min-height: 600px;
`;

export const Title = styled.h5`
    ${FONT.BODY.BODY_LARGE[600]}

    @media (max-width: 768px) {
        align-self: center;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    align-items: flex-start;

    strong{
        ${FONT.BODY.BODY_MEDIUM[600]}
    }
    h4{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color:${NEUTRAL_COLORS.DARK_GRAY};
        margin-bottom: 8px;
    }

    @media (width < 768px) {
        width: 100%;
        h4{
        ${FONT.BODY.BODY_SMALL[400]}
    }
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;

    align-items: flex-start;
`;

export const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;

    align-items: start;


    width: 587px;

    border-radius: 10px;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};

    @media (width < 768px) {
        width: 100%;
    }
`;

export const InputPayment = styled.div`
    display: flex;
    flex-direction: row;

    align-items: start;

    width: calc(100% - 32px);

    padding: 20px 16px;

    &:nth-child(n + 2){
        border-top: 1px solid ${NEUTRAL_COLORS.GRAY};

        width: calc(100% - 32px);
    }

    gap: 18px;
    @media (width < 768px) {
        width: calc(100% - 40px);
        padding-right: 0px;
    }
`;