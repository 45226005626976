import { FONT, FONT_SIZE } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "constants/styleConstants";
import styled, { css } from "styled-components";

export const Container = styled.div`
        input[type="radio"]{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        cursor: pointer;
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;
        border: 2px solid ${NEUTRAL_COLORS.GRAY};
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            font-family: "Font Awesome 5 Free";
            content: "\f111";
            font-weight: 700;
            align-self: center;
            font-size: ${FONT_SIZE.TINY};
            color: ${NEUTRAL_COLORS.WHITE};
            display: none;
        };
        &:hover{
            background: ${NEUTRAL_COLORS.LIGHT_GRAY};
        }
        &:checked{
            background: ${PRIMARY_COLORS.MEDIUM};
            border: 0;
        }
        &:checked::after{
            display: block;
        }
    }

    display: flex;
    justify-content: space-between;

`;

export const ButtonContainer = styled.div`
    display: flex;

    justify-content: end;
`;

export const InputColumn = styled.div`
    display: flex;

    align-items: center;

    gap:8px;

    label{
        display: flex;

        align-items: center;
        justify-content: center;

        gap: 8px;
    }
`;

export const ColumnLabel = styled.div`
    p{
       ${FONT.BODY.BODY_SMALL[400]};
       color:${STATUS_COLORS.ERROR}
    }
`;

export const Collumn = styled.div`
    display: flex;

    align-items: center;
    justify-content: flex-start;

    gap: 12px;

    img{
        height: 24px;
        width: 50px;
    }

    @media (max-width: 768px) {
        img{
        height: 40px;
        width: 56px;
        object-fit: contain;
    }
        gap: 0px;
    }
`;

export const Button = styled.button`

    padding: 8px 16px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    border-radius: 10px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};

    p{
        ${FONT.BODY.BODY_SMALL[500]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const CollumnButtons = styled.div`
    display: flex;

    justify-content: space-between;
    align-items: center;
    gap: 8px;

    @media (max-width: 768px) {
        margin-top: 8px;

        justify-content: center;
        align-items: center;

        width: 100%;

        .confirm{
            flex: 1;
        }
    }

`;

export const BackDrop = styled.div`
    display: flex;

    position: fixed;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 1000;

    backdrop-filter: blur(4px);
`;

export const ModalCard = styled.div`
    display: flex;
    flex-direction: column;

    position: fixed;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    max-width: 744px;
    width: 100%;
    max-height: 600px;
    height: 100%;

    justify-content: space-between;

    background-color: ${NEUTRAL_COLORS.WHITE};

    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 16px;

    z-index: 1000;

`;

export const Header = styled.div`
    display: flex;

    gap: 80px;

    position: relative;

    align-items: center;
    justify-content: flex-start;

    margin-bottom: 42px;


    ::after{
        content: '';

        display: block;

        top: 40px;
        left: 0;

        position: absolute;

        margin-left: -24px;

        height: 3px;
        width: 558px;

        background: linear-gradient(279deg, #FF7A2A 0%, #FFC120 100%);;
    }

    ::before{
        content: '';

        display: block;

        top: 42px;
        left: 0;

        position: absolute;

        margin-left: -24px;

        height: 1px;
        width: calc(100% + 48px);

        background: ${NEUTRAL_COLORS.GRAY};
    }

`;

export const CollumnModalValidateValue = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 36px;
`;

export const Stages = styled.div`
    display: flex;

    gap:8px;

    align-items: center;
`

export const StageArea = styled.p`
    ${FONT.BODY.BODY_LARGE[600]};
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;

    padding: 16px 24px;
`;

export const CollumnModal = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
`;

export const CollumnModalSecond = styled.div`
    display: flex;
    flex-direction: row;

    gap: 32px;

    width: 100%;
`;

export const HeaderValue = styled.div`
    margin-right: 24px;

    ${FONT.BODY.BODY_SMALL[400]};
`;

export const InputContainer = styled.form`
    display: flex;
    flex-direction: column;

    row-gap: 24px;


    margin-top: 16px;
`;

export const WarningRow = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 10px;

    p{
       ${FONT.BODY.BODY_SMALL[500]}
       color: ${PRIMARY_COLORS.MEDIUM};
       cursor: pointer;
    }
    img{
        cursor: pointer;
    }

`;




export const TextHeading = styled.strong`
    ${FONT.BODY.BODY_SMALL[400]};
    font-weight: 400 !important;
`;


export const SecurityContent = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 32px;

    align-items: center;

    justify-content: center;

    margin-top: 16px;
`;

export const ColumnValue = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;
`;

export const CloseButton = styled.button`
   width: 24px;
    height: 24px;

    background-color: transparent;

    border: none;

    position: absolute;
    top: 16px;
    right: 16px;

    cursor: pointer;

`;

export const RowCardInfo = styled.div`
    display: flex;
    flex-direction: row;

    gap: 16px;
`;

export const FlagsContainer = styled.div`
display: inline-flex;
flex-direction: column;

align-items: flex-start;

row-gap: 8px;
`;

export const Flags = styled.div`
display: flex;

align-items: center;

gap: 4px;
`;

export const ContainerCard = styled.div`
    display: flex;
    flex-direction: column;

    gap: 56px;

    justify-content: flex-start;
    margin-top: 86px;

`;

export const HeadlineTop = styled.h2`
    ${FONT.BODY.BODY_LARGE[600]};
`

export const  ModalFooter = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: flex-end;

    gap: 8px;

    padding: 16px 16px;

    border-top: 1px solid ${NEUTRAL_COLORS.GRAY};
`;

export const SecurityText = styled.div`
    display: flex;

    text-align: center;

    p{  text-align: center;
        ${FONT.BODY.BODY_SMALL[400]};
    }
    margin-block: -12px -16px;
`;