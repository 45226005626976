import styled from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../../../../../constants/styleConstants";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../../../../../constants/fontConstants";

export const SessionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 1206px;

    position: relative;

`;

export const TabNavSessions = styled.div<{visible:boolean}>`
    display: ${({visible}) => visible ? 'flex' : 'none'};
    flex-direction: row;

    position: relative;

    width: 100%;
    height: 86px;

    align-items: center;
    justify-content:center;

    border-bottom: 1px solid ${NEUTRAL_COLORS.GRAY};

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    background-color: ${NEUTRAL_COLORS.WHITE};

    z-index: 2000;

    .border-bottom{
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: ${PRIMARY_COLORS.MEDIUM};
  }

    `;

export const TabNavContent = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100%;
    max-width: 1206px;

    align-items: center;
    justify-content: space-between;

    gap: 16px;

    margin: 0 auto;
    padding: 0 32px;

    @media (max-width: 768px) {
        max-width: unset;
    }
    `;

export const TabContainerButtons = styled.div`
    display: flex;

    gap:16px;

    padding-top: 8px;
`;

export const ButtonTab = styled.button`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.LARGE};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
    background-color: transparent;
    border: none;

    cursor: pointer;
    `;

export const PriceTab = styled.div`
    display: flex;
    flex-direction: row;

    justify-self: self-end;

    align-items: center;
    justify-content: flex-start;

    gap:32px;

    h1{
        font-size: ${FONT_SIZE.SMALL};
        font-weight: ${FONT_WEIGTH.HUGE};
        line-height: 140%;
        color:${NEUTRAL_COLORS.HIGH_DARK};

    }
    span{
            ${FONT.BODY.BODY_SMALL[400]};
        }
`;

export const ContentSession = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    width: 690px;

    row-gap: 64px;

    justify-content: flex-start;
`;

export const Session = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;
    `;

export const FirstRow = styled.div `
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    h1{
        font-size: ${FONT_SIZE.SMALL};
        font-weight: ${FONT_WEIGTH.HUGE};
        line-height: 140%;
        color:${NEUTRAL_COLORS.HIGH_DARK}
    }

`;

export const ItensRows = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

interface ItemCollumnProps{
    modal?:boolean;
}

export const ItemCollumn = styled.div<ItemCollumnProps>`
    display: flex;
    flex-direction: column;

    width: 227px;

    gap: 8px;

    white-space: nowrap;

    padding-bottom: ${({modal}) => modal ? '8px' : '0px'};

    `;

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    img{
        width: 16px;
        height: 16px;
    }
`;

export const Text = styled.span`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;

export const WarningContainer = styled.div`
    display: flex;
    flex-direction: row;

    padding: 16px 16px 0px 0px;

    gap: 10px;

    cursor: pointer;
`;

export const WarningText = styled.span`
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140%;
    color:${PRIMARY_COLORS.MEDIUM};

    cursor: pointer;
`;

export const AmenitiesCondominium = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    place-content: center space-between;

    row-gap: 32px;

    margin-top: 30px;
    `

export const CondominiumItem = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 12px;
`;

export const TitleCondominiumItem = styled.div`
    display: flex;
    flex-direction: row;

    gap:16px;

    `
export const ContentCondominiumItem = styled.div`
    display: flex;
    flex-direction: column;
    `

export const CardAccommodations = styled.div`
    display: flex;
    flex-direction: column;

    width: 119px;
    height: 126px;

    padding: 16px 24px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    align-items: flex-start;
    justify-content: flex-start;

    row-gap: 8px;

    h2{
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.HUGE};
        line-height: 140%;
        color:${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const CardAccommodationsIcons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;`

export const CollumAcoomodations = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    align-items: flex-start;

    padding: 16px;
`;


export const MapContainer = styled.div`
    display: flex;
    flex-direction: column;


    width: 100%;
    height: 500px;

    row-gap: 16px;

    h1{
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
    }

`;

export const PaymentContainer = styled.div`
   display: flex;
   flex-direction: column;

   width: 100%;

   row-gap: 16px;

    max-height: 358px;

    overflow: auto;
    padding-bottom: 4px;
`;

export const ContainerValues = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

    `;


export const RowValues = styled.div`
    width:fit-content;



    text-align: end;

    border: 0;
    outline: none;

    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Rows = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    `;

export const ModalTitle = styled.span`
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;