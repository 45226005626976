import * as S from "./styles"
import { Navegation } from "../../../../components/Navegation"
import { Input } from "../../../../components/Input"
import { useState } from "react"
import fetchAxios from "services/http"
import { useUserProvider } from "hooks/userContext"
import useAlert from "components/Notification"
import { Button } from "components/Button"

export const SecurityAndPassword = () => {

    document.title = "Alterar senha | Yogha";

    const { token } = useUserProvider()

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const [actualPassword, setActualPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')

    const handleNewPassword = async () => {

        if (newPassword !== confirmPassword) {
            showAlert({ active: true, title: 'As senha não são iguais ', type: 'ERROR' })
            return
        }

        try {
            const { data } = await fetchAxios.put('/users/updatePassword', {
                password: actualPassword,
                newPassword
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,


                }
            });
            if (data.code === 200) {
                showAlert({ active: true, title: 'Senha alterada com sucesso ', type: 'SUCCESS' })


            } else {
                showAlert({ active: true, title: 'Erro ao alterar senha  ', type: 'ERROR' })
            }
        } catch (error) {
            showAlert({ active: true, title: 'Erro ao alterar senha  ', type: 'ERROR' })

        }
    }

    return (
        <>
            <Alert />
            <Navegation actualPage="Segurança e senhas" />
            <S.TitlePage>Segurança e senhas</S.TitlePage>
            <S.ContainerInputs>
                <Input htmlFor="actualPassword" showPassword onChange={(e) => setActualPassword(e.currentTarget.value)} placeholder="Senha atual" title="Senha atual" type="password" value={actualPassword} width={'100%'} />
                <Input htmlFor="newPassword" showPassword onChange={(e) => setNewPassword(e.currentTarget.value)} placeholder="Digite uma nova senha" title="Nova senha" type="password" value={newPassword} width={'100%'} />
                <div style={{ gridArea: 'button', marginTop: 8, width: '100%' }}>
                    <Button onClickButton={handleNewPassword} type="primary" title="Salvar alterações" size="medium" widthSize={"100%"} />
                </div>
                <Input htmlFor="confirmPassword" showPassword onChange={(e) => setConfirmPassword(e.currentTarget.value)} placeholder="Digite a nova senha novamente" title="Confirmar senha" type="password" value={confirmPassword} width={'100%'} />
            </S.ContainerInputs>
        </>
    )
}