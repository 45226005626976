import { isAfter } from "date-fns";
import { useUserProvider } from "hooks/userContext";
import { ComponentType } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRouteProps {
  component: ComponentType<any>;
  [x: string]: any;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {

  const { token } = useUserProvider();

  const isTokenValid = (): boolean => {
    if (!token) return false;

    const expirationTimeStr = localStorage.getItem('expirationTime');
    if (!expirationTimeStr) {
      return false;
    }

    const expirationDate = new Date(expirationTimeStr);
    const currentDate = new Date();

    return !isAfter(currentDate, expirationDate);
  };

  const isAuth = token ? isTokenValid() : false;

  return isAuth ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;