import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export const useLocalStorageState = (key: string, initialValue: string): [string, Dispatch<SetStateAction<string>>] => {
    const [value, setValue] = useState<string>(() => {
        const storedValue = localStorage.getItem(key);
        return storedValue !== null ? storedValue : initialValue;
    });

    useEffect(() => {
        localStorage.setItem(key, value);
    }, [key, value]);

    return [value, setValue];
};
