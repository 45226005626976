import styled from 'styled-components';
import { NEUTRAL_COLORS } from '../../constants/styleConstants';
import { FONT, FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-around;

  width: 80px;
  height: 56px;

  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  border-radius: 10px;

  padding: 0px 16px;

  z-index: 1030;

`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
`;

export const SubTitle = styled.p`
  ${FONT.BODY.BODY_SMALL[400]}
`;

export const PhotoGrid = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 99px;
`;

export const Photo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 99px;
  object-fit: cover;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  gap:8px;
`;

export const ContainerModal = styled.div`
background: ${NEUTRAL_COLORS.WHITE};
  display: flex;

  width: 302px;

  position: absolute;
  z-index: 1040;
  align-items: center;

  top:65px;
  right:-20px;

  border-radius: 18px;
  border: 0.8px solid ${NEUTRAL_COLORS.LIGHT_GRAY};

  box-shadow: 0px 3px 25px 4px rgba(0, 0, 0, 0.15);

  overflow:auto;

  `;

export const Modal = styled.div<{user:string}>`
display: flex;
flex-direction: column;

max-width: 302px;
width: 100%;

align-content: space-between;

padding: 1rem;
row-gap: 15px;
h1{
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.LARGE};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    line-height: 1.4;
}

`
export const TynyText = styled.h5`
    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.LITTLE};
    color: ${NEUTRAL_COLORS.GRAY};
    line-height: 1.4;
`

export const CustomLink = styled(Link)`
text-decoration: none;
`;

export const ModalContent = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
row-gap: 5px;
overflow: auto;
width: 100%;

`;

export const User = styled.div`
display: flex;
gap:8px;
img{
    height: 40px;
    width: 40px;
    background-color: '#F2F2F2';
    border-radius: 50px;
    object-fit: cover;
}
margin-bottom: 24px;

:hover{
    cursor: pointer;
}
 `;


export const ModalNavLink = styled.div`
display: flex ;

width: calc(268px - 28px);

align-items: center;
justify-content: flex-start;

padding: 14px 0;

gap: 8px;

:hover{
  cursor: pointer;
}

p{
    ${FONT.BODY.BODY_SMALL[500]}
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    white-space: nowrap;
}
`
export const LinkWitArrow = styled.div`
display:inline-flex;
gap:10px;`

export const SectionModal = styled.div`
display:flex;
flex-direction:column;

position: relative;

row-gap: 8px;

:has(.space){
  padding-top: 16px;
  ::before{
    content: '';
    width: calc(100% + 20px);
    height: 1px;

    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

    position: absolute;
    top: 0;

  }
}

`

export const SectionModalLinks = styled.div`
  display:flex;
flex-direction:column;

width: 100%;

position: relative;

:has(.space){
  padding-top: 16px;
  ::before{
    content: '';
    width: 100%;
    height: 1px;

    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

    position: absolute;
    top: 0;

  }
  }

  ${ModalNavLink}{
    width: 100%;
    justify-content: space-between;
    padding: 0;
    ${CustomLink}{
      padding: 16px 0;
      ${FONT.BODY.BODY_SMALL[400]}
      color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
  }
`;

export const LinksCollumn = styled.div`
display: flex;
flex-direction: column;

`;

export const Row = styled.div`
display: flex;
flex-direction: column;

justify-content:flex-start ;

gap: 8px;

:has(.null){
    margin-bottom: 16px;
}
`;