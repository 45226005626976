import { InputSearch } from '../types'
import * as S from './styles'

export const Search = ({...input}:InputSearch) => {

    const searchIcon = require('assets/svg/input/MagnifyingGlass.svg').default;

    return (
        <S.Container width={input.width} >
            <label htmlFor={input.htmlFor}> <img src={searchIcon} alt="" /></label>
            <input id={input.htmlFor} placeholder={input.placeholder} value={input.value} onChange={input.onChange} />
        </S.Container>
    )
}