import { FONT, FONT_SIZE, FONT_WEIGTH } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const ModalVisualizer = styled.div`
  display: flex;

  position: fixed;

  inset: 0;

  width: 100vw;
  height: 100vh;


  background-color: #000000;

  z-index: 3000;

  align-items: center;
  justify-content: center;

`;

export const ContentVisualizer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: start;

  `;

export const HeaderVisualizer = styled.div`
display: flex;
flex-direction: row;

width: 100%;

justify-content: flex-start;

margin-top: 32px;
`;


export const CloseVisualizer = styled.button`
  display: flex;

  align-items: center;
  justify-content: center;

  padding: 12px;

  background-color: transparent;

  border: 0;

  cursor: pointer;

  img{
    width: 24px;
    height: 24px;
  }

  margin-left: 20px;
`;

export const InteractionVisualizerContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: calc(100% - 400px);

  align-items: center;
  justify-content: end;

  margin-bottom: 16px;

  button{
    display: flex;

    align-items: center;
    justify-content: center;

    height: 40px;

    padding: 8px 16px;

    background-color: transparent;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    cursor: pointer;

    gap:16px;


    p{
      color: ${NEUTRAL_COLORS.GRAY};
      ${FONT.BODY.BODY_SMALL[500]}
    }


  }

`;

export const VisualizerIndividual = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  height: 100%;
`;

export const DirectionalButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  align-items: center;
  justify-content: space-between;

  padding: 0px;

  position: absolute;

  top: 50%;
  left: 0;

  transform: translateY(-5%);

`;

interface DirectionalButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    direction: 'left' | 'right';
  }

  export const DirectionalButton = styled.button<DirectionalButtonProps>`
      display: flex;

      visibility: ${({disabled}) => disabled ? 'hidden ' : 'visible'};

      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      border: 1px solid ${NEUTRAL_COLORS.GRAY};
      border-radius: 69px;

      transform: ${({direction}) => direction === 'right' ? 'rotate(180deg)' : 'rotate(0deg)'};

      ${({direction}) => direction === 'right' ? 'margin-right: 24px;' : 'margin-left: 24px;'};

      background-color: transparent;

      cursor: pointer;

      img{
        width: 24px;
        height: 24px;
      }
  `;

export const TotalImagesVisualizer = styled.div`
display: flex;
flex-direction: row;

margin-top: 16px;

p{
  color: ${NEUTRAL_COLORS.GRAY};
  font-size: ${FONT_SIZE.TINY};
  font-weight: ${FONT_WEIGTH.MEDIUM};
  line-height: 140%;
}
`;

export const ImageVisualizer = styled.img`
  object-fit: cover;
  width: 100%;
`;
