import { FONT } from "constants/fontConstants";
import styled from "styled-components";

export const CaptureVideoContainer = styled.div`
    position: relative;
    width: 100%;
    height: 310px;

    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    img{
        position: absolute;
        top: calc(50% + 25px);
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        z-index: 1;
    }
    margin-bottom: 40px;

    @media (max-width: 768px) {
        display: flex;
        width: 100%;
        height: calc(100% - 170px);
        align-items: center;
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
            img{
                position: absolute;
                top: calc(50%);
                left: 50%;
                transform: translate(-50%, -50%);
                object-fit: cover;
                z-index: 1;
                width: 90%;
            }
            margin-top: 24px;
            margin-bottom: 24px;
    }

`;

export const PermissionContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;

    gap: 20px;

    padding: 16px 0;

    h4{
        ${FONT.BODY.BODY_LARGE[600]}
        text-align: center;
    }

    @media (max-width: 768px) {
      align-self: center;
      justify-self: center;
      gap: 32px;
    }
`;

export const ContainerSelfie = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    @media (max-width: 768px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;