import styled from "styled-components";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, STATUS_COLORS } from "../../constants/styleConstants";

export const Container = styled.div``;

export const ResultsContainer = styled.div<{ closed: boolean }>`
width: ${props => props.closed ? '0px' : '51.2%'};
height: calc(100%);
display: ${props => props.closed ? 'none' : 'flex'};
position: absolute;
top: 86px;
z-index: 1;
transition: all 0.8s ease-in-out;
transition: display 1.2s ease-in-out;
z-index: 1000;
background: ${NEUTRAL_COLORS.WHITE};
flex-direction: column;

@media (max-width: 850px) {
    position: relative;
    width: 100% !important;
    top: 0px;
    height: 100vh;
}
`;

export const ResultsHeader = styled.div`
display: flex;
flex-direction: column;
height: 116px;
width: 100%;
justify-content: space-between;
align-items: flex-start;
row-gap: 16px;
border-bottom:1px solid ${STATUS_COLORS.DISABLED};

@media (max-width: 850px) {
    height: max-content;
    padding-bottom: 16px;
}
`;

export const ResultRow = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
width: 100%;
@media (max-width: 850px) {
    justify-content: flex-start;
    align-items: flex-start;
}
`;

export const ContentResult = styled.div`
display: flex;
padding: 16px 32px;
align-self: flex-start;

@media (max-width: 850px) {
    width: calc(100% - 64px);
    padding-top: 8px;
    padding-bottom: 0px;

}
`;

export const InfoSearch = styled.div`
display: flex;
flex-direction: column;
row-gap: 20px;
@media (max-width: 768px) {
    padding-bottom: 16px;
}
`;

export const ResultsTitle = styled.div`
display: flex;
align-items: center;
gap: 13px;
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.MEDIUM};
color: ${NEUTRAL_COLORS.DARK_GRAY};
@media (max-width: 850px) {
    width: 100%;
}
`;

export const SearchResult = styled(ResultsTitle)`
color: ${NEUTRAL_COLORS.DARK};
@media (max-width: 850px) {
    display:block;
    max-width: calc(100vw - 126px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
`;

export const Results = styled.p`
display: flex;
align-items: flex-start;
font-weight: ${FONT_WEIGTH.HUGE};
font-size: ${FONT_SIZE.SMALL};
@media (max-width: 850px) {
    display:block;
    width: calc(100vw - 57px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
`;

export const ResultsList = styled.div`
display: flex;
overflow: auto;
flex-direction: column;
width: calc(100% - 48px);
padding-left: 32px;
padding-right: 8px;
padding-top: 16px;
padding-bottom: 116px;

@media (max-width: 768px) {
    width: 100%;
    padding:0px;
    padding-bottom: 240px;
    overflow-y: scroll;
}

@media (min-width: 1336px) {
    padding-left: 24px;
    padding-right: 16px;
}
`;

export const ResultContent = styled.div`
display: grid;
row-gap: 32px;
gap: 32px;
width: fit-content;

@media (max-width: 768px) {
    width: calc(100% - 48px);
    place-content: center;
    place-items: center;
    padding: 0px 24px;
    padding-top: 16px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}
@media (min-width: 1280px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media (min-width: 1920px) {
    grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 2560px) {
    grid-template-columns: repeat(3, 1fr);
}
`;


export const FilterButton = styled.button`
display: flex;

justify-content: center;
align-items: center;

gap: 10px;

width: fit-content;
height: 40px;

border-radius: 10px;
border: 1px solid ${NEUTRAL_COLORS.GRAY};
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.HUGE};
line-height: 1.4;
background: ${NEUTRAL_COLORS.WHITE};
padding:0px 8px;

@media (max-width: 850px) {
margin-top: 0px;
margin-right: 32px;
align-self: flex-end;
}
`;


export const ActiveAmenities = styled.div`
display: flex;

align-items: center;
justify-content:center;

width: 23px;
height: 23px;

border-radius: 41px;
border: 1px solid ${NEUTRAL_COLORS.HIGH_DARK};

p{
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    ${FONT.BODY.BODY_SMALL[500]};
}
`;
export const ClosedButton = styled.div<{ closed: boolean }>`
display: flex;
justify-content: center;
align-items: center;
width:max-content;
height: 40px;
background: ${NEUTRAL_COLORS.WHITE};
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
position: absolute;
top: calc(0% + 106px);
border-radius: 10px;
left: ${props => props.closed ? '1%' : '52%'};;
z-index: 999;
transition: all 0.8s ease-in-out;
padding: 0px 10px;
    display: none;
`;

export const NoResultContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;

    row-gap: 24px;

    margin-top: 40px;

    p{
        max-width: 413px;
        ${FONT.BODY.BODY_MEDIUM[400]};
        text-align: center;
        margin-bottom: 8px;
    }
`;

export const ButtonFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
`;

export const HeaderResults = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
    padding: 0px 32px;
    width: calc(100% - 64px);

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 0px;

    }
`;

export const FilterColumn = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
    @media (max-width: 768px) {
        width: calc(100% - 24px);
        justify-content: space-between;
    }
`;

export const NoResultImageContainer = styled.div`
    display: flex;

    padding: 29px;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction:column;
  border-radius: 10px;
   `;