import { useSessionStorage } from 'context/sessionStorage';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Hook personalizado para mudar o idioma com base no valor salvo na sessionStorage
const useLanguage = () => {
  const { i18n } = useTranslation();
  const { getFromSessionStorage } = useSessionStorage()

  useEffect(() => {

    const savedLanguage = getFromSessionStorage('language');

    const maybeChangeLanguage = () => {
      // Muda o idioma apenas se o idioma salvo for diferente do atual e se não for null
        if (savedLanguage && i18n.language !== savedLanguage) {
            i18n.changeLanguage(savedLanguage.trim());
        }
    };

    maybeChangeLanguage();
    // Dependência [i18n] para garantir que o efeito seja executado corretamente
    // em resposta a alterações no objeto i18n.
  }, [i18n]);
};

export default useLanguage;
