import { useNavigate } from 'react-router-dom';
import * as S from './styles'

interface CollectionProps {
    name: string;
    ammount: number;
    images: string[];
    id: string | number;
}

export const Collection: React.FC<CollectionProps> = ({ ...collection }) => {

    const navigate = useNavigate();

    return (
        <S.Container onClick={() => navigate(`/minha-conta/favorites/?=:${collection.id}`)}>
            <S.GridContainer size={collection.images.length}>{collection.images.map((item, index) => {
                return (<img src={item} className={`grid` + index} alt="" />)
            })}
            </S.GridContainer>
            <S.RowText>
                <S.Title>{collection.name}</S.Title>
                <S.Subtitle>{collection.ammount} {collection.ammount <= 1 ? 'acomodação' : 'acomodações'} </S.Subtitle>
            </S.RowText>
        </S.Container>
    )
}