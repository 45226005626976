import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { Inputs } from 'components/Input';
import { ChangeEvent, FormEvent, useState } from 'react';

const logo = require('assets/image/logo.png');
const arrowLeft = require('assets/svg/buttons/ArrowLeft.svg').default;

type HeaderBlogProps = {
    onClick?: () => void
}

export const HeaderBlog = ({ onClick }: HeaderBlogProps) => {
    const navigate = useNavigate()

    const [search, setSearch] = useState('')



    // const handleSearch = () => {
    //     navigate('/blog/search?q=' + search)
    // }

    const handleBackButton = () => {
        navigate(-1);
    };

    return (
        <S.Header>
            <S.Content>
                <S.LogoContainer onClick={onClick}>
                    <img src={logo} alt="" />
                </S.LogoContainer>
                <S.NavigateButton onClick={handleBackButton}>
                    <img src={arrowLeft} alt="" />
                </S.NavigateButton>
                {/* <Inputs.Search  htmlFor='search' onChange={(e) => setSearch(e.currentTarget.value)} value={search} placeholder="Pesquisar" width="557px" /> */}

            </S.Content>
        </S.Header>
    )
}