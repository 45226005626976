import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  background-color: #f6f4f4;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 450px;
`;

export const Slide = styled.div<{ active: boolean }>`
  position: absolute;
  width: 100%;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: opacity 0.5s;
`;

export const LeftButton = styled.button`
  display: flex;
  cursor: pointer;

  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: #f6f4f4;

  border-radius: 29px;

  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  `;

export const RightButton = styled(LeftButton)`
  transform: rotate(180deg);
  `;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        width: 100%;
}
    `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;