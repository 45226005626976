import { FONT } from "constants/fontConstants";
import { PRIMARY_COLORS, STATUS_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const ContainerAdditionalInfo = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;

    h5{
        ${FONT.HEADLINES.H5}
        margin-bottom: 40px;
    }
    padding-bottom: 48px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        padding-bottom: 0px;
    }
`

export const FormContainer = styled.form`
    display: flex;
    flex-direction: column;

    gap: 24px;

    width: 100%;
    max-width: 420px;
`;

export const ContainerRadioButtons = styled.div`
    display: flex;

    width: 100%;

    align-items: center;

    gap: 16px;

`;

export const ContainerOptionsRadioButton = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    strong{
        ${FONT.BODY.BODY_SMALL[500]}
    }
`;

export const ContainerTermAndConditions = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    gap: 4px;

    p{
        ${FONT.BODY.BODY_SMALL[500]}
    }

    max-width: 420px;
    margin-top: 48px;
    margin-bottom: 48px;
`;

export const ContentTermAndConditions = styled.div`
    display: flex;

    align-items: center;

    p{
        ${FONT.BODY.BODY_MEDIUM[500]}

    }
    a{
            color:${PRIMARY_COLORS.MEDIUM}
        }
`;

export const ErrorMessage = styled.div`
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: flex-start;

    gap: 4px;

    p{
        ${FONT.CAPTION.CAPTION_SEMI_REGULAR}
        color: ${STATUS_COLORS.ERROR};
    }

    img{

    }
`;