import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const ModalGallery = styled.div`
    display: flex;
    flex-direction: column;

    position: fixed;

    inset: 0;

    width: 100vw;
    height: 100vh;

    &::-webkit-scrollbar-track {
    margin-top: 66px;
  }

    background-color: ${NEUTRAL_COLORS.WHITE};

    z-index: 3000;

    overflow: auto;

    align-items: center;
    justify-content: center;
    `;

export const HeaderGallery = styled.div`
display: flex;
flex-direction: row;

position: fixed;

top: 0;
width: 100vw;
height: 66px;

align-items: center;
justify-content: center;


padding-left: 82px;
padding-right: 20px;

p{
    ${FONT.HEADLINES.H5}
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
}

background-color: ${NEUTRAL_COLORS.WHITE};
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 1366px;
    width: calc(100% - 82px - 20px);
    height: 100%;

    align-items: center;
    justify-content: space-between;
`;

export const ButtonClose = styled.button`
    display: flex;

    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    border: 0;

    background-color: transparent;

    cursor: pointer;
`;

export const ContentGallery = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;

    align-items: center;
    justify-content: flex-start;
`;

export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;

    width:1206px;

    justify-self: center;
    align-self: center;

    align-items: center;
    justify-content: center;

    margin-top: calc(66px + 54px);
    padding-bottom: 64px;

    row-gap: 16px;
    `;

export const ImageGallery = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    `;
