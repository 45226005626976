import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    width: 346px;
    height: 131px;
    border-radius: 10px;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    padding: 16px;

    @media (max-width: 768px) {
        width: 100%;
        height: unset;
    }

    `;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 349px;
    height: 132px;

    @media (max-width: 768px) {
        width: 100%;
        height: calc(176px - 32px);
        padding: 16px;
    }
    `;

export const Header = styled.div`
    display: flex;
    gap: 20px;
    line-height: 1.4;
    `;


export const Title = styled.h1`
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    line-height: 1.4;
    `;

export const Description = styled.p`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    color: ${NEUTRAL_COLORS.DARK};
    line-height: 1.4;
`;

export const Link = styled.div`
display: flex;
align-items: center;
margin-top: 8px;
p{color: ${PRIMARY_COLORS.MEDIUM};
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.LARGE};}
    line-height: 1.4;
    gap: 16px;
    cursor: pointer;
`;
