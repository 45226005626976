import * as S from "./styles"
import { Hero } from "./componentes/Hero"
import { SessionEnterprise } from "./componentes/SessionEnterprise"

export const Developer = () => {

    document.title = "Incorporadores | Yogha";

    return (<>
        <S.Container>
            <Hero />
            <SessionEnterprise />
        </S.Container>


    </>
    )
}