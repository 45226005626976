import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const useAddressSchema = (country: string) => {
    const { t } = useTranslation();

    // Crie o esquema diretamente dentro do hook
    const schemaNational = z.object({
      cep: z.string({required_error:t('checkin.address.forms.errors.zipCode')}),
      address: z.string({required_error:t('checkin.address.forms.errors.street')}).nonempty({
        message: t('checkin.address.forms.errors.street'),
      }),
      complement: z.string().optional(),
      number: z.string({required_error:t('checkin.address.forms.errors.number')}).nonempty({
        message: t('checkin.address.forms.errors.number'),
      }),
      district: z.string({required_error:t('checkin.address.forms.errors.neighborhood')}).nonempty({
        message: t('checkin.address.forms.errors.neighborhood'),
      }),
      city: z.string({required_error:t('checkin.address.forms.errors.city')}).nonempty({
        message: t('checkin.address.forms.errors.city'),
      }),
      state: z.object({
        value: z.string({required_error:t('checkin.address.forms.errors.state')}).nonempty({
          message: t('checkin.address.forms.errors.state'),
        }),
        label: z.string({required_error:t('checkin.address.forms.errors.state')}).nonempty({
          message: t('checkin.address.forms.errors.state'),
        },)
      }, {required_error:t('checkin.address.forms.errors.state')}),
    });
    const schemaInternational = z.object({
      fullAddress: z.string({required_error:t('checkin.address.forms.errors.fullAddress')}).nonempty({
        message: t('checkin.address.forms.errors.fullAddress'),
      }),
      cityInternational: z.string({required_error:t('checkin.address.forms.errors.city')}).nonempty({
        message: t('checkin.address.forms.errors.city'),
      }),
      province: z.string({required_error:t('checkin.address.forms.errors.province')}).nonempty({
        message: t('checkin.address.forms.errors.province'),
      }),
    })

    return { schema : country === 'Brasil' || country === "Brazil" ? schemaNational : schemaInternational, schemaNational,schemaInternational  }
  };
