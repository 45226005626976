import styled from "styled-components";
import { FONT, FONT_SIZE,FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
display: flex;

background: #f6f4f4;

margin: 0px;
padding: 64px auto;

justify-content: center;
align-items: center;

@media (max-width: 768px) {
    width: 100%;
}

`;

export const Comments = styled.div`
width: 570px;
height: 452px;
display: flex;

justify-content: flex-start;
flex-direction: column;
align-items: center;
row-gap: 48px;
padding: 0px 72px;

margin-top: 62px;

@media (max-width: 768px) {
    width: calc(100% - 40px) ;
    padding: 0px 20px;
}
`;

export const Title= styled.h1`
${FONT.HEADLINES.H4};
padding: 0px 20px;
text-align: center;

@media (max-width: 768px) {
    ${FONT.HEADLINES.H5}
}
`;

export const Comment = styled.p`
${FONT.BODY.BODY_LARGE[500]};
text-align: left;
padding-left: 12px;

@media (max-width: 768px) {
    ${FONT.BODY.BODY_MEDIUM[400]};
    padding: 0px;
}

`;

export const UserContainer = styled.div`
display: flex;
flex-direction: column;

row-gap: 8px;

align-self: flex-start;

padding-left: 12px;
`
export const Avaliations = styled.div`
display: flex;
flex-direction: row;

align-items: center;

gap: 4px;

img{
    width: 16px;
    height: 16px;
}
`;

export const UserInfos = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
`;

export const UserName = styled.p`
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.MEDIUM};
`;

export const UserReference = styled(UserName)`
color: ${NEUTRAL_COLORS.DARK_GRAY};`;

