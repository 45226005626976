import { FC, useState } from 'react';
import * as S from './styles'
import { CommentProps, SliderSession } from '../SliderSession';
import { Input } from 'components/Input';
import { ButtonGradient } from '../ButtonGradient';
import { Tag } from 'components/Tag';
import { Forms } from 'components/Forms';
import { Footer } from 'components/Footer';
import fetchAxios from 'services/http';
import useAlert from 'components/Notification';
import { useScreenWidth } from 'utils/useScreenWidth';
import MobileCarouselCommentsFlexHousing from 'components/MobileCarouselCommentsOwner';
import MobileCarouselCommentsOwner from 'components/MobileCarouselCommentsOwner';
import { useNavigate } from 'react-router-dom';

interface CardInfoProps {
    image: string;
    title: string;
    description: string;
}

const CardInfoObj: CardInfoProps[] = [
    {
        description: 'Serviços digitais de marcas especializadas, destinados a descomplicar a experiência de moradia.',
        image: require('assets/svg/owner/DeviceMobileCamera.svg').default,
        title: 'Yogha Digital'
    },
    {
        description: 'Solução para deixar o seu apartamento pronto para ocupar e rentabilizar. ',
        image: require('assets/svg/owner/PaintBrush.svg').default,
        title: 'Yogha Design'
    },
    {
        description: 'Gestão e operação fácil e transparente das locações, com rentabilidade maximizada.',
        image: require('assets/svg/owner/RocketLaunch.svg').default,
        title: 'Yogha Gestão'
    },
    {
        description: 'Serviços adicionais (implantados mediante disponibilidade na região) que proporcionam mais comodidade aos moradores.',
        image: require('assets/svg/owner/Package.svg').default,
        title: 'Serviços On Demand'
    },
]

const CardServices: FC<CardInfoProps> = ({ description, image, title }) => {
    return (
        <S.CardServices>
            <img src={image} alt="" />
            <span>{title}</span>
            <p>{description}</p>
        </S.CardServices>
    )
}


const CardInfoObj2: CardInfoProps[] = [
    {
        title: 'Visibilidade do Imóvel',
        description: 'Fotografamos o imóvel e o anunciamos em canal próprio e nas principais plataformas de alugueis por temporada.',
        image: require('assets/svg/owner/Visibilidade.svg').default
    },
    {
        title: 'Gestão de locações',
        description: 'Atraímos e cuidamos do relacionamento com os hóspedes, incluindo controle de reservas, check-in, check-out e suporte online 24h.',
        image: require('assets/svg/owner/Suporte.svg').default
    },
    {
        title: 'Manutenção contínua',
        description: 'Realizamos as verificações e limpezas entre reservas, limpezas extras e manutenção contínua completa do imóvel.    ',
        image: require('assets/svg/owner/Manutenção.svg').default
    },
    {
        title: 'Dashboard de rendimentos',
        description: 'Acompanhe em tempo real a rentabilidade do seu imóvel e a sua taxa de ocupação através do Aplicativo Yogha.',
        image: require('assets/svg/owner/Dashboard.svg').default
    },
]


const CardInfo: FC<CardInfoProps> = ({ description, image, title }) => {
    return (
        <S.CardInformation>
            <S.CardInformationRow>
                <img src={image} alt="" />
                <span>{title}</span>
            </S.CardInformationRow>
            <p>{description}</p>
        </S.CardInformation>
    )
}

interface SessionSeparatorProps {
    category: string;
    title: string | React.ReactNode;
    description: string;
    image: string;
    textButton: string;
    onClickButton?: () => void;
    widthButton?: string;
}



const SessionSeparator: FC<SessionSeparatorProps> = ({ description, image, title, category, textButton, widthButton, onClickButton }) => {
    return (
        <S.InfoManagement>
            <img src={image} alt="" />
            <S.InfoManagementCollumnText>
                <h5>{category}</h5>
                <span>{title}</span>
                <h4>{description}</h4>
                <S.ButtonContainer>
                    <ButtonGradient width={widthButton} onClick={onClickButton} title={textButton} />
                </S.ButtonContainer>
            </S.InfoManagementCollumnText>
        </S.InfoManagement>
    )
}

type CardFeaturesProps = {
    title: string;
    image: string;
}

const CardFeaturesObj: CardFeaturesProps[] = [
    {
        image: require('assets/svg/owner/Building.svg').default,
        title: 'Seu imóvel pronto para rentabilizar'
    },
    {
        image: require('assets/svg/owner/Monetize.svg').default,
        title: 'Excelente  custo-benefício'
    },
    {
        image: require('assets/svg/owner/Planning.svg').default,
        title: 'Projetos com qualidade e rapidez'
    },
    {
        image: require('assets/svg/owner/Helmet.svg').default,
        title: 'Execução completa da obra.'
    },
    {
        image: require('assets/svg/owner/Shield.svg').default,
        title: 'Componentes de alta duração'
    },
]

const CardFeatures = ({ image, title }: CardFeaturesProps) => {
    return (
        <S.CardFeature>
            <div>
                <img src={image} alt="" />
            </div>
            <p>{title}</p>
        </S.CardFeature>
    )
}

const pubs = ['Lavandeira OMO', 'Serviços On Demand', 'Kuritibike', 'Minimarkets', 'Soluções IoT', 'Suporte 24h']

const comments: CommentProps[] = [
    {
        author: 'João Marcelo',
        comment: 'Em quase uma década de uma parceria bem sucedida, testemunhei a melhora da taxa de ocupação e das receitas de locação do meu imóvel. Sempre com mto profissionalismo e um ótimo relacionamento.    ',
        id: '1',
        type: 'Proprietário'
    },
    {
        author: 'Cleuber',
        comment: 'Como investidor, procuro renda compatível com o mercado, segurança e tranquilidade e são estes os requisitos que a empresa Yogha vem me entregando ao longo dos anos.',
        id: '1',
        type: 'Proprietário'
    },
    {
        author: 'Henrique',
        comment: 'Minha experiência com a Yogha nesses últimos 3 anos tem sido muito positiva, principalmente na transparência e na comunicação. Espero que a Yogha continue crescendo e se consolidando no mercado.',
        id: '1',
        type: 'Proprietário'
    },
]




export const SessionManagament = () => {
    const navigate = useNavigate();

    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        enterpriseCity: '',
        stateProperty: '',
    });

    const breakpoint = useScreenWidth()

    const handleCreate = async () => {
        if (form.name === '' || form.email === '' || form.phone === '' || form.stateProperty === '') {

            return showAlert({ active: true, title: 'Preencha todos os campos', type: "ERROR" })
        }
        if (!form.email.includes('@')) {
            return showAlert({ active: true, title: 'Esse e-mail é inválido. tente usar assim: exemplo@email.com', type: "ERROR" })
        }
        try {
            const { data } = await fetchAxios.post('/land', {
                "name": form.name,
                "email": form.email,
                "phone": form.phone,
                "uf": form.stateProperty,
                "city": form.enterpriseCity
            })
            if (data.code === 200) {
                showAlert({ active: true, title: 'Formulário enviado com sucesso', type: "SUCCESS" })
                setForm({
                    name: '',
                    email: '',
                    phone: '',
                    enterpriseCity: '',
                    stateProperty: '',
                })
                navigate('/proprietarios/obrigado/');
            } else {
                showAlert({ active: true, title: data.message, type: "ERROR" })

            }



        } catch (error) {
            console.error(error)
        }
    }

    const handleButtonClick = () => {
        const element = document.getElementById('Forms');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    return (
        <S.Container>
            <S.Content>
                <S.Header>
                    <h1>Tudo que você precisa para <span>morar</span> ou <span>rentabilizar</span></h1>
                    <p>Com o Yogha Admin, você conta com soluções inteligentes e inovadoras para aprimorar a experiência de moradia e locação do seu imóvel e maximizar os seus lucros. Conheça as vantagens:</p>
                </S.Header>
                <S.CardServicesContainer>
                    {CardInfoObj.map((item) => (
                        <CardServices key={item.title} description={item.description} image={item.image} title={item.title} />
                    ))
                    }
                </S.CardServicesContainer>
                <SessionSeparator onClickButton={handleButtonClick} widthButton='100%' title='Com o Yogha Gestão, rentabilidade e praticidade caminham juntas.' category='Yogha Gestão' description='Yogha Gestão garante aos nossos parceiros proprietários uma administração completa e inteligente do seu imóvel, visando a máxima rentabilidade, de forma ágil e transparente.' image={breakpoint ? require('assets/image/owner/managementphotomobile.jpg') : require('assets/image/owner/managementphoto.png')} textButton='Quero conectar meu imóvel' />

                <S.CardInformationContainer>
                    {CardInfoObj2.map((item) => (
                        <CardInfo key={item.title} description={item.description} image={item.image} title={item.title} />
                    ))
                    }
                </S.CardInformationContainer>
                <S.ContainerPubs>
                    <h1>O Yogha Admin conecta seu imóvel a serviços, marcas e tecnologias inteligentes, levando-o a um novo paradigma de moradia.</h1>
                    <S.PubsCollumn>
                        <S.Pubs>
                            {pubs.slice(0, 3).map((item) => (
                                <Tag title={item} key={item} />
                            ))}</S.Pubs>
                        <S.Pubs>
                            {pubs.slice(3, 6).map((item) => (
                                <Tag title={item} key={item} />
                            ))}
                        </S.Pubs>
                    </S.PubsCollumn>
                </S.ContainerPubs>
                <SessionSeparator widthButton='100%' title={<span>Deixe seu imóvel pronto para <strong>morar</strong> ou <strong>rentabilizar</strong> com Yogha Design</span>} category='Yogha Design' onClickButton={handleButtonClick} description='Da arquitetura ao enxoval, da mobília à decoração, com o Yogha Design, cuidamos de tudo para que você usufrua ou rentabilize com o seu imóvel com rapidez e sem complicações.
' image={breakpoint ? require('assets/image/owner/designphotomobile.png') : require('assets/image/owner/designphoto.png')} textButton='Quero conectar meu imóvel' />

                <S.Features>
                    <S.FeatureRow>
                        {CardFeaturesObj.slice(0, 3).map((item) => (
                            <CardFeatures key={item.title} image={item.image} title={item.title} />
                        ))
                        }
                    </S.FeatureRow>
                    <S.FeatureRow>
                        {CardFeaturesObj.slice(3, 5).map((item) => (
                            <CardFeatures key={item.title} image={item.image} title={item.title} />
                        ))
                        }
                    </S.FeatureRow>
                </S.Features>
                <S.HeaderCarousel>
                    <h1>O que dizem outros <span>proprietários</span></h1>
                </S.HeaderCarousel>
                {breakpoint ? <MobileCarouselCommentsOwner comments={comments} /> : <SliderSession comments={comments} />}
            </S.Content>
            <S.Footer>
                <S.FooterContent id='Forms'>
                    <h1><span>Conecte</span> o seu imóvel</h1>
                    <Forms>
                        <S.InputContainer>
                            <Input width={'100%'} theme='dark' htmlFor='name' onChange={handleChange} placeholder='Nome' title='Nome' type='' value={form.name} />
                        </S.InputContainer>
                        <S.InputContainer>
                            <Input width={'100%'} theme='dark' htmlFor='email' onChange={handleChange} placeholder='Seu melhor e-mail' title='E-mail' type='' value={form.email} />
                        </S.InputContainer>
                        <S.InputContainer>
                            <Input width={'100%'} theme='dark' htmlFor='phone' onChange={handleChange} placeholder='Telefone' title='Telefone (Celular)' type='' value={form.phone} />
                        </S.InputContainer>
                        <S.InputContainer>
                            <Input width={'100%'} theme='dark' htmlFor='enterpriseCity' onChange={handleChange} placeholder='Cidade do empreendimento' title='Cidade do empreendimento (Opcional)' type='' value={form.enterpriseCity} />
                        </S.InputContainer>
                        <S.InputContainer>
                            <Input width={'100%'} theme='dark' htmlFor='stateProperty' onChange={handleChange} placeholder='PR' title='UF do Imóvel' type='' value={form.stateProperty} />
                        </S.InputContainer>

                    </Forms>
                    <S.Footerbaseboard>
                        <li>Ao preencher o formulário, concordo * em receber comunicações de acordo com meus interesses. </li>
                        <li>Ao informar meus dados, eu concordo com a nossa <span>Política de privacidade.</span></li>
                        <li>Você pode alterar suas permissões de comunicação a qualquer memento.</li>
                    </S.Footerbaseboard>
                    <ButtonGradient width='309px' onClick={handleCreate} title='Quero conectar meu imóvel' />

                </S.FooterContent>
            </S.Footer>
            <Alert />

            <Footer theme='dark' />

        </S.Container>
    )
}