import React, { useEffect, useState } from 'react';
import { TileLayer, Marker, useMap, } from 'react-leaflet'
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import * as S from './styles';
import { useAnyProvider } from 'hooks/Context';

interface MapProps {
  contrain: boolean;
  buildings?: DataMap[],
  handleAddress: (value: string) => void
}
export interface DataMap {
  id_unit: number,
  name_unit: string,
  latitude: number,
  longitude: number,
  address: number,
  units: number
}



const iconBuilding = require('../../assets/svg/building.svg').default;

export function Location({ contrain, buildings, handleAddress }: MapProps) {
  const [mapInstance, setMapInstance] = useState<L.Map | null>(null);
  const [zoomLevel, setZoomLevel] = useState(14);
  const [markerZoomLevel, setMarkerZoomLevel] = useState(16);
  const { searchValue, setSearchValue, anyValue, date, setDate, setSearchType, setAutoCompletSearch } = useAnyProvider();

  const [selectedPin, setSelectedPin] = useState(false)
  const [selectedId, setSelectedId] = useState('')
  const [center, setCenter] = useState<L.LatLngTuple>([-25.44538578640348, -49.283599230713705])
  const [markersData, setMarkersData] = useState<any>([])
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [totalUnits, setTotalUnits] = useState(0)

  let units = 0;

  function updateMapSizeWithContrain(contrain: boolean, map: L.Map) {
    if (contrain) {
      map.getContainer().style.width = "100%";
      map.getContainer().style.height = "100%";
      map.getContainer().style.marginLeft = "calc(0%)";
    } else {
      map.getContainer().style.width = "50%";
      map.getContainer().style.height = "100%";
      map.getContainer().style.marginLeft = "calc(50%)";
    }
    setTimeout(() => {
      map.invalidateSize();
    }, 1);
  }

  function MapUpdater({ contrain }: { contrain: boolean }) {
    const map = useMap();

    useEffect(() => {
      updateMapSizeWithContrain(contrain, map);
    }, [contrain, map]);

    return null;
  }


  useEffect(() => {
    if (mapInstance) {
      mapInstance.flyTo(center, zoomLevel, {
        animate: true,
        duration: 1.5,
        easeLinearity: 0.45,
      });
    }
    mapInstance?.on("zoomend", () => {
      setZoomLevel(mapInstance.getZoom());
    });
  }, [center, mapInstance, zoomLevel]);

  const handleSelect = (item: any) => {
    if (item) {
      setCenter([item.latitude, item.longitude]);
      setSelectedId(item.id_luminaria);
      setLatitude(item.latitude);
      setLongitude(item.longitude);
      setZoomLevel(markerZoomLevel);
    }
  };


  useEffect(() => {
    if (selectedPin) {
      setSelectedPin(false)
    }
  }, [selectedPin])
  return (
    <S.Container >
      <S.CustomMapContainer contrain={contrain} ref={setMapInstance} selected={selectedPin} zoomControl={false} minZoom={14} boundsOptions={{
        padding: [100, 100],
        animate: true,
        duration: 2,
        easeLinearity: 0.25,
      }} zoomAnimation={true} markerZoomAnimation={true}
        center={center} zoom={14} scrollWheelZoom={false}>

        <S.CustomZoomControl position="topright" />
        <TileLayer
          eventHandlers={{
            load: () => {
            },
          }}
          url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        {buildings?.map((item: any) => (
          <Marker key={item.latitude} autoPanOnFocus={true} eventHandlers={{
            click: (e) => {
              handleSelect(item)
              setSelectedPin(true)
              handleAddress(item.name_unit)
              setSearchValue(item.name_unit)
              setSearchType('name_building')
              setAutoCompletSearch(item.name_unit + ' - ' + item.address)
            },
          }} position={[item.latitude, item.longitude]}
            icon={L.divIcon({
              html: `<div>
              <p> ${item.units} </p>
              </div>`,
              className: 'iconMap',
              iconAnchor: [10, 10],
              popupAnchor: [10, -19],
              iconSize: [18, 18],
            })
            }
          >
            <S.CustomPopup>
              <span>
                <img src={iconBuilding} alt="" />
                {item.name_unit}
              </span>
              <p>
                {item.address}
              </p>
              <p>
                {item.units} unidades
              </p>
            </S.CustomPopup>

          </Marker>
        ))}
        <MapUpdater contrain={contrain} />
      </S.CustomMapContainer>

    </S.Container>


  );
}



