import { MapContainer, ZoomControl } from 'react-leaflet';
import styled from 'styled-components';
import { NEUTRAL_COLORS, PRIMARY_COLORS } from '../../../../constants/styleConstants';
import { FONT_SIZE, FONT_WEIGTH } from '../../../../constants/fontConstants';


export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 1206px;
    height: 500px;

    margin-top: 64px;
    margin-bottom: 36px;

    row-gap: 16px;

    h1{
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
    }

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        height: unset;
        padding: 0px 20px;
        margin-top: 32px;
        margin-bottom: 0px;
    }

`;

export const CustomZoomControl = styled(ZoomControl)`
border-radius: 50% !important;
background: #fff !important;
 `;

export const Text = styled.span`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    line-height: 140%;
    color:${NEUTRAL_COLORS.HIGH_DARK};
`;

export const CustomMapContainer = styled(MapContainer)<{selected:boolean}>`
width: 100%;
height: 427px;

.iconMap{
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 600;
    /* background: ${props => props.selected ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.WHITE}; */
    background: ${NEUTRAL_COLORS.WHITE};
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${PRIMARY_COLORS.MEDIUM};
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.25);
    /* &:focus{
        background-color: ${PRIMARY_COLORS.MEDIUM};
        color: ${NEUTRAL_COLORS.WHITE};
        border:0
    }
    &:hover{
        border: 2px solid ${PRIMARY_COLORS.MEDIUM};
    }
    &:has(div):focus{
        background-color: ${PRIMARY_COLORS.MEDIUM};
    }
    &:focus-within{
        background-color: ${PRIMARY_COLORS.MEDIUM};
    } */
}
`;
