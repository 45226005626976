import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    width: 100%;

    padding-top: 115px;

    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        width: calc(100% - 20px);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 7.5rem;
        padding-bottom: 120px;
        /* padding-top: unset; */
    }
`;

export const Main = styled.main`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    height: 100vh;

@media (max-width: 768px) {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
}

`;

export const TitlePayment = styled.h1`
margin-top:-16px;
    margin-bottom: 8px;
`;