import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { Input } from "components/Input";
import { Controller } from "react-hook-form";
import { DropdownWithError } from "components/DropdownWithError";
import { FooterWithButtons } from "pages/Checkin/components/FooterWithButtons";
import { NewInput } from "components/NewInput";
import { IMaskInput } from "react-imask";
import { useAddress } from "./useAddress";

export const AddressCheckin = () => {
    const { t } = useTranslation();

    const { OnSubmit, control, errors, handleBack, handleSubmit, validateError, contryYouLive, trigger, states } = useAddress();

    return (<>
        {contryYouLive === 'Brasil' || contryYouLive === 'Brazil' ? (
            <S.AddressContainer>
                <h1>{t('checkin.address.title')}</h1>
                <h4>{t('checkin.address.subtitle')}</h4>
                <S.FormContainer>
                    <S.LeftColumn>
                        <Controller
                            name="cep"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <NewInput mask={''} width={'100%'} name="cep" htmlFor="cep" placeholder="" title={t('checkin.address.forms.label.cep')} type="text" errorText={validateError('cep', errors)}>
                                    <IMaskInput
                                        width={'100%'}
                                        mask={[{ mask: "00000-000" }]}
                                        radix="."
                                        unmask={true} // true|false|'typed'
                                        value={value}
                                        onAccept={(value, mask) => {
                                            onChange(value)
                                        }}
                                        placeholder=""
                                        onBlur={() => trigger('cep')}
                                    />
                                </NewInput>
                            )
                            }
                        />
                        <Controller
                            name="number"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input width={"100%"} id="number" onBlur={() => trigger('number')} htmlFor="number" errorText={validateError('number', errors)} onChange={onChange} placeholder={''} title={t('checkin.address.forms.label.number')} value={value} />
                            )
                            }
                        />
                        <Controller
                            name="district"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input width={"100%"} id="ditrict" onBlur={() => trigger('district')} htmlFor="ditrict" errorText={validateError('ditrict', errors)} onChange={onChange} placeholder={''} title={t('checkin.address.forms.label.district')} value={value} />
                            )
                            }
                        />
                        <Controller
                            name={'state'}
                            control={control}
                            render={({ field }) => (
                                <DropdownWithError widthDrop={''} label={t('checkin.address.forms.label.state')} name="state" value={field.value} onChange={(option) => option ? field.onChange(option) : field.onChange(undefined)}
                                    placeholder={t('checkin.address.forms.placeholder.state')} options={states} errorText={validateError('state', errors)} />
                            )}
                        />
                    </S.LeftColumn>
                    <S.RigthColumn>
                        <Controller
                            name="address"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input width={"100%"} id="address" onBlur={() => trigger('address')} htmlFor="address" errorText={validateError('address', errors)} onChange={onChange} placeholder={''} title={t('checkin.address.forms.label.address')} value={value} />
                            )
                            }
                        />

                        <Controller
                            name="complement"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input width={"100%"} id="complement" onBlur={() => trigger('complement')} htmlFor="complement" errorText={validateError('complement', errors)} onChange={onChange} placeholder={''} title={t('checkin.address.forms.label.complement')} value={value} />
                            )
                            }
                        />

                        <Controller
                            name="city"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Input width={"100%"} id="city" onBlur={() => trigger('city')} htmlFor="city" errorText={validateError('city', errors)} onChange={onChange} placeholder={''} title={t('checkin.address.forms.label.city')} value={value} />
                            )
                            }
                        />
                    </S.RigthColumn>
                </S.FormContainer>
                <FooterWithButtons handleBackStep={handleBack} handleNextStep={handleSubmit(OnSubmit)} />
            </S.AddressContainer>
        ) : (
            <S.AddressContainer>
                <h1>{t('checkin.address.title')}</h1>
                <h4>{t('checkin.address.subtitle')}</h4>
                <S.FormContainerInternational>
                    <Controller
                        name="fullAddress"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input width={"100%"} id="fullAddress" onBlur={() => trigger('fullAddress')} htmlFor="fullAddress" errorText={validateError('fullAddress', errors)} onChange={onChange} placeholder={''} title={t('checkin.address.forms.label.fullAddress')} value={value} />
                        )
                        }
                    />
                    <Controller
                        name="cityInternational"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input width={"100%"} id="cityInternational" onBlur={() => trigger('cityInternational')} htmlFor="cityInternational" errorText={validateError('cityInternational', errors)} onChange={onChange} placeholder={''} title={t('checkin.address.forms.label.city')} value={value} />
                        )
                        }
                    />
                    <Controller
                        name="province"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input width={"100%"} id="province" onBlur={() => trigger('province')} htmlFor="province" errorText={validateError('province', errors)} onChange={onChange} placeholder={''} title={t('checkin.address.forms.label.province')} value={value} />
                        )
                        }
                    />
                </S.FormContainerInternational>
                <FooterWithButtons handleBackStep={handleBack} handleNextStep={handleSubmit(OnSubmit)} />
            </S.AddressContainer>
        )}


    </>
    )
}