import { useTranslation } from "react-i18next";
import * as S from "./styles";

const SuccessfulCheckoutSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="89" height="89" viewBox="0 0 89 89" fill="none">
            <circle cx="44.5" cy="44" r="38" fill="#51B755" />
            <path d="M62.0063 32.8728L39.7065 56.0082L28.5566 44.4405" stroke="white" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const SuccessfulCheckout = () => {
    const { t } = useTranslation();

    return (
        <S.SuccessfulContainer>
            <SuccessfulCheckoutSVG />
            <h1>{t('checkin.successfulCheckout.title')}</h1>
            <p>{t('checkin.successfulCheckout.subtitle')}</p>
        </S.SuccessfulContainer>
    )
}