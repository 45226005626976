import styled from "styled-components";
import { FONT_SIZE,FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
display: flex;

margin: 0px;

justify-content: center;
align-items: center;

`;

export const Comments = styled.div`
display: flex;
flex-direction: column;

width: calc(638px - 64px);
padding: 0px 32px;

align-items: center;
justify-content: flex-start;

row-gap: 48px;

`;

export const Title= styled.h1`
font-size: ${FONT_SIZE.HUGE};
font-weight: ${FONT_WEIGTH.HUGE};
line-height: 1.4;
padding: 0px 20px;
text-align: center;
`;

export const Comment = styled.p`
font-size: ${FONT_SIZE.MEDIUM};
font-weight: ${FONT_WEIGTH.LARGE};
line-height: 1.4;
text-align: left;
padding-left: 12px;
`;

export const UserContainer = styled.div`
display: flex;
flex-direction: column;

row-gap: 8px;

align-self: flex-start;

padding-left: 12px;
`
export const Avaliations = styled.div`
display: flex;
flex-direction: row;

align-items: center;

gap: 4px;

img{
    width: 16px;
    height: 16px;
}
`;

export const UserInfos = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
`;

export const UserName = styled.p`
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.MEDIUM};
`;

export const UserReference = styled(UserName)`
color: ${NEUTRAL_COLORS.DARK_GRAY};`;

