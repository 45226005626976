import * as S from "./styles";
import useAlert from "components/Notification";
import { InputFileProps } from "./types";
import { useInputFile } from "./useInputFile";


export const InputFile: React.FC<InputFileProps> = ({ name, description, onFileChange, initialPreviewUrl }) => {

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" });

    const { handleDelete, handleFileChange, preview } = useInputFile({ onFileChange, initialPreviewUrl, showAlert });

    return (
        <>
            {preview ? (
                <S.PreviewImage>
                    <img src={preview} alt="Preview" />
                    <S.DeleteButton onClick={handleDelete}>
                        <img src={require('../../../../assets/svg/spaceUser/TrashRed.svg').default} alt="Delete" />
                    </S.DeleteButton>
                </S.PreviewImage>
            ) : (
                <S.InputFileContainer htmlFor={name}>
                    <S.InputFileContent>
                        <S.InputFileIcon>
                            <img src={require('../../../../assets/svg/checkin/PlusIcon.svg').default} alt="Upload" />
                        </S.InputFileIcon>
                        <p>{description}</p>
                    </S.InputFileContent>
                    <input onChange={handleFileChange} id={name} name={name} type="file" accept="image/jpeg, image/png, image/jpg" />
                </S.InputFileContainer>
            )}
            <Alert />
        </>
    );
};
