import { useMobileNavigation } from "./useMobileNavigation"
import * as S from "./styles"
import { useState, } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

type MobileNavigationProps = {
    isLoadingOrIsFetching?: boolean,
}

type HomeButtonProps = {
    pathname: string,
}

export const MobileNavigation = ({ isLoadingOrIsFetching = false, }: MobileNavigationProps) => {

    const [visibleNavigation, setVisibleNavigation] = useState(true);

    const navigate = useNavigate();

    const { pathname } = useLocation();

    useMobileNavigation({ isLoadingOrIsFetching, setVisibleNavigation });

    const HomeButton = ({ pathname }: HomeButtonProps) => <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <path d="M18.1716 7.72843L10.6716 0.65218C10.6679 0.648981 10.6644 0.645536 10.6613 0.641867C10.3851 0.390742 10.0253 0.251587 9.65203 0.251587C9.27878 0.251587 8.91895 0.390742 8.64281 0.641867L8.6325 0.65218L1.14094 7.72843C0.988123 7.86895 0.866141 8.03966 0.782711 8.22976C0.699281 8.41986 0.656218 8.62521 0.65625 8.83281V17.5C0.65625 17.8978 0.814285 18.2793 1.09559 18.5607C1.37689 18.842 1.75843 19 2.15625 19H6.65625C7.05407 19 7.43561 18.842 7.71691 18.5607C7.99821 18.2793 8.15625 17.8978 8.15625 17.5V13H11.1562V17.5C11.1562 17.8978 11.3143 18.2793 11.5956 18.5607C11.8769 18.842 12.2584 19 12.6562 19H17.1562C17.5541 19 17.9356 18.842 18.2169 18.5607C18.4982 18.2793 18.6562 17.8978 18.6562 17.5V8.83281C18.6563 8.62521 18.6132 8.41986 18.5298 8.22976C18.4464 8.03966 18.3244 7.86895 18.1716 7.72843ZM17.1562 17.5H12.6562V13C12.6562 12.6022 12.4982 12.2206 12.2169 11.9393C11.9356 11.658 11.5541 11.5 11.1562 11.5H8.15625C7.75843 11.5 7.37689 11.658 7.09559 11.9393C6.81429 12.2206 6.65625 12.6022 6.65625 13V17.5H2.15625V8.83281L2.16656 8.82343L9.65625 1.74999L17.1469 8.82156L17.1572 8.83093L17.1562 17.5Z" fill={pathname.includes('home') ? '#FF7A2A' : '#818181'} />
    </svg>

    const SearchButton = () => <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M11.5312 18.75C15.8805 18.75 19.4062 15.2242 19.4062 10.875C19.4062 6.52576 15.8805 3 11.5312 3C7.18201 3 3.65625 6.52576 3.65625 10.875C3.65625 15.2242 7.18201 18.75 11.5312 18.75Z" stroke="#818181" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.1 16.4436L21.6563 20.9999" stroke="#818181" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

    const MenuButton = () => <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M4.25 12H20.75" stroke="#818181" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.25 6H20.75" stroke="#818181" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.25 18H20.75" stroke="#818181" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    return (<>

        <S.Container>
            <button onClick={() => navigate('/')}>
                <HomeButton pathname={pathname} />
                <p>
                    Home
                </p>
            </button>
            <button onClick={() => navigate('/search-location')}>
                <SearchButton />
                <p>
                    Buscar
                </p>
            </button>
            <button onClick={() => navigate('/menu')}>
                <MenuButton />
                <p>
                    Menu
                </p>
            </button>
        </S.Container>
    </>

    )
}