import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

const background = require('assets/image/owner/sliderbackground.png');


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100vw - 6px);
  padding-bottom: 50px;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(295px + 88px);
`;

export const CarouselItem = styled.div`
    display: flex;

    width: 100%;

    align-items: center;
    justify-content: center;
`;


export const BackgroundContainer = styled.div`
    display: flex;

    flex-direction: column;

    width: calc(100% - 32px);
    justify-content: flex-start;
    height: calc(295px + 88px);

    padding: 32px;


    h3{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.WHITE};
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    strong{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    gap: 20px;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: row;

    gap: 8px;

    p{
        color: ${NEUTRAL_COLORS.WHITE} !important;
    }

    width: 100%;
    justify-content: space-between;
`;

export const Logo = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    img{
      width: 58px;
    }
`;

export const Author = styled.div`
    display: flex;
    flex-direction: column;

    gap: 8px;

    strong{
        ${FONT.BODY.BODY_MEDIUM[500]};
        color: ${PRIMARY_COLORS.MEDIUM};
    }
    p{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.WHITE};
    }
`;

export const Slide = styled.div<{ active: boolean }>`
  position: absolute;
  width: 100%;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: opacity 0.5s;
`;

export const LeftButton = styled.button`
  display: flex;
  cursor: pointer;

  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  background: transparent;

  border-radius: 29px;

  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);

  `;

export const RightButton = styled(LeftButton)`
    transform: rotate(180deg);


  `;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
        width: 100%;
}
    `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;