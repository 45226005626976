import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToTop = (selector?: string) => {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const element = selector ? document.querySelector(selector) : window;
    if (element) {
      element.scrollTo(0, 0);
    }
  }, [pathname, selector]);
};

export default useScrollToTop;
