import { NEUTRAL_COLORS } from "constants/styleConstants";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Container = styled.div``;

export const AddressContainer = styled.div<{hasFrom?:boolean,type:string}>`
    display: flex;
    flex-direction: column;

    row-gap: 0px;

    max-height: 70px;

    ${({type}) => type!=='unit' ? 'margin-bottom: 0px;' : 'margin-bottom: 16px;'}

`;

export const ValueContainer = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    max-height:47px;
`;

export const ValueContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const Image = styled(motion.img)`
  min-width: 100%;
  min-height: 192px;
  max-height: 192px;
  pointer-events: none;
  object-fit: cover;
  background-color: ${NEUTRAL_COLORS.GRAY};
  flex-shrink: 0;

  @media (max-width: 768px) {
    border-radius: 8px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: 192px;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  margin-bottom: 16px;
`;

export const ImageContainer = styled.div<{ translateValue: number; transition: boolean }>`
  display: flex;
  transition: ${(props) => (props.transition ? 'transform 0.2s ease-in-out' : 'none')};
  transform: translateX(${(props) => props.translateValue}%);
`;