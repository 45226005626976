import { FONT } from "constants/fontConstants";
import styled from "styled-components";
export const Container = styled.div``;

export const CollectionCards = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 278px);
    gap: 32px;
    width: 100%;

    @media (width < 768px) {
        grid-template-columns: repeat(1, fit-content(100%));

        place-content: center;
        place-items: center;

        padding-bottom: 140px;

    }
`;
export const MyReservations
= styled.h1`
    font-size: 20px;
    align-self: flex-start;

    @media (width < 768px) {
        align-self: center;
        margin-top: 12px;
    }
`;
export const Description
= styled.p`
    font-size: 16px;
    color: #818181;
    width: 50%;
    margin-top: 16px;

@media (width < 768px) {
    width: 100%;
    text-align: center;
}

`;
export const DivDescription = styled.div`
    width: 100%;
    margin-top: -16px;
    div{
        width: 170px;
        margin-top: 40px;
    }
    height: calc(480px - 76px);
    @media (height < 900px) {
        height: calc(480px - 96px);
    }
    @media (width < 768px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        text-align: center;
        & > div{
            align-self: center;
            margin:0;
            padding: 0;
        }
    }
`;

export const ContainerCards = styled.div`
        display: flex;
        flex-direction: column;
        margin-top: -16px;
        row-gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        align-self: flex-start;

        @media (width < 768px) {
            align-self: center;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
        }

`

export const LoadingImage = styled.img`

    width: 96px;
    height: 96px;


    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }

    `;

export const ContainerLoading = styled.div`
display: flex;

flex-direction: column;

justify-content: center;
align-items: center;
width: calc(4 * 278px);
margin-top: 40px;

height: calc(480px - 96px);

h3{
    ${FONT.BODY.BODY_MEDIUM[600]}
    margin-bottom: 16px;
}

        @media (width < 768px) {
            width: 100%;
            text-align: center;
        }
`;
