import React, { useState, useRef } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { ButtonCarousel } from 'components/ButtonCarousel';
import { SlideBlogProps } from 'pages/Blog/components/SlideBlog';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface MobileBlogHeroProps {
    blogContent: SlideBlogProps[];
}



const MobileBlogHero = ({ blogContent }: MobileBlogHeroProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate()


    const nextSlide = () => {
        if (currentIndex < blogContent.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    const convertDateFormat = (dateStr: string | undefined) => {
        if (!dateStr) return '';
        const dateObj = parseISO(dateStr);
        const convertedDate = format(dateObj, 'dd MMM \'de\' yyyy', { locale: ptBR });
        return convertedDate;
    };

    const imageUrl = 'https://blog.yogha.com.br'

    const handleSlug = (slug: string | undefined) => {
        navigate(`article/${slug}`)
    }

    return (
        <S.Container>
            <S.CarouselContainer ref={slideRef}>
                {blogContent.map((item, index) => {
                    return (
                        <S.Slide
                            key={index + item.id}
                            active={index === currentIndex}
                            onClick={() => handleSlug(item.attributes.slug)}
                        >
                            <S.SlideContent key={item.id + index + item.attributes.title}>
                                <S.SlideImage src={imageUrl + item.attributes.thumbnail.data.attributes.url} alt="" />
                                <S.SlideTextContent>
                                    <h2>{item.attributes.category.data.attributes.title}</h2>
                                    <h4>{item.attributes.title}</h4>
                                    <S.FooterSlide>
                                        <p>{convertDateFormat(item.attributes.datePublication)}</p>
                                        <p>|</p>
                                        <p>Leitura: {item.readingTime} Minutos</p>
                                    </S.FooterSlide>
                                </S.SlideTextContent>
                            </S.SlideContent>
                        </S.Slide>
                    )
                })}
            </S.CarouselContainer>
            <S.ButtonContainer>
                <ButtonCarousel onClick={prevSlide} direction="left" />
                {blogContent.map((_: any, i: number) => {
                    return <S.Bullet key={i} active={currentIndex === i ? true : false} />;
                })}
                <ButtonCarousel onClick={nextSlide} direction="right" />
            </S.ButtonContainer>
        </S.Container>
    );
};

export default MobileBlogHero;
