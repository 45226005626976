import { Service } from "pages/Product/components/CustomStayCard";

export const generateReserveServices = (finalCleaningServices: Service[], extraCleaningServices: Service[], weekValue: number): { service_id: number; amount: number, value: number }[] => {
    const services: { service_id: number; amount: number ; value: number }[] = [];

    if (finalCleaningServices.length > 0) {
        services.push({
            service_id: finalCleaningServices[0].service_id,
            amount: 1,
            value: Number(finalCleaningServices[0].value)
        });
    }

    if (weekValue > 1 && extraCleaningServices.length > 0) {
        services.push({
            service_id: extraCleaningServices[0].service_id,
            amount: weekValue - 1,
            value: Number(finalCleaningServices[0].value)
        });
    } else {
        const index = services.findIndex(service => service.service_id === extraCleaningServices[0]?.service_id);
        if (index !== -1) {
            services.splice(index, 1);
        }
    }

    return services;
};
