import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
`;

export const LoadingImage = styled.img`

    width: 96px;
    height: 96px;


    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }

    `;