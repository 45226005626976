import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const AccompanyingContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;

    h1{
        ${FONT.HEADLINES.H5}
    }

    padding-bottom: 48px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        padding-bottom: 0px;

    }
`;

export const ExplainContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    strong{
        ${FONT.BODY.BODY_SMALL[400]};
        margin-bottom: 48px;
        margin-top: 16px;
    }
`;

export const ContainerDropDownHeadline = styled.div`
    display: flex;

    width: 100%;
    max-width: 476px;

    justify-content: space-between;

    strong{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${NEUTRAL_COLORS.DARK};
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]}
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }
    margin-bottom: 32px;
`;

export const EditButton = styled.button`
    display: flex;

    justify-content: center;
    align-items: center;

    background-color: ${NEUTRAL_COLORS.WHITE};

    min-width: 44px;
    min-height: 44px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 999px;

    img{
        width: 24px;
        height: 24px;
    }

    cursor: pointer;
`;

export const DeleteButton = styled(EditButton)`

`;

export const ContainerDropDown = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    width: 100%;
    max-width: 476px;
    min-height: 300px;

    padding-bottom: 48px;

    @media (max-width: 768px) {
        min-height: auto;
        max-width: calc(100%);
    }

`

export const NoHasCompanion = styled.div`

    p{
        ${FONT.BODY.BODY_MEDIUM[400]}
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }
`;

export const ContainerContentAccompanying = styled.div`
    display: flex;

    flex-direction: column;

    width: 100%;

`;

export const ActionButtons = styled.div`
    display: flex;

    gap: 8px;
    padding-left: 32px;
    padding-bottom: 16px;
`;

export const ContainerAccompanyingAdd = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    border: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    border-radius: 10px;
`;

export const DropdownContainer = styled.div`
    width: 100%;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    height:64px;
    border-radius: 10px;
    div{
        display: flex;
        align-items: center;
        height: 64px;
        padding-left: 16px;

        p{
        padding-left: 16px;

            font-size: 16px;
        }
    }
    cursor: pointer;
`;