import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const ContainerPage = styled.div`
    display:flex;
    flex-direction:column;

    width: 100%;
    max-height: 100lvh;
    height: 100lvh;
    overflow-y: auto;

`;

export const Header = styled.div`
    display:flex;

    width: 548px;
    height: 96px;

    gap:16px;

    background: ${STATUS_COLORS.SUCCESS};

    border-radius: 6px;

    align-items: center;
    justify-content: center;

    margin-bottom: 48px;

    @media (width < 768px) {
        width: 100%;
        border-radius: 0px;
    }

  `;

export const HeaderText = styled.h2`
    ${FONT.BODY.BODY_SMALL[600]};
    color:${NEUTRAL_COLORS.WHITE};
`;

export const ButtonContainer = styled.div`
    display:flex;

    justify-content: center;

    gap: 8px;

    margin-top: 64px;
    margin-bottom: 64px;

    @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 36px;
        margin-bottom: 46px;
    }
`;

export const ContainerInfo = styled.div`
    display:flex;
    flex-direction:column;

    width: 504px;

    h1{
        ${FONT.BODY.BODY_LARGE[600]};
        color:${NEUTRAL_COLORS.HIGH_DARK};
    }

    text-align: center;

    @media (width < 768px) {
        width: calc(100% - 96px);
        padding: 0px 48px;

        h1{
            ${FONT.BODY.BODY_MEDIUM[600]}
        }
    }
`;

export const UserMessage = styled.div`
    display:flex;
    flex-direction:column;

    gap:8px;

    align-items: center;
    justify-content: center;

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color:${NEUTRAL_COLORS.HIGH_DARK};
    }

    margin-top: 64px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
    margin-top: 48px;

    }

`;

export const Highlight = styled.span`
    color:${PRIMARY_COLORS.MEDIUM};
`;

export const Container = styled.div`
    display:flex;

    align-items: center;
    justify-content: center;

    height: 100%;
    flex: 1;

    margin-top: 32px;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
        color:${NEUTRAL_COLORS.HIGH_DARK};
    }

    @media (width < 768px) {
        flex-direction: column;
        margin-top: 0px;
    }
`;

export const Content = styled.div`
    display:flex;
    flex-direction:column;

    max-width: 548px;
    width: 100%;

    flex-shrink: 0;

    justify-content: flex-start;
    align-items: center;

    @media (width < 768px) {
        max-width: unset;
        width: 100%;
    }

`;

export const Concluded = styled.div`
    display: flex;

    width: 379px;

    text-align: center;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 0px 20px;
    }
`;
