import { useSessionStorage } from "context/sessionStorage"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { usePaymentTypeProvider } from "./usePaymentType"
import { usePaymentProvider } from "context/paymentContext"
import { useAnyProvider } from "hooks/Context"
import { useDateDifference } from "hooks/useDateDifference"
import dayjs from "dayjs"
import { UsePaymentCardProps } from "../components/PaymentCard/types"
import usePaymentStore, { DataSessionPaymentProps } from "../components/PaymentCard/useStorage"
import useExpensesStore from "hooks/useExpensesStorage"

export const usePaymentCard = ({ accommodation }: UsePaymentCardProps) => {

    const { paymentData } = usePaymentStore();

    const [nights, setNights] = useState<any>()

    const { getFromSessionStorage } = useSessionStorage()

    const location = useLocation()
    const validationCupom = location.pathname === '/payment' ? true : false

    const { typePayment } = usePaymentTypeProvider()
    const { totalValue, reserveDetails } = usePaymentProvider()
    const { date, setDate } = useAnyProvider()
    const { expensesData } = useExpensesStore();
    const { accommodationName, address, mainImage } = accommodation ?? reserveDetails
    const { dayDifference: difference } = useDateDifference(date)
    const [hasCodePix, setHasCodePix] = useState<string | null>(null)

    const discountDifference = expensesData && (expensesData.definedDailyValue * difference) - (expensesData.definedDailyValue * difference * 0.1)

    const discountValue = expensesData && (expensesData.definedDailyValue * difference * 0.1)

    const { weeklyDifference: weekValue } = useDateDifference(date)

    // const cupomIcon = require('../../assets/svg/Cupom.svg').default;

    useEffect(() => {
        const paymentDetails = getFromSessionStorage('payment');
        if (paymentDetails !== null) {
            setNights(paymentDetails.difference)
        } else {
            setNights(difference)
        }
        const getDate = getFromSessionStorage('reserver')
        if (!!date && getDate) {
            setDate([dayjs(getDate.arrival_date).toDate(), dayjs(getDate.departure_date).toDate()])
        }
        const codePix = getFromSessionStorage('hasCodePix')

        if (codePix) {
            setHasCodePix(codePix)
        }
    }, []);

    return {
        mainImage,
        accommodationName,
        dataPayment: paymentData as DataSessionPaymentProps,
        typePayment,
        hasCodePix,
        address,
        date,
        weekValue,
        nights,
        expensesData,
        discountDifference,
        discountValue,
        validationCupom,
        difference,
        totalValue
    }
}