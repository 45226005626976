import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div `
    display: flex;

    flex-direction: column;

    width: calc(100% - 160px);

    padding: 0 80px;

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        white-space: pre-wrap;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin-top: -64px;
    }

`;

export const Title = styled.h1`
    ${FONT.HEADLINES.H3};
    color: ${NEUTRAL_COLORS.HIGH_DARK};

    @media (max-width: 768px) {
        ${FONT.BODY.BODY_LARGE[600]};
    }
`;

export const HeaderInfo = styled.div`
    display: flex;

    justify-content: space-between;

    margin-top: 40px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
        margin-bottom: 24px;
    }
`;

export const CollumnArticleInfo = styled.div`
    display: flex;
    flex-direction: row;

    gap: 16px;
`;

export const Hero = styled.img`
    width: 100%;
    height: 575px;

    object-fit: cover;

    border-radius: 15px;

    @media (max-width: 768px) {
        height: unset;
        max-height: 160px;
    }
`;

export const Content = styled.div`
    display: flex;
    position: relative;

    justify-content: space-between;

    width: 100%;

    padding-top: 32px;

    overflow: visible ;

    height:max-content;
`;

export const TextContentDefault = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 722px;
    width: 100%;

    row-gap: 48px;

    p + p:has(img) {
        margin-top: 26px;
    }

    ul{
        padding-left: 8px;
        li{
            list-style: none;
            &::before{
                content: '•';
                font-size: large;
                padding-right: 8px;
            }
        }
    }
    blockquote{
        margin-top: -16px;
        p{
            ${FONT.CAPTION.CAPTION_SEMI_REGULAR}
        }
    }

    @media (max-width: 768px) {
        max-width: unset;
    }

    `;

export const TextContent = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 722px;
    width: 100%;

    row-gap: 16px;

    p + span {
        margin-top: 8px;
    }

    img{
        width: 100%;
        height: 398px;

        border-radius: 16px;

        object-fit: cover;
    }

    p + p:has(a) {
        margin-top: 23px;
    }
    span{
        ${FONT.HEADLINES.H5};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    p:has(em){
        margin: 32px 0;
        ${FONT.BODY.BODY_LARGE[500]};
        white-space: pre-wrap;
    }

    @media (max-width: 768px) {
        span{
            ${FONT.BODY.BODY_MEDIUM[600]};
        }
        p{
            ${FONT.BODY.BODY_SMALL[400]};
        }

        img{
        width: 100%;
        height: unset;
        max-height: 185px;

        border-radius: 16px;

        object-fit: cover;
    }
    }
    `;

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;

    position: sticky;
    top: 120px;
    bottom: 0;

    width: 100%;
    max-width: 310px;
    height: 100%;
    max-height: 416px;

    row-gap: 32px;

    z-index: 1;

    h1{
        ${FONT.BODY.BODY_LARGE[600]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
    p{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;