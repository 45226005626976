import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const ValidationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h1{
        margin-top: calc(120px - 32px);
        ${FONT.HEADLINES.H5}
        margin-bottom: 32px;
    }
    padding-bottom: 40px;

    @media (max-width: 768px) {
        h1{
        margin-top: 0;
    }
        padding-bottom: 0;
    }
`;

export const ArticleText = styled.p`
    margin-top: 186px;

    color: ${NEUTRAL_COLORS.DARK_GRAY};

    text-align: center;

    ${FONT.BODY.BODY_SMALL[400]}

    max-width: 990px;
    @media (max-width: 768px) {
        margin-top: 32px;
        max-width: calc(100% - 32px);
    }
`;

export const ContainerButtons = styled.div`
        display: flex;
    flex-direction: column;

    gap: 8px;

    max-width: 302px;
    width: 100%;
    `;

export const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;

    gap: 32px;

    width: 100%;
    max-width: 302px;
`;

export const InputPayment = styled.div`
    display: flex;
    flex-direction: row;

    position: relative;

    justify-content: space-between;

    align-items: center;

    width: calc(100% - 32px);

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        gap: 0px;
    }

    `;

export const CardRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 10px;

    justify-content: flex-start;
`;

export const InlineCode = styled(CardRow)`
    align-items: center;
    justify-content: space-between;
    h5{
        width: 205px;
        ${FONT.BODY.BODY_MEDIUM[400]};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

@media (max-width: 768px) {
        width: calc(100% - 32px);
        h5{
        width: 205px;
        ${FONT.BODY.BODY_MEDIUM[400]};
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    }
`;

export const ButtonContainer = styled.div`
    width: 81px;

    @media (max-width: 768px) {
        width: 81px;
        align-self: flex-end;
        justify-self: flex-end;
    }

`;