import { useAnyProvider } from 'hooks/Context'
import * as S from './styles'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSessionStorage } from 'context/sessionStorage'

export const HeaderMobile = () => {
    const { setDate } = useAnyProvider();
    const { searchValue } = useAnyProvider()
    const { removeFromSessionStorage } = useSessionStorage()
    const navigate = useNavigate()

    const { pathname } = useLocation()

    const SearchIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.875 18.75C15.2242 18.75 18.75 15.2242 18.75 10.875C18.75 6.52576 15.2242 3 10.875 3C6.52576 3 3 6.52576 3 10.875C3 15.2242 6.52576 18.75 10.875 18.75Z" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4438 16.4436L21.0001 20.9999" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

    const logo = require('assets/svg/Logo.svg').default
    const backToHome = () => {
        setDate([])
        removeFromSessionStorage('dateHeader')
        navigate('/')
    }


    const getWidthByPath = () => {
        if (pathname.includes('property')) {
            return 'calc(100vw - 48px)'
        }
        if (pathname.includes('moradia-flexivel')) {
            return 'calc(100vw - 48px)'
        }
        return 'calc(100vw - 40px)'
    }


    return (
        <S.Container style={{
            width: `${getWidthByPath()}`,
        }}>
            <S.LogonContainer onClick={backToHome}>
                <img src={logo} alt="logo" />
            </S.LogonContainer>
            <S.RightContent>
                {!!searchValue ? <input value={searchValue} readOnly onClick={() => navigate('/search-location')} /> : <input placeholder='Onde você quer ficar?' onClick={() => navigate('/search-location')} readOnly />}
                <S.Button>
                    <SearchIcon />
                </S.Button>
            </S.RightContent>

        </S.Container>
    )
}