import { Container, Image } from './styles';

type Props = {
  width?: string;
  height?: string;
}

export const Logo = ({height,width}:Props) => {

  const logo = require('assets/image/logo.png');

  return (
    <Container>
        <Image width={width} height={height} src={logo}/>
    </Container>
  );
};
