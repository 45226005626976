import styled, { css } from "styled-components";
import { FONT } from "../../../constants/fontConstants";
import { NEUTRAL_COLORS, STATUS_COLORS } from "../../../constants/styleConstants";
import { ReservesProps } from "pages/SpaceUser/pages/Reservations";

export const CardReserve = styled.div`
position: relative;

max-width: 278px;
width: 100%;

border-radius: 16px;

box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);

img{
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;

    width: 100%;
    height: 185px;
    object-fit: cover;
}

cursor: pointer;

@media (width < 768px) {
max-width: 100%;
}
`;

export const Title = styled.h1`
    ${FONT.BODY.BODY_SMALL[600]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Subtitle = styled.h2`
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const ContentCard = styled.div`
    display: flex;
    flex-direction: column;

    padding: 16px;

    align-items: flex-start;

    row-gap: 16px;
`;

export const RowCard = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 210px;
    width: 100%;

    row-gap: 8px;
`;

export const ButtonContainer = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    padding-block: 2px;

    border-top: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};

    div{
     font-weight: 500;
    }


`;

export const Gadget = styled.div<Pick<ReservesProps, 'type'>>`
    display: flex;
    position: absolute;

    align-items: center;
    justify-items: center;

    border-radius: 10px;

    top: 12px;
    left: 12px;

    p{
        ${FONT.CAPTION.CAPTION_SEMI_BOLD};
    }

    padding: 2px 8px;

    ${({ type }) => {
        switch (type) {

            case "UNPAID":
                return css`
                    background-color: ${STATUS_COLORS.WARNING};
                    color: ${NEUTRAL_COLORS.HIGH_DARK};
                    `;
            case "CANCELLED":
                return css`
                    background-color: ${NEUTRAL_COLORS.DARK_GRAY};
                    color: ${NEUTRAL_COLORS.WHITE};
                    `;
            case "CONFIRMADA":
                return css`
                    background-color: ${STATUS_COLORS.INFO};
                    color: ${NEUTRAL_COLORS.WHITE};
                    `;
            case "RECEIVED":
                        return css`
                            background-color: ${STATUS_COLORS.SUCCESS};
                            color: ${NEUTRAL_COLORS.WHITE};
                            `;
            case "PAID":
                        return css`
                            background-color: ${STATUS_COLORS.SUCCESS};
                            color: ${NEUTRAL_COLORS.WHITE};
                            `;
        }
    }};
`;

export const Guests = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: flex-end;

    position: absolute;

    top: 200px;
    right: 8px;

    width: 31px;
    height: 29px;

    img{
        width: 20px;
        height: 20px;
    }
`;

export const TotalGuests = styled.div`
    display: flex;

    position: absolute;

    margin-left: 12px;
    margin-bottom: 11px;


    align-items: center;
    justify-content: center;

    width: 16px;
    height: 16px;

    border-radius: 50%;
    background-color: ${NEUTRAL_COLORS.DARK};

    p{
        ${FONT.CAPTION.CAPTION_SEMI_BOLD};
        color: ${NEUTRAL_COLORS.WHITE};
    }
`;