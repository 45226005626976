export const readingTime = (text: string) => {
    const wordsPerMinute = 200;
    const noOfWords = text.split(/\s/g).length;
    const minutes = noOfWords / wordsPerMinute;
    const readTime = Math.ceil(minutes);
    return readTime;
  };


  export function transformSections(objects: any[]) {
    return objects.map((obj) => {
        let sectionString = '';

        if (obj.attributes && obj.attributes.section) {
            obj.attributes.section.forEach((section: any) => {
                sectionString += section.title ? section.title + ' ' : '';
                section.paragraph.forEach((paragraph: any) => {
                    sectionString += paragraph.text + ' ';
                });
            });
        }

        return sectionString.trim();
    });
}