import { ChangeEvent } from "react";
import * as S from "./styles";
import useAlert from "components/Notification";
import { useTranslation } from "react-i18next";

interface InputFileProps {
    name: string;
    onFileChange?: (file: File | null) => void; // Callback opcional
    setPreview: (preview: string | null) => void;
}

export const InputFileAlternativeSelfie: React.FC<InputFileProps> = ({ name, onFileChange, setPreview }) => {
    const { t } = useTranslation();
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" });

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;

        if (selectedFile) {
            // Verificações do tipo de arquivo
            const fileType = selectedFile.type;
            const allowedTypes = ['image/jpeg', 'image/png'];

            if (!allowedTypes.includes(fileType)) {
                onFileChange?.(null);
                showAlert({ active: true, title: "Tipo de arquivo não permitido", type: "ERROR" });
                return;
            }

            onFileChange?.(selectedFile);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            // Limpa o estado e notifica o componente pai em caso de não seleção
            setPreview(null);
            onFileChange?.(null);
        }
    };

    return (
        <>
            <S.InputFileContainer htmlFor={name}>
                <S.InputFileContent>
                    <p>{t('checkin.selfieValidation.alternateInputText')}</p>
                </S.InputFileContent>
                <input onChange={handleFileChange} id={name} name={name} type="file" accept=".jpg,.jpeg,.png" capture="environment" />
            </S.InputFileContainer>
            <Alert />
        </>
    );
};
