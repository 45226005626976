import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div``;
export const CardInfo = styled.div`
    display: flex;
    flex-direction: column;

    width: calc(587px - 32px);

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    justify-content: start;
`;
export const CardRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 10px;

    justify-content: flex-start;
`;

export const ContainerInformation = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;
`;


export const ButtonContainer = styled(Link)`
    width: 81px;
    text-decoration: none;

    @media (max-width: 768px) {
        width: auto;
        div{
            padding: 0px;
        }
    }
`;

export const InputPayment = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    align-items: center;

    width: calc(690px - 32px);

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    @media (max-width: 768px) {
        width: calc(100% - 24px);
        padding-left: 8px;
    }

    `;

export const InputRow = styled(CardRow)`
    align-items: center;

    @media (max-width: 768px) {
        p{
            ${FONT.BODY.BODY_SMALL[400]}
        }
        img{
            height: 20px;
        }
    }
    `;

export const ButtonConclude = styled.div`
        width: 194px;
    @media (max-width: 768px) {
        width: 100%;

    }
`;

export const Dropdown = styled.div`
    display: flex;

    flex-direction: column;

    h4{
        ${FONT.BODY.BODY_MEDIUM[600]}
    }

    row-gap: 16px;
`;

export const ContainerCreditPayment = styled.div`
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const SelectContainer = styled.div`
    display: flex;

    flex-direction: column;

    row-gap: 40px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;