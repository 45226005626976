import styled from "styled-components";
import { IInput } from "../types";
import { NEUTRAL_COLORS } from "constants/styleConstants";

export const Container = styled.div<Pick<IInput, 'width'>>`
    display: flex;
    flex-direction: row;

    height: 48px;

    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

    border-radius: 99px;

    opacity: 0.9;

    align-items: center;
    justify-content: start;

    padding-left: 18px;

    gap: 10px;

    input{

        border: none;
        outline: none;

        background-color: transparent;

        width: 100%;

        ::placeholder{
            color: ${NEUTRAL_COLORS.DARK_GRAY};
        }
    }

    ${({ width }) => width && `
        width: calc(${width} - 16px);`};

    label{
        margin-top: 5px;
    }
`;