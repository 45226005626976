import { set } from 'date-fns';
import * as S from './styles';
const closerIcon = require('../../assets/svg/calendar/CloseIcon.svg').default;

interface ProductCardProps {
    children: React.ReactNode;
    bottomLine?: boolean;
    title: string;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    transparent?: boolean;
    clickAnotherFunctions?: boolean
    arg?: any,
    width?: string,
    omitCloseButton?: boolean
    reduceModal?: boolean
    fill?: boolean
    center?: boolean
}

export const ProductCard = ({ children, bottomLine, title, isOpen = false, setIsOpen, transparent = false, clickAnotherFunctions, arg, width, omitCloseButton, reduceModal, fill, center }: ProductCardProps) => {


    const onClick = () => {
        arg(false)
        setIsOpen(false)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    return (
        <>
            {isOpen ? <>
                <S.Blur transparent={transparent}>
                    <S.Container fill={fill} width={width} reduceModal={reduceModal} transparent={transparent}>
                        <S.Head bottomLine={bottomLine} >
                            <S.Title>{title}</S.Title>
                            <S.CloseButton style={{
                                visibility: omitCloseButton ? 'hidden' : 'visible'
                            }} onClick={clickAnotherFunctions ? onClick : closeModal}>
                                <img src={closerIcon} alt="" />
                            </S.CloseButton>
                        </S.Head>
                        <S.Content fill={fill} center={center} >{children}</S.Content>
                    </S.Container>
                </S.Blur>
            </>
                : null}

        </>
    )
}