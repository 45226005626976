import styled, { keyframes } from 'styled-components';
import { NEUTRAL_COLORS } from '../../constants/styleConstants';

const slideDown = keyframes`
  from {
  transform: translateY(-50px);
  opacity: 0;
}
to {
  transform: translateY(0px);
  opacity: 1;
}`

const slideRight = keyframes`
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0px);
  }
`;

export const HidedContainer = styled.div<{expanded: boolean, mounted: boolean}>`
  display: flex;
  animation-duration: 1000ms;
  animation-name: ${props => props.mounted && !props.expanded ? slideRight : 'none'};
  gap: 16px;
`;

export const Blur = styled.div<{expanded: boolean}>`
  position: ${({expanded}) => expanded ? 'sticky' : 'fixed' } ;
  top: 0;
  width: 100%;
  height: ${({expanded}) => expanded ? 'auto' : '100%' };
  backdrop-filter: blur(${props => props.expanded ? '0px' : '5px'});
  -webkit-backdrop-filter: blur(${props => props.expanded ? '0px' : '5px'});
  z-index: 1010;
`;

export const GlobalContainer = styled.div<{expanded: boolean, mounted: boolean}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: ${props => props.expanded ? 'center' : 'flex-start'};
  height: ${props => props.expanded ? '86px' : '585px'};
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  align-items: ${props => props.expanded ? 'center' : 'center'};
  animation-duration: ${props => props.expanded ? '400ms' : '800ms'};
  animation-name: ${props => props.mounted && !props.expanded ? 'growDown' : 'none'};
  animation-name: ${props => props.mounted && props.expanded ? 'growUp' : 'none'};
  position: absolute;
  z-index: 1010;
  background-color: ${NEUTRAL_COLORS.WHITE};
  transition: height ${props => props.expanded ? '200ms' : '400ms'};
  .container {
    animation: ${slideDown} 0.8s ease-out 0s forwards;
    max-width: 1366px;
}
`;

export const HeaderContainer = styled.div<{expanded: boolean, pageResult?:boolean}>`
  width: ${props => props.pageResult ? '100%' : '1366px'} ;
  background-color: ${NEUTRAL_COLORS.WHITE};
  display: flex;
  padding-top: ${props => props.expanded ? '0px' : '15px'};
`;

export const LeftContainer = styled.div<{pageResults?:boolean}>`
  width: 50%;
  display: flex;
  align-items: center;
  padding: ${props => props.pageResults ? '0 32px' : '0 80px'};
`;

export const RightContainer = styled.div<{pageResults?:boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${props => props.pageResults ? '0 32px' : '0 80px'};
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
width: 1366px;
margin: 0px auto;

@media (max-width: 768px) {
  width: 100%;
  margin: 0px;
}

`;

export const Text = styled.p`
  display: flex;
  align-items: center;
  height: 60px;
  font-weight: 600;
  font-size: 20px;
  margin-left: 82px;
`;
export const ErrorInput = styled.p`
  color:red;
  font-size: 12px;

`;
