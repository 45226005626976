import { useState, useCallback, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import * as S from './styles';
import { CommentsUsers, CommentsUsersProps } from '../CommentsUsers';

interface SliderCommentsProps {
  comments: CommentsUsersProps[];
}

export const SliderComments = ({ comments }: SliderCommentsProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragX, setDragX] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const autoSlideTimer = useRef<NodeJS.Timeout | null>(null);

  const arrowIcon = require('assets/svg/arrowComments.svg').default;

  const updateSlide = useCallback(
    (newSlide: any) => {
      setCurrentSlide(newSlide);
      setDragX(-newSlide * 713);
      setIsTransitioning(true);
      setTimeout(() => setIsTransitioning(false), 300);
    },
    [setCurrentSlide, setDragX, setIsTransitioning]
  );

  const onDragStop = (e: any, data: any) => {
    const delta = data.x - dragX;
    const newSlide = currentSlide - Math.round(delta / 713);

    const maxX = -((Math.min(comments.length, 5) - 1) * 713);
    const newX = Math.min(0, Math.max(data.x, maxX));
    setDragX(newX);

    if (newSlide < 0) {
      updateSlide(0);
    } else if (newSlide > Math.min(comments.length, 5) - 1) {
      updateSlide(Math.min(comments.length, 5) - 1);
    } else {
      updateSlide(newSlide);
    }
  };

  const goBackward = useCallback(() => {
    const newSlide = Math.max(currentSlide - 1, 0);
    updateSlide(newSlide);
    resetAutoSlideTimer();
  }, [currentSlide, updateSlide]);

  const goForward = useCallback(() => {
    const maxSlide = Math.min(comments.length, 3) - 1;
    const newSlide = currentSlide === maxSlide ? 0 : currentSlide + 1;
    updateSlide(newSlide);
    resetAutoSlideTimer();
  }, [currentSlide, comments.length, updateSlide]);

  const resetAutoSlideTimer = useCallback(() => {
    if (autoSlideTimer.current) {
      clearTimeout(autoSlideTimer.current);
    }
    autoSlideTimer.current = setTimeout(goForward, 5000);
  }, [goForward]);

  const maxDragWidth = comments.length * 713;

  useEffect(() => {
    resetAutoSlideTimer();
    return () => {
      if (autoSlideTimer.current) {
        clearTimeout(autoSlideTimer.current);
      }
    };
  }, [resetAutoSlideTimer]);

  return (
    <>
      <S.Container>
        <S.PortraitCarousel>
          <Draggable
            axis="x"
            bounds={{ left: -maxDragWidth, right: 0 }}
            position={{ x: dragX, y: 0 }}
            onStop={onDragStop}
          >
            <S.CarouselContent
              style={{
                transform: `translateX(${dragX}px)`,
                transition: isTransitioning ? 'transform 300ms' : 'none',
              }}
            >
              {comments.map((comments: any, i: number) => (
                <CommentsUsers key={comments.user + i} {...comments} />
              ))}
            </S.CarouselContent>
          </Draggable>
          <S.BorderRight />
          <S.BorderLeft />
          <S.BorderPortrain />
        </S.PortraitCarousel>
        <S.ButtonLeft onClick={goBackward}>
          <img src={arrowIcon} alt="" />
        </S.ButtonLeft>
        <S.ButtonRight onClick={goForward}>
          <img src={arrowIcon} alt="" />
        </S.ButtonRight>
      </S.Container>
    </>
  );
};
