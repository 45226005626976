import { useSessionStorage } from "context/sessionStorage"
import { useEffect, useState } from "react"

type PersonalData = {
    name: string;
    surname: string;
    email: string;
    birthDate: string;
    documentType: string;
    documentNumber: string;
    phone: string;
}

type ArrivalAndDepartureTime = {
    arrival_time: string,
    checkout_time: string
}

type GuestCheckinData = {
    name: string,
    surname: string,
    email: string,
    phone: string,
    document: string,
    birth: string,
    customer_id: string,
    identity_document: string,
    method: string,
    accepted_email: boolean
}

type CheckingDetailsData = {
        departure_date: string,
        arrival_date: string,
        max_accompanying: number;
        booking_id: string;
        customer_id : string;
        checkinId: string;
}

type NationalAddress = {
    cep: string,
    address: string,
    complement: string,
    number: string,
    district: string,
    city: string,
    state: {
      value: string,
      label: string
    }
}

type InternationalAddress = {
    fullAddress: string,
    cityInternational: string,
    province: string
}

type AddressData = {
    country?: string,
    state?: string,
    street: string,
    cep?: string,
    number?: string,
    city: string,
    district?: string,
    complement?: string
}

type RequestCustomerData = {
    "name": string,
    "surname": string,
    "email": string,
    "date_of_birth": string,
    "identity_document": string,
    "mobile_phone": string,
    "document": string,
    "booking_id": string
}

export const useCheckin = () => {
    const [accompanying, setAccompanying] = useState<RequestCustomerData[]>([])
    const [checkinDetails, setCheckinDetails] = useState<CheckingDetailsData>({} as CheckingDetailsData)
    const [guestCheckinData, setGuestCheckinData] = useState<GuestCheckinData>({} as GuestCheckinData)
    const [address, setAddress] = useState<AddressData>({} as AddressData)
    const [arrivelAndDepartureHours, setArrivelAndDepartureHours] = useState<ArrivalAndDepartureTime>({} as ArrivalAndDepartureTime)

    const { getFromSessionStorage } = useSessionStorage()

    const removeSpecialCharacters = (phone: string): string | undefined => {
        if (!phone) return;
        return phone.replace(/[^0-9]/g, '');
    }

    const formatAccompanyingPhone = (phone: string) => {
        if(!phone) return;
        return '+' + removeSpecialCharacters(phone)
    }

    function formatBirthDate(data: string): string  {
        // Verifica se a data tem o comprimento esperado de 8 caracteres (ddmmyyyy)

        // Extrai o dia, mês e ano da string
        const dia = data.substring(0, 2);
        const mes = data.substring(2, 4);
        const ano = data.substring(4);

        // Formata a data no formato yyyy-mm-dd
        return `${ano}-${mes}-${dia}`;
}

    useEffect(() => {
        const dataCheckinDetails = getFromSessionStorage('checkin-details')
        if (dataCheckinDetails) {
            setCheckinDetails(dataCheckinDetails)
        }
        const dataPersonalInfo = getFromSessionStorage('personal-info')
        if (dataPersonalInfo) {
            setGuestCheckinData({ email: dataPersonalInfo.email.toLowerCase(), document: dataPersonalInfo.documentType, phone: '+' + removeSpecialCharacters(dataPersonalInfo.phone), name: dataPersonalInfo.name, surname: dataPersonalInfo.surname, birth: formatBirthDate(dataPersonalInfo.birthDate), customer_id: dataPersonalInfo.customer_id ? dataPersonalInfo.customer_id : null, identity_document: dataPersonalInfo.documentNumber, method: dataPersonalInfo.method, accepted_email: dataPersonalInfo.acceptTerms })
        }
        const dataAccompanying = getFromSessionStorage('accompanying')
        if (dataAccompanying) {
            const dataAccompanyingMapping = dataAccompanying.map((item: PersonalData) => {
                return { booking_id: dataCheckinDetails.booking_id , name: item.name, surname: item.surname, email:  item.email && item.email.trim().length !== 0 ? item.email.toLowerCase() : null, date_of_birth: formatBirthDate(item.birthDate), identity_document: item.documentNumber, mobile_phone: formatAccompanyingPhone(item.phone), document: item.documentType }
            })
            setAccompanying(dataAccompanyingMapping)
        }
        const dataAddress = getFromSessionStorage('address')

        const transformCep = (cep: string) => {
            return `${cep.slice(0, 5)}-${cep.slice(5, 8)}`
        }

        if (dataAddress) {
            const mappingAddress: AddressData  = dataPersonalInfo.countryYouLive.value === 'Brasil' || dataPersonalInfo.countryYouLive.value === "Brazil" ? {
                cep: transformCep(dataAddress.cep),
                street: dataAddress.address,
                number: dataAddress.number,
                district: dataAddress.district,
                city: dataAddress.city,
                state:  dataAddress.state.value,
                country: 'Brasil',
                complement: dataAddress.complement
            }  : {
                street: dataAddress.fullAddress,
                city: dataAddress.cityInternational,
                state: dataAddress.province,
                country: dataPersonalInfo.countryYouLive.value
            }
            setAddress(mappingAddress)
        }
        const arrivalAndDepartureHours = getFromSessionStorage('arrival-and-departure-time')
        if (arrivalAndDepartureHours) {
            setArrivelAndDepartureHours({ arrival_time: dataCheckinDetails.arrival_date+ ' ' + arrivalAndDepartureHours.arrival_time, checkout_time: dataCheckinDetails.departure_date + ' ' + arrivalAndDepartureHours.checkout_time })
        }
    }, [])

    return { accompanying, checkinDetails, address,arrivelAndDepartureHours, guestCheckinData }
}