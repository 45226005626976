import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const InputFileContainer = styled.label`
    display: flex;

    position: relative;

    padding: 12px 19px;
    padding-left: 4px;

    flex-direction: column;

    justify-content: center;
    align-items: flex-start;

    border: none;
    border-radius: 10px;

    background: ${NEUTRAL_COLORS.WHITE};

    input{
        display: none;
        z-index: -1;
    }

    margin-top: -8px;

    cursor: pointer;

    @media (max-width: 768px) {
        margin-top: 0;
    }
    `

export const InputFileIcon = styled.div`
    display: flex;

    align-items: flex-start;
    justify-content: center;

    padding: 10px;

    border-radius: 999px;

    img{
        width: 24px;
        height: 24px;
    }

    background-color: ${PRIMARY_COLORS.MEDIUM};
`

export const InputFileContent = styled.div`
    display: flex;

    flex-direction: column;

    align-items: flex-start;
    justify-content: center;

    gap: 16px;

    p{
        ${FONT.BODY.BODY_SMALL[500]};
        color: ${PRIMARY_COLORS.MEDIUM};
    }
`