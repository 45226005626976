import { zodResolver } from "@hookform/resolvers/zod";
import { useSessionStorage } from "context/sessionStorage";
import { useArrivalAndDepartureSchema } from "pages/Checkin/schemas/ArrivalAndDeparture";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export const useArrivalAndDepartureTime = () => {
    const { saveToSessionStorage, getFromSessionStorage } = useSessionStorage()
    const [arrivalDate, setArrivalDate] = useState<string>('')
    const [departureDate, setDepartureDate] = useState<string>('')

    const schema = useArrivalAndDepartureSchema();

    type FormData = z.infer<typeof schema>;

    const { handleSubmit, formState: { errors }, setValue, control, trigger, watch } = useForm<FormData>({
        resolver: zodResolver(schema),
        defaultValues: {
            accept: false
        }
    });


    function formatDate(data: string): string {
        const [ano, mes, dia] = data.split('-');
        const dataFormatada = new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia));
        return dataFormatada.toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    }

    const pathnameActual: string = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));

    const formatHours = (hour: string) => {

        return `${hour.slice(0, 2)}:${hour.slice(2, 4)}`

    }


    const navigate = useNavigate()

    useEffect(() => {

        const dataReserves = getFromSessionStorage('checkin-details')
        if (dataReserves) {
            setArrivalDate(formatDate(dataReserves.arrival_date))
            setDepartureDate(formatDate(dataReserves.departure_date))
        }
        const hourReserves = getFromSessionStorage('arrival-and-departure-time')
        if (hourReserves) {
            setValue('arrivalHour', hourReserves.arrival_time)
            setValue('departureHour', hourReserves.checkout_time)
        }
    }, [])

    const onSubmit = (data: FormData) => {
        saveToSessionStorage('arrival-and-departure-time', { arrival_time: formatHours(data.arrivalHour), checkout_time: formatHours(data.departureHour) })
        navigate(`${pathnameActual}/additional-info`,{replace: true})
    }

    const handleBackPage = () => {
        navigate(`${pathnameActual}/accompanying`)
    }
    return{
        handleSubmit,
        errors,
        control,
        trigger,
        watch,
        onSubmit,
        arrivalDate,
        departureDate,
        handleBackPage
    }
}