export function validateCPF(cpf: string): boolean {
    cpf = cpf.replace(/\D/g, '');

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    const calcularDigito = (base: string, peso: number): number => {
      const soma = base.split('').reduce((acc, valorAtual) => {
        return acc + parseInt(valorAtual) * peso--;
      }, 0);
      const resto = soma % 11;
      return resto < 2 ? 0 : 11 - resto;
    };

    const digito1 = calcularDigito(cpf.slice(0, 9), 10);
    const digito2 = calcularDigito(cpf.slice(0, 9) + digito1, 11);

    return cpf === cpf.slice(0, 9) + digito1.toString() + digito2.toString();
  }

