import { Routes, Route, Outlet } from 'react-router-dom';
import { Home } from '../pages/Home';
import { Results } from '../pages/Results';
import { Login } from '../pages/Login';
import { Product } from '../pages/Product';
import { Register } from '../pages/Register';
import { ForgotPassword } from '../pages/ForgotPassword';
import { NewPassword } from '../pages/NewPassword';
import { SpaceUser } from '../pages/SpaceUser';
import { SecurityAndPassword } from '../pages/SpaceUser/pages/SecurityAndPassword';
import { AccountInfo } from '../pages/SpaceUser/pages/AccountInfo';
import { Reservations } from '../pages/SpaceUser/pages/Reservations';
import { ReserveDetails } from '../pages/SpaceUser/pages/ReserveDetails';
import { Favorites } from '../pages/SpaceUser/pages/Favorites';
import { HelpCenter } from 'pages/HelpCenter';
import { BlogPage } from 'pages/Blog';
import { Search } from 'pages/Blog/pages/Search';
import { Article } from 'pages/Blog/pages/Article';
import { TermsOfUse } from 'pages/TermsOfUse';
import { Payment } from 'pages/Payment';
import { PixPayment } from 'pages/Payment/pages/PixPayment';
import { CreditPayment } from 'pages/Payment/pages/CreditPayment';
import { CompletePaymente } from 'pages/Payment/pages/CompletPayment';
import { FailedPayment } from 'pages/Payment/pages/FailedPayment';
import { PixCode } from 'pages/Payment/pages/PixCode';
import { Owner } from 'pages/Owner';
import { Developer } from 'pages/Developers';
import { Teste } from 'pages/Testing';
import PrivateRoute from 'components/PrivateRoute';
import { Maintenance } from 'pages/Maintenance';
import { PaymentInfo } from 'pages/SpaceUser/pages/PaymentInfo';
import { FlexHousing } from 'pages/FlexHousing';
import { Menu } from 'pages/Mobile/Menu';
import { CancelReserve } from 'pages/Mobile/CancelReserve';
import { RegisterCardMobile } from 'pages/Mobile/RegisterCardMobile';
import { SearchMobile } from 'pages/Mobile/Search';
import { OwnerThankYou } from 'pages/OwnerThankYou';
import { DeveloperThankYou } from 'pages/DeveloperThankYou';
import { Checkin } from 'pages/Checkin';
import { PersonalInformation } from 'pages/Checkin/Steps/PersonalInformation';
import { ValidationToken } from 'pages/Checkin/Steps/ValidationToken';
import { VerifyDocumentCPF } from 'pages/Checkin/Steps/VerifyDocumentCPF';
import { Accompanying } from 'pages/Checkin/Steps/Accompanying';
import { AddressCheckin } from 'pages/Checkin/Steps/Address';
import { AdditionalInfo } from 'pages/Checkin/Steps/AdditionalInfo';
import { ArrivalAndDepartureTime } from 'pages/Checkin/Steps/ArrivalAndDepartureTime';
import { AddAccompanying } from 'pages/Checkin/Steps/AddAccompanying';
import { SelfieValidation } from 'pages/Checkin/Steps/SelfieValidation';
import { VerifyDocumentPassport } from 'pages/Checkin/Steps/VerifyDocumentPassport';
import { SuccessfulCheckout } from 'pages/Checkin/Steps/SuccessfulCheckout';
import { EditAccompanying } from 'pages/Checkin/Steps/EditAccompanying';
import { RedirectPage } from 'pages/RedirectPage';

export const Routers = () => {

  return (
    <Routes>
      {/* <Route path="/" element={<AwaitingPage />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/manutencao" element={<Maintenance />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Register />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/newpassword/:token/:userId" element={<NewPassword />} />

      <Route path='/menu' element={<Menu />} />
      <Route path='/register-card' element={<RegisterCardMobile />} />
      <Route path='/search-location' element={<SearchMobile />} />

      <Route path="/minha-conta" element={<PrivateRoute component={SpaceUser} />}>
        <Route path="/minha-conta" element={<SpaceUser />}>
          <Route path="informacoes-de-pagamento/" element={<PaymentInfo />} />
          <Route path="alterar-senha/" element={<SecurityAndPassword />} />
          <Route path="informacoes/" element={<AccountInfo />} />
          <Route path="reservas/" element={<Reservations />} />
          <Route path="reserva/:id" element={<ReserveDetails />} />
          <Route path="reserva/:id/cancelar-reserva" element={<CancelReserve />} />
          <Route path="favorites" element={<Favorites />} />
          <Route path="favorites/?=:id" element={<Favorites />} />
        </Route>

      </Route>

      <Route path="/properties/" element={<Results />} />
      <Route path="/property/:id/:id" element={<Product />} />

      <Route path="/payment" element={<PrivateRoute component={Payment} />}>
        <Route path="/payment" element={<Payment />}>
          <Route index element={<Outlet />} />
          <Route path="type_payment=PIX" element={<PixPayment />} />
          <Route path="PIX_CODE/:id" element={<PixCode />} />
          <Route path="type_payment=CREDIT_CARD" element={<CreditPayment />} />
        </Route>
      </Route>

      <Route path="/check-in" element={<Checkin />}>
        <Route element={<Outlet />} />
        <Route index element={<ValidationToken />} />
        <Route path=":id" element={<ValidationToken />} />
        <Route path=":id/personal-info" element={<PersonalInformation />} />
        <Route path=":id/address" element={<AddressCheckin />} />
        <Route path=":id/verify-document" element={<VerifyDocumentCPF />} />
        <Route path=":id/verify-passport" element={<VerifyDocumentPassport />} />
        <Route path=":id/personal-info" element={<PersonalInformation />} />
        <Route path=":id/selfie-validation" element={<SelfieValidation />} />
        <Route path=":id/accompanying" element={<Accompanying />} />
        <Route path=":id/add-accompanying" element={<AddAccompanying />} />
        <Route path=":id/edit-accompanying/:id/" element={<EditAccompanying />} />
        <Route path=":id/arrival-and-departure" element={<ArrivalAndDepartureTime />} />
        <Route path=":id/additional-info" element={<AdditionalInfo />} />
        <Route path=":id/successful" element={<SuccessfulCheckout />} />
      </Route>

      <Route path="/payment/:id/confirmado" element={<CompletePaymente />} />
      <Route path="/payment/:id/erro-de-pagamento" element={<FailedPayment />} />

      <Route path="/helpdesk" element={<HelpCenter />} />
      <Route path="/teste" element={<Teste />} />

      <Route path="/blog" element={<BlogPage />}>
        <Route index element={<Outlet />} />
        <Route path="pesquisar/:query" element={<Search />} />
        <Route path="article/:id" element={<Article />} />
      </Route>

      <Route path="/termos-e-condicoes/" element={<TermsOfUse />} />

      <Route path="/proprietarios/" element={<Owner />} />
      <Route path="/h/:id" element={<RedirectPage />} />

      <Route path="/proprietarios/obrigado/" element={<OwnerThankYou />} />
      <Route path="/incorporadores/" element={<Developer />} />
      <Route path="/incorporadores/obrigado/" element={<DeveloperThankYou />} />
      <Route path="/moradia-flexivel/" element={<FlexHousing />} />

    </Routes>
  );
};