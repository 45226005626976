import { useEffect, useState, useCallback } from 'react';
import * as S from './styles';
import { ButtonCarousel } from '../../../../components/ButtonCarousel';
import Draggable from 'react-draggable';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';

const imageUrl = 'https://blog.yogha.com.br'

export type SlideProps = {
  blogContent: SlideBlogProps[];
};
export interface SlideBlogProps {
  id: number;
  attributes: {
    title: string;
    datePublication: string;
    slug: string;
    category: {
      data: {
        attributes: {
          title: string;
        };
      };
    };
    thumbnail: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
  readingTime?: number;
}

export const SlideBlog = (props: SlideProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragX, setDragX] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [mouseDownTime, setMouseDownTime] = useState<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [cardSelected, setCardSelected] = useState<any>(null);

  const { blogContent } = props;

  const navigate = useNavigate();

  const updateSlide = useCallback(
    (newSlide: any) => {
      setCurrentSlide(newSlide);
      setDragX(-newSlide * 1208);
      setIsTransitioning(true);
      setTimeout(() => setIsTransitioning(false), 300);
    },
    [setCurrentSlide, setDragX, setIsTransitioning]
  );

  const convertDateFormat = (dateStr: string | undefined) => {
    if (!dateStr) return '';
    const dateObj = parseISO(dateStr);
    const convertedDate = format(dateObj, 'dd MMM \'de\' yyyy', { locale: ptBR });
    return convertedDate;
  };

  const onDragStop = (e: any, data: any) => {

    const timeDiff = Number(new Date()) - mouseDownTime;

    if (timeDiff < 125) {
      handleClick();
    }
    setMouseDownTime(null);
    const delta = data.x - dragX;
    const newSlide = currentSlide - Math.round(delta / 1208);

    const maxX = -((Math.min(blogContent.length, 4) - 1) * 1208);
    const newX = Math.min(0, Math.max(data.x, maxX));
    setDragX(newX);

    if (newSlide < 0) {
      updateSlide(0);
    } else if (newSlide > Math.min(blogContent.length, 4) - 1) {
      updateSlide(Math.min(blogContent.length, 4) - 1);
    } else {
      updateSlide(newSlide);
    }
    setIsDragging(false);
  };

  const onStart = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }


  const onDrag = (e: any, data: any) => {
    setIsDragging(true);
  };

  const handleClick = () => {
    navigate('article/' + cardSelected, { preventScrollReset: true });
  };

  const goBackward = () => {
    const newSlide = Math.max(currentSlide - 1, 0);
    updateSlide(newSlide);
  };

  const goForward = () => {
    const newSlide = Math.min(currentSlide + 1, Math.min(blogContent.length, 4) - 1);
    updateSlide(newSlide);
  };

  const maxDragWidth = blogContent.length * 1208;

  const handleMouseDown = (e: any) => {
    setMouseDownTime(Number(new Date()));
  };

  return (
    <S.Container>
      <S.PortraitCarousel>
        <Draggable
          axis="x"
          bounds={{ left: -maxDragWidth, right: 0 }}
          position={{ x: dragX, y: 0 }}
          onStop={onDragStop}
          onStart={onStart}
          onDrag={onDrag}
          onMouseDown={handleMouseDown}
        >
          <S.CarouselContent
            style={{
              transform: `translateX(${dragX}px)`,
              transition: isTransitioning ? 'transform 300ms' : 'none',
            }}
          >
            {blogContent.length > 0 ? blogContent.slice(0, 4).map((item: SlideBlogProps, i: any) => {
              return (
                <S.SlideContent onMouseDown={() => setCardSelected(item.attributes.slug)} key={item.id + i + item.attributes.title}>
                  <S.SlideImage src={imageUrl + item.attributes.thumbnail.data.attributes.url} alt="" />
                  <S.SlideTextContent>
                    <h2>{item.attributes.category.data.attributes.title}</h2>
                    <h4>{item.attributes.title}</h4>
                    <S.FooterSlide>
                      <p>{convertDateFormat(item.attributes.datePublication)}</p>
                      <p>|</p>
                      <p>Leitura: {item.readingTime} Minutos</p>
                    </S.FooterSlide>
                  </S.SlideTextContent>
                </S.SlideContent>
              )
            }) : null}
          </S.CarouselContent>
        </Draggable>
        <S.BorderRight />
        <S.BorderLeft />
      </S.PortraitCarousel>
      <S.ButtonContainer>
        <ButtonCarousel onClick={goBackward} direction="left" />
        {blogContent.slice(0, 4).map((_: any, i: number) => {
          return <S.Bullet key={i} active={currentSlide === i ? true : false} />;
        })}
        <ButtonCarousel onClick={goForward} direction="right" />
      </S.ButtonContainer>
    </S.Container>
  );
};
