import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

const smartphoneMobile = require('assets/image/owner/smartphones/mobilesmartphone.jpg')
const backgroundHeroMobile = require("assets/image/owner/background/mobileresolution.png");
const backgroundHeroMacBook2 = require("assets/image/owner/background/MacBook2.png");
const backgroundHero1920 = require("assets/image/owner/background/1920x1080.png");
const backgroundHero2560 = require("assets/image/owner/background/2560x1080.png");

export const Container = styled.div `
    display: grid;


    height: 100vh;
    width: 100%;

    span{
        ${FONT.BODY.BODY_LARGE[400]};
    }
`;

export const MobileContainer = styled.div`
    h5,h3,span{
        color:${NEUTRAL_COLORS.HIGH_DARK} !important;
    }
`;

export const MobileHero = styled.div`
    display: flex;

    align-items: flex-end;
    justify-content: center;

    width: 100%;
    min-height: 370px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    background-image: url(${smartphoneMobile});
    background-size: auto 1;
    background-position: center;

`;

export const LogoHeader = styled.div`
    display: flex;
    align-items: center;

    h3{
        margin-top: -10px;
        font-size: 46px;
        font-weight: 400;
        color:${NEUTRAL_COLORS.WHITE};
    }
    margin-bottom: 16px;

    @media (max-width: 768px) {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;

        justify-content: center;

        h3{
        margin-top: -10px;
        font-size: 32px;
    }
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;
    justify-content: center;
    margin-left: 80px;
    h5{
        max-width: 430px;
        ${FONT.HEADLINES.H5};
        color:${NEUTRAL_COLORS.WHITE};
    }
    p{
        ${FONT.BODY.BODY_LARGE[600]};
        color:${NEUTRAL_COLORS.WHITE};
    }
    span{
        max-width: 380px;
        ${FONT.BODY.BODY_LARGE[400]};
        color:${NEUTRAL_COLORS.WHITE};
        margin-bottom: 16px;
    }

    @media (max-width: 768px) {
        width: calc(100% - 48px);

        padding: 0px 24px;
        margin-top: 38px;

        text-align: center;

        h5{
            max-width: unset;
            ${FONT.BODY.BODY_LARGE[600]};
        }
        span{
            max-width: unset;
            ${FONT.BODY.BODY_SMALL[400]};
        }
        margin-left: 0px;

    }
`;

export const Hero = styled.div`
    display: flex;
    position: relative;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;

    @media (min-width: 1920px) {
        background-image: url(${backgroundHero2560});
        background-size: auto 1;
        background-position: center;
    }
    @media (max-width: 1920px) and (min-width: 1728px) {
        background-image: url(${backgroundHero2560});
    }
    @media (max-width: 1728px) and (min-width: 1366px) {
        background-image: url(${backgroundHero1920});
    }
    @media (max-width: 1366px) {
        background-image: url(${backgroundHeroMacBook2});
        }
    `;

export const HeroContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 82px;

    max-width: 1366px;
    width: 100%;
    height: 85vh;

`;

export const HeroLeft = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;

    align-self: flex-end;
    img{
        height: 100%;
        object-fit: fill;
    }
`;