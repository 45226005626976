import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Header = styled.header`
    display: flex;

    position: fixed;
    top: 0;

    width: 100%;
    min-height: 86px;

    align-items: center;
    justify-content: center;

    background: ${NEUTRAL_COLORS.WHITE};
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);

    z-index: 9999;

    @media (max-width: 768px) {
        min-height: unset;
        height: 62px;
    }
`;

export const Content = styled.div`
    display: flex;

    width: 100%;
    max-width: calc(1366px - 160px);

    padding: 0 80px;

    justify-content:flex-start;
    align-items: center;

    gap: 202px;

    @media (max-width: 768px) {
    max-width: unset;

    justify-content:center;

    position: relative;
    }
`;

export const NavigateButton = styled.button`
    display: none;
    @media (max-width: 768px) {
        display: flex;

        position: absolute;

        width: 40px;
        height: 40px;

        border: none;

        background-color: transparent;

        align-items: center;
        justify-content: center;

        left: 20px;

        img{
            width: 18px;
            height: 18px;
        }

        justify-self: flex-start;
    }

`;

export const LogoContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  img{
        width: 130px;
        height: 45px;
    }

    cursor: pointer;

    @media (max-width: 768px) {
        img{
            width: 87px;
            height: 30px;
        }
    }
`;