import { useEffect } from "react";

const useScrollToTopByState = (selector?: string, state?: any) => {

  useEffect(() => {
    const element = selector ? document.querySelector(selector) : window;
    if (element) {
      element.scrollTo(0, 0);
    }
  }, [state, selector]);
};

export default useScrollToTopByState;
