import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 370px;
`;

export const Slide = styled.img<{ active: boolean }>`
  position: absolute;
  width: 100%;
  height: 370px;
  object-fit: cover;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: opacity 0.5s;
`;

export const Button = styled.button`
  position: absolute;
  bottom: 10px;
  background-color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
`;

export const NextButton = styled(Button)`
  right: 10px;
`;

export const PrevButton = styled(Button)`
  left: 10px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 62px;

    @media (max-width: 768px) {
        width: 100%;
}
    `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;