import { OptionSelect, OptionSelectProps } from "components/OptionSelect"
import * as S from "./styles"

type FilterCategoryProps = {
    articles: number;
    setArticles: (value: number) => void;
}


export const FilterCategory = ({ articles = 0, setArticles }: FilterCategoryProps) => {

    const types: OptionSelectProps[] = [
        {
            id: 0,
            htmlFor: 'allarticles',
            value: 'allarticles',
            title: 'Todos os artigos',
            selected: true,
        },
        {
            id: 1,
            htmlFor: 'yoghainmedia',
            value: 'yoghainmedia',
            title: 'Yogha na Mídia',
            selected: true,
        },
        {
            id: 3,
            htmlFor: 'flexhousing',
            value: 'flexhousing',
            title: 'Moradia Flexível',
            selected: true,
        },
        {
            id: 4,
            htmlFor: 'enterprise',
            value: 'enterprise',
            title: 'Empreendimentos',
            selected: true,
        },
        {
            id: 2,
            htmlFor: 'news',
            value: 'news',
            title: 'Notícias',
            selected: true,
        },
    ]

    return (
        <S.ContainerOptions>
            <S.RowOption className="blog">
                {types.map((item: OptionSelectProps) => {
                    return (
                        <OptionSelect id={item.id} key={item.id} htmlFor={String(item.id)} selected={item.id === Number(articles) ? true : false} value={item.id} title={item.title} onClick={(e: any) => setArticles(e.currentTarget.value)} />
                    )
                })}
            </S.RowOption>
        </S.ContainerOptions>
    )
}