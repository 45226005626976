import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: grid;

    grid-template-columns: 1fr 1fr;

    width: calc(100% - 160px);

    padding: 0px 80px;

    gap: 90px;

    place-items: center;

    @media (max-width: 768px) {
    grid-template-columns: 1fr;

    width: 100%;

    padding: 0px;

    gap: 24px;
    }

`;

export const InputContainer = styled.div`
    width: 380px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const Image = styled.img`
    min-width: 580px;
    width: 100%;

    transform: rotateY(180deg);

    border-radius: 10px;

    object-fit: cover;

    @media (max-width: 768px) {
    min-width: unset;
    order: -1;
    }
`;

export const LeftCollumn = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 530px;

    text-align: left;

    h1{
        ${FONT.HEADLINES.H5};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    @media (max-width: 768px) {
        max-width: unset;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 483px;

    row-gap: 8px;

    @media (max-width:768px) {
            max-width: unset;
            h1{
                ${FONT.BODY.BODY_LARGE[600]};
            }
    }
`;

export const Form = styled.div`
    display: flex;
    flex-direction: row;

    align-items: flex-end;

    width: 100%;

    gap: 16px;

    margin-top: 40px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
    gap: 32px;

    flex-direction: column;
    }
`;

export const Footer = styled.div`
    display: flex;

    width: 100%;
    max-width: 483px;

    text-align: left;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    @media (max-width: 768px) {
    max-width: unset;
    }
`;