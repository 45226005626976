import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";
import { Icons } from "../Icons";

interface IProps {
  isOpen: boolean;
  disabled: boolean;
  isSelected: boolean;
  widthDrop: number | string;
  itemSelected: boolean;
}

export const DropdownContainer = styled.div<IProps>`
    position: relative;
    z-index: ${props => props.isOpen ? 10 : 1 };;
    width: ${props => typeof props.widthDrop === "number" ? `${props.widthDrop}px`: props.widthDrop};
    height:52px;
    max-height: 52px;
    border: 1px solid ${props =>  props.isSelected ? PRIMARY_COLORS.MEDIUM :  props.disabled ? NEUTRAL_COLORS.LIGHT_GRAY : NEUTRAL_COLORS.GRAY};
    display: flex;
    align-items: center;
    gap: 10px;
    row-gap: 10px;
    padding: 0px 1rem;
    border-radius: ${props => props.isOpen ? '10px 10px 0px 0px' : '10px' };
    color: ${props => props.isOpen ? NEUTRAL_COLORS.HIGH_DARK : props.isSelected ? NEUTRAL_COLORS.HIGH_DARK : props.disabled ? NEUTRAL_COLORS.LIGHT_GRAY :  NEUTRAL_COLORS.DARK_GRAY};
    outline: none;
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 1.4;
    border-bottom: ${props => props.isOpen ? 'none' : ''} ;

    &:hover {
      border-color: ${props => props.isOpen ? '': NEUTRAL_COLORS.DARK_GRAY};
    }

    &:focus {
    border-color:${props => props.isOpen ? PRIMARY_COLORS.MEDIUM : props.disabled ? NEUTRAL_COLORS.LIGHT_GRAY : NEUTRAL_COLORS.DARK_GRAY}
    }

    &::before{
    display:${props=> props.isOpen ? 'block': 'none'  };
    position:absolute;
    content: '';
    width:100%;
    height: 1px;
    margin: 0px;
    margin-left: -16px;
    margin-top: 42px;
    align-self: center;
    padding: 0px auto;
    background-color: ${NEUTRAL_COLORS.GRAY};
    z-index: 20;
  }
  ;


  ul{
    max-height: 272px;
    overflow-y: auto;
    position: relative;

  }

  span {
    flex-grow: 1;
    display: flex;
    margin-top: -1px;
    gap: .5em;
    flex-wrap: wrap;
    font-weight: ${FONT_WEIGTH.MEDIUM};
    color: ${props => props.isOpen ? NEUTRAL_COLORS.DARK_GRAY : props.isOpen === false && props.itemSelected === true ? NEUTRAL_COLORS.HIGH_DARK : '' };
  }

  li{
    padding-left: 48px;
    font-weight: ${FONT_WEIGTH.LARGE};
    &:last-child{
      margin-bottom: 5px;
    }
  }

  .options {
    position: absolute;
    display: none;
    overflow-y: auto;
    border: 1px solid ${props => props.isOpen ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.DARK_GRAY};
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    border-top: none;
    left: -1px;
    top: calc(90%);
    background-color: white;
    z-index: 11;
  }



  .options.show {
    display: block;
    li{
      padding-top: 15px;
      padding-bottom: 15px;
      z-index: 11;
      &:hover{
        z-index: 12;
      background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};
      cursor: pointer;
    }
    }

  }

  .option {
    padding: .25em;
    cursor: pointer;
  }

  .option.selected{
    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY} !important;
  }

  .option.focused{
    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY} !important;
  }

  input{
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
  }

  input::placeholder{
    font-size: ${FONT_SIZE.TINY} !important;
  }

  img{
    width: 14px;
    height: 7px;
    rotate: ${props => props.isOpen ? '180deg' : '0deg'};
    transition: rotate 0.4s ease-in-out;
  }


`;

export const ResultLi = styled.li`
  display: flex !important;
  align-items: center !important;
  gap: 8px ;
  padding-left: 16px !important;
  font-weight: ${FONT_WEIGTH.HUGE} !important;
  font-size: ${FONT_SIZE.TINY} !important;
`
