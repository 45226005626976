import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: calc(100vw - 40px);

    background-color: ${NEUTRAL_COLORS.WHITE};

    position: sticky;
    top: 0;

    padding: 16px 20px;

    align-items: center;
    justify-content: center;

    gap: 16px;

    z-index: 1000;
`;


export const LogonContainer = styled.div`
    display: flex;

    flex: 1;
    img{
        max-width: 73px;
        height: 100%;
        min-height: 26px;
    }

`;


export const Button = styled.div`
    display:flex;

    justify-content: center;
    align-items: center;

    padding: 12px 16px;

    background-color: ${PRIMARY_COLORS.MEDIUM};

    border-radius: 8px;
`;

export const RightContent = styled.div`
    display:flex;

    input{
        display: flex;
        width: 100%;
        flex: 1;
        border: none;
    }

    padding: 8px 16px;
    padding-right: 8px;

    border-radius: 10px;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};

    gap: 8px;
`;