import styled from 'styled-components';
import { FONT_WEIGTH } from '../../constants/fontConstants';
import { motion } from 'framer-motion';
import { PRIMARY_COLORS, NEUTRAL_COLORS } from '../../constants/styleConstants';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 36px;
  margin: 32px 0px 16px 82px;
  position: relative;
  .border-bottom{
    position: absolute;
  bottom: 0;
  height: 4px;
  background-color: ${PRIMARY_COLORS.MEDIUM};
  margin: 0px 0px 0px 0px;
  }

  @media (max-width: 768px) {
    margin: 0px;
  }
`;

export const SingleContainer = styled.div<{selected: boolean}>`
  color: ${props => props.selected ? NEUTRAL_COLORS.HIGH_DARK : NEUTRAL_COLORS.DARK_GRAY};
  font-weight: ${props => props.selected ? FONT_WEIGTH.LARGE : null};
  width: fit-content;
  display: flex;
  align-items: flex-start;
  padding-bottom: 19px;
  margin-right: 16px;
  cursor: pointer;
  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
`;

