import { FONT } from "constants/fontConstants";
import styled from "styled-components";

export const SuccessfulContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 0 auto;
    margin-top: calc(221px - 32px);

    h1{
        ${FONT.HEADLINES.H5}
        margin-top: 25px;
        margin-bottom: 16px;
    }
    p{
        ${FONT.BODY.BODY_SMALL[400]}
    }

    @media (max-width: 768px) {
        text-align: center;
        margin-top: 83px;
    }
`;