import { useState } from "react";
import * as S from "./styles";
import { HeaderAlt } from "../../components/HeaderAlt"
import { Input } from "../../components/Input"
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import useAlert from "../../components/Notification";
import fetchAxios from "services/http";
import { UserContext, UserProvider } from "hooks/userContext";
import { WhatsappButton } from "components/WhatsappButton";

export const ForgotPassword = () => {

    const navigate = useNavigate()

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    // active={notification } title="Você solicitou a recuperação de senha a poucos minutos. Aguarde um minuto para tentar novamente." type="ERROR"

    const [sendEmail, setSendEmail] = useState<boolean>(false);
    const [emailRecover, setEmailRecover] = useState<string>('');
    const [erroEmailRecover, setErroEmailRecover] = useState<string>('');
    const [errorLink, setErrorLink] = useState<string>('');
    const [errorLinkText, setErrorLinkText] = useState<string>('');
    const [requestNumber, setRequestNumber] = useState<number>(0);
    const [notification, setNotification] = useState<boolean>(false);

    const emailSendIcon = require('assets/svg/EmailSend.svg').default;
    //verifyEmail
    const handleRecover = async () => {
        setRequestNumber(prev => prev + 1)
        if (emailRecover === '') {
            setErroEmailRecover('Digite o seu e-mail.');
            setErrorLinkText('');
            setErrorLink('');
            return
        }
        if (!emailRecover.includes('@')) {
            setErroEmailRecover('Esse e-mail é inválido. tente usar assim: exemplo@email.com');
            return
        }


        try {
            const { data } = await fetchAxios.post(`/users/verifyEmail`, {
                email: emailRecover
            });
            if (data.code === 200) {
                const email = await fetchAxios.get(`/users/recoverPassword/${data.user[0].id}`)


            } else {
                showAlert({ active: true, title: data.message, type: "ERROR" })

            }

        } catch (error) {
            setErroEmailRecover('Não encontramos nenhuma conta vinculada a este endereço de e-mail. Verifique o endereço de e-mail ou');
            setErrorLink('/signup');
            setErrorLinkText('Clique aqui caso ainda não tenha uma conta.');
            return

        }


        setSendEmail(true);
    }

    return (<>
        <S.Container>
            <HeaderAlt />
            <S.Content sendEmail={sendEmail} >
                {sendEmail ?
                    <>
                        <S.Header>
                            <S.Title>Recuperar senha</S.Title>
                        </S.Header>
                        <S.SendEmailContainer>
                            <img src={emailSendIcon} alt="" />
                            <S.SendEmailText>Verifique seu email</S.SendEmailText>
                            <S.Subtitle>Verifique a sua caixa de email e siga as instruções para redefinir sua senha.</S.Subtitle>
                        </S.SendEmailContainer>
                        <Button disabled={false} icon="" onClickButton={() => navigate('/login')} size="large" title="Voltar ao login" type="primary" widthSize={'302px'} />
                    </>
                    :
                    <>
                        <S.Header>
                            <S.Title>Recuperar senha</S.Title>
                            <S.Subtitle>Insira seu e-mail cadastrado</S.Subtitle>
                        </S.Header>
                        <Input htmlFor="email" onChange={(e) => setEmailRecover(e.currentTarget.value)} placeholder="E-mail usado no seu cadastro" title="E-mail" type="text" value={emailRecover} errorText={erroEmailRecover} width={'100%'} errorLinkText={errorLinkText} linkError={errorLink} />
                        <S.ButtonContainer>
                            <Button disabled={false} icon="" onClickButton={handleRecover} size="large" title="Enviar" type="primary" widthSize={'100%'} />
                            <Button disabled={false} icon="" onClickButton={() => navigate(-1)} size="large" title="Voltar" type="secondary" widthSize={'100%'} />
                        </S.ButtonContainer>
                    </>
                }
            </S.Content>
            <WhatsappButton />
        </S.Container>
        <Alert />
    </>
    )
}