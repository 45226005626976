import styled from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "../../constants/styleConstants";
import { FONT } from "../../constants/fontConstants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    width: 100%;

    overflow-y: auto;
    height: 100vh;

     @media (width < 768px) {
        width: 100vw;
  }
    `

export const ContainerInputPhoto = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    position: absolute;

    bottom: 0;
    left: 55px;

    input[type='file']{
        display: none;
    }

    border: 1px solid ${NEUTRAL_COLORS.WHITE};
    border-radius: 999px;

    background-color: ${PRIMARY_COLORS.DARK};

    width: 24px;
    height: 24px;

    label{
        display: flex;

        align-items: center;
        justify-content: center;

        cursor: pointer;
        img{
        width: 16px;
        height: 16px;
    }
    }

    @media (width < 768px) {
        left: 45px;
  }

`;

export const ButtonAddPhoto = styled.div`
display: flex;

align-items: center;

margin-top: 16px;

width: 100%;

input[type='file']{
    display: none;
}

label{

    width: 100%;

    height:32px;

    display: flex;

    align-items: center;

    cursor: pointer;

    gap: 8px;

    ${FONT.BODY.BODY_SMALL[500]}
}
`;

export const ButtonDeletePhoto = styled.div`
    display: flex;

    align-items: center;

    width: 100%;

    label{
        display: flex;

        width: 100%;

        align-items: center;

        cursor: pointer;

        height:32px;

        gap: 8px;

        color:${STATUS_COLORS.ERROR};

        ${FONT.BODY.BODY_SMALL[500]}
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: calc(1366px - 158px);

    align-items: center;

    row-gap: 48px;

    padding-top: 40px;
    padding-bottom: 64px;

    @media (width < 768px) {
        width: calc(100% - 40px);
        max-width: 100%;

        padding-left: 20px;
        padding-right: 20px;

        row-gap: 32px;
        padding-top: 32px;
  }

    `;

export const Header = styled.div`
    display: flex;

    width: 100%;

    position: sticky;
    top: 0;

    align-items: center;
    justify-content: center;

    min-height: 86px;
    background-color: ${NEUTRAL_COLORS.WHITE};

    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);

    z-index:100;

    @media (width < 768px) {
        min-height: 62px;
        height: 62px;
    }

`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: calc(1366px - 158px);

    align-items: center;
    justify-content: space-between;

  @media (width < 768px) {

    width: calc(100% - 40px);

    position: relative;

    max-width: auto;

    justify-content: center;

  }

`;

export const BackArrow = styled.img`
display: none;

@media (width < 768px) {
 display: block;

 position: absolute;
 top:0px;
 left: 0px;

 width: 18px;
 height: 15px;

 object-fit: contain;

 cursor: pointer;

 padding: 8px;

}

`;

export const Logo = styled.img`
    width:112px;
    height: 40px;
    padding-bottom: 10px;
    padding-left: 1px;

    cursor: pointer;

    @media (width < 768px) {

        padding:0px;
        width:87px;
        height: 30px;
    }
`;

export const UserContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    width: 100%;

    gap: 16px;

    position: relative ;

    @media (width < 768px) {
        width: 100%;
    }

`;

export const UserImage = styled.div`
    display: flex;


    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);

    max-width: 80px;
    max-height: 80px;


    border-radius: 999px;

    align-items: center;
    justify-content: center;

    border: 4px solid ${NEUTRAL_COLORS.WHITE};


    img{
        width: 80px;
        height: 80px;

        border-radius: 999px;

        object-fit: cover;
    }

    @media (width < 768px) {


        img{
        width: 64px;
        height: 64px;
    }
  }

`;

export const UserName = styled.div`
    display: flex;
    flex-direction: column;

    h1{
        ${FONT.BODY.BODY_LARGE[600]}
    }

     @media (width < 768px) {
    h1{
        ${FONT.BODY.BODY_MEDIUM[600]}
    }
  }
`;

export const GridCard = styled.div`
    display: grid;

    grid-template-columns: repeat(3, 1fr);

    grid-gap: 32px;

    @media (width < 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  }
`;

export const UserActions = styled.div`
    display: flex;
    flex-direction: column;

    align-items: start;

    width: 100%;

    text-align: left;

    row-gap: 20px;

    @media (width < 768px) {
    h2{
        ${FONT.BODY.BODY_LARGE[600]}
    }
  }
`;