import { useState, useRef } from 'react';
import * as S from './styles';
import Draggable from 'react-draggable';
import { CardProps, LinkCard, PostCard } from 'components/Card/types';
import { Card } from 'components/Card';
import { useNavigate } from 'react-router-dom';
import { useAnyProvider } from 'hooks/Context';
import { useSpaProvider } from 'hooks/spaRouterContext';
import { useSessionStorage } from 'context/sessionStorage';

interface ImageCarouselProps {
  data: any,
  titleCarousel: string,
  card: string,
  link?: boolean,
  hasFrom?: boolean,
  type: string,
};

export const ImageCarousel = ({ type, data, titleCarousel, card, link, hasFrom }: ImageCarouselProps) => {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragX, setDragX] = useState(0);
  const dragRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [mouseDownTime, setMouseDownTime] = useState<any>(null);
  const [cardSelected, setCardSelected] = useState<any>(null);
  const [cardSelectedBuilding, setCardSelectedBuilding] = useState<any>(null);

  const arrayItens = data

  const { setSearchValue, setSearchType } = useAnyProvider()
  const { setExpanded, setGetLocation } = useSpaProvider()
  const { saveToSessionStorage } = useSessionStorage()

  const navigate = useNavigate();

  const onDragStop = (e: any, data: any) => {
    e.stopPropagation();
    setIsDragging(false);

    const timeDiff = Number(new Date()) - mouseDownTime;

    if (timeDiff < 400 && cardSelected !== null) {
      handleClick();
    }
    setMouseDownTime(null);

    const delta = data.lastX - dragX;
    const newSlide = currentSlide - Math.round(delta / 370);

    const maxX = -((arrayItens.length - 4) * 370);
    const newX = Math.min(0, Math.max(data.x, maxX));
    setDragX(newX);

    if (newSlide < 0) {
      setCurrentSlide(0);
    } else if (newSlide > arrayItens.length - 4) {
      setCurrentSlide(arrayItens.length - 4);
    } else {
      setCurrentSlide(newSlide);
    }

  };

  const onDragStart = (e: any) => {
    e.stopPropagation();
    setIsDragging(true);
  };


  const onDrag = (e: any, data: any) => {
    setIsDragging(true);
  };

  const goBackward = () => {
    const newSlide = Math.max(currentSlide - 1, 0);
    setCurrentSlide(newSlide);
    setDragX(-newSlide * 370);
    setIsTransitioning(true);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const getButtonStyles = (position: 'left' | 'right') => {
    const baseStyles: React.CSSProperties = {
      position: 'absolute',
      top: '30%',
      pointerEvents: 'auto',
      zIndex: 2,
    };

    if (position === 'left') {
      return {
        ...baseStyles,
        left: '0px',
      };
    } else {
      return {
        ...baseStyles,
        right: '0px',
      };
    }
  };

  const directionIcon = (direction: string): string => {
    if (direction === 'left') {
      return require('../../assets/svg/arrowLeft.svg').default;
    } else {
      return require('../../assets/svg/arrowRight.svg').default;
    }
  }

  const iconLeft = directionIcon('left')
  const iconRight = directionIcon('right')

  const goForward = () => {
    const newSlide = Math.min(currentSlide + 1, data.length - 4);
    setCurrentSlide(newSlide);
    setDragX(-newSlide * 370);
    setIsTransitioning(true);
    setTimeout(() => setIsTransitioning(false), 300);
  };

  const maxDragWidth = (data.length - 1) * 370;

  const handleMouseDown = (e: any) => {
    setMouseDownTime(Number(new Date()));
  };


  const handleClick = () => {
    if (type === 'default') {
      saveToSessionStorage('guest', 1)
      window.open(`/property/${cardSelected}/${cardSelectedBuilding}`, '_blank')
    } else if (type === 'unit') {
      setExpanded(true)
      setSearchValue(cardSelected)
      setSearchType('name_building')
      navigate(`/properties?search=${cardSelected}`)
      setGetLocation(cardSelected)
      saveToSessionStorage('guest', 1)

    } else if (type === 'post') {
      saveToSessionStorage('guest', 1)

      window.location.href = `https://blog.yogha.com.br/${cardSelected}`
    }
  };

  return (
    <S.Container>
      <S.ButtonsCarousel style={getButtonStyles('left')} disabled={currentSlide === 0} onClick={goBackward}><img src={iconLeft} alt='' /></S.ButtonsCarousel>
      <S.ButtonsCarousel style={getButtonStyles('right')} disabled={currentSlide === data.length - 4} onClick={goForward}><img src={iconRight} alt='' /></S.ButtonsCarousel>
      <S.ContentCaroussel>
        <S.Title>{titleCarousel}</S.Title>
        <Draggable
          axis="x"
          position={{ x: dragX, y: 0 }}
          onStop={onDragStop}
          bounds={{ left: -maxDragWidth, right: 0 }}
          onStart={onDragStart}
          onDrag={onDrag}
          onMouseDown={handleMouseDown}
        >
          <S.Carrousel transition={isTransitioning} ref={dragRef}>
            {card === 'post' ? data.filter((item: any) => item.hasOwnProperty('title')).map((image: PostCard, index: any) => {
              return (
                <Card.Post slug={image.slug} cardSelected={setCardSelected} key={image.id} title={image.title} id={image.id} jetpack_featured_media_url={image.jetpack_featured_media_url}
                />
              )
            }) : data.filter((item: any) => item.hasOwnProperty('thumbnail')).map((props: CardProps, index: any) => {
              return (
                <Card.Default isDragging={isDragging} building_yogha={props.building_yogha} cardSelectedBuilding={setCardSelectedBuilding} type={type} hasFrom={hasFrom} cardSelected={setCardSelected} buttonText='' onNavigate={handleClick} accommodation_id={props.accommodation_id} cardType='imoveis' id={props.id} imageLink='' linkText='' key={props.accommodation_id} title={props.title} thumbnail={props.thumbnail} uniqueValue={props.uniqueValue} rooms={props.rooms} address={{
                  city: props.address && props.address.city,
                  region: props.address && props.address.region,
                }}
                  referencePoint={props.nearby_landmark !== null ? `${props.nearby_landmark} ${props.nearby_landmark_distance}` : null}

                />
              )
            })}
            {link && data.slice(-1).map((props: LinkCard, index: any) => {
              return (
                <Card.Link {...props} key={props.id}
                />
              )
            })}
          </S.Carrousel>
        </Draggable>
      </S.ContentCaroussel>

    </S.Container>
  );
};
