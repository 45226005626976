export const comments = [
  {
    comment: '“Melhor hospedagem de Curitiba. Localização incomparável. Estrutura perfeita”.',
    user: 'Paty Lopes',
    reference: 'Hóspede do Helbor Stay Batel, via google',
  },
  {
    comment: '“Foi maravilhosa nossa estadia no 7th avenue, o site da yogha e atendimento top 🙏🙏 Obrigada voltaremos mais vezes”.',
    user: 'Deyse Mooura',
    reference: 'Hóspede do 7th Avenue, via google',
  },
  {
    comment: '“O prédio é muito funcional, muito bonito. Tem café, academia e parece que muitos jovens vivem lá pela localização perfeita. Tem também um mini market perfeito para compras mais rápidas e com preço bom!!!!”',
    user: 'Thais Bomtempo',
    reference: 'Hóspede do All You Need, via google',
  },
  {
    comment: '“Fiquei muito bem hospedado em um flat com roupas de cama de boa qualidade, aquecimento e ducha excelente além de ter um suporte bacana do time do Yogha que fez a diferença, voltarei a hospedar com certeza. Vista linda!”',
    user: 'Marques Gontijo',
    reference: 'Hóspede do All You Need, via google',
  },
  {
    comment: '“Recomento bastante! Tive uma experiência muito positiva, bem organizado, limpo e com profissionais muito simpáticos. Paraquem busca uma boa hospedagem em Curitiba, pode confiar”.',
    user: 'Joaldo',
    reference: 'Morador, pelo airbnb',
  },
  {
    comment: '“Adorei a hospedagem e com certeza ficarei mais vezes! Fomos muito bem recebidos, lugar organizado e limpo, arredores com muita coisa pra fazer, em um bairro nobre de Curitiba! Recomendo”.',
    user: 'Maristela',
    reference: 'Moradora, pelo airbnb',
  },
  {
    comment: '"Ótima estadia! O cheirinho de limpeza ficou até o último dia. A vista é maravilhosa e o anfitrião responde as dúvidas prontamente. Pessoal da portaria também é bastante solícito e educado. Tudo muito bom! Voltarei assim que possível!”.',
    user: 'Viviane',
    reference: 'Moradora, pelo airbnb',
  }
]