import * as S from './styles'
import * as PS from '../styles'
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import pt from 'react-phone-input-2/lang/pt.json'
import { IInput } from '../types';
import { errorIconInput } from '..';

export const Phone = ({ ...input }: IInput) => {
  return (
    <>

      <S.PhoneContainer id={input.htmlFor} width={input.width}>
        <label htmlFor={input.htmlFor}>{input.title}</label>
        <ReactPhoneInput
          onBlur={input.onBlur}
          country={'br'}
          value={input.value}
          placeholder="Número de telefone"
          onChange={input.onChangePhoneNumber}
          localization={pt}
          containerClass="container"
          inputClass="container input"
          buttonClass="buttonInput"
          dropdownClass="dropdown"
          searchClass="searchInput"
          enableSearch={true}
          searchPlaceholder="Pesquisar país"
          masks={{ br: '(..) .....-....' }}
          searchNotFound="Nenhum resultado encontrado"
          inputProps={
            {
              name: input.htmlFor,
            }
          }
        />
        {input.errorText && (
          <PS.ErrorContainer errorMessages={input.errorMessages}>
            <img src={errorIconInput} alt="" />
            <PS.ErrorText errorLinkText={input.errorLinkText}>
              {input.errorText}{' '}
              {input.errorLinkText && (
                <PS.ErrorLink to={input.linkError ? input.linkError : ''}>
                  {input.errorLinkText}
                </PS.ErrorLink>
              )}
            </PS.ErrorText>
          </PS.ErrorContainer>
        )}
      </S.PhoneContainer>
    </>
  )
}