
import { Logo } from 'components/Logo'
import * as S from './styles'
import { ButtonGradient } from 'pages/Owner/components/ButtonGradient'
import { useScreenWidth } from 'utils/useScreenWidth';


export const Hero = () => {

    const handleButtonClick = () => {
        const element = document.getElementById('Forms');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const breakpoint = useScreenWidth()



    return (<>
        {
            breakpoint ? (
                <S.MobileContainer>
                    <S.CollumText>
                        <S.CollumLeft>
                            <S.Logo>
                                <Logo height='37px' width='108px' />
                                <h3>AppSuite</h3>
                            </S.Logo>
                            <S.Header>
                                <h1>A tecnologia que transforma o seu empreendimento.</h1>
                                <p>A inovação que seu prédio precisa com o ecossistema de serviços AppSuite</p>
                            </S.Header>

                            <ButtonGradient width='100%' title='Fale com um especialista' onClick={handleButtonClick} /></S.CollumLeft>
                    </S.CollumText>
                </S.MobileContainer>
            ) : (
                <S.Container>
                    <S.Content>
                        <S.CollumLeft>
                            <S.Logo>
                                <Logo height='50px' width='145px' />
                                <h3>AppSuite</h3>
                            </S.Logo>
                            <S.Header>
                                <h1>A tecnologia que transforma o seu empreendimento.</h1>
                                <p>A inovação que seu prédio precisa com o ecossistema de serviços AppSuite</p>
                            </S.Header>

                            <ButtonGradient width='309px' title='Fale com um especialista' onClick={handleButtonClick} /></S.CollumLeft>

                    </S.Content>
                </S.Container>)
        }
    </>

    )
}