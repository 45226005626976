import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Triangle = require('assets/svg/spaceUser/TriangleTooltip.svg').default;

export const CancelledSession = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;

    row-gap: 16px;

    @media (max-width: 768px) {
        margin-top: -36px;
    }
`;

export const ScaledDate = styled.div`
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
    }
`;

export const ModalCancelled = styled.div`
    display: flex;
    flex-direction: row;

    position: relative;

    gap:8px;
`;

export const SubTitle = styled.h2`
    ${FONT.BODY.BODY_MEDIUM[600]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Tooltip = styled.div`
    display: flex;
    flex-direction: row;

    max-width: 395px;
    width: 100%;

    position: absolute;

    padding: 8px;

    top: 28px;
    left: 127px;

    border-radius: 8px;

    background-color: ${NEUTRAL_COLORS.DARK};

    ::after{
        content:url(${Triangle});
        position: absolute;

        top: -12px;
    }

    p{
     ${FONT.CAPTION.CAPTION_SEMI_REGULAR};
     color: ${NEUTRAL_COLORS.WHITE}
    }

    @media (max-width: 768px) {
        left: -10px;
    }

`;

export const LinkTerms = styled(Link)`
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${PRIMARY_COLORS.MEDIUM};
    text-decoration: none;
    &:hover{
        color: ${PRIMARY_COLORS.DARK};
    }
`;

export const Terms = styled.div`
    display: flex;
    flex-direction: row;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }
`;
