import * as S from './styles'
type ButtonGradientProps = {
    title: string;
    width?: string;
    onClick?: () => void;
}

export const ButtonGradient = ({ title, width, onClick }: ButtonGradientProps) => {
    return (
        <S.ButtonContainer onClick={onClick} width={width}>
            <p>{title}</p>
        </S.ButtonContainer>
    )
}