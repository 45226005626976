import { errorIconInput } from ".."
import { IInput } from "../types"
import * as S from "./styles"
import * as PS from "../styles"

interface ICheckbox extends React.HTMLAttributes<HTMLInputElement>  {
    htmlFor: string;
    onClick?: () => void;
    onChange?: () => void;
    title: string;
    disabled?: boolean;
    value:any
}


export const Checkbox =({...input} : IInput) => {
    return (
        <>
            <PS.InputContainer>
              <PS.Row>
            <input id={input.htmlFor} disabled={input.disabled} type='checkbox' value={input.value}
            onClick={input.onClick} onChange={input.onChange} checked={input.value} />
            <label htmlFor={input.htmlFor}>{input.title} { input.highlight && <PS.Highlight to='/'>{input.highlight}</PS.Highlight>}</label>
            </PS.Row>
            <div>
            {input.errorText && <PS.ErrorContainer errorMessages={!!input.errorText}> <img src={errorIconInput} alt="" /><PS.ErrorText errorLinkText={input.errorLinkText} >{input.errorText} {input.errorLinkText && <PS.ErrorLink to={input.linkError ? input.linkError : ''} >{input.errorLinkText}</PS.ErrorLink>}</PS.ErrorText>  </PS.ErrorContainer>}
            </div>
            </PS.InputContainer>
        </>
    )

}
export const Checkboxs = ({...input} : ICheckbox) => {
    return (
        <input id={input.htmlFor} disabled={input.disabled} type='checkbox' value={input.value}
            onClick={input.onClick} onChange={input.onChange} checked={input.value} />
    )
}

