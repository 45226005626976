import { Card } from "components/Card"
import { BlogCard } from "components/Card/types"
import * as S from "./styles"
import { useEffect, useState } from "react"
import { FilterCategory } from "../components/FilterCategory"
import { useNavigate } from "react-router-dom"
import { fetchAxiosBlog } from "services/http"
import { parseISO } from "date-fns"
import { SlideBlogProps } from "../components/SlideBlog"
import { readingTime, transformSections } from "utils/readingTime"


type SessionArticlesProps = {
    articlesArray: any
    setArticlesArray: (value: any) => void
}

export const SessionArticles = ({ articlesArray, setArticlesArray }: SessionArticlesProps) => {

    const navigate = useNavigate()

    const [articlesType, setArticlesType] = useState(0)

    const arrowRight = require('assets/svg/buttons/ArrowRightOrange.svg').default

    const imageUrl = 'https://blog.yogha.com.br'

    useEffect(() => {

        const filterArticles = async () => {
            if (articlesType <= 0) {
                try {
                    const { data } = await fetchAxiosBlog.get(`api/articles?&populate=deep`, {
                    })
                    const sortedData = data.data.sort((a: SlideBlogProps, b: SlideBlogProps) => {
                        const dateA = parseISO(a.attributes.datePublication);
                        const dateB = parseISO(b.attributes.datePublication);
                        return dateB.getTime() - dateA.getTime();
                    });
                    const sortedDataWithReadingTime = sortedData.map((obj: SlideBlogProps) => {
                        const sectionString = transformSections([obj])[0]; // Transforma a seção em string
                        const readTime = readingTime(sectionString); // Calcula o tempo de leitura
                        return {
                            ...obj,
                            readingTime: readTime, // Adiciona a chave 'readingTime' ao objeto
                        };
                    });
                    setArticlesArray(sortedDataWithReadingTime);
                } catch (error) {
                }
            } else {
                try {
                    const { data } = await fetchAxiosBlog.get(`api/articles?filters[category][id][$eq]=${articlesType}&populate=deep,3`, {
                    })
                    const sortedData = data.data.sort((a: SlideBlogProps, b: SlideBlogProps) => {
                        const dateA = parseISO(a.attributes.datePublication);
                        const dateB = parseISO(b.attributes.datePublication);
                        return dateB.getTime() - dateA.getTime();
                    });
                    const sortedDataWithReadingTime = sortedData.map((obj: SlideBlogProps) => {
                        const sectionString = transformSections([obj])[0];
                        const readTime = readingTime(sectionString);
                        return {
                            ...obj,
                            readingTime: readTime,
                        };
                    });
                    setArticlesArray(sortedDataWithReadingTime)
                } catch (error) {
                }
            }


        }

        filterArticles()

    }, [articlesType])

    const handleNavigate = () => {
        navigate('/blog/pesquisar/todos-os-artigos/')
    }

    return (<> {articlesArray ? <S.Container>
        <S.Title>Confira todas as novidades</S.Title>
        <S.Content>
            <FilterCategory articles={articlesType} setArticles={setArticlesType} />
            <S.GridContainer>
                {articlesArray.length > 0 &&
                    articlesArray.map((articles: any, index: number) => {
                        return (
                            <Card.Blog key={index + articles?.attributes.category.data.attributes.title} category={articles?.attributes.category.data.attributes.title} createdAt={articles?.attributes.datePublication} description={articles?.attributes.title} id={articles?.attributes.id} image={imageUrl + articles?.attributes.thumbnail.data.attributes.url} readingTime={articles?.readingTime} onClick={() => navigate('article/' + articles?.attributes.slug)} />
                        )
                    })}
            </S.GridContainer>
            <S.ButtonSeeMore onClick={handleNavigate} >
                <p>Ver todos</p>
                <img src={arrowRight} alt="" />
            </S.ButtonSeeMore>
        </S.Content>
    </S.Container> : null}

    </>
    )
}