import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;
`;

export const Content = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;

`;

export const MainSection = styled.div`
    display: flex;

    width: 796px;

    flex-direction: column;

    align-items: center;

    row-gap: 64px;

    img{
        width: 437px;
        height: 276px;
    }

    margin-bottom: 32px;

    @media (width < 640px) {
        width: 335px;
        img{
            width: 292px;
            height: 184px;
            flex-shrink: 0;
        }
    }
`;

export const TextSection = styled.div`

    text-align: center;

    h1{
        ${FONT.HEADLINES.H5};
    }

    @media (width < 640px) {
        h1{
            ${FONT.BODY.BODY_MEDIUM[600]};
        }
    }

    `;

export const FooterContainerText = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;

    row-gap: 32px;

    width: 475px;

    text-align: center;

    span{
        ${FONT.BODY.BODY_LARGE[400]}
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
    @media (width < 640px) {
        width: 335px;
        span{
            ${FONT.BODY.BODY_SMALL[500]}
        }
    }
`;

export const ButtonContainer = styled.div`
    width:100%;
`;