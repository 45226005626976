import * as S from "./styles"
import { useEffect, useState } from "react"
import { Button } from "../../../../components/Button"
import { Navegation } from "../../../../components/Navegation"
import { Input, Inputs } from "../../../../components/Input"
import fetchAxios from "services/http"
import { useUserProvider } from "hooks/userContext"
import useAlert from "components/Notification"
import { NewInput } from "components/NewInput"
import { z } from "zod"
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IMaskInput } from "react-imask"

export const accountInfoSchema = z.object({
    username: z.string().nonempty({ message: 'Digite o seu nome' }).regex(/^[a-zA-Z ]+$/, { message: 'Nome inválido' }),
    surname: z.string().nonempty({ message: 'Digite o seu sobrenome' }).regex(/^[a-zA-Z ]+$/, { message: 'Nome inválido' }),
    phone: z.string()
        .nonempty({ message: 'Digite seu número de telefone' })
        .refine((value) => {
            if (value.startsWith('55') && value.length < 13) {
                return false;
            }
            return true;
        }, { message: 'Telefone inválido' }),
    email: z.string().nonempty({ message: 'Digite o seu email' }).email({ message: 'Email inválido' }),
    birthDate: z.string().optional()
        .refine(value => value === undefined || value === '' || value.length >= 8, {
            message: 'Data de nascimento inválida',
        }),
    identification: z.string().optional()
        .refine(value => value === undefined || value === '' || (value.length >= 11 && value.length <= 14), {
            message: 'CPF ou CNPJ inválidos',
        }),
});

type FormData = z.infer<typeof accountInfoSchema>;

export const AccountInfo = () => {

    document.title = "Informações pessoais | Yogha";

    const { token } = useUserProvider()

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const { register, handleSubmit, formState: { errors }, setValue, getValues, control, trigger, watch } = useForm<FormData>({
        resolver: zodResolver(accountInfoSchema)
    });


    const [reload, setReload] = useState<string>('')

    watch('phone')

    useEffect(() => {
        const getUser = async () => {
            try {
                const user = await fetchAxios.get('users/user', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setValue("username", user.data.user[0].name);
                setValue("surname", user.data.user[0].surname);
                setValue("email", user.data.user[0].email);
                setValue("phone", user.data.user[0].mobile_phone);
                setValue("identification", user.data.user[0].identity_document);
                setValue("birthDate", invertYear(user.data.user[0].date_of_birth.toString().replace(/-/g, '').slice(0, 8)));
            } catch (error) {
            }
        }
        getUser()
    }, [reload]);


    const invertYear = (date: string) => {
        const day = date.slice(6, 8);
        const month = date.slice(4, 6);
        const year = date.slice(0, 4);

        return `${day}${month}${year}`;
    }

    function formatBirthDate(birthDate: string, formmated?: boolean): string {
        if (birthDate.length === 8 && formmated === true) {
            const day = birthDate.slice(0, 2);
            const month = birthDate.slice(2, 4);
            const year = birthDate.slice(4, 8);

            return `${year}-${month}-${day}`;
        }
        if (birthDate.length === 8) {
            const day = birthDate.slice(0, 2);
            const month = birthDate.slice(2, 4);
            const year = birthDate.slice(4, 8);

            return `${day}/${month}/${year}`;
        }
        return birthDate;
    }
    const putInfoUser = async (data: FormData) => {
        try {
            const user = await fetchAxios.put('/users', {
                "mobile_phone": data.phone,
                "document": data.identification,
                "surname": data.surname,
                "name": data.username,
                "date_of_birth": data.birthDate !== undefined && formatBirthDate(data.birthDate, true),
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            if (user.data.code === 200) {
                showAlert({ active: true, title: 'Usuario alterado com sucesso ', type: 'SUCCESS' })
            }
        } catch (error) {
            showAlert({
                active:
                    true, title: 'Falha ao alterar usuario', type: 'ERROR'
            })
        }
    }

    return (
        <>
            <Alert />

            <Navegation actualPage="Informações pessoais" />
            <S.TitlePage>Informações pessoais</S.TitlePage>
            <S.ContainerInputs>
                <Controller
                    name="username"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <NewInput mask={''} name="username" htmlFor="username" placeholder="Seu primeiro nome" title="Nome" type="text" errorText={errors.username?.message}>
                            <input placeholder="Seu primeiro nome" {...register('username')} onChange={(value) => {
                                onChange(value)
                            }}
                                onBlur={() => trigger('username')}
                                value={value} onKeyDown={(event) => {
                                    if (!/[a-zA-Z ]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                        </NewInput>
                    )}
                />
                <Controller
                    name="surname"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <NewInput mask={''} name="surname" htmlFor="surname" placeholder="Seu último" title="Sobrenome" type="text" errorText={errors.surname?.message}>
                            <input placeholder="Seu último nome" {...register('surname')} onChange={(value) => {
                                onChange(value)
                            }} onBlur={() => trigger('surname')} value={value} onKeyDown={(event) => {
                                if (!/[a-zA-Z ]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }} />
                        </NewInput>
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <NewInput mask={''} name="email" htmlFor="email" placeholder="Seu melhor e-mail" title="Email" type="text" errorText={errors.email?.message}>
                            <input placeholder="Seu melhor e-mail" readOnly type="email" {...register('email')} onChange={(value) => {
                                onChange(value)
                            }} onBlur={() => trigger('email')} value={value} />
                        </NewInput>
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Número de telefone é obrigatório' }}
                    render={({ field }) => (
                        <Inputs.Phone
                            width={'320px'}
                            onChangePhoneNumber={field.onChange}
                            htmlFor={field.name}
                            placeholder="Seu número de telefone"
                            title="Telefone"
                            type=""
                            {...field}
                            value={field.value}
                            errorText={errors.phone?.message}
                            onBlur={() => {
                                trigger('phone')
                            }}
                        />
                    )}
                />
                <Controller
                    name="birthDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <NewInput mask={''} name="birthDate" htmlFor="birthDate" placeholder="Sua data de nascimento" title="Data de nascimento" type="text" errorText={errors.birthDate?.message}>
                            <IMaskInput
                                mask={'**/**/****'}
                                radix="."
                                unmask={true} // true|false|'typed'
                                value={value}
                                onAccept={(value, mask) => {
                                    onChange(value)
                                }}
                                placeholder="Sua data de nascimento"
                                onBlur={() => trigger('birthDate')}
                            />
                        </NewInput>
                    )}
                />
                <Controller
                    name="identification"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <NewInput mask={''} name="identification" htmlFor="identification" placeholder="Digite o seu documento de identificação" title="Documento de identificação" type="text" errorText={errors.identification?.message}>
                            <IMaskInput
                                mask={[{ mask: "***.***.***-**" }, { mask: "**.***.***/****-**" }]}
                                radix="."
                                unmask={true} // true|false|'typed'
                                value={value}
                                onAccept={(value, mask) => {
                                    onChange(value)
                                }}
                                placeholder="Digite o seu documento de identificação"
                                onBlur={() => trigger('identification')}
                            />
                        </NewInput>
                    )
                    }
                />
            </S.ContainerInputs>
            <S.InputContainer>
                <Button onClickButton={handleSubmit(putInfoUser)} size="medium" title="Salvar alterações" type="primary" widthSize={'100%'} />
            </S.InputContainer>
        </>

    )
}