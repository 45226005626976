import * as S from './styles'
import dayjs from 'dayjs'
import { CustomStayCardProps } from './types'
import { usePaymentCard } from 'pages/Payment/hooks/usePaymentCard'
import { useRenderMistay } from './useRenderMidstay'
import { useRenderShortStay } from './useRenderShortStay'
import { useEffect, useState } from 'react'
import { ProductCard } from 'components/ProductCard'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { fetchAxiosAdmin } from 'services/http'
import { useSessionStorage } from 'context/sessionStorage'
import { ReserverDataProps } from 'pages/Product/types'
import { useDiscountCookie } from './useDiscountCupomCookie'
import useAlert from 'components/Notification'
import { useLocation } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Warning } from 'assets/svg/Warning'
import { useScreenWidth } from 'utils/useScreenWidth'


export const PaymentCard = ({ discount, accommodation, }: CustomStayCardProps) => {

    const cupomIcon = require('../../../../assets/svg/Cupom.svg').default
    const closedIcon = require('../../../../assets/svg/closedFilter.svg').default

    const breakpoint = useScreenWidth()

    const pathname = useLocation().pathname

    const hasntCupom = ['PIX_CODE', 'type_payment=PIX'].some((page) => pathname.includes(page))

    const { mainImage, accommodationName, dataPayment, address, typePayment, date, nights, expensesData, hasCodePix, discountDifference, discountValue, difference, weekValue, totalValue } = usePaymentCard({ accommodation })

    const [reserverInfo, setReserverInfo] = useState<ReserverDataProps | null>(null)

    const { getFromSessionStorage } = useSessionStorage()

    useEffect(() => {
        const reserver: ReserverDataProps = getFromSessionStorage('reserver')
        setReserverInfo(reserver)
    }, []);

    const [cupom, setCupom] = useState<string>('')

    const { isDiscountValid, saveDiscount, clearDiscount } = useDiscountCookie();

    const { RenderMidStayCard } = useRenderMistay({ discount, nights, dataPayment, expensesData, discountDifference, discountValue, hasCodePix, typePayment, totalValue })
    const { RenderShortStayCard } = useRenderShortStay({ discount, nights, dataPayment, expensesData, discountDifference, discountValue, hasCodePix, typePayment, totalValue, difference, weekValue })

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: 'ERROR' })

    const [openCupomModal, setOpenCupomModal] = useState<boolean>(false)

    const handleApplyCupom = async () => {
        try {
            const { data } = await fetchAxiosAdmin.post('/cupons/validate', {
                "code": cupom,
                "checkin_date": date && date[0],
                "checkout_date": date && date[1],
                "price": reserverInfo?.rental_price,
                "idAccommodation": reserverInfo?.accommodation_id,
                "customerId": reserverInfo?.customer_id
            });

            setOpenCupomModal(false);
            saveDiscount(data.data.percentage, data.valid, data.data.id);
            showAlert({ title: 'Cupom aplicado', type: 'SUCCESS', active: true });

        } catch (error) {
            if (error instanceof AxiosError) {
                const errorMessage: string = error.response?.data?.message || 'Ocorreu um erro ao aplicar o cupom.';

                // Tratamento específico para diferentes mensagens de erro
                if (errorMessage === "Cupom não está relacionado à acomodação especificada.") {
                    showAlert({ title: errorMessage, type: 'ERROR', active: true });
                } else {
                    showAlert({ title: errorMessage, type: 'ERROR', active: true });
                }
            } else {
                console.error('Erro inesperado:', error);
                showAlert({ title: 'Erro', type: 'ERROR', active: true });
            }
        }
    }

    return (
        <S.Container>
            <S.TitlePayment>Conclua sua reserva</S.TitlePayment>
            <S.TitleCard>Resumo da sua hospedagem</S.TitleCard>
            <S.Header>
                <S.ImageAccommodation>
                    <img src={mainImage === '' ? dataPayment?.reserveDetails.mainImage : mainImage} alt="" />
                </S.ImageAccommodation>
                <S.AccommodationInfo>
                    <S.Subtitle>{accommodationName === '' ? dataPayment?.reserveDetails.accommodationName : accommodationName}</S.Subtitle>
                    <p>{address.street === '' ? dataPayment?.reserveDetails.address?.street : address.street} -{address.city === '' ? dataPayment?.reserveDetails.address?.city : address.city}, {address.stateCode === '' ? dataPayment?.reserveDetails.address?.stateCode : address.street}</p>
                    <S.LabelContainer>
                        <div>
                            <span>Entrada </span>
                            <p>{!!date && date[0] && dayjs(date[0]).format('DD/MM/YYYY')}</p>
                        </div>
                        <div>
                            <span>Saída </span>
                            <p> {!!date && date[1] && dayjs(date[1]).format('DD/MM/YYYY')}</p>
                        </div>

                    </S.LabelContainer>
                </S.AccommodationInfo>
            </S.Header>
            <S.Content>
                {/* {MidStay} */}
                {difference >= 27 && <RenderMidStayCard />}
                {/* {ShortStay} */}
                {difference < 27 && <RenderShortStayCard />}
            </S.Content>
            {!hasntCupom && <S.ContentFooter>
                {isDiscountValid ? (
                    <S.CupomButton >
                        <p>Cupom aplicado</p>
                        <button onClick={clearDiscount}>
                            <img src={closedIcon} alt="" />
                        </button>
                    </S.CupomButton>
                ) : (
                    <S.CupomButton onClick={() => setOpenCupomModal(true)}>
                        <img src={cupomIcon} alt="" />
                        <p>Possui algum cupom?</p>
                    </S.CupomButton>
                )
                }
            </S.ContentFooter>}
            <ProductCard isOpen={openCupomModal} setIsOpen={setOpenCupomModal} title='Aplicar Cupom' width={breakpoint ? 'calc(100% - 48px )' : '420px'} >
                <S.ContainerCupomExplanation>
                    <Warning color='#161616' size={18} />
                    <S.ExplanationText>
                        O cupom não é acumulativo com o desconto disponível para pagamentos via Pix.
                    </S.ExplanationText>
                </S.ContainerCupomExplanation>
                <Input htmlFor='cupom' onChange={(e) => setCupom(e.currentTarget.value.toUpperCase())} placeholder='' width={'100%'} title='Cupom' value={cupom} />
                <S.ContainerButtonCupom>
                    <Button title='Aplicar cupom' onClickButton={handleApplyCupom} size='medium' type='primary' />
                </S.ContainerButtonCupom>
            </ProductCard>
            <Alert />
        </S.Container>
    )
}