import styled, { css } from "styled-components";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";
import { FONT } from "../../constants/fontConstants";

interface IModal {
  transparent: boolean;
  width?: string | number;
}

export const Blur = styled.div`
  display: flex;

  position: fixed;

  top: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  width: 100svw;
  height: 100dvh;

  z-index: 9999;

  `;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  position: absolute;

  width: calc(100% - 48px);
  height: 100%;

  padding: 0px 24px;

  background-color: ${NEUTRAL_COLORS.WHITE};
  border-radius: 10px;

  overflow-y: auto;
  `;

interface IHead {
  bottomLine?: boolean;
}

export const Head = styled.div<IHead>`
  display: flex;
  flex-direction: row;

  position: sticky;

  width: 100%;

  top: 0;

  padding: 10px 0px;

  align-items: center;
  justify-content: space-between;

  background-color: ${NEUTRAL_COLORS.WHITE};

  z-index: 100;


  ${({bottomLine}) => bottomLine && css`::after {
    content: "";
    display: block;
    position: absolute;

    margin-top: 54px;

    width: calc(100% + 48px);

    margin-left: -24px;

    height: 1px;

    background-color: ${NEUTRAL_COLORS.GRAY}};

    margin-bottom: 12px;
  }
  `}
`;

export const CloseButton = styled.button`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;


  background-color: transparent;

  border: none;
  outline: none;
  cursor: pointer;

  margin-top: -5px;

  height: 40px;

  /* &:hover {
    svg {
      fill: ${NEUTRAL_COLORS.DARK_GRAY};
    }
  } */


`;

export const Title = styled.h5`
  ${FONT.BODY.BODY_MEDIUM[600]}
  color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  row-gap: 32px;

  width: 100%;

`;