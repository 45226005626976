import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Header = styled.header`
    display: grid;

    position: fixed;
    top: 0;
    bottom: 0;

    width: calc(100% - 64px);
    height: 83px;

    grid-template-columns: 1fr fit-content(100px);

    place-items: center;
    place-content: center;

    padding: 0 32px;

    background: ${NEUTRAL_COLORS.WHITE};
    border-bottom: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};

    z-index: 1000;
`;

export const ChangeLanguageButton = styled.button`
    display: flex;

    border: none;

    img{
        width: 20px;
        height: 20px;
    }

    padding: 12px;

    cursor: pointer;

    background-color: transparent;
`;

export const LogoContainer = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    cursor: pointer;

    img{
            width: 130px;
            height: 45px;
        }
`;

export const ChangeLanguageModal = styled.div`
    display: flex;
    flex-direction: column;

    width: 200px;

    position: absolute;
    bottom: -100px;
    right: 20px;
    border-radius: 10px;
    background: #FFF;

    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);

    padding: 8px;
`;

export const LanguageContainer = styled.div`
    display: flex;

    align-items: center;

    width: calc(100% - 32px);

    gap: 8px;

    padding: 16px;

    cursor: pointer;

    &:hover{
        background: ${NEUTRAL_COLORS.LIGHT_GRAY};
    }

    p{
        ${FONT.BODY.BODY_SMALL[400]}
    }
`;