import { useSessionStorage } from "context/sessionStorage";
import { uploadFile } from "pages/Checkin/utils/uploadFile";
import {  useState } from "react";
import { useNavigate } from "react-router-dom";


export const useVerifyDocumentCPF = () => {
    const [innerStep, setInnerStep] = useState(0);

    const navigate = useNavigate();
    const [frontSide, setFrontSide] = useState<File | null>(null);
    const [backSide, setBackSide] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false)

    const {  getFromSessionStorage } = useSessionStorage()

    const pathnameActual: string = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));

    const handleNextStep = async () => {
        const dataCheckinDetails = getFromSessionStorage('checkin-details')
        if(!dataCheckinDetails) return

        if (innerStep === 0) {
            setInnerStep(1);
        } else if (innerStep === 2) {
            if (frontSide && backSide) {
                setIsLoading(true)
                try {
                    await uploadFile(frontSide, dataCheckinDetails.checkinId, 'front')
                    await uploadFile(backSide, dataCheckinDetails.checkinId, 'back')
                    navigate(`${pathnameActual}/selfie-validation`,{replace: true})
                } catch (error) {
                    console.error(error)
                } finally {
                    setIsLoading(false)
                }
            }
        } else if (innerStep < 3) {
            setInnerStep(innerStep + 1);
        }
    }

    const handleBackStep = () => {
        if (innerStep === 0) {
            navigate(`${pathnameActual}/address`)
        } else {
            setInnerStep(innerStep - 1);
        }
    }

    const handleFileChangeFrontSide = (file: File | null) => {
        setFrontSide(file);
    };

    const handleFileChangeBackSide = (file: File | null) => {
        setBackSide(file);
    };

    return{
        innerStep,
        handleNextStep,
        handleBackStep,
        handleFileChangeFrontSide,
        handleFileChangeBackSide,
        frontSide,
        backSide,
        isLoading
    }
}