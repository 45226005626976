import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

const background1280 = require("../../assets/image/trankyou-developer/desktop-developers-thank-you-bg-1280x720.jpg");
const background1920 = require("../../assets/image/trankyou-developer/desktop-developers-thank-you-bg-1920x1080.jpg");
const background2650 = require("../../assets/image/trankyou-developer/desktop-developers-thank-you-bg-2560x1080.jpg");
const backgroundMobile = require("../../assets/image/trankyou-developer/mobile-developers-thank-you-bg.jpg");

export const Container = styled.div`

display: flex;
flex-direction: column;

align-items: center;
justify-content: center;

height: 100vh;
width: 100%;

h1{
    color: ${NEUTRAL_COLORS.WHITE};
    ${FONT.HEADLINES.H5}
}
p{
    color: ${NEUTRAL_COLORS.WHITE};
    ${FONT.BODY.BODY_MEDIUM[400]}
}

gap: 32px;

text-align: center;

background-size: cover;
background-position: center;
background-repeat: no-repeat;

@media (min-width: 1920px) {
    background-image: url(${background2650});
}
@media (max-width: 1920px) and (min-width: 1728px) {
    background-image: url(${background1920});
}
@media (max-width: 1728px) and (min-width: 1366px) {
    background-image: url(${background1920});
}
@media (max-width: 1366px) and (min-width: 768px) {
    background-image: url(${background1280});
    }

@media (max-width: 768px) {
    background-image: url(${backgroundMobile});
    background-position: bottom;
    h1{
        ${FONT.BODY.BODY_LARGE[600]}
    }
    p{
        ${FONT.BODY.BODY_SMALL[400]}
    }
    }
`;

export const FirstRow = styled.div`
display: flex;
flex-direction: column;

max-width: 519px;
width: 100%;

gap: 16px;

@media (max-width: 768px) {
    max-width: 307px;
}
`;

export const SecondRow = styled.div`
display: flex;
flex-direction: column;

max-width: 443px;
width: 100%;

gap: 32px;

@media (max-width: 768px) {
    max-width: 307px;
}
`;