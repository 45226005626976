import { FONT } from "constants/fontConstants";
import styled from "styled-components";

export const ContainerAddAccompanying = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;

    h1{
        ${FONT.HEADLINES.H5}
    }

    h5{
        ${FONT.BODY.BODY_SMALL[400]};
        margin-top: 16px;
        margin-bottom: 40px;
    }
    padding-bottom: 48px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        padding-bottom: 0px;
    }
`;

export const FormContainer = styled.form`
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto, 1fr);

    place-content: start start;
    place-items: start start;

    grid-template-areas:
    "documentType documentType"
    "documentNumber documentNumber"
    "name surname"
    "birthDate birthDate"
    "phone phone"
    "email email"
    "residence residence";

    gap: 24px;

    max-width: 413px;
    width: 100%;

    margin-bottom: 48px;

    & :has(#name) {
        grid-area: name;
        width: 100%;
    }

    & :has(#surname) {
        grid-area: surname;
        width: 100%;
    }
    & :has(#documentType) {
        grid-area: documentType;
        grid-column: 1 / 3;
        width: 100%;
    }

    #documentNumber {
        grid-area: documentNumber;
        grid-column: 1 / 3;
        width: 100%;
        display: block;
        input{
            width: calc(100% - 16px);
        }
    }

    #birthDate {
        grid-area: birthDate;
        grid-column: 1 / 3;
        width: 100%;
    }

    #phone {
        grid-area: phone;
        grid-column: 1 / 3;
        width: 100%;
    }
    & :has(#email) {
        grid-area: email;
        grid-column: 1 / 3;
        width: 100%;
    }

    & :has(#countryYouLive) {
        grid-area: residence;
        grid-column: 1 / 3;
        width: 100%;
    }

    @media (max-width: 768px) {
        grid-template-areas:
        "documentType documentType"
        "documentNumber documentNumber"
        "name name"
        "surname surname"
        "birthDate birthDate"
        "phone phone"
        "email email"
        "residence residence";
        max-width: 100%;
        margin-bottom: 24px;
    }
`;

export const ContainerRadio = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    width: 100%;

    strong{
        ${FONT.BODY.BODY_SMALL[500]}
    }
`;

export const ContainerRadioButtons = styled.div`
    display: flex;

    width: 100%;

    align-items: center;

    gap: 16px;

`;