import { Button } from "components/Button"
import { t } from "i18next";
import { useStepStore } from "store/store";
import * as S from "./styles";

type FooterWithButtonsProps = {
    disableContinueButton?: boolean;
    handleNextStep?: () => void;
    handleBackStep?: () => void;
    nextButtonTitle?: string;
}

const backIcon = require('../../../../assets/svg/checkin/BackIconButton.svg').default

export const FooterWithButtons = ({ disableContinueButton, handleBackStep, handleNextStep, nextButtonTitle }: FooterWithButtonsProps) => {

    const { setStep, step } = useStepStore((state) => ({ setStep: state.setStep, step: state.step }));


    const handleNext = handleNextStep ? handleNextStep : () => setStep(step + 1);
    const handleBack = handleBackStep ? handleBackStep : () => setStep(step - 1);

    return (
        <S.FooterButtonContainer>
            <Button icon={backIcon} iconWidth={16} iconHeight={16} iconDirection="left" widthSize={"fit-content"} onClickButton={handleBack} size="large" title={t('checkin.button.back')} type="secondary" />
            <S.ConfirmButton>
                <Button disabled={disableContinueButton} widthSize={"100%"} onClickButton={handleNext} size="large" title={nextButtonTitle ? nextButtonTitle : t('checkin.button.next')} type="primary" />
            </S.ConfirmButton>

        </S.FooterButtonContainer>
    )
}