import { Link, useNavigate } from 'react-router-dom';
import { Icons } from '../Icons';
import { Logo } from '../Logo';
import * as S from './styles';
import { useSessionStorage } from 'context/sessionStorage';
import { useAnyProvider } from 'hooks/Context';
import { useEffect } from 'react';

export type FooterProps = {
  theme?: 'default' | 'dark';
}

export const Footer = ({ theme }: FooterProps) => {

  const navigate = useNavigate();

  const pathname = window.location.pathname

  const { removeFromSessionStorage } = useSessionStorage()
  const { setDate } = useAnyProvider()

  const backToHome = () => {
    setDate([])
    removeFromSessionStorage('dateHeader')
    navigate('/')
  }


  const iconFacebook = require('assets/svg/social-midias/facebookIcon.svg').default;
  const iconInstagram = require('assets/svg/social-midias/instagramIcon.svg').default;
  const iconLinkedin = require('assets/svg/social-midias/linkedinIcon.svg').default;

  return (
    <S.ContainerFooter theme={theme}>
      <S.Container theme={theme}>
        <S.Content>
          <S.UtilLinks theme={theme} >
            <div onClick={backToHome}>
              <Logo />
            </div>
            <S.CollumContainer>
              <S.Collumns>
                <S.StyledLink theme={theme} to={'https://negocios.yogha.com.br/'}>Proprietários</S.StyledLink>
                <S.StyledLink theme={theme} to={'https://negocios.yogha.com.br/parceiros/'}>Parceiros</S.StyledLink>
                <S.StyledLink theme={theme} to={'/moradia-flexivel/'} style={{
                  whiteSpace: 'nowrap'
                }}>Moradia flexível</S.StyledLink>
              </S.Collumns>
              <S.Collumns>
                <S.StyledLink theme={theme} to={'https://blog.yogha.com.br/'}>Blog</S.StyledLink>
                <S.StyledLink theme={theme} to={'https://api.whatsapp.com/send?phone=5541996941949'}>Suporte</S.StyledLink>
                <S.StyledLink className='wrap' theme={theme} to={'/termos-e-condicoes/'}>Termos de uso e condições de cancelamento</S.StyledLink>
              </S.Collumns>
            </S.CollumContainer>
          </S.UtilLinks>
        </S.Content>
      </S.Container>
      <S.InfoContainer theme={theme}>
        <S.FooterInfos>
          <p className='cnpj'>Yogha Gestão e Hospitalidade CNPJ: 32.303.641/0001-01 </p>
          <S.SocialMedia>
            <p>Siga Nossas Redes Sociais</p>
            <S.FooterIcons>
              <Link target={'_blank'} to={'https://www.facebook.com/yogha.host'}>
                <Icons icon={iconFacebook} width={24} height={24} />
              </Link>

              <Link target={'_blank'} to={'https://www.instagram.com/yogha.moradiaflexivel/'}>
                <Icons icon={iconInstagram} width={24} height={24} />
              </Link>
              <Link target={'_blank'} to={'https://www.linkedin.com/company/65265804/'}>
                <Icons icon={iconLinkedin} width={24} height={24} />
              </Link>
            </S.FooterIcons>
          </S.SocialMedia>
        </S.FooterInfos>
      </S.InfoContainer>
    </S.ContainerFooter>
  );
};