import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;

    position: absolute;

    inset: 0px;
    justify-content: center;
    align-items: center;

    height: 100vh;
    width: 100vw;

    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    z-index: 3000;

`;

type LoadingContainerProps = {
    isIos: boolean;
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
    display: flex;
    flex-direction: column;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


    justify-content: center;
    align-items: center;

    width: calc(320px - 32px);
    height: calc(240px - 64px);

    background-color: ${NEUTRAL_COLORS.WHITE};

    row-gap: 24px;

    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 16px;

    padding: 32px 16px;

    text-align: center;


    align-self: center;

    @media (max-width: 768px) {
        margin-top: ${({ isIos }) => (isIos ? '-32px' : '-16px')};
    }
`;

export const LoadingText = styled.span`
    ${FONT.BODY.BODY_MEDIUM[600]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};

`;

export const LoadingImage = styled.img`

    width: 96px;
    height: 96px;


    animation: spin 1s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }

    `;