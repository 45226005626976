import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { FooterWithButtons } from "pages/Checkin/components/FooterWithButtons";
import { useState } from "react";
import { InputFile } from "pages/Checkin/components/InputFile";
import { useVerifyDocumentCPF } from "./useVerifyDocumentCPF";
import { Loading } from "pages/Payment/components/Loading";
import useScrollToTopByState from "hooks/useScrollToTopByState";

const identifyDocumentSvg = require('../../../../assets/svg/checkin/IdentifyDocument.svg').default
const selfiePhotoSvg = require('../../../../assets/svg/checkin/SelfiePhoto.svg').default
const referenceCpfPhoto = require('../../../../assets/image/checkin/ReferenceCPF.png')

export const VerifyDocumentCPF = () => {
    const { t } = useTranslation();

    const { backSide, frontSide, handleBackStep, handleFileChangeBackSide, handleFileChangeFrontSide, handleNextStep, innerStep, isLoading } = useVerifyDocumentCPF()

    useScrollToTopByState('.main', innerStep)

    return (
        <S.VerifyDocumentContainer>
            <h1>{t('checkin.verifyDocument.title')}</h1>
            {innerStep === 0 ? (<S.ExplainContainer>
                <strong>{t('checkin.verifyDocument.subtitle')}</strong>
                <S.ExplainContent>
                    <img src={identifyDocumentSvg} alt="" />
                    <p>{t('checkin.verifyDocument.documentIdentify')}</p>
                </S.ExplainContent>
                <S.ExplainContent>
                    <img src={selfiePhotoSvg} alt="" />
                    <p>{t('checkin.verifyDocument.takeASelfie')}</p>
                </S.ExplainContent>
                <small>{t('checkin.verifyDocument.footer')}</small>
            </S.ExplainContainer>
            ) : innerStep === 1 ? (
                <S.GuideContent>
                    <p>{t('checkin.verifyDocument.cpf.subtitle')}</p>
                    <img src={referenceCpfPhoto} alt="" />
                    <ul>
                        <li>{t('checkin.verifyDocument.cpf.documentWithPhoto')}</li>
                        <li>{t('checkin.verifyDocument.cpf.documentHasntExpiration')}</li>
                        <li>{t('checkin.verifyDocument.cpf.documentOriginal')}</li>
                        <li>{t('checkin.verifyDocument.cpf.removeCase')}</li>
                        <li>{t('checkin.verifyDocument.cpf.putDocument')}</li>
                    </ul>
                </S.GuideContent>
            ) : (
                <S.SendFilesContainer>
                    <p>{t('checkin.verifyDocument.cpf.sendBothSides')}</p>
                    <S.ColumnInputFiles>
                        <S.ContainerInputFiles>
                            <p>{t('checkin.verifyDocument.cpf.frontSide')}</p>
                            <InputFile onFileChange={handleFileChangeFrontSide} description={t('checkin.verifyDocument.inputFileLabel')} name="frontSide" />
                        </S.ContainerInputFiles>
                        <S.ContainerInputFiles>
                            <p>{t('checkin.verifyDocument.cpf.backSide')}</p>
                            <InputFile onFileChange={handleFileChangeBackSide} description={t('checkin.verifyDocument.inputFileLabel')} name="backSide" />
                        </S.ContainerInputFiles>
                    </S.ColumnInputFiles>
                </S.SendFilesContainer>
            )}
            <FooterWithButtons disableContinueButton={innerStep === 2 && frontSide === null && backSide === null} handleNextStep={handleNextStep} handleBackStep={handleBackStep} />
            {isLoading && <Loading text={t('checkin.verifyDocument.cpf.waitingSendFile')} />}
        </S.VerifyDocumentContainer>
    )
}