import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div``;

export const InputContainer = styled.div`
    display: flex;

    width: 690px;

    @media (max-width: 768px) {
        width: 100%;
    }

`;

export const CardInfo = styled.div`
    display: flex;
    flex-direction: column;

    width: 187px;

    height: 132px;

    gap: 10px;

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    justify-content: start;

    @media (max-width: 768px) {
        height: unset;
        width: calc(100% - 32px);
    }
`;
export const CardRow = styled.div`
    display: flex;

    strong{
      ${FONT.BODY.BODY_SMALL[400]};
      color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    flex-direction: row;

    gap: 10px;

    justify-content: flex-start;
`;

export const CardHeader = styled.div`
    display: flex;
    flex-direction: row;

    gap: 8px;

    height: 32px;

    justify-content: flex-start;
    align-items: center;
    p{
      ${FONT.BODY.BODY_SMALL[600]};
      color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const ContainerInformation = styled.div`
    display: grid;
    grid-template-columns: repeat(3,fit-content(100%));

    gap: 16px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    @media (max-width: 768px) {
        grid-template-columns: repeat(1,fit-content(100%));
    }

`;
export const ButtonContainer = styled(Link)`
    width: 81px;
    text-decoration: none;

`;

export const InputPayment = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    align-items: center;

    width: calc(690px - 32px);

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
    }

    `;

export const InputRow = styled(CardRow)`
    align-items: center;
    justify-content: space-between;
    h5{
        width: 470px;
        ${FONT.BODY.BODY_MEDIUM[400]};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: 768px) {
        h5{
            width: 100%;
        }
    }
    `;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
    }
     width: 100%;
`;

