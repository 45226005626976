import styled from "styled-components";
import { FONT } from "../../constants/fontConstants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100vh;

    align-items: center;
    justify-content: start;

`;

interface ContentProps {
    isValid: boolean;
}

export const Content = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;

    margin-top: ${({isValid}) => isValid ? '64px': '149px'} ;

    row-gap: 32px;

    max-width: 314px;
    width: 100%;

    align-items: center;

`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const Title = styled.h1`
    ${FONT.HEADLINES.H5}
`;

export const Subtitle = styled.h2`
    ${FONT.BODY.BODY_MEDIUM[400]}
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 24px;

`;