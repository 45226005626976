import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: start;
    justify-content: start;

    white-space: nowrap;

    width: 256px;

    @media (max-width: 768px) {
        width: 100%;
    }

`;

export const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;

    align-items: start;

    width:587px;

    border-radius: 10px;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const InputPayment = styled.div`
    display: flex;
    flex-direction: row;

    align-items: start;

    width: 100%;

    padding: 20px 16px;

    &:nth-child(n + 2){
        border-top: 1px solid ${NEUTRAL_COLORS.GRAY};

        width: calc(100% - 32px);
    }

    gap: 18px;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        padding: 12px 16px;
    }
`;

export const GridContent = styled.section`
    display: grid;

    grid-template-columns: 1fr;

    gap: 32px;

    margin:0 auto;

    place-items: start center;
    place-content: center ;

    max-width: calc(1366px - 160px);
    width: 100%;

    padding: 0px 80px;

    margin-top: 32px;
    margin-bottom: 62px;

    @media (max-width: 768px) {

        width: calc(100% - 40px);
        max-width: unset;
        place-items: center;
        padding: 0px;
        padding-left: 20px;
        padding-right: 20px;

    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    align-items:start;
    justify-content: space-between;

    h1{
        ${FONT.HEADLINES.H5};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    h2{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
    }

    @media (max-width: 768px) {

        width: calc(100% - 20px);
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const SectionPayment = styled.div`
    display: flex;
    flex-direction: column;

    align-items: start;

    row-gap: 32px;

    @media (max-width: 768px) {
        width: 100%;

        order: 1;
        align-items: center;
        justify-content: center;
    }
`;

export const CardPaymentContainer = styled.div`

    @media (max-width: 768px) {
        width: 100%;
        order: 0;
    }

`;

export const PaymentOption = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const Main = styled.main`

    overflow-y: auto;
    height: 100svh;

@media (max-width: 768px) {
     width: 100%;
}

`;

export const TitlePayment = styled.h1`
margin-top:-16px;
    margin-bottom: 8px;
`;