import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Header = styled.header`
display: flex;

position: sticky;
top: 0;

width: 100%;
min-height: 83px;

align-items: center;
justify-content: center;

background: ${NEUTRAL_COLORS.WHITE};
box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);

z-index: 1000;
`;

export const NavigateButton = styled.button`
  display: flex;

  position: absolute;

  width: 40px;
  height: 40px;

  border: none;

  background-color: transparent;

  align-items: center;
  justify-content: center;

  left: 32px;

  img{
    width: 18px;
    height: 18px;
  }

  justify-self: flex-start;
`;

export const LogoContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  cursor: pointer;

  img{
        width: 130px;
        height: 45px;
    }
`;