import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div``;

export const CardInfo = styled.div`
    display: flex;
    flex-direction: column;

    width: calc(587px - 32px);

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    justify-content: start;
`;
export const CardRow = styled.div`
    display: flex;
    flex-direction: row;

    gap: 10px;

    justify-content: flex-start;
`;

export const ContainerInformation = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 16px;
`;
export const ButtonContainer = styled.div`
    width: 81px;

    @media (max-width: 768px) {
        width: 81px;
        align-self: flex-end;
        justify-self: flex-end;
    }

`;

export const ContainerPixCode = styled.div`

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const InputPayment = styled.div`
    display: flex;
    flex-direction: row;

    position: relative;

    justify-content: space-between;

    align-items: center;

    width: calc(587px - 32px);

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        gap: 0px;
    }

    `;

export const InputRow = styled(CardRow)`
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
    }
    `;

export const InlineCode = styled(CardRow)`
align-items: center;
    justify-content: space-between;
    h5{
        width: 470px;
        ${FONT.BODY.BODY_MEDIUM[400]};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

@media (max-width: 768px) {
        width: calc(100% - 32px);
        h5{
        width: 200px;
        ${FONT.BODY.BODY_MEDIUM[400]};
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
        white-space: pre-wrap;
    }
    @media (max-width: 768px) {
       width: 100%;
    }

    `;

export const FooterButton = styled.div`
    margin-bottom: 62px;

    @media (max-width: 768px) {
     width: 100%;
    }
`;

export const ContainerInfo = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    margin-top: 32px;

    h3{
       ${FONT.BODY.BODY_LARGE[600]};
       ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const RowInfo = styled.div`
    display: flex;

  >  p:nth-child(1){
        width: 10px;
        text-align: right;
    }

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
    }

    gap:8px;
`;

export const PixCodeContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 32px;
    margin-top: 32px;
    margin-bottom: 32px;

    @media (max-width: 768px) {
      width: 100% ;
    }
`;

export const RowPaymentTime = styled.div`
    display: flex;

    padding-left: 8px;

    width: 571px;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }
`;

export const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 8px;

    div{
        display: flex;
        gap: 8px;
        p{
            white-space:pre-wrap;
        }
    }

    strong{
        ${FONT.BODY.BODY_SMALL[600]};
    }

    @media (max-width: 768px) {
      width: 100% ;

        margin-top: 16px;

      div{
        display: flex;
        gap: 8px;
        p{
            white-space:unset;
        }
    }
    }
`;

export const Footer = styled.div`
    margin-top: 32px;

    @media (max-width: 768px) {
     width: 100%;
    }
`;

export const QrCodeImageContainer = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;

    width: 587px;

    img{
        height: 100%;
        max-height: 207px;
    }
    margin-left: -16px;
    margin-block: 28px;

    @media (max-width: 768px) {
        width: 100%;

        img{
        height: 100%;
        }

    margin-left: 0;
    margin-block: 0;
    }
`;