import { HeaderAlt } from "components/HeaderAlt"
import * as S from "./styles"
import { Footer } from "components/Footer"
import { useRef } from "react";

export const TermsOfUse = () => {

    document.title = "Termos e Condições | Yogha"

    const containerRef = useRef<HTMLDivElement>(null);

    const arrowTop = require("assets/svg/buttons/ArrowTop.svg").default;

    const scrollToTop = () => {
        const container = containerRef.current;
        if (container) {
            container
                .scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
        };
    };

    return (
        <>
            <S.Container ref={containerRef}>
                <HeaderAlt />
                <S.Content>
                    <S.Row>
                        <h1 className="title">REGRAS E CONDIÇÕES DE USO E POLÍTICA DE PRIVACIDADE</h1>
                        <p>O acesso e o uso da Plataforma Yogha vinculam você, Usuário, às Regras e Condições de Uso e Política de Privacidade descritas a seguir.
                            Os termos listados abaixo são utilizados a seguir, no singular ou no plural, sempre com o significado aqui definido.</p>
                        <p>
                            <li><span>Plataforma:</span> conjunto de funcionalidades, incluindo aquelas disponíveis no site e nos aplicativos móveis, que permite ao Usuário realizar a reserva dos imóveis e a contratação dos serviços terceirizados fornecidos pelos Parceiros da <span><span>YOGHA</span></span>. Para tanto, a <span><span>YOGHA</span></span>: (i) atua no planejamento, manutenção e atualização de sua Plataforma e (ii) realiza a intermediação da locação dos imóveis e na contratação dos serviços fornecidos pelos Parceiros.</li>
                            <li><span>Usuário:</span> toda pessoa que utiliza da Plataforma, inclusive reservando os imóveis disponibilizados através da Plataforma da <span>YOGHA</span>.
                                · Conta de Usuário: conta única cadastrada pelo Usuário para a utilização, reserva e/ou contratação dos imóveis e Serviços disponibilizados na Plataforma.
                            </li>
                            <li>                            <span>Serviços:</span> englobam os serviços oferecidos pela <span>YOGHA</span> ao Usuário incluindo: (i) acesso e uso da Plataforma e (ii) intermediação de locação dos imóveis e contratação dos serviços disponíveis na Plataforma ou os serviços oferecidos pelos Parceiros da <span>YOGHA</span> ao Usuário por meio de sua Plataforma.</li>
                            <li><span>Reserva:</span> momento em que o Usuário escolhe e define o imóvel a locar entre os disponíveis na Plataforma, bem como a duração da reserva.
                                · Parceiros: empresas parceiras da <span>YOGHA</span> que, através da Plataforma, disponibilizam seus imóveis e/ou Serviços ao Usuário.</li>

                        </p>
                    </S.Row>
                    <S.Row>
                        <span>Utilização da Plataforma pelo Usuário</span>
                        <p>Todo o conteúdo disponibilizado na Plataforma e domínios da <span>YOGHA</span> têm caráter meramente informativo e ilustrativo, com o objetivo único de facilitar o acesso às informações e procedimentos de reservas, vendas e contratação de serviços.</p>
                        <p>Todos os materiais e conteúdos disponibilizados na Plataforma (textos, fotos, vídeos, logotipos, marcas, etc.) são de propriedade da <span>YOGHA</span> ou a ela foram regularmente licenciados, estando sob a proteção da legislação de direitos autorais e propriedade intelectual.</p>

                        <p>O Usuário, ao ter acesso a estes materiais, compromete-se a respeitar os direitos de titularidade da <span>YOGHA</span>, sendo expressamente vedada qualquer forma de reprodução ou circulação dos materiais disponibilizados sem a permissão da <span>YOGHA</span> nestes Termos em conformidade com as Leis nº 9.279/96 (Propriedade Industrial), 9.609/1998 (Proteção de Softwares) e 9.610/1998 (Direitos Autorais).</p>
                        <p>O Usuário expressamente reconhece também que a contratação dos serviços não constitui qualquer espécie de cessão dos softwares e direitos da Plataforma <span>YOGHA</span>, sendo que o conteúdo decorrente da contratação e/ou utilização dos serviços e conteúdo constitui mero licenciamento para uso, individual e exclusivo, pelo próprio Usuário.</p>
                        <p>O Usuário se declara ciente de que determinadas seções e funcionalidades da Plataforma somente poderão ser utilizadas e acessadas mediante cadastro, oportunidade na qual o Usuário deverá fornecer todos os dados a ele solicitados.</p>
                        <p>O Usuário reconhece que, quando da realização do cadastro, deverá fornecer seus dados pessoais (nome completo, documento de identidade, CPF, endereço residencial completo, endereço de e-mail e telefone celular), inclusive número de telefone, ao qual o seu cadastro ficará vinculado, sendo facultado à <span>YOGHA</span> o envio de mensagens de texto ou WhatsApp ao número cadastrado, ou envio de mensagens eletrônicas ao endereço de e-mail cadastrado, exclusivamente para a realização de validação do cadastro do Usuário.</p>
                        <p>A <span>YOGHA</span> utilizará meios de comunicação como, mas não se limitando a: e-mail, mensagem de texto e mensagens por WhatsApp, visando o envio de informações diretamente relacionadas aos interesses dos Usuários, e, especialmente acerca da Reserva e Serviços, se esta for efetuada pelo Usuário.</p>
                        <p>
                            O Usuário é o único e exclusivamente responsável por todas as informações fornecidas quando de seu acesso na Plataforma, responsabilizando-se, inclusive perante terceiros, por quaisquer danos ou prejuízos decorrentes de informações incorretas, incompletas ou inverídicas.
                        </p>
                        <p>O Usuário reconhece, ainda, que a inclusão de qualquer informação inverídica, falsa ou adulterada o sujeitará a todas as sanções aplicáveis conforme a legislação brasileira, inclusive em âmbito criminal.</p>

                        <p>Ao acessar a Plataforma e/ou fazer uso dos Serviços, o Usuário se declara ciente e concorda que:</p>
                        <p>
                            a) Apenas pessoas civilmente capazes, à luz da legislação brasileira, poderão utilizar a Plataforma;<br />
                            b) Cada Conta de Usuário ficará associada a apenas um único número de CPF/CNPJ por Usuário;<br />
                            c) Não é permitida a criação de nova Conta de Usuário por Usuários que tenham sido inabilitados pela <span>YOGHA</span>, temporária ou definitivamente, independentemente do motivo e de justificativa;<br />
                            d) O Usuário não deverá compartilhar suas credenciais de acesso com terceiros, sendo o único responsável por quaisquer operações, inclusive financeiras, realizadas por meio de sua Conta de Usuário;<br />
                            e) O Usuário não deverá inserir na Plataforma, por quaisquer meios, conteúdos e/ou materiais capazes de incorporar malware, spyware e/ou outros elementos que possam impedir o funcionamento normal da Plataforma, de equipamentos informáticos da <span>YOGHA</span> e/ou de terceiros, bem como capazes de danificar documentos eletrônicos e/ou arquivos armazenados em tais equipamentos;<br />
                            f) O Usuário não deverá utilizar a Plataforma de forma a violar quaisquer disposições legais, estando sujeito, nestes casos, às sanções aplicáveis dispostas na legislação brasileira, inclusive em âmbito criminal.
                        </p>
                        <p>O cadastro na <span>YOGHA</span> na qualidade de Usuário é gratuito. O Usuário poderá, a qualquer momento, cancelar o seu cadastro, entrando em contato com os canais de atendimento.
                        </p>
                        <p>A <span>YOGHA</span> se reserva o direito de cancelar um cadastro previamente aceito, a seu exclusivo critério, caso se verifique o descumprimento de qualquer das cláusulas ou condições previstas neste instrumento pelo Usuário, de modo que não caberá nenhuma indenização ou reparação pelo cancelamento de quaisquer cadastros.</p>
                        <p>O Usuário se obriga a cumprir e atender a todas e quaisquer solicitações feitas pela <span>YOGHA</span>, independentemente do momento, para fins de validação de sua Conta e utilização da Plataforma, tais como: preencher dados e processos de verificação, inclusão de informações na ficha cadastral, dentre outros.</p>
                        <p>A <span>YOGHA</span> utiliza cookies para facilitar o uso e melhor adaptar o site aos interesses do Usuário. Os cookies também podem ser utilizados para ajudar a acelerar suas atividades e experiências futuras na Plataforma. Também são utilizados cookies para compilar estatísticas anônimas e agregadas que permitem entender como o Usuário utiliza a Plataforma e para ajudar a melhorar suas estruturas e conteúdo.</p>
                        <p>O Usuário declara e reconhece que, em qualquer hipótese, é o único responsável pela utilização da Plataforma, bem como pelos dados e informações fornecidos e cadastrados por si para utilização da Plataforma, isentando a <span>YOGHA</span> de qualquer responsabilidade por danos e/ou prejuízos decorrentes do uso indevido da Plataforma e/ou do fornecimento de dados e/ou informações inverídicas e/ou incompletas.</p>
                        <p>
                            Tendo em vista a impossibilidade de funcionamento integral e ininterrupto de qualquer sistema de telecomunicação ou de informática, inclusive em razão da dependência de serviços de telecomunicações prestados por terceiros, a <span>YOGHA</span> não garante a prestação do serviço de forma ininterrupta e/ou isenta de erros. Eventualmente, a Plataforma poderá não estar disponível por motivos técnicos ou falhas da internet, ou por qualquer outro evento fortuito ou de força maior alheio ao controle da <span>YOGHA</span>.
                        </p>
                        <p>A <span>YOGHA</span> não será obrigada a indenizar os Usuários ou Parceiros de Serviços por eventuais prejuízos sofridos em razão da indisponibilidade da Plataforma.</p>
                        <p>O uso das informações disponibilizadas na Plataforma e domínios, para quaisquer fins que sejam, é de responsabilidade exclusiva do Usuário, não sendo a <span>YOGHA</span> responsável, perante o Usuário e terceiros, por eventuais perdas e danos decorrentes de equívocos, erros, omissões ou inexatidões contidas nos materiais disponibilizados.</p>

                        <p>A <span>YOGHA</span> também se reserva ao direito de criar limitações de uso e armazenamento, a critério próprio, a qualquer momento e com ou sem aviso prévio, para atendimento das obrigações estabelecidas nos termos da legislação em vigor, bem como não tem nenhuma responsabilidade pela exclusão, falha de armazenamento ou falha de transmissão de qualquer conteúdo.</p>

                        <p>
                            A conta de qualquer Usuário pode ser encerrada a qualquer momento, tanto por iniciativa própria, quanto por iniciativa da <span>YOGHA</span>, bem como em casos de encerramento das atividades da Plataforma. Sem prejuízo de outras medidas cabíveis, a <span>YOGHA</span> poderá advertir, suspender, temporária ou definitivamente, a conta de um Usuário, a qualquer tempo, iniciando as ações legais cabíveis e/ou suspendendo a prestação de seus serviços em caso de:
                        </p>
                        <p>
                            a) Suspeita de ilegitimidade, ilegalidade e/ou fraude;<br />
                            b) Impossibilidade em verificar a identidade do Usuário ou se qualquer informação fornecida por ele no cadastro estiver incorreta;<br />
                            c) Descumprimento dos deveres pelo Usuário;<br />
                            d) Prática de atos pelo Usuário que tenha causado algum dano, ou tenham a potencialidade de assim o fazer, a terceiros ou a própria <span>YOGHA</span>;<br />
                            e) Violação ou descumprimento de qualquer das condições aqui descritas, demais políticas e legislações aplicáveis.
                            Em qualquer hipótese de suspensão ou cancelamento da conta de acesso do Usuário, este não terá direito a qualquer indenização ou ressarcimento (financeira ou não).<br />
                        </p>

                        <p>As páginas e os conteúdos de terceiros apontados ou referências que não estão sob o controle da <span>YOGHA</span> não a torna corresponsável pelo conteúdo de qualquer outro website indicado ou acessado por meio do domínio “https://www.yogha.com.br”. A <span>YOGHA</span> reserva-se no direito de eliminar qualquer link ou direcionamento a outros sites ou serviços a qualquer momento.</p>
                        <p>A <span>YOGHA</span> não endossa firmas ou produtos indicados, acessados ou divulgados através desses websites, tampouco pelos anúncios lá publicados, reservando-se se no direito de publicar este alerta em suas páginas eletrônicas sempre que considerar necessário.</p>
                    </S.Row>
                    <S.Row>
                        <span>Proteção e uso de dados</span>
                        <p>A <span>YOGHA</span> está ciente de suas obrigações perante a Lei Geral de Proteção de Dados (Lei n° 13.709/18 – LGPD) e está comprometida em recolher e tratar seus dados de forma segura e transparente.</p>
                        <p>A <span>YOGHA</span> coleta dados pessoais necessários à prestação de seus serviços no momento de uma reserva; pedido; subscrição de newsletter ou inserção de dados no website. Assim, serão ou poderão ser coletados os seguintes dados e informações:</p>

                        <p>a) Anúncio de imóvel (proprietário)<br />
                            b) Dados do contato: nome; sobrenome; empresa; endereço de correio eletrônico.<br />
                            c) Características dos alojamentos: número de alojamentos; localização; tipo; observações.<br />
                            d) Reservas, vendas e locações (hóspede)<br />
                            e) Dados pessoais (hóspede principal): nome; sobrenome; segundo apelido; passaporte ou CPF; país; endereço; cidade; CEP; bairro; número de telefone; endereço de correio eletrônico.<br />
                            f) Dados do hóspede (reserva para outra pessoa): nome; sobrenome; país; endereço; cidade; CEP; bairro; passaporte ou CPF; número de telefone; endereço de correio eletrônico.<br />
                            g) Modo de pagamento (cartão de crédito - TPV): número do cartão de crédito; data de validade do cartão de crédito; titular do cartão de crédito; código de segurança.<br />
                            h) Dados cadastrais do Usuário: nome; apelido; número de telefone; país; endereço de correio eletrônico; senha etc.<br />
                            i) Logs de acesso: informações como endereço de IP; tipo e versão de dispositivo e navegador; tipo e versão de sistema operacional; informações de geolocalização etc.<br />
                            j) Informações sobre o uso e interações do Usuário: dados de analytics das seções; itens; páginas e funcionalidades disponíveis na Plataforma.<br />
                            k) Cookies: somente serão coletados via cookies, dados essenciais para o funcionamento do nosso site e para medir métricas de acesso à Plataforma. Tais dados não serão compartilhados com terceiros. Para mais informações, leia nossa Política de Cookies.<br />
                        </p>
                        <p>As informações pessoais coletadas estão contidas em redes seguras e acessíveis somente por um número limitado de pessoas com direito especial de acesso ao sistema, de forma que são obrigadas a manter as informações confidenciais. Em relação aos dados pessoais tratados, a <span>YOGHA</span> se compromete a:</p>
                        <p>a) Tratá-lo de forma justa, legal, clara e transparente<br />
                            b) Coletar dados apenas por razões que consideramos adequadas para a execução dos serviços contratos;<br />
                            c) Usá-los apenas da maneira que lhe informamos;<br />
                            d) Garantir que os dados estão corretos e atualizados;<br />
                            e) Manter seus dados, apenas, pelo tempo que precisarmos;<br />
                            f) Tratá-los de forma que garanta que os dados não serão usados para finalidade que você não tenha consentido.<br />
                        </p>
                        <p>As transações de cartão de crédito, quando e se ocorrerem, serão processadas através de um provedor de gateway seguro, assim, não são armazenadas ou processadas em nossos servidores.</p>
                        <p>A <span>YOGHA</span> também utiliza – ou pode vir a utilizar – serviços de terceiros para hospedar suas Plataformas, websites, e-mails, provedor gateway para recebimento de pagamentos, bem como para entender quais são as demandas dos Usuários por conteúdo e serviços. Para isso, às vezes será necessário o compartilhamento de dados dos Usuários com estes terceiros, mas só será compartilhado o estritamente necessário, de acordo com os parâmetros e garantias estipulados nestas Políticas de Privacidade.</p>
                        <p>A <span>YOGHA</span> não vende, repassa ou transfere os dados pessoais coletados dos Usuários, exceto nas situações aqui previstas.</p>
                        <p>Com fundamento nas bases legais previstas no art. 7º da Lei Geral de Proteção de Dados (Lei nº 13.907/2018), a <span>YOGHA</span> poderá coletar, utilizar, transferir, armazenar ou processar informações pessoais dos Usuários, seja mediante o consentimento em relação às hipóteses aqui descritas, para execução de obrigações contratuais, para cumprimento das obrigações legais e para atender aos interesses legítimos da <span>YOGHA</span> ou de terceiros, sempre com respeito aos direitos e liberdades fundamentais do(s) titular(es).</p>
                        <p>As informações pessoais coletadas serão ou poderão ser utilizadas, transferidas, armazenadas ou processadas para as seguintes finalidades:</p>
                        <p>a) Melhorar o nosso website a fim de atendê-lo melhor;<br />
                            b) Pesquisas e desenvolvimento de novos serviços; segurança e gerenciamento de riscos;<br />
                            c) Estabelecer contato e relacionamento com o usuário e clientes finais da <span>YOGHA</span>;<br />
                            d) Processar as suas transações mais rapidamente;<br />
                            e) Enviar e-mails, correspondências, newsletters, informações, responder a perguntas e/ou outras solicitações;<br />
                            f) Processar reservas e enviar informações e atualizações referentes aos pedidos;<br />
                            g) Enviar informações e mensagens adicionais relacionadas ao produto e/ou prestações de serviços pela <span>YOGHA</span>, assim como outras informações adicionais que sejam do interesse dos clientes finais da <span>YOGHA</span>.<br />
                            h) Quando necessário em decorrência de obrigação legal, determinação de autoridade competente ou ainda decisão judicial e;<br />
                            i) Prevenção e detecção de quaisquer violações às presentes condições.<br />
                        </p>
                        <p>Para o tratamento de dados pessoais, para qualquer outra finalidade distinta das hipóteses mencionadas acima, serão devidamente comunicadas ao usuário.</p>
                        <p>Haverá o término do tratamento dos dados pessoais, com a consequente eliminação de tais dados, ressalvada as hipóteses de conservação autorizadas pela legislação em vigor, nos seguintes casos:</p>
                        <p>a) Atingimento da finalidade do tratamento;<br />
                            b) Fim do período de tratamento;<br />
                            c) Solicitação pelo próprio titular; e/ou <br />
                            d) por determinação da autoridade nacional (ANPD).</p>
                        <p>A política de privacidade da <span>YOGHA</span> visa a garantir que quaisquer informações e dados dos usuários não serão fornecidos, publicados ou comercializados em quaisquer circunstâncias, sem consentimento do Usuário e/ou para finalidades distintas das descritas no presente documento.</p>
                        <p>A <span>YOGHA</span> exigirá que todos os prestadores de serviços que tratarem dados pessoais também o façam em conformidade com a LGPD e demais normas vigentes.</p>
                        <p>Os dados pessoais informados são armazenados em um banco de dados seguro, reservado e com acesso restrito a funcionários habilitados, que são obrigados a manter a confidencialidade das informações e não as utilizar inadequadamente.</p>
                        <p>A <span>YOGHA</span> observará todos os direitos assegurados aos titulares dos dados pessoais, previstos no art. 18 da LGPD, sendo garantido o atendimento às requisições do usuário de:</p>
                        <p>a) Confirmação da existência de tratamento;<br />
                            b) Acesso aos dados;<br />
                            c) Correção de dados incompletos, inexatos ou desatualizados;<br />
                            d) Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD<br />
                            e) Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial;<br />
                            f) Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;<br />
                            g) Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da LGPD<br />
                            h) Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;<br />
                            i) Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e<br />
                            j) Revogação do consentimento.</p>
                        <p>Os direitos assegurados aos titulares de dados pessoais poderão ser exercidos pelo usuário, a qualquer momento, mediante envio de requisição específica ao endereço <span>privacidade@yogha.com.br</span></p>
                        <p>Caso o usuário tenha dúvidas ou reclamações sobre as práticas de uso e tratamento de dados, deverá manifestá-las pelo envio de e-mail ao mesmo endereço indicado acima.</p>
                        <p>Por fim, a <span>YOGHA</span> informa que sempre que houver necessidade de compartilhar seus dados com terceiros, são fornecidas instruções por escrito para garantir que seus dados sejam mantidos em segurança e em conformidade com os requisitos da LGPD, bem como é exigido que esse terceiro também possua sistema de segurança da informação e esteja conformidade com a LGPD.</p>
                    </S.Row>
                    <S.Row>
                        <span>Reservas</span>
                        <p>O Usuário está plenamente ciente de que a <span>YOGHA</span> não é proprietária dos imóveis por ela divulgados. A <span>YOGHA</span>, por meio de sua Plataforma, apenas intermedia a relação entre o Usuário, os proprietários dos imóveis e os fornecedores dos Serviços.</p>
                        <p>O objeto da atividade da <span>YOGHA</span> é divulgar e intermediar na Plataforma as ofertas de seus Parceiros. Todas as ofertas disponibilizadas na Plataforma passam por critérios de qualificação com base nas certificações obrigatórias de cada atividade, enviadas pelos Parceiros, que são responsáveis pela autenticidade das informações. Dessa forma, a <span>YOGHA</span> apenas disponibiliza imóveis e serviços que estejam de acordo com a legislação vigente.</p>
                        <p>A <span>YOGHA</span>, por ser uma plataforma de concentração de imóveis e Serviços disponibilizados por parceiros, não é a proprietária dos imóveis/serviços ofertados pelos Parceiros e não realiza ofertas em seu nome, entretanto, está disponível para, através de seus canais de atendimento ao Usuário, intermediar qualquer comunicação necessária com os Parceiros e tomar as providências necessárias para a solução de eventuais transtornos.</p>
                        <p>Ao reservar um imóvel ou contratar um Serviço através das Plataforma, o Usuário declara ter aderido às condições fixadas para a oferta e conhecer suas formas de prestação, bem como as eventuais cobranças de taxa por serviços adicionais contratados e/ou obrigatórios, formas de pagamento, recebimento e prazo de validade, política de cancelamento, etc.</p>
                        <p>Ao reservar um imóvel ou contratar um Serviço através das Plataforma, o Usuário declara ter aderido às condições fixadas para a oferta e conhecer suas formas de prestação, bem como as eventuais cobranças de taxa por serviços adicionais contratados e/ou obrigatórios, formas de pagamento, recebimento e prazo de validade, política de cancelamento, etc.</p>
                        <p>A Reserva do imóvel escolhido pelo Usuário é realizada na Plataforma da <span>YOGHA</span>, dentre os imóveis disponíveis, aptos ao ingresso imediato na data reservada, conforme valores divulgados na Plataforma.</p>
                        <span>Ao realizar a Reserva do imóvel, o Usuário concorda com a realização do pagamento antecipado dos valores divulgados na Reserva e com a contratação e o pagamento dos serviços adjuntos divulgados no processo da reserva, tal como os serviços de limpeza semanal do imóvel, sempre conforme os valores vigentes na Plataforma.</span>
                        <p>Cada prestador de serviço, incluindo a <span>YOGHA</span>, será responsável pela emissão de suas respectivas notas fiscais ao Usuário, conforme legislação aplicável.</p>
                        <p>A <span>YOGHA</span> não será responsabilizada caso a reserva não seja realizada em razão de ausência de limite de cartão de crédito, falha na comunicação com operadoras, estorno ou quaisquer outros eventos que possam ser considerados de força maior ou por culpa de terceiros e que inviabilizem o pagamento dos valores devidos. Em nenhuma hipótese a <span>YOGHA</span> será responsabilizada em razão de uma negociação ou tentativa de negociação malsucedida.</p>
                        <p>Pela Reserva do imóvel, o Usuário compromete-se a pagar antecipadamente o valor indicado na Plataforma no momento da Reserva, conforme meios de pagamento autorizados na Plataforma.</p>
                        <p>O Usuário será responsável pelo pagamento de todas as despesas contraídas em função da contratação de Serviços adicionais disponibilizados pelos Parceiros.</p>
                        <p>Caso, por qualquer motivo não imputável à <span>YOGHA</span>, a realização da cobrança mencionada não seja possível ou, ainda, o Usuário deixe por qualquer motivo de pagar os valores devidos, de modo a caracterizar o atraso no pagamento pelo Usuário, este <span> (o Usuário) poderá ficar, a critério da YOGHA, impedido de entrar no imóvel e, ainda, sujeito ao pagamento de multa de 10% (dez por cento) sobre o valor devido, bem como ao pagamento de juros de mora de 1% (um por cento) ao mês e correção monetária pelo IGP-M, calculados pro rata die até a data do efetivo pagamento.</span></p>
                        <p>Ao fim do período de reserva ou, ainda, ao fim de períodos pré-determinados a critério da <span>YOGHA</span>, a <span>YOGHA</span> enviará fatura ao e-mail do Usuário cadastrado na Plataforma, listando todos os itens consumidos e o valor devido, no caso de utilização dos serviços adicionais disponibilizados na plataforma da <span>YOGHA</span>.</p>
                        <p>Em caso de não pagamento da fatura pelos serviços adicionais contratados e consumidos, o valor <span>ficará sujeito ao pagamento de multa de 10% (dez por cento) sobre o valor devido, bem como ao pagamento de juros de mora de 1% (um por cento) ao mês e correção monetária pelo IGP-M, calculados pro rata die até a data do efetivo pagamento e poderá ser objeto de cobrança judicial.</span></p>
                        <p>Adicionalmente, em caso de inadimplemento, será imediatamente suspenso o acesso do Usuário à Plataforma e aos imóveis e serviços disponibilizados pela <span>YOGHA</span> e seus Parceiros.</p>
                    </S.Row>
                    <S.Row>
                        <span>Utilização do imóvel</span>
                        <p>O Usuário compreende que a confirmação da Reserva de um Imóvel é uma autorização concedida pela Plataforma da <span>YOGHA</span> para entrar, ocupar, e utilizar a acomodação pela duração da reserva, conforme dados da reserva.
                        </p>
                        <span>O Usuário concorda em deixar o imóvel na data e horário de saída especificada pela Plataforma da <span>YOGHA</span> no momento da Reserva do Imóvel.</span>
                        <p>O Usuário poderá estender a duração da locação, desde que:</p>
                        <p>a) O imóvel esteja disponível para nova reserva pelo período desejado pelo Usuário; <br />
                            b) O Usuário realize novo processo de reserva, através da Plataforma, <span>com 5 (cinco) dias de antecedência do último dia de vigência da sua reserva;</span> <br />
                            c) Realize novo Check-in por meio da Plataforma;<br />
                            d) Concorde e promova os pagamentos aplicáveis, conforme valores vigentes e divulgados na Plataforma.<br /></p>
                        <p>Caso o Usuário permaneça além do período acordado para a saída, sem o consentimento da Plataforma da <span>YOGHA</span>, não terá mais autorização para permanecer no imóvel, e a <span>YOGHA</span> terá o direito de requisitar imediatamente sua saída nos termos da legislação aplicável.</p>
                        <span>Caso o Usuário permaneça indevidamente no imóvel além do tempo da reserva, ou seja, sem que tenha havido novo processo de reserva, sem prejuízo das medidas de restituição a serem adotadas pela YOGHA, a título de penalidade indenizatória, o Usuário ficará sujeito ao pagamento do valor equivalente a 2 (duas) diárias atuais e vigentes para o imóvel, por cada período de 24h (vinte e quatro horas) que o Usuário permanecer no imóvel, sem prejuízo do pagamento dos serviços adjuntos e obrigatórios da reserva, conforme valores vigentes na Plataforma.</span>
                        <p>O Usuário será responsável por quaisquer multas e/ou penalidades por desobediência às normas de civilidade e vizinhança vigentes na localidade do imóvel, bem como por quaisquer violações às regras condominiais.</p>
                        <span>Caso o Usuário ou, ainda convidados do Usuário, incorram em infrações de qualquer natureza, que implique em sanção de multa condominial, conforme previsão da Convenção de Condomínio, tais multas poderão ser cobradas diretamente pelo condomínio onde está localizado o imóvel ou pela <span>YOGHA</span>.</span>
                        <p>Fica vedada ao Usuário a locação, o comodato, a cessão ou empréstimo a qualquer título imóvel, quer no todo, ou em parte, ou sob qualquer título, sem a expressa autorização da <span>YOGHA</span>.</p>
                        <p>Fica vedado ao Usuário promover qualquer tipo de benfeitoria, independentemente do grau de necessidade, incluindo serviços, manutenção ou reparo do imóvel. Havendo necessidade de qualquer serviço de manutenção ou reparo ao imóvel ou aos seus pertences e utensílio, o Usuário deverá requisitá-los imediatamente para a <span>YOGHA</span>, que deverá executá-los em prazo compatível com a urgência de cada situação. Não serão indenizadas, a nenhum título, qualquer tipo de benfeitoria que o Usuário execute no imóvel, exceto quando houve prévio ajuste e permissão da execução dos serviços, com seus respectivos valores acordados, com a <span>YOGHA</span>.</p>
                    </S.Row>
                    <S.Row>
                        <span>Ressarcimento de danos</span>
                        <p>O Usuário concorda em utilizar de boa-fé o imóvel e as estruturas e equipamentos necessários a eventuais Serviços contratados, sem danificar a estrutura física e/ou os objetos do imóvel, do Condomínio ou do prestador dos Serviços. O usuário também concorda em manter a decoração original do interior do imóvel tal como lhe foi entregue na data de início da reserva, incluindo todos os móveis, acessórios e utensílios em seu estado original. Salvo pelo desgaste natural de uso, o Usuário declara estar ciente que será́ responsável por indenizar, reparar e/ou restituir qualquer dano gerado por si ou por terceiros a quem deu acesso ao imóvel durante o período em que este permaneceu em sua posse.</p>
                        <p>Caso se verifiquem danos, durante ou após o período de prestação de Serviços e/ou reserva, e desde que sejam devidamente comprovados, por meio de relatório elaborado pela <span>YOGHA</span>, contendo descrição e fotos dos danos, a <span>YOGHA</span> enviará, por e-mail e/ou carta, ao Usuário, notificação com orçamento contendo os valores para reparação dos danos, para indenização, reparação e/ou reposição dos danos causados.</p>
                        <p>Caso o Usuário não realize o pagamento da indenização em até 5 (cinco) dias úteis contados da data do recebimento da notificação da <span>YOGHA</span>, o Usuário estará sujeito ao pagamento de multa de 10% (dez por cento) sobre o valor total da Indenização, bem como ao pagamento de juros de mora de 1% (um por cento) ao mês e correção monetária pelo IGP-M, calculados pro rata die até a data do efetivo pagamento integral da Indenização, estando a <span>YOGHA</span> livre para tomar todas as medidas judiciais e extrajudiciais cabíveis para reparação do dano, sendo que neste caso o Usuário também arcará com honorários e custas judiciais.</p>
                        <p>O Usuário tem ciência e concorda que, nos casos em que houver dano ao imóvel, mobília e/ou quaisquer dos seus utensílios ou equipamentos instalados, que impossibilitem a disponibilização do imóvel para reserva por outros Usuários na Plataforma da <span>YOGHA</span>, de acordo com os critérios de qualidade impostos por esta e levando em consideração as condições usuais em que se encontram os outros imóveis disponibilizados para reserva na Plataforma, será feita cobrança proporcional do valor do aluguel inicialmente contratado com o Usuário causador do dano, pelo período em que o apartamento esteve bloqueado para reparos, além do valor dos itens, mencionado acima.</p>
                        <p>O Usuário concorda em cooperar e ajudar a <span>YOGHA</span> de boa-fé, bem como fornecer à <span>YOGHA</span> todas as informações necessárias, empregando todos os esforços ao seu alcance e tomando as medidas que venham a ser razoavelmente solicitadas pela <span>YOGHA</span>, na ocorrência de quaisquer queixas e/ou reivindicações apresentadas no que se refere à estrutura física do Condomínio para a reserva ou prestação dos Serviços ou aos equipamentos e materiais utilizados.</p>
                    </S.Row>
                    <S.Row>
                        <span>Política de alterações e cancelamento da Reserva e Serviços</span>
                        <p>O Usuário tem ciência que é o responsável por quaisquer alterações em sua reserva, hipótese em que deverá fazer contato com a <span>YOGHA</span>, através do canal de atendimento disponível em suas plataformas. Tais alterações estarão sujeitas a cobrança de taxas e multas, conforme o caso.</p>
                        <p>Caso o Usuário deseje cancelar a Reserva e Serviços efetuada na Plataforma, o Usuário ficará submetido ao procedimento de cancelamento aplicável ao canal utilizado para a reserva ou às políticas específicas de promoções aderidas. O cancelamento deverá ser efetuado diretamente com a <span>YOGHA</span>, por meio da Plataforma, ou mediante o envio de e-mail para a <span>YOGHA</span>.</p>
                        <p>Em regra, nos casos de cancelamento <span>antes de 5 (cinco) dias da data programada para o check-in</span>, a <span>YOGHA</span> restituirá a integralidade do valor pago no momento da Reserva, salvo nos casos em que esta tiver sido realizada eventualmente via cartão de crédito, hipótese em que será descontado o valor equivalente a taxa do cartão. <span>Se o cancelamento for realizado em período inferior aos 5 (cinco) dias anteriores à data programada para o check-in, a YOGHA se reserva no direito de reter 30% (trinta por cento) do valor pago no momento da Reserva, a título de multa pelo cancelamento, sem prejuízo de reter eventuais taxas de cartões e outras despesas financeiras.</span> </p>
                        <span>O Usuário desde já manifesta a sua ciência de que, no caso de reservas com condições promocionais, o valor da Reserva pode não ser reembolsável na hipótese de cancelamento por parte do Usuário, condições às quais o Usuário aceitou vincular-se no momento em que realizou sua Reserva.</span>
                        <p>O reembolso do valor da Reserva também ficará condicionado à observância da data limite para cancelamento indicada no momento da Reserva, sendo que, após referida data, a <span>YOGHA</span> não estará obrigada a realizar o reembolso de valores.</p>
                        <p>O Usuário tem ciência e concorda que a reserva poderá ser automaticamente cancelada pela <span>YOGHA</span>, nos seguintes casos:</p>
                        <p>a) Em caso de identificação de Contestação de Cobrança proveniente da operação de pagamento da Reserva (Chage-Back) ou estorno;<br />
                            b) Em caso de cancelamento da autorização do pagamento da Reserva pela operadora do cartão de crédito ou pela instituição financeira emitente, ou não realização de transferência bancária;<br />
                            c) Em caso de qualquer outra situação que repercuta no não pagamento dos valores da reserva, na forma e prazos estabelecidos no procedimento de reserva;<br />
                            d) Caso o Usuário descumpra quaisquer das regras previstas neste instrumento ou, quando previamente advertido, não sanar o descumprimento;<br />
                            e) Caso o imóvel, apesar do processo de reserva, não esteja disponível para ocupação na data selecionada pelo Usuário, a qualquer título ou situação, com ou sem culpa da <span>YOGHA</span>.<br /></p>
                        <p>Caso a <span>YOGHA</span> cancele uma Reserva confirmada, o Usuário receberá o reembolso integral da Reserva, no prazo máximo de 5 (cinco) dias úteis.</p>
                        <p>Caso ocorram quaisquer circunstâncias que tornem o imóvel indisponível para o uso, por todo ou por parte do período da Reserva, a <span>YOGHA</span> terá o direito de realocar o Usuário para outro imóvel similar, no mesmo edifício ou em outro edifício de mesmo padrão, mediante aviso prévio em tempo razoável dentro das circunstâncias. Tais circunstâncias incluem, mas não se limitam, a manutenção significativa do edifício e desastres naturais.</p>
                        <p>
                            Nos casos em que a <span>YOGHA</span> notifique o Usuário de sua intenção de realocá-lo e o Usuário não esteja de acordo com a mudança, o Usuário poderá rescindir o presente contrato, sendo certo que será reembolsado dos valores proporcionais ao tempo restante de reserva. A <span>YOGHA</span> não será responsável por quaisquer valores adicionais eventualmente existentes e decorrentes do respectivo cancelamento da reserva.
                            O Usuário renuncia expressamente ao seu direito de preferência na aquisição do imóvel, tal como previsto na legislação vigente e aplicável, conforme e se for o caso.
                        </p>
                    </S.Row>
                    <S.Row>
                        <span>Obrigações e Responsabilidades do Usuário</span>
                        <p>O Usuário é o único responsável pelo cumprimento de toda e qualquer lei, regra, regulamento e obrigação fiscal que lhe possa ser aplicável em virtude da utilização dos serviços, da reserva de imóveis e do uso da Plataforma e dos Serviços. Desta forma, o Usuário não poderá:</p>
                        <p>a) Violar, ignorar ou contornar quaisquer leis ou regulamentos aplicáveis, incluindo Convenções de Condomínios e Regulamentos Internos aplicáveis; contratos com terceiros; direitos de terceiros; e/ou as disposições presentes neste documento;<br />
                            b) Utilizar a Plataforma para qualquer fim comercial ou para fins diversos daqueles expressamente autorizados por este documento;<br />
                            c) Agir com atitude de discriminação ou assédio com qualquer Prestador de Serviços ou funcionário da <span>YOGHA</span> com base em raça, nacionalidade de origem, religião, gênero, identidade de gênero, deficiência física ou mental, condição médica, estado civil, idade ou orientação sexual, ou de outra forma participar de um comportamento violento, prejudicial, abusivo e/ou desordeiro, enquanto estiver nas dependências do condomínio ou durante a prestação dos Serviços;<br />
                            d) Usar, exibir, imitar ou reproduzir a Plataforma, ou qualquer elemento individual da Plataforma, o nome <span>YOGHA</span>, qualquer marca comercial da <span>YOGHA</span>, logotipo ou outras informações de propriedade intelectual pertencente e/ou relacionada à <span>YOGHA</span>, o layout, design e/ou qualquer recurso gráfico e de identidade visual da <span>YOGHA</span> e/ou de qualquer página ou formulário contido na Plataforma, sem o consentimento expresso e por escrito da <span>YOGHA</span>;<br />
                            e) Manchar ou prejudicar a marca <span>YOGHA</span> de qualquer forma, inclusive por meio de utilização não autorizada do conteúdo da Plataforma, registrar e/ou usar a <span>YOGHA</span> ou termos derivados em nomes de domínio, marcas registradas, marcas comerciais ou outros identificadores de origem, ou registrar e/ou usar nomes de domínios, marcas registradas, marcas comerciais ou outros identificadores de origem que imitem estreitamente ou sejam confusamente similares a domínios da <span>YOGHA</span>, suas marcas registradas e/ou slogans;<br />
                            f) Realizar qualquer ação que cause prejuízo ou afete negativamente, ou que possa causar prejuízo, à Plataforma ou aos Parceiros de Serviço, bem como afetar negativamente o desempenho ou o devido funcionamento da Plataforma;<br />
                            g) Violar ou infringir o direito de qualquer pessoa ou ainda causar dano a qualquer pessoa, enquanto estiver nas dependências do condomínio e ao longo da prestação dos Serviços;<br />
                            h) Danificar e/ou de qualquer forma prejudicar qualquer dependência do condomínio utilizada na prestação dos Serviços, incluindo seus objetos; e<br />
                            i) Violar ou infringir o direito de qualquer pessoa ou ainda causar dano a qualquer pessoa.<br /></p>
                        <p>O Usuário declara estar ciente, entender e concordar que é exclusiva e totalmente responsável pelo cumprimento de todas e quaisquer leis, normas, regulamentos e obrigações legais e fiscais que possam ser aplicadas para o uso da Plataforma, inclusive as normas específicas de cada condomínio parceiro, como a Convenção Condominial e o Regulamento Interno de cada edifício no qual se realizará a prestação dos Serviços.</p>
                    </S.Row>
                    <S.Row>
                        <span>Isenções e Limitação de Responsabilidade</span>
                        <p>Para além das demais isenções de responsabilidade indicadas neste documento, o Usuário se declara ciente de que a <span>YOGHA</span> não será responsável:</p>
                        <p>a) Pelas obrigações tributárias decorrentes das atividades dos Usuários relacionadas à utilização da Plataforma. O Usuário é exclusiva e integralmente responsável por recolher todos e quaisquer impostos e taxas que eventualmente incidam sobre suas atividades relacionadas à utilização da Plataforma;<br />
                            b) Por quaisquer indisponibilidades, erros e/ou falhas apresentados pela Plataforma, assim como por eventual defraudação da utilidade que o Usuário possa atribuir a Plataforma, pela falibilidade desta, nem por qualquer dificuldade de acesso a esta;<br />
                            c) Por erros ou eventuais inconsistências na transmissão de dados, pela qualidade ou disponibilidade da conexão de Internet, que impeçam o adequado recebimento de informações pela <span>YOGHA</span> ou pelo Usuário;<br />
                            d) Pela presença de malware, spyware ou demais elementos nocivos na Plataforma, capazes de causar alterações em seus sistemas informáticos (software e hardware) e documentos eletrônicos, eximindo-se a <span>YOGHA</span> de qualquer responsabilidade por eventuais danos e prejuízos decorrentes de quaisquer elementos nocivos inseridos por terceiros;<br />
                            e) Pelos danos e prejuízos de toda natureza decorrentes do conhecimento que terceiros não autorizados possam ter de quaisquer dados fornecidas pela Plataforma, em decorrência de falha exclusivamente relacionada ao Usuário ou a terceiros que fujam a qualquer controle razoável da <span>YOGHA</span>;<br />
                            f) Por eventuais danos e prejuízos suportados pelos Usuários em decorrência da prestação dos serviços pelos Parceiros de Serviços disponibilizados na Plataforma.<br /></p>
                        <p>Em todo e qualquer caso, sem prejuízo das isenções de responsabilidades da <span>YOGHA</span>, a responsabilidade da <span>YOGHA</span> perante o Usuário, em qualquer caso, extensão ou natureza, ficará limitada ao valor total do valor da reserva praticada pelo Usuário no período em que ocorreu o evento.</p>
                    </S.Row>
                    <S.Row>
                        <span>Rescisão</span>
                        <p>A <span>YOGHA</span> pode encerrar a reserva ou o acesso do Usuário à Plataforma, a qualquer momento, sem aviso ou consentimento prévio, a seu exclusivo critério. O Usuário pode cancelar a sua conta na Plataforma, desde que não tenha nenhuma obrigação pendente em relação à <span>YOGHA</span>.</p>
                        <p>Em caso de rescisão: (a) a <span>YOGHA</span> deverá pagar, em tempo hábil, quaisquer valores devidos ao Usuário, se houver; e (b) o Usuário permanecerá responsável por todos os montantes devidos à <span>YOGHA</span>.</p>
                    </S.Row>
                    <S.Row>
                        <span>Disposições finais</span>
                        <p>Os casos fortuitos ou de força maior serão excludentes de responsabilidade das partes, na forma da Legislação Brasileira.</p>
                        <p>Todas as comunicações encaminhadas pelo Usuário à <span>YOGHA</span> serão consideradas plenamente válidas quando realizadas por escrito e entregues pelos canais oficiais de contato da <span>YOGHA</span>.</p>
                        <p>As relações entre <span>YOGHA</span> e o Usuário serão regidos pelas leis vigentes na República Federativa do Brasil.</p>
                        <p>Todos os assuntos referentes à interpretação, ao cumprimento ou qualquer outro questionamento relacionado a estas condições ou à relação entre <span>YOGHA</span> e o Usuário, serão submetidos ao Foro Central da Comarca da Região Metropolitana de Curitiba, Estado do Paraná, com a renúncia expressa a qualquer outro, por mais privilegiado que seja ou possa vir a ser.</p>
                        <p>A <span>YOGHA</span> manterá a versão atualizada deste documento, que poderá ser consultado a qualquer momento pelo Usuário, mediante acesso ao domínio “https://www.yogha.com.br”. Contudo, a <span>YOGHA</span> poderá alterar, a qualquer tempo, estas Regras e Condições de Uso e Política de Privacidade, visando seu aprimoramento e melhoria.</p>
                        <p>O Usuário declara que leu e compreendeu todos os termos aqui constantes, tendo anuído a todo o seu teor e conteúdo antes da finalização do seu cadastro e ou do seu processo de Reserva.</p>
                    </S.Row>
                </S.Content>
                <S.EndText>
                    <span>YOGHA</span>
                </S.EndText>
                <S.ScrollContainer>
                    <S.ScrollToTopButton onClick={scrollToTop} >
                        <img src={arrowTop} alt="" />
                    </S.ScrollToTopButton>
                </S.ScrollContainer>
                <Footer />
            </S.Container>
        </>
    )
}