import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { validateCPF } from '../hook/useValidateCpf';

function isValidPastDate(value: string): boolean {
  // Remover espaços em branco antes de validar
  const trimmedValue = value.trim();

  if (trimmedValue.length !== 8) return false;

  const day = parseInt(trimmedValue.substring(0, 2), 10);
  const month = parseInt(trimmedValue.substring(2, 4), 10) - 1; // month is 0-indexed in JavaScript Date
  const year = parseInt(trimmedValue.substring(4, 8), 10);

  const date = new Date(year, month, day);
  if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
    return false; // Invalid date
  }

  const now = new Date();
  return date <= now; // Date must be in the past
}

function isAtLeastTenYearsOld(value: string): boolean {
  // Remover espaços em branco antes de validar
  const trimmedValue = value.trim();

  const day = parseInt(trimmedValue.substring(0, 2), 10);
  const month = parseInt(trimmedValue.substring(2, 4), 10) - 1;
  const year = parseInt(trimmedValue.substring(4, 8), 10);

  const birthDate = new Date(year, month, day);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age >= 10; // Person must be at least 10 years old
}

function isAtLeastEighteenYearsOld(value: string): boolean {
  const day = parseInt(value.substring(0, 2), 10);
  const month = parseInt(value.substring(2, 4), 10) - 1;
  const year = parseInt(value.substring(4, 8), 10);

  const birthDate = new Date(year, month, day);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age >= 18; // Person must be at least 18 years old
}

export const usePersonalInfoSchema = () => {
  const { t } = useTranslation();

  // Crie o esquema diretamente dentro do hook
  const originalSchema = z.object({
    documentType: z.enum(['CPF', 'PASSPORT']),
    name: z.string({ required_error: t('checkin.personalInformation.forms.errors.name') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.name'),
    }),
    surname: z.string({ required_error: t('checkin.personalInformation.forms.errors.surname') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.surname'),
    }),
    email: z.string({ required_error: t('checkin.personalInformation.forms.errors.email') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.email'),
    }).email({
      message: t('checkin.personalInformation.forms.errors.formatEmail'),
    }).transform((value) => value.toLowerCase()),
    countryYouLive: z.object({
      label: z.string({ required_error: t('checkin.personalInformation.forms.errors.countryYouLive') }).nonempty({
        message: t('checkin.personalInformation.forms.errors.countryYouLive'),
      }),
      value: z.string({ required_error: t('checkin.personalInformation.forms.errors.countryYouLive') }).nonempty({
        message: t('checkin.personalInformation.forms.errors.countryYouLive'),
      }),
    }, { required_error: t('checkin.personalInformation.forms.errors.countryYouLive') }),
    birthDate: z.string({ required_error: t('checkin.personalInformation.forms.errors.birthDate') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.birthDate'),
    }),
    phone: z.string({ required_error: t('checkin.personalInformation.forms.errors.phone') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.phone'),
    }).refine((value) => {
      if (value.startsWith('55') && value.length < 13) {
        return false;
      }
      return true;
    }, { message: t('checkin.personalInformation.forms.errors.phone') }),
    documentNumber: z.string().optional(),
    acceptTerms: z.boolean().optional(),
  })

  const accompanyingSchema = z.object({
    documentType: z.enum(['CPF', 'PASSPORT']),
    name: z.string({ required_error: t('checkin.personalInformation.forms.errors.name') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.name'),
    }),
    surname: z.string({ required_error: t('checkin.personalInformation.forms.errors.surname') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.surname'),
    }),
    email: z.string().optional().transform((value) => {
      const trimmedEmail = value?.trim();
      return trimmedEmail === '' ? undefined : trimmedEmail?.toLowerCase();
    }),
    birthDate: z.string({ required_error: t('checkin.personalInformation.forms.errors.birthDate') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.birthDate'),
    }),
    phone: z.string({ required_error: t('checkin.personalInformation.forms.errors.phone') }).nonempty({
      message: t('checkin.personalInformation.forms.errors.phone'),
    }).refine((value) => {
      if (value.startsWith('55') && value.length < 13) {
        return false;
      }
      return true;
    }, { message: t('checkin.personalInformation.forms.errors.phone') }),
    documentNumber: z.string().optional(),
  }).superRefine((data, context) => {
    if (data.documentType === 'PASSPORT' && !data.documentNumber) {
      context.addIssue({
        path: ['documentNumber'],
        code: 'custom',
        message: t('checkin.personalInformation.forms.errors.passport')
      })
    }
    if (data.documentType === 'CPF' && !data.documentNumber) {
      context.addIssue({
        path: ['documentNumber'],
        code: 'custom',
        message: t('checkin.personalInformation.forms.errors.cpf')
      })
    }
    if (data.documentType === 'CPF' && data.documentNumber) {
      // Se o validateCPF retornar false, adiciona um problema ao contexto
      if (!validateCPF(data.documentNumber)) {
        context.addIssue({
          path: ['documentNumber'], // Caminho do campo com erro
          message: t('checkin.personalInformation.forms.errors.invalidCPF'), // Mensagem de erro
          code: 'custom', // Código do erro
        });
      }
    }
    if (!isValidPastDate(data.birthDate)) {
      context.addIssue({
        path: ['birthDate'],
        code: 'custom',
        message: t('checkin.personalInformation.forms.errors.invalidDate')
      });
    }
    if (!isAtLeastTenYearsOld(data.birthDate)) {
      context.addIssue({
        path: ['birthDate'],
        code: 'custom',
        message: t('checkin.personalInformation.forms.errors.atLeastTenYearsOld')
      });
    }
    if (data.email) {
      const emailValidation = z.string().email().safeParse(data.email);
      if (!emailValidation.success) {
        context.addIssue({
          path: ['email'],
          code: 'custom',
          message: t('checkin.personalInformation.forms.errors.formatEmail')
        });
      }
    }
  }
  );

  const schema = originalSchema.superRefine((data, context) => {
    if (data.documentType === 'PASSPORT' && !data.documentNumber) {
      context.addIssue({
        path: ['documentNumber'],
        code: 'custom',
        message: t('checkin.personalInformation.forms.errors.passport')
      })
    }
    if (data.documentType === 'CPF' && !data.documentNumber) {
      context.addIssue({
        path: ['documentNumber'],
        code: 'custom',
        message: t('checkin.personalInformation.forms.errors.cpf')
      })
    }
    if (data.documentType === 'CPF' && data.documentNumber) {
      // Se o validateCPF retornar false, adiciona um problema ao contexto
      if (!validateCPF(data.documentNumber)) {
        context.addIssue({
          path: ['documentNumber'], // Caminho do campo com erro
          message: t('checkin.personalInformation.forms.errors.invalidCPF'), // Mensagem de erro
          code: 'custom', // Código do erro
        });
      }
    }
    if (!isValidPastDate(data.birthDate)) {
      context.addIssue({
        path: ['birthDate'],
        code: 'custom',
        message: t('checkin.personalInformation.forms.errors.invalidDate')
      });
    }
    if (!isAtLeastEighteenYearsOld(data.birthDate)) {
      context.addIssue({
        path: ['birthDate'],
        code: 'custom',
        message: t('checkin.personalInformation.forms.errors.tooYoung')
      });
    }
  }
  );

  return { schema, omitCountryYouLive: accompanyingSchema };
};
