import { Link } from "react-router-dom";
import styled from "styled-components";
import { FONT } from "../../constants/fontConstants";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 100%;

    gap: 8px;

    @media (width < 768px) {
        display: none;
    }
`
export const InitialLink = styled(Link)`
    text-decoration: none;
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${NEUTRAL_COLORS.DARK_GRAY};
    &:hover {
        cursor: pointer;
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const ActualPage = styled.p`
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;