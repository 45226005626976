import { fetchReservations } from 'pages/SpaceUser/pages/ReserveDetails/useReserveDetails';
import * as S from './styles'
import { useQuery } from 'react-query';
import useAlert from 'components/Notification';
import { ReservesProps } from 'pages/SpaceUser/pages/Reservations';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAnyProvider } from 'hooks/Context';
import { useUserProvider } from 'hooks/userContext';
import { differenceInDays, format, parseISO } from 'date-fns';
import { TextArea } from 'components/TextArea';
import { Button } from 'components/Button';
import fetchAxios from 'services/http';
import { useState } from 'react';

export const CancelReserve: React.FC = () => {

    const [isOpen, setIsOpen] = useState(false)
    const [reasonCancel, setReasonCancel] = useState('')
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { token } = useUserProvider()

    const { pathname } = useLocation()

    const today = new Date();
    const todayFormatted = today.setHours(0, 0, 0, 0);

    const navigate = useNavigate()

    const idReserve = pathname.split('/')[3]

    const { showAlert } = useAlert({ active: false, type: 'SUCCESS', title: '' });

    const { data: reservations, isLoading, isFetching, isError } = useQuery<ReservesProps>('reservations', () => fetchReservations(token, idReserve), {
        onError: () => {
            showAlert({ active: true, title: 'Erro ao listar reserva', type: 'ERROR' });
        },
    });

    const differenceDays = reservations && differenceInDays(parseISO(reservations.initialDate), todayFormatted);

    const convertDateFormat = (dateStr: string) => {
        if (!dateStr) return;
        const dateObj = parseISO(dateStr);
        const convertedDate = format(dateObj, 'dd/MM/yyyy');
        return convertedDate;
    };

    const cancelReserve = async () => {
        if (!reservations) return;
        try {
            const { data } = await fetchAxios.post(`/reservers/changeStatus/${reservations.id}`, {
                status: 'CANCELLED',
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            showAlert({ active: true, title: 'Reserva cancelada com sucesso', type: 'SUCCESS' })
            setTimeout(() => {
                navigate('/minha-conta/reservas/')
            }, 3000);

        } catch (error) {
            showAlert({ active: true, title: 'Erro ao cancelar reserva', type: 'ERROR' })
        }

    }

    const handleConfirmCancel = () => {
        if (reasonCancel === null || reasonCancel.trim() === '') {
            setErrorMessage('Este campo é obrigatório')
        } else {
            cancelReserve()
            setIsOpen(false)
            setErrorMessage('')
        }
    }

    const question = require('assets/svg/spaceUser/Question.svg').default;

    const handleOpenTooltip = () => {
        setTooltipOpen((prevState) => !prevState)
    }

    return (
        <>
            {reservations !== undefined ? (
                <S.Container>
                    <S.Title>Cancelamento de reserva</S.Title>
                    <S.ModalCancel>
                        <S.ModalHeader>
                            <img src={reservations.image} alt="" />
                            <S.ModalHeaderRight>
                                <S.SubTitle>{reservations.title}</S.SubTitle>
                                <S.ModalRow>
                                    <S.Text>Entrada:</S.Text>
                                    <S.Text>{convertDateFormat(reservations.initialDate)}</S.Text>
                                </S.ModalRow>
                                <S.ModalRow>
                                    <S.Text>Saída</S.Text>
                                    <S.Text>{convertDateFormat(reservations.finalDate)}</S.Text>
                                </S.ModalRow>
                            </S.ModalHeaderRight>
                        </S.ModalHeader>
                    </S.ModalCancel>
                    <S.TextAreaContainer>
                        <TextArea placeholder="Esta informação é necessária para concluir o cancelamento" label="Nos informe o motivo do cancelamento" name="reasonCancel" value={reasonCancel} errorMessage={errorMessage} onChange={(e: any) => setReasonCancel(e.currentTarget.value)} />
                    </S.TextAreaContainer>
                    <S.CancelledSession>
                        <S.ModalCancelled >
                            <S.SubTitle>Políticas de cancelamento</S.SubTitle>
                            <img id="modal-cancelled" onClick={handleOpenTooltip} src={question} alt="" />
                            {tooltipOpen && <S.Tooltip id="tooltip">
                                <p>Cancelamentos feitos com pelo menos 7 dias de antecedência ao check-in terão reembolso integral, exceto para pagamentos via cartão de crédito, que terão uma taxa correspondente. Cancelamentos feitos dentro dos 7 dias anteriores ao check-in terão retenção de 20% do valor pago, além de possíveis taxas e despesas financeiras. Reservas com condições promocionais podem não ser reembolsáveis. O estorno pode acontecer em até duas faturas após o pedido de cancelamento.</p>
                            </S.Tooltip>}
                        </S.ModalCancelled>
                        <S.Terms>
                            <p> Saiba mais sobre as políticas de cancelamento acessando os nossos{' '}
                                <S.LinkTerms to={'/termos-e-condicoes/'}>termos de uso</S.LinkTerms></p>
                        </S.Terms>
                    </S.CancelledSession>
                    <S.TaxCancelled>
                        <S.SubTitle>Custo do cancelamento</S.SubTitle>
                        {reservations.paymentType === 'PIX' && differenceDays && differenceDays >= 7 ? null : <S.TaxRow>
                            <S.Text>Valor da reserva:</S.Text>
                            {reservations.promotional ?
                                <S.Text>Reserva promocional</S.Text>
                                :
                                <S.Text>R$ 225,00</S.Text>
                            }
                        </S.TaxRow>}
                        {reservations.paymentType === 'PIX' && differenceDays && differenceDays <= 7 ? null : reservations.promotional ? null :
                            <S.TaxRow>
                                <S.Text>Taxa do cartão</S.Text>
                                <S.Text>R$ 50,00</S.Text>
                            </S.TaxRow>}
                        {reservations.promotional ? null : reservations.paymentType === "CREDIT_CARD" && differenceDays && differenceDays <= 7 ?
                            <S.TaxRow>
                                <S.Text>Taxa de retenção:</S.Text>
                                <S.Text>R$ 75,00</S.Text>
                            </S.TaxRow>
                            : reservations.paymentType === 'PIX' && differenceDays && differenceDays <= 7 ? <S.TaxRow>
                                <S.Text>Taxa de retenção:</S.Text>
                                <S.Text>R$ 75,00</S.Text>
                            </S.TaxRow> : null}
                        {reservations.paymentType === 'PIX' && differenceDays && differenceDays >= 7 ? null :
                            <S.TaxRow>
                                <S.Text>Total de reembolso:</S.Text>
                                {reservations.promotional ? <S.Text invalid> Não aplicável </S.Text> : <S.Text>R$ 175,00</S.Text>}
                            </S.TaxRow>}
                        {reservations.paymentType === 'PIX' && differenceDays && differenceDays >= 7 &&
                            <S.TaxRow>
                                <S.Text free >Gratuito</S.Text>
                            </S.TaxRow>}
                    </S.TaxCancelled>
                    <S.ModalButtonsContainer>
                        <S.ConfirmCancelButton onClick={handleConfirmCancel} > <p>Confirmar cancelamento</p></S.ConfirmCancelButton>
                        <Button onClickButton={() => setIsOpen(false)} size="medium" title="Cancelar" type="secondary" widthSize={'100%'} />
                    </S.ModalButtonsContainer>
                </S.Container>
            ) : 'Carregando...'}
        </>

    )
}