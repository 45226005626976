import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { Input, Inputs } from "components/Input";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { usePersonalInfoSchema } from "pages/Checkin/schemas/PersonalInfo"; import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "context/sessionStorage";
import { useEffect, useState } from "react";
import { NewInput } from "components/NewInput";
import { IMaskInput } from "react-imask";
import { RadioButton } from "components/RadioButton";
import { FooterWithButtons } from "pages/Checkin/components/FooterWithButtons";
import { DropdownWithError, SelectOption } from "components/DropdownWithError";
import axios from "axios";
import fetchAxios from "services/http";
import useAlert from "components/Notification";

type Address = {
    address: string;
    cep: string;
    city: string;
    complement: string;
    district: string;
    number: string;
    state: { value: string, label: string }
}

export const PersonalInformation = () => {

    const [countryYouLiveOptions, setCountryYouLiveOptions] = useState<SelectOption[]>([])
    const { t, i18n } = useTranslation();
    const { showAlert, Alert } = useAlert({ title: '', type: 'SUCCESS', active: false });
    const [readOnlyEmail, setReadOnlyEmail] = useState(false)
    const [customerId, setCustomerId] = useState<string | null>(null)

    useEffect(() => {
        const getCountries = async () => {
            try {
                const { data } = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/paises?orderBy=nome')
                const mappedOptions = data.map((item: any) => {
                    return {
                        label: item.nome,
                        value: item.nome
                    }
                }
                )
                setCountryYouLiveOptions(mappedOptions);
            } catch (error) {
                console.error(error);
            }
        }
        const getCountriesEnglish = async () => {
            try {
                const { data } = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/paises?lang=EN')

                const mappedOptions = data.map((item: any) => {
                    return {
                        label: item.nome
                        ,
                        value: item.nome

                    }
                }
                )
                const sortedOptions = mappedOptions.sort((a: SelectOption, b: SelectOption) => {
                    if (a.label !== undefined && b.label !== undefined)
                        return a.label.localeCompare(b.label)
                })
                setCountryYouLiveOptions(sortedOptions);
            } catch (error) {
                console.error(error);
            }
        }
        if (i18n.language === 'pt') {
            getCountries()
        } else {
            getCountriesEnglish()
        }



    }, [i18n.language])


    const pathnameActual: string = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));

    const navigate = useNavigate();

    const { saveToSessionStorage, getFromSessionStorage } = useSessionStorage()

    const { schema } = usePersonalInfoSchema();

    type FormData = z.infer<typeof schema>;

    const { handleSubmit, formState: { errors }, setValue, control, trigger, watch } = useForm<FormData>({
        resolver: zodResolver(schema),
        defaultValues: {
            documentType: 'CPF',
            countryYouLive: { label: 'Brasil', value: 'Brasil' }
        }
    });

    const cpfNumber = watch('documentNumber')

    const formatDate = (date: string) => {
        // Divide a data em partes usando "-" como separador
        const parts = date.split('-');
        // Reorganiza as partes. Como `split` retorna [ano, mês, dia], reorganizamos para [dia, mês, ano]
        const formattedDate = `${parts[2]}${parts[1]}${parts[0]}`;
        return formattedDate;
    }

    useEffect(() => {
        const data = getFromSessionStorage('personal-info')
        if (cpfNumber && cpfNumber.length === 11) {
            const getInfoByCpf = async () => {
                try {
                    const { data } = await fetchAxios.get(`checkin/${cpfNumber}`)
                    setValue('name', data.owner.name)
                    setValue('surname', data.owner.surname)
                    setValue('birthDate', !!data.owner.date_of_birth ? formatDate(data.owner.date_of_birth) : '')
                    setValue('phone', data.owner.mobile_phone)
                    setValue('email', data.owner.email)
                    setCustomerId(data.owner.id)
                    setReadOnlyEmail(true)
                    if (data.owner.country) {
                        setValue('countryYouLive', { label: data.owner.country, value: data.owner.country })
                    }
                } catch (error) {
                    console.error(error);
                }
            }
            if (watch('documentType') === 'CPF' && data && data.documentNumber !== cpfNumber || !data) {
                getInfoByCpf()
            }

        } else {
            setReadOnlyEmail(false)
        }
    }, [cpfNumber])


    useEffect(() => {
        const data = getFromSessionStorage('personal-info')
        if (data) {
            setValue('documentType', data.documentType)
            setValue('documentNumber', data.documentNumber)
            setValue('name', data.name)
            setValue('surname', data.surname)
            setValue('birthDate', data.birthDate)
            setValue('phone', data.phone)
            setValue('email', data.email)
            setValue('countryYouLive', data.countryYouLive)
        }
    }, [])



    const onSubmit = (data: FormData) => {
        const validateEmailAndDocument = async () => {
            try {
                const res = await fetchAxios.get(`checkin/${data.documentNumber}/${data.email}`)
                if (res.data.message ===
                    "Cpf e email são do mesmo usuario") {
                    saveToSessionStorage('personal-info', { ...data, customer_id: customerId, method: 'update' })
                    navigate(`${pathnameActual}/address`, { replace: true })
                } else if (res.data.message ===
                    "Email pertence a outro cpf") {
                    showAlert({ title: t("checkin.personalInformation.forms.errors.emailExistInAnotherDocument"), type: 'ERROR', active: true });
                } else {
                    saveToSessionStorage('personal-info', { ...data, method: 'create' })
                    navigate(`${pathnameActual}/address`, { replace: true })
                }
                // navigate(`${pathnameActual}/address`)
            } catch (error) {
                console.error(error);
            }
        }
        if (!!errors) {
            validateEmailAndDocument()
        }
    }

    const handleBack = () => {
        navigate(pathnameActual)
    }

    return (
        <S.PersonalInformationContainer>
            <h1>{t('checkin.personalInformation.title')}</h1>
            <h4>{t('checkin.personalInformation.subtitle')}</h4>
            <S.FormContainer >
                <S.ContainerRadio className="document" id="document">
                    <strong>{t('checkin.personalInformation.forms.label.typeDocument')}</strong>
                    <S.ContainerRadioButtons >
                        <Controller
                            name="documentType"
                            control={control}
                            render={({ field }) => (
                                // <Input width={"100%"} id="documentType" onBlur={() => trigger('documentType')} htmlFor="documentType" errorText={errors.documentType?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.typeDocument')} value={value} />
                                <RadioButton {...field} control={control} label={t('checkin.personalInformation.forms.label.cpf')} value="CPF" />
                            )
                            }
                        />
                        <Controller
                            name="documentType"
                            control={control}
                            render={({ field }) => (
                                // <Input width={"100%"} id="documentType" onBlur={() => trigger('documentType')} htmlFor="documentType" errorText={errors.documentType?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.typeDocument')} value={value} />
                                <RadioButton {...field} control={control} label={t('checkin.personalInformation.forms.label.passport')} value='PASSPORT' />
                            )
                            }
                        />
                    </S.ContainerRadioButtons>
                </S.ContainerRadio>
                {watch('documentType') === 'PASSPORT' ? (
                    <Controller
                        name="documentNumber"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Input maxLength={20} width={"100%"} id="documentNumber" onBlur={() => trigger('documentNumber')} htmlFor="documentNumber" errorText={errors.documentNumber?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.passport')} value={value} />
                        )
                        }
                    />
                ) : (
                    <Controller
                        name="documentNumber"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <NewInput mask={''} width={'100%'} name="documentNumber" htmlFor="documentNumber" placeholder="" title={t('checkin.personalInformation.forms.label.cpf')} type="text" errorText={errors.documentNumber?.message}>
                                <IMaskInput
                                    width={'100%'}
                                    mask={[{ mask: "000.000.000-00" }]}
                                    radix="."
                                    unmask={true} // true|false|'typed'
                                    value={value}
                                    onAccept={(value, mask) => {
                                        onChange(value)
                                    }}
                                    placeholder=""
                                    onBlur={() => trigger('documentNumber')}
                                />
                            </NewInput>
                        )
                        }
                    />
                )}
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input width={"100%"} id="name" onBlur={() => trigger('name')} htmlFor="name" errorText={errors.name?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.name')} value={value} />
                    )
                    }
                />
                <Controller
                    name="surname"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input width={"100%"} id="surname" onBlur={() => trigger('surname')} htmlFor="surname" errorText={errors.surname?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.surname')} value={value} />
                    )
                    }
                />
                <Controller
                    name="birthDate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <NewInput width={'100%'} mask={''} id="birthDate" name="birthDate" htmlFor="birthDate" placeholder={t('checkin.personalInformation.forms.placeholder.birthDate')} title={t('checkin.personalInformation.forms.label.birthDate')} type="text" errorText={errors.birthDate?.message}>
                            <IMaskInput
                                mask={'**/**/****'}
                                radix="."
                                unmask={true} // true|false|'typed'
                                value={value}
                                onAccept={(value, mask) => {
                                    onChange(value)
                                }}
                                placeholder={t('checkin.personalInformation.forms.placeholder.birthDate')}
                                onBlur={() => trigger('birthDate')}
                            />
                        </NewInput>
                    )}
                />
                <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                        <Inputs.Phone
                            width={'100%'}
                            onChangePhoneNumber={field.onChange}
                            htmlFor={field.name}
                            placeholder=""
                            title={t('checkin.personalInformation.forms.label.phone')}
                            type=""
                            {...field}
                            value={field.value}
                            errorText={errors.phone?.message}
                            onBlur={() => {
                                trigger('phone')
                            }}
                        />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input readOnly={readOnlyEmail} width={"100%"} id="email" onBlur={() => trigger('email')} htmlFor="email" errorText={errors.email?.message} onChange={onChange} placeholder={''} title={t('checkin.personalInformation.forms.label.email')} value={value} />
                    )
                    }
                />
                <Controller
                    name={'countryYouLive'}
                    control={control}
                    render={({ field }) => (
                        <DropdownWithError label={t('checkin.personalInformation.forms.label.countryYouLive')} name="countryYouLive" value={field.value} onChange={(option) => option ? field.onChange(option) : field.onChange(undefined)}
                            placeholder={t('checkin.personalInformation.forms.placeholder.countryYouLive')} options={countryYouLiveOptions} widthDrop={'calc(100% - 32px)'} errorText={errors.countryYouLive?.message} />
                    )}
                />
                <S.CheckboxContainer>
                    <Controller
                        name={'acceptTerms'}
                        control={control}
                        render={({ field }) => (
                            <Inputs.Checkbox
                                htmlFor="accept"
                                type="checkbox"
                                value={field.value}
                                onChange={field.onChange}
                                placeholder=""
                                title="Aceito receber novidades da Yogha por email (opcional)."
                            />
                        )}
                    />
                </S.CheckboxContainer>
            </S.FormContainer>

            <FooterWithButtons handleBackStep={handleBack} handleNextStep={handleSubmit(onSubmit)} />
            <Alert />
        </S.PersonalInformationContainer>
    )
}