import React, { useState, useRef } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { ButtonCarousel } from 'components/ButtonCarousel';
import { MobileModalGallery } from '../MobileModalGallery';
import { MobileVisualizeModal } from '../MobileVisualizeModal';

interface ImageGalleryProps {
    imagesRef: React.RefObject<HTMLDivElement>
    name: string
    city: string
    isLoading: boolean
    accommodationId: string | undefined
    previwImages: string[]
}

const MobileGallery: React.FC<ImageGalleryProps> = ({ accommodationId, name, city, imagesRef, isLoading, previwImages }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideRef = useRef<HTMLDivElement>(null);
    const [openGallery, setOpenGallery] = useState(false);
    const [openVisualizer, setOpenVisualizer] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    const nextSlide = () => {
        if (currentIndex < previwImages.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    return (<>
        <S.CarouselContainer ref={slideRef}  >
            {previwImages.map((image, index) => (
                <S.Slide
                    key={index}
                    src={image}
                    alt={`Slide ${index}`}
                    active={index === currentIndex}
                    onClick={() => setOpenGallery(true)}
                />
            ))}
            <S.ButtonContainer>
                <ButtonCarousel disabled={currentIndex === 0 ? true : false} onClick={prevSlide} direction="left" />
                <ButtonCarousel disabled={currentIndex === previwImages.length - 1 ? true : false} onClick={nextSlide} direction="right" />
            </S.ButtonContainer>
            <S.TotalCount>
                {currentIndex + 1}/{previwImages.length}
            </S.TotalCount>
            <S.AccommodationName>
                {name}
            </S.AccommodationName>
        </S.CarouselContainer>
        {openGallery && (
            <MobileModalGallery accommodationId={accommodationId} setCurrentImageIndex={setCurrentImageIndex} setGalleryIsOpen={setOpenGallery} setOpenVisualizer={setOpenVisualizer} />
        )}
        {openVisualizer && <MobileVisualizeModal accommodationId={accommodationId} currentImageIndex={currentImageIndex} setCurrentImageIndex={setCurrentImageIndex} setOpenVisualizer={setOpenVisualizer} />}
    </>
    );
};

export default MobileGallery;
