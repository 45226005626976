type IconProps = {
    size?: number;
    color?: string;
}

export const CalendarIcon = ({ color = "#818181", size = 24 }: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.5 2.25V5.25" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.5 2.25V5.25" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.75 8.25H20.25" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}