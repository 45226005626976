import styled, { css } from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../../constants/styleConstants";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../../constants/fontConstants";
import { PostCard } from "../types";

export const PostContainer = styled.div<Pick<PostCard, 'titleButton'>>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  word-wrap: break-word !important;
  white-space: pre-wrap ;
  word-break: break-word !important;
    &:hover{
      p{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
${({ titleButton }) => titleButton && css`
    justify-content: center;
    align-items: center;

    border:1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    row-gap:32px;

    max-height:274px;

    `
     }

  `

export const TextPost = styled.p`
  font-weight: ${FONT_WEIGTH.HUGE};
  font-size: ${FONT_SIZE.SMALL};
  color: ${NEUTRAL_COLORS.DARK};
`;

export const LinkText = styled.div`
width: 220px;
${FONT.BODY.BODY_MEDIUM[600]}
color: ${NEUTRAL_COLORS.HIGH_DARK};
text-align: center;
word-wrap: break-word;
white-space: pre-wrap;
  `;
