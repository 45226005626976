import { ReactNode, createContext, useContext, useState } from "react";

type HistoryContextType = {
    historyStack: string[];
    pushToHistory: (path: string) => void;
    popFromHistory: () => string | undefined;
};

const HistoryContext = createContext<HistoryContextType | undefined>(undefined);

export const useHistoryStack = () => {
    const context = useContext(HistoryContext);
    if (!context) {
        throw new Error("useHistoryStack must be used within a HistoryProvider");
    }
    return context;
};

interface HistoryProviderProps {
    children: ReactNode;
}

export const HistoryProvider: React.FC<HistoryProviderProps> = ({ children }) => {
    const [historyStack, setHistoryStack] = useState<string[]>([]);

    const pushToHistory = (path: string) => {
        setHistoryStack([...historyStack, path]);
    };

    const popFromHistory = () => {
        const newHistory = [...historyStack];
        const lastItem = newHistory.pop();
        setHistoryStack(newHistory);
        return lastItem;
    };

    return (
        <HistoryContext.Provider value={{ historyStack, pushToHistory, popFromHistory }}>
            {children}
        </HistoryContext.Provider>
    );
};
