import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const ContainerArrivalAndDepartureTime = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-items: center;

    width: 100%;

    h3{
        ${FONT.HEADLINES.H5}
        margin-bottom: 16px;
    }
    h5{
        ${FONT.BODY.BODY_SMALL[400]}
    }
    padding-bottom: 48px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        h3{
            text-align: center;
        }
        padding-bottom: 0px;
    }
`

export const FormArrivalAndDeparture = styled.form`
    display: grid;
    grid-template-columns: 302px 302px;

    place-content: center;

    gap: 95px;

    margin-top: 40px;
    margin-bottom: 84px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 32px;
        margin-top: 32px;
        margin-bottom: 40px;
    }
`;

export const HoursContainerInput = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 16px;
`;

export const HoursContainerLabel = styled.div`
    display: flex;

    width: 100%;

    justify-content: space-between;

    strong{
        ${FONT.BODY.BODY_MEDIUM[600]}
    }

    p{
        ${FONT.BODY.BODY_MEDIUM[400]}
    }
`;

export const Warningmessage = styled.div`
    display: flex;

    align-items: center;

    max-width: calc(699px - 32px);

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    border-radius: 10px;

    p{
        ${FONT.BODY.BODY_SMALL[400]}
    }
    margin-bottom: 36px;
`;