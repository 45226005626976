import { Controller } from 'react-hook-form'
import * as S from './styles'
import { DropdownWithError } from 'components/DropdownWithError';
import { t } from 'i18next';
import { RadioButton } from 'components/RadioButton';
import { Input } from 'components/Input';
import { Link } from 'react-router-dom';
import { FooterWithButtons } from 'pages/Checkin/components/FooterWithButtons';

import { Checkbox } from 'components/Input/Checkbox';
import { Loading } from 'pages/Payment/components/Loading';
import { useAdditionalInfo } from './useAdditionalInfo';

const warningIcon = require('assets/svg/error/WarningError.svg').default;

export const AdditionalInfo = () => {

    const { Alert, arrayOptionHowFindYogha, arrayOptionReasonForTravel, control, errors, handleBackPage, handleSubmit, hasAnswerHowToFindYogha, hasAnswerReasonTrip, loading, onSubmit, trigger } = useAdditionalInfo();

    return (
        <S.ContainerAdditionalInfo>
            <h5>
                {t('checkin.additionalInformation.title')}
            </h5>
            <S.FormContainer>
                <Controller
                    name={'reasonToTrip'}
                    control={control}
                    render={({ field }) => (
                        <DropdownWithError label={t('checkin.additionalInformation.forms.label.reasonOfTheTrip')} name="reasonToTrip" value={field.value} onChange={(option) => option ? field.onChange(option) : field.onChange(undefined)}
                            placeholder={t('checkin.personalInformation.forms.placeholder.countryYouLive')} options={arrayOptionReasonForTravel} widthDrop={'calc(100% - 16px)'} errorText={errors.reasonToTrip?.message} />
                    )}
                />
                {hasAnswerReasonTrip && <Controller
                    name={'reasonToTripAnswer'}
                    control={control}
                    render={({ field }) => (
                        <Input htmlFor='reasonToTripAnswer' width={'100%'} onChange={field.onChange} title='' placeholder={t("checkin.additionalInformation.forms.placeholder.reasonOfTheTripAswer")} value={field.value} errorText={errors.reasonToTripAnswer?.message} errorMessages={!!errors.reasonToTripAnswer?.message} onBlur={() => trigger('reasonToTripAnswer')} />
                    )}
                />}
                <Controller
                    name="originCity"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Input width={"100%"} id="originCity" onBlur={() => trigger('originCity')} htmlFor="originCity" errorText={errors.originCity?.message} onChange={onChange} placeholder={''} title={t('checkin.additionalInformation.forms.label.cityOfOrigin')} value={value} />
                    )
                    }
                />
                <Controller
                    name={'howToFindYogha'}
                    control={control}
                    render={({ field }) => (
                        <DropdownWithError label={t('checkin.additionalInformation.forms.label.howDidYouFindYogha')} name="howToFindYogha" value={field.value} onChange={(option) => option ? field.onChange(option) : field.onChange(undefined)}
                            placeholder={t('checkin.personalInformation.forms.placeholder.countryYouLive')} options={arrayOptionHowFindYogha} widthDrop={'calc(100% - 16px)'} errorText={errors.howToFindYogha?.message} />
                    )}
                />
                {hasAnswerHowToFindYogha && <Controller
                    name={'howToFindYoghaAnswer'}
                    control={control}
                    render={({ field }) => (
                        <Input htmlFor='howToFindYoghaAnswer' width={'100%'} onChange={field.onChange} title='' placeholder={t("checkin.additionalInformation.forms.placeholder.howDidYouFindYoghaAswer")} value={field.value} errorText={errors.howToFindYoghaAnswer?.message} onBlur={() => trigger('howToFindYoghaAnswer')} />
                    )}
                />}

                <S.ContainerOptionsRadioButton>
                    <strong>{t('checkin.additionalInformation.forms.label.didYouVisitTheCityBefore')}</strong>
                    <S.ContainerRadioButtons >
                        <Controller
                            name="knownTheCity"
                            control={control}
                            render={({ field }) => (
                                <RadioButton {...field} control={control} label={t('checkin.additionalInformation.forms.label.optionYes')} value="Sim" />
                            )
                            }
                        />
                        <Controller
                            name="knownTheCity"
                            control={control}
                            render={({ field }) => (
                                <RadioButton {...field} control={control} label={t('checkin.additionalInformation.forms.label.optionNo')} value="Não" />
                            )
                            }
                        />
                    </S.ContainerRadioButtons>
                </S.ContainerOptionsRadioButton>
            </S.FormContainer>
            <S.ContainerTermAndConditions>
                <S.ContentTermAndConditions>
                    <Controller
                        name="accept"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <Checkbox htmlFor="accept" onChange={onChange} placeholder='' title='' value={value} />)}
                    />
                    <p>{t('checkin.additionalInformation.termsAndConditions')} <Link target='_blank' to='/termos-e-condicoes/' >{t('checkin.additionalInformation.linkTermsAndConditions')}</Link></p>
                </S.ContentTermAndConditions>
                {errors.accept?.message && (
                    <S.ErrorMessage>
                        <img src={warningIcon} alt="" />
                        <p>{errors.accept?.message}</p>
                    </S.ErrorMessage>
                )}
            </S.ContainerTermAndConditions>
            <FooterWithButtons handleBackStep={handleBackPage} handleNextStep={handleSubmit(onSubmit)} nextButtonTitle={t('checkin.additionalInformation.finishButton')} />
            {loading && <Loading text={t('checkin.additionalInformation.textLoading')} />}
            <Alert />
        </S.ContainerAdditionalInfo>
    )
}