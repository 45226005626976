import * as S from './styles';
import { ProductCard } from "components/ProductCard";
import { useSessionStorage } from 'context/sessionStorage';
import { FooterWithButtons } from "pages/Checkin/components/FooterWithButtons";
import { InputFileAlternativeSelfie } from 'pages/Checkin/components/InputFileAlternativeSelfie';
import SelfieCapture from 'pages/Checkin/components/SelfieCapture';
import { uploadFile } from 'pages/Checkin/utils/uploadFile';
import { Loading } from 'pages/Payment/components/Loading';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useScreenWidth } from 'utils/useScreenWidth';

const check = require('assets/svg/checkin/Check.svg').default;
const camera = require('assets/svg/checkin/Camera.svg').default;
const invalid = require('assets/svg/checkin/Invalid.svg').default;
const warning = require('assets/svg/checkin/Warning.svg').default;
const trash = require('assets/svg/spaceUser/TrashRed.svg').default;

export const SelfieValidation = () => {
    const [selfiePreviewImage, setSelfiePreview] = useState<string | null>(null);
    const [selfieFile, setSelfieFile] = useState<File | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();
    const [backPage, setBackPage] = useState<string>('');
    const [checkinId, setCheckinId] = useState<string>('');
    const isMobile = useScreenWidth();
    const [isLoading, setIsLoading] = useState(false);

    const { getFromSessionStorage } = useSessionStorage();

    useEffect(() => {
        const dataPersonal = getFromSessionStorage('personal-info');
        const dataCheckinDetails = getFromSessionStorage('checkin-details');
        if (dataCheckinDetails) {
            setCheckinId(dataCheckinDetails.checkinId);
        }
        if (dataPersonal) {
            if (dataPersonal.documentType === 'passport') {
                setBackPage('verify-passport');
            } else {
                setBackPage('verify-document');
            }
        }

    }, []);


    const navigate = useNavigate();

    const pathnameActual: string = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));

    const handleDelete = () => {
        setSelfiePreview(null);
    }


    const handleContinue = async () => {
        if (selfieFile) {
            setIsLoading(true);
            try {
                await uploadFile(selfieFile, checkinId, 'selfie');
                navigate(`${pathnameActual}/accompanying`, { replace: true });
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }

        }
    };

    const handleBack = () => {
        navigate(`${pathnameActual}/${backPage}`)
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handleFileSelfie = (file: File | null) => {
        if (file) {
            setSelfieFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelfiePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCapture = (imageSrc: string) => {
        setSelfiePreview(imageSrc);
        // Este método não precisa mais enviar a imagem para o servidor, isso será feito no handleFileSelfie
    };


    return (
        <S.SelfieValidationContainer>
            <h1>{t('checkin.selfieValidation.title')}</h1>
            <S.WarningMessage>
                <img src={warning} alt="" />
                <p>
                    {t('checkin.selfieValidation.warningMessage')}
                </p>
            </S.WarningMessage>
            <S.ContentSelfieValidation>
                <S.ContainerInputFile>
                    <strong>
                        {t('checkin.selfieValidation.takeASelfie')}
                    </strong>
                    {selfiePreviewImage ? (
                        <S.SelfiContainer>
                            <img src={selfiePreviewImage} alt="Selfie" />
                            <S.DeleteButton onClick={handleDelete}>
                                <img src={trash} alt="Delete" />
                            </S.DeleteButton>
                        </S.SelfiContainer>
                    ) : (
                        <S.InputFileSelfie onClick={handleOpenModal}>
                            <img src={camera} alt="" />
                            <p>{t('checkin.selfieValidation.inputSelfieText')}</p>
                        </S.InputFileSelfie>
                    )}
                    <InputFileAlternativeSelfie onFileChange={handleFileSelfie} setPreview={setSelfiePreview} name='selfie' />
                </S.ContainerInputFile>
                <S.ColumnCheckSelfie>
                    <S.RowCheckSelfie>
                        <img src={check} alt="" />
                        <p>{t('checkin.selfieValidation.rules.takeASelfieNatural')}</p>
                    </S.RowCheckSelfie>
                    <S.RowCheckSelfie>
                        <img src={check} alt="" />
                        <p>{t('checkin.selfieValidation.rules.visivelFace')}</p>
                    </S.RowCheckSelfie>
                    <S.RowCheckSelfie>
                        <img src={check} alt="" />
                        <p>{t('checkin.selfieValidation.rules.takeASelfieNaturalLight')}</p>
                    </S.RowCheckSelfie>
                    <S.RowCheckSelfie>
                        <img src={invalid} alt="" />
                        <p>{t('checkin.selfieValidation.rules.noUseDocumentsPhotos')}</p>
                    </S.RowCheckSelfie>
                    <S.RowCheckSelfie>
                        <img src={invalid} alt="" />
                        <p>{t('checkin.selfieValidation.rules.takeASelfieWithout ThingsInYourFace')}</p>
                    </S.RowCheckSelfie>
                </S.ColumnCheckSelfie>
            </S.ContentSelfieValidation>
            <ProductCard fill={isMobile} center={isMobile} isOpen={openModal} setIsOpen={setOpenModal} title="Tire uma selfie" bottomLine width={isMobile ? 'calc(100% - 80px)' : ''}   >
                <SelfieCapture isModalOpen={openModal} handleFileSelfie={handleFileSelfie} onCapture={handleCapture} setIsOpenModal={setOpenModal} />
            </ProductCard>
            <FooterWithButtons handleBackStep={handleBack} handleNextStep={handleContinue} disableContinueButton={!selfiePreviewImage} />
            {isLoading && <Loading text={t('checkin.selfieValidation.waitingSendFile')} />}
        </S.SelfieValidationContainer>
    )
}