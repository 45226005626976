import { LinkCard } from "components/Card/types"
import { useAnyProvider } from "hooks/Context"
import { useSpaProvider } from "hooks/spaRouterContext"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import fetchAxios from "services/http"
import { useScreenWidth } from "utils/useScreenWidth"

type UseHome = {
  accommodations: any;
  buildings: any;
  blog: any;
  isFetching: boolean

}

export const useHome = ({ accommodations, blog, buildings, isFetching }: UseHome) => {

  const [bannersDesktop, setBannersDesktop] = useState<any>([])
  const [bannersMobile, setBannersMobile] = useState<any>([])

  const { setExpanded, setPageChosen } = useSpaProvider();

  const { setSearchType, setSearchValue } = useAnyProvider()

  const breakpoint = useScreenWidth()

  useEffect(() => {
    const getBannersDesktop = async () => {
      try {
        const { data } = await fetchAxios('/banner?type=Desktop&quantity=3')
        const mappedData = data.data.map((item: any) => {
          return {
            icon: 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/2362x956/filters:quality(65)/' + item.path,
            onClick: item.link ? () => window.location.assign(item.link) : () => { }
          }
        })
        setBannersDesktop(mappedData)
      } catch (error) {
        console.log(error)
      }
    }
    const getBannersMobile = async () => {
      try {
        const { data } = await fetchAxios('/banner?type=Mobile&quantity=3')
        const mappedData = data.data.map((item: any) => {
          return {
            icon: 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/2362x956/filters:quality(65)/' + item.path,
            onClick: item.link ? () => window.location.assign(item.link) : () => { }
          }
        })
        setBannersMobile(mappedData)
      } catch (error) {
        console.log(error)
      }
    }
    breakpoint ? getBannersMobile() : getBannersDesktop()
  }, [])


  const openHeader = () => {
    if (breakpoint) {
      navigate('/search-location')
      setPageChosen(2)
    } else {
      setTimeout(() => {
        setExpanded(false)
      }, 10);
      setTimeout(() => {
        setPageChosen(2)
      }, 50);
    }

  }
  const openHeaderPromo = () => {
    if (breakpoint) {
      navigate('/properties?search=Curitiba')
      setSearchValue('Curitiba')
      setSearchType('city')
    } else {
      setTimeout(() => {
        setExpanded(false)
      }, 10);
      setTimeout(() => {
        setPageChosen(1)
      }, 50);
    }

  }

  const navigate = useNavigate()

  const buildingLink: LinkCard = {
    image: require('assets/svg/Units.svg').default,
    buttonText: 'Ver ofertas',
    linkText: "Conheça todas as unidades",
    size: 'unit',
    id: 'unit',
    onClick: openHeader
  }

  const blogLink: LinkCard = {
    image: require('assets/svg/NewBlogNotices.svg').default,
    id: 'blog',
    size: 'post',
    linkText: 'Quer acompanhar essas e outras matérias?',
    buttonText: 'Visitar blog',
    onClick: () => window.location.href = `https://blog.yogha.com.br/`
  }

  const postsWithLink = [...blog, blogLink]
  const notices = [
    {
      title: 'Moradia Flexível',
      description: 'Uma forma de morar descomplicada. Encontre o apê que é a sua cara e fique o tempo que quiser. ',
      icon: require('assets/svg/MapPin.svg').default,
      onClick: () => navigate('/moradia-flexivel/')
    },
    {
      title: 'Para proprietários',
      description: 'Obtenha a máxima rentabilidade com o seu imóvel sem sair de casa com o Yogha Gestão.',
      icon: require('assets/svg/UsersThree.svg').default,
      onClick: () => window.location.assign('https://negocios.yogha.com.br/')
    },
    {
      title: 'Para parceiros',
      description: 'Transforme o seu empreendimento em um prédio inteligente com o ecossistema Yogha',
      icon: require('assets/svg/Briefcase.svg').default,
      onClick: () => window.location.assign('https://negocios.yogha.com.br/parceiros/')
    },
  ]

  const accomodationsLink: LinkCard = {
    image: require('assets/svg/Percent.svg').default,
    buttonText: 'Ver ofertas',
    linkText: "Conheça todas as nossas ofertas",
    size: 'unit',
    id: 'all',
    onClick: () => openHeaderPromo()
  }

  const accomodationsWithLink = [...accommodations, accomodationsLink]
  const buildingsWithLink = [...buildings, buildingLink]

  return {
    breakpoint,
    navigate,
    accomodationsWithLink,
    buildingsWithLink,
    image: bannersDesktop,
    imageMobile: bannersMobile,
    notices,
    postsWithLink
  }

}

