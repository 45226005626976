import Skeleton from "react-loading-skeleton"

export const SkeletonPayment = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 32
        }}>
            <Skeleton width={218} height={22} borderRadius={10} />
            <Skeleton width={569} height={32} borderRadius={10} />
            <Skeleton width={569} height={32} borderRadius={10} />
            <Skeleton width={184} height={32} borderRadius={10} style={{
                marginTop: 22
            }} />
        </div>
    )
}