import styled, { keyframes } from "styled-components";
import { NEUTRAL_COLORS, STATUS_COLORS } from "../../constants/styleConstants";
import { FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { AlertProps } from ".";


const widthReduce = keyframes`
    from{
        width: 100%;
    }
    to{
        width: 0%;
    }
`;

export const Container  = styled.div<Pick<AlertProps,'type'> >`
    display: flex;
    flex-direction: row;

    position: fixed;

    bottom: 28px;
    left: 56px;

    width: calc(455px - 32px);

    padding: 0px 16px;

    justify-content: start;

    row-gap: 24px;

    z-index: 12000;

    border-width: 1px 1px 0px 1px;

    border-style: solid;

    border-color: #F2FFFA;

    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    ::after{
        content: "";
        display: flex;
        position: absolute;

        width: calc(100% + 2px);
        margin-left: -17px;
        height: 3px;

        bottom: 0;

        border-radius: 0px 0px 5px 5px;

        background-color: ${({type}) => type  && STATUS_COLORS[type]};

        animation: ${widthReduce} 3s linear ;
    }

    @media(width < 768px){
        width: calc(100% - 60px);
        bottom: 20px;
        left: 50%;

        transform: translateX(-50%);

        ::after{
            margin-left: -16px;
            width: calc(100% + 60px);
        }
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;

    max-width:387px;
    width: 100%;
    height: 48px;

    align-items: center;
    justify-content: flex-start;

    padding-top:10px;
    padding-bottom:14px;

    p{
        width: 281px;

        font-weight: ${FONT_WEIGTH.MEDIUM};
        font-size: ${FONT_SIZE.TINY};
        line-height: 140%;
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    gap:12px;


    img{
        width: 24px;
        height: 24px;
    }
`;

export const ClosedButton = styled.button`
    display: flex;
    flex-direction: row;

    position: absolute;

    align-items: center;

    background-color: transparent;
    border: none;

    padding: 8px;

    cursor: pointer;

    img{
        width: 16px;
        height: 16px;
    }


    top: 0px;
    right: 0px;
`;