import styled from "styled-components"
import { Link } from 'react-router-dom';
import { FONT, FONT_SIZE, FONT_WEIGTH } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";

export const Container = styled.div`
width: calc(100% - 40px);

padding-right: 20px;
padding-left: 20px;

@media (width > 768px) {
    display: none;
}

`;

export const Header = styled.div`
display: flex;

position: relative;

align-items: center;
justify-content: center;

height: 72px;

h1{
    ${FONT.BODY.BODY_LARGE[600]}
}

`;

export const Back = styled.button`
position: absolute;

display: flex;

align-items: center;
justify-content: center;

top:50%;
left: 0px;
transform: translateY(-50%);

padding: 8px;

background-color: transparent;
border: none;

img{
    width: 18px;
    height: 15px;

    object-fit: contain;
}

`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
`;

export const SubTitle = styled.p`
  ${FONT.BODY.BODY_SMALL[400]}
`;

export const Username = styled.div`
    display: flex;
    flex-direction: column;

    h1{
        ${FONT.BODY.BODY_LARGE[600]}
    }
`;

export const PhotoGrid = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 99px;
`;

export const Photo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 99px;
  object-fit: cover;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;

  gap:8px;
`;

export const ContainerModal = styled.div`
  display: flex;
flex-direction: column;

  width: 100%;

  align-items: center;

  `;

export const Modal = styled.div<{user:string}>`
display: flex;
flex-direction: column;

max-width: 302px;
width: 100%;

align-content: space-between;

padding: 1rem;
row-gap: 15px;
h1{
    ${FONT.BODY.BODY_SMALL[500]}
    color: ${NEUTRAL_COLORS.HIGH_DARK};
}

`
export const TynyText = styled.h5`
        ${FONT.CAPTION.CAPTION_SEMI_REGULAR}
        color: ${NEUTRAL_COLORS.GRAY};
`

export const CustomLink = styled(Link)`
text-decoration: none;
`;

export const ModalContent = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
row-gap: 5px;
overflow: auto;
width: 100%;

`;

export const User = styled.div`
display: flex;

width: 100%;

gap: 8px;

align-items: center;
justify-content: flex-start;

img{
    height: 64px;
    width: 64px;
    background-color: '#F2F2F2';
    border-radius: 50px;
    object-fit: cover;
}
margin-bottom: 24px;

 `;


export const ModalNavLink = styled.div`
display: flex ;

width: 100%;

div{
    display: flex;

align-items: center;

    gap: 8px;
}

align-items: center;
justify-content: space-between;

padding: 14px 0;

gap: 8px;

:hover{
  cursor: pointer;
}

p{
    ${FONT.BODY.BODY_SMALL[500]}
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    white-space: nowrap;
}
`
export const LinkWitArrow = styled.div`
display:inline-flex;
gap:10px;`

export const SectionModal = styled.div`
display:flex;
width: 100%;
flex-direction:column;

position: relative;

row-gap: 8px;

:has(.space){
  padding-top: 16px;
  ::before{
    content: '';
    width: calc(100% + 20px);
    height: 1px;

    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

    position: absolute;
    top: 0;

  }
}

`

export const SectionModalLinks = styled.div`
  display:flex;
flex-direction:column;

width: 100%;

position: relative;

:has(.space){
  padding-top: 16px;
  ::before{
    content: '';
    width: 100%;
    height: 1px;

    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

    position: absolute;
    top: 0;

  }
  }

  ${ModalNavLink}{
    width: 100%;
    justify-content: space-between;
    padding: 0;
    ${CustomLink}{
      padding: 16px 0;
      ${FONT.BODY.BODY_SMALL[400]}
      color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
  }
`;

export const LinksCollumn = styled.div`
display: flex;
flex-direction: column;

`;

export const Row = styled.div`
display: flex;
flex-direction: column;

justify-content:flex-start ;

gap: 8px;

:has(.null){
    margin-bottom: 16px;
}
`;