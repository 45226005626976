import { useSearchParams } from 'react-router-dom';

// Definição do Hook Customizado
export function useUpdateQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryParams = (params: Record<string, string | number>) => {
    // Crie uma nova instância de URLSearchParams baseada nos parâmetros atuais
    const newSearchParams = new URLSearchParams(searchParams.toString());

    // Atualize cada parâmetro fornecido
    for (const [key, value] of Object.entries(params)) {
      newSearchParams.set(key, value.toString());
    }

    // Aplique todas as mudanças de uma vez aos parâmetros de busca na URL
    setSearchParams(newSearchParams, { replace: true });
  };

  return updateQueryParams;
}
