import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled, { css } from "styled-components";

export const gridStyles = (size:number) => {
    switch (size) {
      case 9:
        return css`
          .grid0 {
      grid-column: span 8;
      grid-row: 1 / 2;
    }

    .grid1,.grid2{
      grid-row: 2 / 3;
    }

    .grid1 {
      grid-column: span 4;
      width: 100%;
    }

    .grid2 {
      grid-column: span 4;
      width: 100%;
    }

    .grid3,.grid6{
      margin-right: 32px;
    }

    .grid3 {
      grid-column: 1 / span 5;
      grid-row: 3 / 5;
    }

    .grid4{
      grid-column:  span 3;
      grid-row: 3 / 4;
    }

    .grid5 {
      grid-column:  span 3;
      grid-row: 4 / 5;
    }

    .grid6 {
      grid-column: 1 / span 3;
      grid-row: 5 / 6;
    }

    .grid7 {
      grid-column: span 3;
      grid-row: 6 / 7;
    }

    .grid8 {
      grid-column: span 5;
      grid-row: 5 / 7;
    }
        `;
      case 8:
        return css`
      .grid0,.grid1{
      grid-column:  span 3;
    }

    .grid0 {
      grid-row: 1 / 2;
    }

    .grid1 {
      grid-row: 2 / 3;
    }

    .grid2{
      grid-column: span 5;
      grid-row: 1 / 3;
    }

    .grid3 {
      grid-column:  span 5;
      grid-row: 3 / 5;
    }

    .grid4{
      grid-column:  span 3;
      grid-row: 3 / 4;
    }

    .grid5 {
      grid-column:  span 3;
      grid-row: 4 / 5;
    }

    .grid6,.grid7{
      grid-row: 5 / 6;
    }

    .grid6 {
        grid-column:  span 4;
    }

    .grid7 {
        grid-column:  span 4;
    }


        `;
      case 7:
        return css`
          .grid0,.grid1{
      grid-column: span 3;
    }

    .grid0 {
      grid-row: 1 / 2;
    }

    .grid1 {
      grid-row: 2 / 3;
    }

    .grid2{
      grid-column: span 5;
      grid-row: 1 / 3;
    }

    .grid3 {
      grid-column:  span 5;
      grid-row: 3 / 5;
    }

    .grid4{
      grid-column: span 3;
      grid-row: 3 / 4;
    }

    .grid5 {
      grid-column:  span 3;
      grid-row: 4 / 5;
    }

    .grid6{
      grid-row: 5 / 6;
      grid-column: span 8;
    }
        `;
      case 6:
        return css`
          .grid0,.grid1{
      grid-column:  span 5;
    }

    .grid0 {
      grid-row: 1 / 2;
    }

    .grid1 {
      grid-row: 2 / 3;
    }

    .grid2{
      grid-column:  span 4;
      grid-row: 1 / 3;
    }

    .grid3,.grid4{
      grid-row: 3 / 4;
    }

    .grid3 {
      grid-column: span 4;
    }

    .grid4 {
      grid-column: span 5;
    }

    .grid5{
      grid-row: 4 / 5;
      grid-column: span 9;
    }
        `;
      case 5:
        return css`
            .grid0,.grid1{
      grid-column: span 3;
    }

    .grid0 {
      grid-row: 1 / 2;
    }

    .grid1 {
      grid-row: 2 / 3;
    }

    .grid2{
      grid-column: span 5;
      grid-row: 1 / 3;
    }

    .grid3,.grid4{
      grid-row: 3 / 4;
    }

    .grid3 {
      grid-column: span 4;

    }

    .grid4 {
        grid-column: span 4;
    }
        `;
      case 4:
        return css`
            .grid0,.grid1{
      grid-column:  span 4;
    }

    .grid0 {
      grid-row: 1 / 2;
    }

    .grid1 {
      grid-row: 2 / 3;
    }

    .grid2{
      grid-column: span 4;
      grid-row: 1 / 3;
    }

    .grid3 {
      grid-row: 3 / 4;
      grid-column: span 8;
    }
        `;
      case 3:
        return css`
            .grid0,.grid1{
      grid-column: span 4;
    }

    .grid0 {
      grid-row: 1 / 2;
    }

    .grid1 {
      grid-row: 2 / 3;
    }

    .grid2{
      grid-column:  span 4;
      grid-row: 1 / 3;
    }
        `;
      case 2:
        return css`
            .grid0,.grid1{
    }

    .grid0 {
      grid-column: span 8;
    }

    .grid1 {
      grid-column: span 8;
    }
        `;
      case 1:
        return css`
            .grid0 {
      grid-column: span 8;
      grid-row: 1 / 2;
    }
        `;
      default:
        return css``;
    }
  };

export const ModalGallery = styled.div`
    display: flex;
    flex-direction: column;

    position: fixed;

    inset: 0;

    width: 100vw;
    height: 100vh;

    &::-webkit-scrollbar-track {
    margin-top: 66px;
  }

    background-color: ${NEUTRAL_COLORS.WHITE};

    z-index: 3000;

    overflow: auto;

    align-items: center;
    justify-content: center;
    `;

export const HeaderGallery = styled.div`
display: flex;
flex-direction: row;

position: fixed;

top: 0;
width: 100vw;
height: 66px;

align-items: center;
justify-content: center;


padding-left: 82px;
padding-right: 20px;

p{
    ${FONT.HEADLINES.H5}
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
}

background-color: ${NEUTRAL_COLORS.WHITE};
`;

export const ContentHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    align-items: center;
    gap: 28px;
`;

export const ButtonClose = styled.button`
    display: flex;

    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    border: 0;

    background-color: transparent;

    cursor: pointer;
`;

export const ContentGallery = styled.div`
    display: flex;
    flex-direction: column;

    height: 100%;

    align-items: center;
    justify-content: flex-start;
`;

export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;

    width:100%;

    /* justify-self: center;
    align-self: center; */

    /* align-items: center; */
    /* justify-content: center; */

    padding-bottom: 16px;

    /* row-gap: 16px; */
    `;

export const ImageGallery = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    `;


export const GridGallery = styled.div<{size:number}>`
  ${({size}) => size !==  0 ?  css`
  display: grid;

  gap: 8px 8px;

  width: calc(100% - 8px);
  height: fit-content;

  padding-top: 8px;

  padding-left: 8px;

  ` : css`
  display: none;
  `}

  ${({size}) => gridStyles(size)}

  /* @media (max-width: 768px) {
  grid-template-columns: unset

  } */
`;