import { useState, useCallback } from 'react';
import Draggable from 'react-draggable';
import * as S from './styles';
import { CommentsUsers, CommentsUsersProps } from '../CommentsUsers';
import { CommentsUsersFlexHousing } from 'components/CommentsUsersFlexHousing';

interface SliderCommentsProps {
  comments: CommentsUsersProps[];
}

export const SliderCommentsFlexHousing = ({ comments }: SliderCommentsProps) => {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragX, setDragX] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);



  const arrowIcon = require('assets/svg/flexhousing/CaretLeft.svg').default;

  const updateSlide = useCallback(
    (newSlide: any) => {
      setCurrentSlide(newSlide);
      setDragX(-newSlide * 638);
      setIsTransitioning(true);
      setTimeout(() => setIsTransitioning(false), 300);
    },
    [setCurrentSlide, setDragX, setIsTransitioning]
  );

  const onDragStop = (e: any, data: any) => {
    const delta = data.x - dragX;
    const newSlide = currentSlide - Math.round(delta / 638);

    const maxX = -((Math.min(comments.length, 5) - 1) * 638);
    const newX = Math.min(0, Math.max(data.x, maxX));
    setDragX(newX);

    if (newSlide < 0) {
      updateSlide(0);
    } else if (newSlide > Math.min(comments.length, 5) - 1) {
      updateSlide(Math.min(comments.length, 5) - 1);
    } else {
      updateSlide(newSlide);
    }
  };

  const goBackward = () => {
    const newSlide = Math.max(currentSlide - 1, 0);
    updateSlide(newSlide);
  };

  const goForward = () => {
    const newSlide = Math.min(currentSlide + 1, Math.min(comments.length, 5) - 1);
    updateSlide(newSlide);
  };

  const maxDragWidth = comments.length * 638;

  return (
    <>
      <S.Container>
        <S.PortraitCarousel>
          <Draggable
            axis="x"
            bounds={{ left: -maxDragWidth, right: 0 }}
            position={{ x: dragX, y: 0 }}
            onStop={onDragStop}
          >
            <S.CarouselContent
              style={{
                transform: `translateX(${dragX}px)`,
                transition: isTransitioning ? 'transform 300ms' : 'none',
              }}
            >
              {comments.map((comments: any, i: any) => (
                <CommentsUsersFlexHousing key={comments.user + i} {...comments} />
              ))}
            </S.CarouselContent>
          </Draggable>
        </S.PortraitCarousel>
        <S.BulletsGroup>
          {comments.map((_: any, i: number) => {
            return <S.Bullet key={i} active={currentSlide === i ? true : false} />;
          })}
        </S.BulletsGroup>

        <S.ButtonLeft onClick={goBackward}> <img src={arrowIcon} alt="" /> </S.ButtonLeft>
        <S.ButtonRight onClick={goForward}> <img src={arrowIcon} alt="" /></S.ButtonRight>
      </S.Container>
    </>
  )
}