import styled from "styled-components";

export const FooterButtonContainer = styled.div`
    display: flex;

    gap: 8px;

    align-items: center;
`;

export const ConfirmButton = styled.div`
    display: flex;
    width: 310px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;