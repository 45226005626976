import { useEffect, useState } from 'react';

const useIsMobileIOS = (): boolean => {
  const [isMobileIOS, setIsMobileIOS] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      setIsMobileIOS(true);
    }
  }, []);

  return isMobileIOS;
};

export default useIsMobileIOS;
