import { FONT_SIZE } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "constants/styleConstants";
import styled from "styled-components";
import { InputContainer } from "../styles";
import { IInput } from "../types";

export const PhoneContainer = styled(InputContainer)<Pick<IInput, 'errorMessages' | 'disabled' | 'width' | 'heightInput'>>`
.container{
    border: 1px solid ${props => props.errorMessages === true ? STATUS_COLORS.ERROR : props.disabled ? STATUS_COLORS.DISABLED :  NEUTRAL_COLORS.GRAY};
    line-height: 140%;
    border-radius: 10px;
    font-size: ${FONT_SIZE.TINY};
    height: ${props => props.heightInput !== undefined ? props.heightInput : 52  }px;
    color: ${props => props.disabled  ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK };
    width: ${props => props.width !== undefined ? props.width : '100%'};


    ::placeholder {
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }

    :focus-within{
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        outline: none;
        border: 1px solid ${PRIMARY_COLORS.MEDIUM};
        ::placeholder {
            color: ${NEUTRAL_COLORS.HIGH_DARK};
        }
        .buttonInput{
            border-color: ${PRIMARY_COLORS.MEDIUM};
        }
    }

    :disabled{
        color: ${STATUS_COLORS.DISABLED};
        border: 1px solid ${STATUS_COLORS.DISABLED};
    }
}
.container .input{
    padding-left:68px;
    border: none;

}

.buttonInput{
    height: calc(100%);
    background-color: transparent;
    padding: 0 8px;
    border: none;
    border-radius: 0px;
    border-right: 1.5px solid ${NEUTRAL_COLORS.GRAY};
    :focus-within{
    border-color: ${PRIMARY_COLORS.MEDIUM};
  }
}
.dropdown{
    height: 250px !important; ;
}
.searchInput{

    display: flex;

    padding-right: 8px !important;
    padding-left: 0px !important;


    align-items: center;

    justify-content: flex-start;

    padding-bottom: 16px !important;

    padding-top: 16px !important;
    input{

        max-height: 48px;

        padding: 16px 0px !important;



        width: calc(100%) !important;

        padding-left: 53px !important;

        border-radius: 99px !important;
        border: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY} !important;

        background-color: ${NEUTRAL_COLORS.LIGHT_GRAY} !important;


    }
}
label{
        margin-bottom: 8px;
    }
`;