import { Container, Icon } from './styles';
import { ButtonProps } from './types';

export const Button: React.FC<ButtonProps> = ({ ...button }) => {

  return (
    <Container
     iconDirection={button.iconDirection}
     widthSize={button.widthSize}
     heightSize={button.heightSize}
     disabled={button.disabled}
     size={button.size} type={button.type}
     onClick={button.disabled ? () => { } : button.onClickButton}>
      {button.icon  &&
        <Icon src={button.icon}
        width={button.iconWidth}
        height={button.iconHeight}/>
      }
      {button.title}
    </Container>
  );
};
