import styled from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;

  position: relative;
  max-width: 1366px;
  width: 100%;
    `;



export const PortraitCarousel = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-start;
  width: 638px;
  height: 272px;
  background-color: #FAF8F8;
  border-radius: 16px;
  overflow: hidden;
  align-self: center;
`;

export const CarouselContent = styled.div`
    display:flex;
    width: 100%;
    transition: all 1s ease-in-out;
    margin: 0;
    padding: 0;
    border-radius: 10px;
`;

export const BulletsGroup = styled.div`
    display: flex;

    gap:8px;

    margin-top: 24px;
    margin-bottom: 8px;
`;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;

export const CaroselImage = styled.div`
    width: 1206px;
    /* @media (min-width: 1660px) {
        margin: 0px 13%;
    }
    @media (min-width: 1870px) {
        margin: 0px 17%;
    }
    @media (min-width: 2000px) {
        margin: 0px 24%;
    }
    @media (min-width: 2400px) {
        margin: 0px 29%;
    }
    &:first-child {
        margin-left: 0px;
    } */
    `;

export const ButtonLeft = styled.button`
  cursor: pointer;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: none;
  inset: 0;
  top: 120px;

  border: none;
  left: -80px;

  `;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotate(180deg);
  left: calc(576px + 162px);
  `;


export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    gap: 32px;
    `;

