import axios from "axios";
import fetchAxios from "services/http";

export async function uploadFile(file: File, checkinId: string,type: 'back' | 'front' | 'selfie' ) {
    const formData = new FormData();

    const typeFile = '.' + file.type.split('/')[1];

    formData.append("image", file, file.name + typeFile); // "file" é o nome do campo na API

    try {
        const response = await fetchAxios.post(`/checkin/image/${checkinId}/${type}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Erro no upload:', error.response?.data);
        } else {
            console.error('Erro desconhecido:', error);
        }
    }
}