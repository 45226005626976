import styled, { css } from 'styled-components';
import { FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';
import { NEUTRAL_COLORS } from '../../constants/styleConstants';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1220px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  .caroulse{
    display: flex;
  gap: 32px;
  justify-content: flex-start;
  align-items: center;
  width: 1200px;
  height: fit-content;
  white-space: nowrap;
  margin-left: 42px;

  }
`;

export const ContentCaroussel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right:0px;
  flex-wrap: nowrap;
  padding-bottom: 16px;
`;

export const Carrousel = styled.div<{transition:boolean}>`
  display: flex;
  gap: 32px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  margin-left: 42px;

  ${props => props.transition && css`
    transition: transform 0.3s ease-out;
  `}

`;

export const Title = styled.h3`
  display: flex;
  align-self: flex-start;
  height: 60px;
  margin-bottom: -42px;
  color: ${NEUTRAL_COLORS.DARK};
  font-weight: ${FONT_WEIGTH.HUGE};
  font-size: ${FONT_SIZE.MEDIUM};
`;

interface ButtonCarouselProps {
  disabled: boolean;
}

export const ButtonsCarousel = styled.button<ButtonCarouselProps>`
  cursor: pointer;
  visibility: ${props => props.disabled ? "hidden" : "visible"};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${NEUTRAL_COLORS.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 29px;
  border: none;
  img{
    width: 20px;
    height: 20px;
  }
`;


