import styled from "styled-components";
import { FONT } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";
import { Link } from "react-router-dom";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100vh;

    align-items: center;
    justify-content: start;

    overflow: hidden;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    align-items: center;
    justify-content: start;


    overflow: auto;

    padding-bottom: 4px;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    width: 330px;

    align-items: center;
    justify-content: center;

    gap:6px;

    margin-top: 40px;
    margin-bottom: 40px;

@media (max-width: 768px) {
    width: 100%;
}

    `;

export const SocialContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    width: 100%;

    align-items: center;
    justify-content: center;

`;

export const RegisterButton = styled.button`
    display: flex;
    flex-direction: row;

    width: 335px;
    height: 54px;

    border-radius: 8px;

    align-items: center;
    justify-content: center;

    border:1px solid ${NEUTRAL_COLORS.GRAY};

    background-color: ${NEUTRAL_COLORS.WHITE};

    gap: 8px;

    p{
        ${FONT.BODY.BODY_SMALL[400]}
    }
`;

export const Separator = styled.div`
  display: flex;

  max-width: 335px;
  width: 100%;

  align-items: center;
  justify-content: center;

  gap: 8px;

  ::after{
    content: '';
    flex: 1;
    height: 1px;

    background: ${NEUTRAL_COLORS.GRAY};

  }

  ::before{
    content: '';
    flex: 1;
    height: 1px;

    background: ${NEUTRAL_COLORS.GRAY};

  }

  margin-top: 24px;
  margin-bottom: 24px;
`;

export const RegisterForms = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: -12px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 412px;


    gap:32px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 0px 20px;
        max-width: unset;
        padding-bottom: 140px;
    }
`;

export const NameContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Title = styled.h1`
    ${FONT.HEADLINES.H5}
`;

export const SubTitle = styled.p`
    ${FONT.BODY.BODY_SMALL[400]}
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;

    width: 335px;
    height: 54px;

    align-items: center;
    justify-content: center;

    margin-top: 48px;

    @media (max-width: 768px) {
        width: 100%;
    }

`;

export const FooterText = styled.p`
    ${FONT.BODY.BODY_MEDIUM[400]};

    padding-bottom: calc(80px - 32px);
`;

export const FooterLink = styled(Link)`
    color: ${PRIMARY_COLORS.MEDIUM};
    &:hover {
        color: ${PRIMARY_COLORS.DARK};
    }
    ${FONT.BODY.BODY_MEDIUM[400]}
`;

export const EmailContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap:4px;
`;

export const LittleText = styled.p`
    padding-right: 64px;
    ${FONT.CAPTION.CAPTION_SEMI_REGULAR};
    color: ${NEUTRAL_COLORS.DARK_GRAY};
`;

export const PasswordContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;`

export const PhoneContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`
export const NameDiv = styled.div`

    width: 45%;
    margin-right: 10%;

    @media (max-width: 768px) {
    width: 100%;

    }
`;
export const Surname
= styled.div`

    width: 45%;

    @media (max-width: 768px) {
    width: 100%;
    margin-top: 32px;    }
`;