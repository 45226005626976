import { ReactNode, useState } from "react";
import * as S from "./styles";
import { t } from 'i18next'
interface IDropdowncheckin {
    name: string;
    email: string;
    phone: string;
    documentNumber: string;
    birthDate: string;
    documentType: 'CPF' | 'PASSPORT';
    children: ReactNode;
    surname: string;
}
const user = require("assets/svg/UserCheckin.svg").default
const carret = require("assets/svg/checkin/CarretDropdown.svg").default

const DropdownCheckin = ({ birthDate, documentNumber, email, name, phone, documentType, children, surname }: IDropdowncheckin) => {

    const [open, setOpen] = useState(false);

    const formatDate = (date: string) => {
        // Assume que a data está no formato DDMMYYYY
        if (date.length !== 8) {
            console.error("Formato de data inválido");
            return date; // Retorna a entrada não modificada ou você pode lançar um erro
        }

        // Extrai dia, mês e ano da string usando slice
        const day = date.slice(0, 2);
        const month = date.slice(2, 4);
        const year = date.slice(4);

        // Reorganiza no formato YYYY-MM-DD
        const dateFormatted = `${year}-${month}-${day}`;

        // Cria um objeto Date e formata para o formato local
        const dateObject = new Date(dateFormatted);
        return dateObject.toLocaleDateString('pt-BR');
    }

    const formatPhone = (phone: string) => {
        if (!phone) return phone;
        // Verifica se o número começa com 55 e tem o comprimento esperado para incluir o código do país
        if (phone.startsWith("55") && phone.length === 13) {
            // Formata com o código do país +55, seguido pelo número formatado
            return `+${phone.slice(0, 2)} ${phone.slice(2, 4)} ${phone.slice(4, 9)}-${phone.slice(9)}`;
        } else if (phone.length === 11) {
            // Formata números que não começam com 55, mas têm 11 dígitos (código de área + número local sem o código do país)
            return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;
        } else if (phone.length === 10) {
            // Caso adicionado para números com 10 dígitos (sem o 9 inicial nos celulares)
            return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`;
        }
        // Retorna o número sem formatação se não atender a nenhum dos critérios acima
        return phone;
    }

    function formatDocument(documentNumber: string): string {
        if (documentType === 'CPF' && documentNumber.length === 11) {
            const documentNumberFormatado = `${documentNumber.slice(0, 3)}.${documentNumber.slice(3, 6)}.${documentNumber.slice(6, 9)}-${documentNumber.slice(9, 11)}`;

            return documentNumberFormatado;
        } else {

            return documentNumber
        }
    }

    return (
        <S.DropdownContainer isOpen={open} onClick={() => setOpen((open) => !open)}>
            <S.DropdownHeadline>
                <S.LeftColumnDropdown isOpen={open}>
                    <img src={user} alt="" width={24} height={24} />
                    <strong>
                        {name} {surname}
                    </strong>
                </S.LeftColumnDropdown>
                <S.CarretIcon src={carret} alt="" isOpen={open} />
            </S.DropdownHeadline>
            {open && (
                <S.DivInfos>
                    <p>
                        {email}
                    </p>
                    {phone && <p>
                        {formatPhone(phone)}
                    </p>}
                    <p>
                        {documentType === 'CPF' ? t('checkin.addAccompanying.cpf') : t('checkin.addAccompanying.passport')} {formatDocument(documentNumber)}
                    </p>
                    <p>
                        {t('checkin.addAccompanying.birthDate')} {formatDate(birthDate)}
                    </p>

                    {children}

                </S.DivInfos>
            )
            }
        </S.DropdownContainer>
    );
};

export default DropdownCheckin;