import React, { useState, useRef, useCallback, useEffect } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { CommentsUsers } from 'components/CommentsUsers';

type Comments = {
    comment: string;
    user: string;
    reference: string;
}

interface CarouselCommentsProps {
    comments: Comments[]

}

const MobileCarouselComments: React.FC<CarouselCommentsProps> = ({ comments }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideRef = useRef<HTMLDivElement>(null);
    const autoSlideTimer = useRef<NodeJS.Timeout | null>(null);
    const arrowIcon = require('assets/svg/arrowComments.svg').default;


    const nextSlide = () => {
        if (currentIndex < comments.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        } else {
            setCurrentIndex(0);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    const resetAutoSlideTimer = useCallback(() => {
        if (autoSlideTimer.current) {
            clearTimeout(autoSlideTimer.current);
        }
        autoSlideTimer.current = setTimeout(nextSlide, 5000);
    }, [nextSlide]);

    useEffect(() => {
        resetAutoSlideTimer();
        return () => {
            if (autoSlideTimer.current) {
                clearTimeout(autoSlideTimer.current);
            }
        };
    }, [resetAutoSlideTimer]);

    return (
        <S.Container>
            <S.CarouselContainer ref={slideRef}>
                {comments.map((item, index) => (
                    <S.Slide
                        key={index}
                        active={index === currentIndex}>
                        <CommentsUsers comment={item.comment} reference={item.reference} user={item.user} />
                    </S.Slide>
                ))}
            </S.CarouselContainer>
            <S.ButtonContainer>
                <S.LeftButton onClick={prevSlide} >
                    <img src={arrowIcon} alt="" />
                </S.LeftButton>
                <S.RightButton onClick={nextSlide} >
                    <img src={arrowIcon} alt="" />
                </S.RightButton>
            </S.ButtonContainer>
        </S.Container>
    );
};

export default MobileCarouselComments;
