import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Container, SingleContainer } from './styles';

interface NavBarProps {
  selected: number;
  setSelected: (index: number) => void;
}

export const NavBar: React.FC<NavBarProps> = ({ selected, setSelected }) => {
  // const items = ['Em destaque', 'Promoções', 'Cidades', 'Unidades'];
  const items = ['Em destaque', 'Promoções', 'Unidades'];

  const [highlightStyle, setHighlightStyle] = useState<{ width?: number; x?: number }>({});

  useEffect(() => {
    const selectedItem = document.querySelector(`[data-selected='${selected}']`);
    if (selectedItem) {
      const rect = selectedItem.getBoundingClientRect();
      const container = document.querySelector('.container');
      const containerRect = container?.getBoundingClientRect();

      // contêiner pai position left
      const containerLeft = containerRect?.left;

      // posição x em relação a posição contêiner pai
      const relativeX = rect.left - Number(containerLeft);

      setHighlightStyle({
        width: selectedItem.clientWidth,
        x: relativeX,
      });
    }
  }, [selected]);

  return (
    <Container className='container'>
      {items.map((item, index) => (
        <SingleContainer
          key={item}
          onClick={() => setSelected(index)}
          selected={selected === index}
          data-selected={index}
        >
          {item}
        </SingleContainer>
      ))}
      <motion.div
        className="border-bottom"
        initial={false}
        animate={highlightStyle}
        transition={{ duration: 0.2, ease: 'easeOut' }}
      />
    </Container>
  );
};

