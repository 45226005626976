import { useState, useRef,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SelectOption } from '.';
import { useSpaProvider } from 'hooks/spaRouterContext';
import { useAnyProvider } from 'hooks/Context';
import { useDebounce } from 'use-debounce';
import { fetchAxiosPayment } from 'services/http';

interface UseDropdownProps {
    getProperties?: any;
    value?: any;
    onChange?: any;
    inputRef?: any;
    search?: any;
    setSearch?: any;
    isOpen?:boolean,
    shouldFocus?: any
    setShouldFocus?: any
}

export const useDropdown = ({getProperties,inputRef,isOpen,search, shouldFocus,setShouldFocus}:UseDropdownProps) => {

  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<any>([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [debouncedValue, setDebouncedValue] = useDebounce(search, 500);

  const { setExpanded } = useSpaProvider()

  useEffect(() => {
    if (isOpen) {
      const getAutoComplete = async () => {
        try {
          if (debouncedValue) {
            setIsLoading(true);
            const { data } = await fetchAxiosPayment.get(`/search?query=${debouncedValue}`,)
            const filterData = (options: any, search: any) => {
              const seen = new Set();
              const searchLower = search.toLowerCase();

              // Primeiro, filtre os dados
              const filteredData = options.filter((item: any) => {
                const isMatch = item.termo.toLowerCase().includes(searchLower);
                const uniqueKey = `${item.termo}_${item.tipo}`;

                if (isMatch && !seen.has(uniqueKey)) {
                  seen.add(uniqueKey);
                  return true;
                }
                return false;
              });

              // Agora, ordene os dados
              const sortedData = filteredData.sort((a: any, b: any) => {
                const termA = a.termo.toLowerCase();
                const termB = b.termo.toLowerCase();

                // Verifica se os termos começam com a string de pesquisa
                const startsWithA = termA.startsWith(searchLower);
                const startsWithB = termB.startsWith(searchLower);

                // Ordena primeiro aqueles que começam com a string de pesquisa
                if (startsWithA && !startsWithB) {
                  return -1;
                } else if (!startsWithA && startsWithB) {
                  return 1;
                } else {
                  // Se ambos começam, ou não começam, com a string de pesquisa, compare os termos normalmente
                  return termA.localeCompare(termB);
                }
              });

              // Mapeie para o formato desejado
              return sortedData.map((item: any) => ({
                name: item.termo,
                type: item.tipo,
              }));
            };


            setResults(filterData(data, debouncedValue));
            setNoResultsFound(filterData(data, debouncedValue).length === 0);
            setIsLoading(false);
          } else {
            setResults([]);
            setNoResultsFound(false);
          }
        } catch (err) {
          setIsLoading(false);
          setNoResultsFound(false);
        }
      }
      getAutoComplete()
    }
    if (shouldFocus && inputRef.current && setShouldFocus) {
      setExpanded(false)
      inputRef.current.focus();
      setShouldFocus(false);
    }
  }, [isOpen, debouncedValue, shouldFocus]);

      return { results, isLoading, noResultsFound, debouncedValue }
}