import useIsMobileIOS from 'hooks/useIsMobileIOS';
import * as S from './styles'
import ReactDOM from 'react-dom'

type LoadingProps = {
    text?: string
}

export const Loading = ({ text = 'Aguarde enquanto processamos seu pagamento' }: LoadingProps) => {
    const loadingRoot = document.getElementById('loading-root');
    const loadingImage = require('assets/image/payment/Loading.png');

    const isMobileIOS = useIsMobileIOS();

    return loadingRoot
        ? ReactDOM.createPortal(
            (
                <S.Container>
                    <S.LoadingContainer isIos={isMobileIOS} >
                        <S.LoadingImage src={loadingImage} />
                        <S.LoadingText>{text}</S.LoadingText>
                    </S.LoadingContainer>
                </S.Container>
            ),
            loadingRoot
        )
        : null;
};