import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const Triangle = require('assets/svg/spaceUser/TriangleTooltip.svg').default;

export const CancelledSession = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;

    row-gap: 16px;
`;

export const ScaledDate = styled.div`
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
    }
`;

export const TooltipModal = styled.div`
    display: flex;
    flex-direction: row;

    position: relative;

    gap:8px;
`;



export const Tooltip = styled.div<{inModal: boolean}>`
    display: flex;
    flex-direction: row;

    position: absolute;

    padding: 8px;

    width: calc(240px - 16px);

    top: 30px;

    border-radius: 8px;

    background-color: ${NEUTRAL_COLORS.DARK};

    ::after{
        content:url(${Triangle});
        position: absolute;

        top: -12px;
    }

    p{
     ${FONT.CAPTION.CAPTION_SEMI_REGULAR};
     color: ${NEUTRAL_COLORS.WHITE}
    }

    ${props => props.inModal && css`
        width: 260px;
        top: 0px;
        left: 24px;

        ::after{
        content: none;
        position: absolute;

        top: 0px;
    }

    `}

`;

export const LinkTerms = styled(Link)`
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${PRIMARY_COLORS.MEDIUM};
    text-decoration: none;
    &:hover{
        color: ${PRIMARY_COLORS.DARK};
    }
`;

export const Terms = styled.div`
    display: flex;
    flex-direction: row;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }
`;
