import { FONT } from "constants/fontConstants";
import styled from "styled-components";

export const ContainerInputs = styled.div`
    display: grid;
    grid-template-areas:
        "actual none"
        "new confirm"
        "button n"
        ;

    grid-template-columns: repeat(2, fit-content(100%));
    grid-template-rows: repeat(2, fit-content(100%));

    place-content: start start;
    place-items: start start;

    width: 100%;

    gap: 24px;

    & :has(#actualPassword) {
        grid-area: actual;
    }

    & :has(#newPassword) {
        grid-area: new;
    }

    & :has(#confirmPassword) {
        grid-area: confirm;
    }

    @media (width < 768px) {
        grid-template-areas:
        "actual"
        "new"
        "confirm"
        "button"        ;

    grid-template-columns: repeat(1, fit-content(100%));
    grid-template-rows: repeat(1, fit-content(100%));
    place-content: center;
    place-items: center;
    }
`;

export const TitlePage = styled.h1`
display: none;
@media (width < 768px) {
    display: block;
    ${FONT.BODY.BODY_LARGE[600]}
    }

`;