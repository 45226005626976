import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    width: 100%;
    height: 100%;

`;

export const ContainerTitle = styled.h1`
    ${FONT.BODY.BODY_LARGE[600]};
`;

export const CollectionCards = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 277px);

    gap: 32px;

    width: 100%;
`;

export const ContainerCard = styled.div`
    display: flex;
    flex-direction: column;

    width: fit-content;
`;

export const ContainerNoFavorites = styled.div`
    display: flex;
    align-items: flex-start;

    flex-direction: column;

    width: 100%;
    height: 100%;
`;

export const ContentNoFavorites = styled.div`
    display: flex;
    flex-direction: column;

    width: 587px;

`;

export const ContainerFavorites = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;

    width: 100%;

    row-gap: 32px;

    margin-top: 40px;
`;

export const Title = styled.h1`
    ${FONT.BODY.BODY_LARGE[600]}
`;

export const Collections = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    gap: 32px;
`;

export const EditButton = styled.button`
    display: flex;

    position: absolute;

    right: 0px;

    align-items: center;
    justify-content: center;

    background-color: transparent;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 94px;

    width: 40px;
    height: 40px;
`;

export const ContainerInput = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 24px;
`;

export const ContainerButton = styled(ContainerInput)`
margin:0px;`;

export const OptionsContainer = styled.div`
    position: absolute;

    display: flex;
    flex-direction: column;

    background: ${NEUTRAL_COLORS.WHITE};

    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    width: 200px;

    padding: 8px;

    right: 0px;
    top: 48px;
`;

export const ButtonExcludeContainer = styled.div`
    display: flex;
    flex-direction: row;

    justify-content: flex-end;
`;

export const Option = styled.div`
    display: flex;

    align-items: center;
    justify-content: flex-start;

    padding: 16px 0;

    gap:12px;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }

    &:hover{
    cursor: pointer;
    }
`

export const Text = styled.p`
    ${FONT.BODY.BODY_SMALL[400]};
`;