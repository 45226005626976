import styled from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../../../constants/styleConstants";
import { Icons } from "../../../../components/Icons";
import { FONT } from "constants/fontConstants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1366px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
    `;

export const PortraitCarousel = styled.div`
  position: relative;
  padding: 0px 90px;
  display: flex;
  flex-wrap: nowrap;
  align-self: flex-start;
`;

export const BorderPortrain = styled.div`
position: absolute;
width: 1200px;
height: 100%;
outline: 8px solid white;
border-radius: 16px;
inset: 0;
pointer-events: none;
align-self: center;
justify-self: center;
margin:0px 64px;
margin-left: calc(64px + 16px);

`;

export const BorderRight = styled.div`
position: absolute;
width: 146px;
height: 100%;
top: 0;
right: calc(100% - 144px);
background-color: white;
pointer-events: none;
align-self: center;
justify-self: center;
margin:0px 64px;
margin-left: calc(64px + 16px);
pointer-events: none;
`;

export const BorderLeft = styled.div`
position: absolute;
width: 150px;
height: 100%;
top: 0;
right: calc(100% - 1430px);
background-color: white;
pointer-events: none;
align-self: center;
justify-self: center;
pointer-events: none;
`;

export const CarouselContent = styled.div`
    display:flex;
    width: 100%;
    transition: all 1s ease-in-out;
    margin: 0;
    padding: 0;
`;


export const CaroselImage = styled.div`
    width: 1206px;
    /* @media (min-width: 1660px) {
        margin: 0px 13%;
    }
    @media (min-width: 1870px) {
        margin: 0px 17%;
    }
    @media (min-width: 2000px) {
        margin: 0px 24%;
    }
    @media (min-width: 2400px) {
        margin: 0px 29%;
    }
    &:first-child {
        margin-left: 0px;
    } */
    `;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 64px;
    margin-bottom: 12px;
    `;

export const ButtonLeft = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 69px;
  background: ${NEUTRAL_COLORS.WHITE};
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  `;

export const ButtonRight = styled(ButtonLeft)`
  transform: rotate(180deg);
  `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 1206px;
    gap: 32px;
    `;

export const SlideContent = styled.div`
    display: grid;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    gap: 64px;

    width: 100%;
    min-width: 1206px;

    place-items:  center start;


    &:hover{
    h4{
        text-decoration: underline;
    }
    cursor: pointer;
  }

`;

export const SlideImage = styled.img`
    width: 100%;
    max-width: 624px;

    max-height: 374px;
    height: 100%;

    object-fit: cover;

    border-radius: 16px;
`;

export const SlideTextContent = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 473px;

    gap: 16px;

    h2{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${PRIMARY_COLORS.MEDIUM};
    }
    h4{
        ${FONT.HEADLINES.H4};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const FooterSlide = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    gap: 10px;

`;