import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

type ButtonProps = {
    width?: string;
}

export const ButtonContainer = styled.button<ButtonProps>`
display: flex;

align-items: center;
justify-content: center;

border-radius: 10px;
border: none;

background: linear-gradient(34deg, #FF7A2A 0%, #FFC120 100%);

padding:16px;

width: ${({width}) => (width ? width : "100%")};

p{
    ${FONT.BODY.BODY_MEDIUM[600]};
    color: ${NEUTRAL_COLORS.WHITE} !important;
}

:hover{
    cursor: pointer;
    background: linear-gradient(34deg, #FFC120 0%, #FF7A2A 100%);
    transition: background 0.5s ease-in-out;
}


`;
