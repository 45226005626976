import { IInput } from "./types"
import { useState } from "react"
import * as S from "./styles"
import { Checkbox } from "./Checkbox";
import { Password } from "./Password";
import { Phone } from "./Phone";
import { Search } from "./Search";
import { Radio } from "./Radio";
import { InputError } from "./InputError";
import { InputRoot } from "./InputRoot";

export const errorIconInput = require('assets/svg/error/WarningError.svg').default;

export const Input = ({ htmlFor, value, onChange, disabled = false, type = 'text', placeholder, title, errorText, dataList, readOnly = false, heightInput = undefined, width, showPassword, errorLinkText, linkError, onClick, theme, onBlur, maxLength = 50 }: IInput) => {

    const errorMessages = errorText ? true : false;

    const [inputType, setInputType] = useState(type);

    const changeType = () => {
        setInputType(prevType => prevType === 'password' ? 'text' : 'password');
    };

    return (
        <>
            <S.InputContainer id={htmlFor} theme={theme}>
                {title !== null && <label htmlFor={htmlFor}>{title}</label>}
                <S.Inputs maxLength={maxLength} onBlur={onBlur} theme={theme} showPassword={showPassword} width={width} heightInput={heightInput} list={dataList} onClick={onClick} name={htmlFor} readOnly={readOnly} id={htmlFor} disabled={disabled} type={inputType} placeholder={placeholder} value={value} onChange={onChange} errorMessages={errorMessages} />
                {errorMessages === true && <S.ErrorContainer errorMessages={errorMessages}> <img src={errorIconInput} alt="" /><S.ErrorText errorLinkText={errorLinkText} >{errorText} {errorLinkText && <S.ErrorLink to={linkError ? linkError : ''} >{errorLinkText}</S.ErrorLink>}</S.ErrorText>  </S.ErrorContainer>}
                {showPassword && <S.ShowPassword onClick={changeType}><p>{inputType === 'text' ? 'Ocultar' : 'Mostrar'}</p></S.ShowPassword>}
            </S.InputContainer>
        </>
    )
}

export const Inputs = {
    Root: InputRoot,
    Checkbox,
    Password,
    Phone,
    Search,
    Radio,
    Error: InputError,
}