import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const useAdditionalInfoSchema = () => {
    const { t } = useTranslation();

    // Crie o esquema diretamente dentro do hook
    const schema = z.object({
      accept: z.boolean(),
      howToFindYogha: z.object({
        label: z.string({required_error:t('checkin.additionalInformation.forms.errors.howDidYouFindYogha')}).nonempty({
          message: t('checkin.additionalInformation.forms.errors.howDidYouFindYogha'),
        }),

        value: z.string({required_error:t('checkin.additionalInformation.forms.errors.howDidYouFindYogha')}).nonempty({
          message: t('checkin.additionalInformation.forms.errors.howDidYouFindYogha'),
        }),
      },{required_error: t('checkin.additionalInformation.forms.errors.howDidYouFindYogha')}),
      howToFindYoghaAnswer: z.string().optional(),
      reasonToTrip: z.object({
        label: z.string({required_error:t('checkin.additionalInformation.forms.errors.reasonOfTheTrip')}).nonempty({
          message: t('checkin.additionalInformation.forms.errors.reasonOfTheTrip'),
        }),

        value: z.string({required_error:t('checkin.additionalInformation.forms.errors.reasonOfTheTrip')}).nonempty({
          message: t('checkin.additionalInformation.forms.errors.reasonOfTheTrip'),
        }),
      },{required_error: t('checkin.additionalInformation.forms.errors.reasonOfTheTrip')}),
      reasonToTripAnswer: z.string().optional(),
      originCity: z.string({required_error:t('checkin.additionalInformation.forms.errors.cityOfOrigin')}).nonempty({
        message: t('checkin.additionalInformation.forms.errors.cityOfOrigin'),
      }),
      knownTheCity: z.enum(['Sim', 'Não'], {required_error:t('checkin.additionalInformation.forms.errors.didYouVisitTheCityBefore')}),
    }).superRefine((data, context) => {
      const errors: { path: (keyof typeof data)[], message: string }[] = [];

      if ((data.howToFindYogha.value === 'Outro' || data.howToFindYogha.value === 'Other') && !data.howToFindYoghaAnswer) {
        errors.push({ path: ['howToFindYoghaAnswer'], message: t('checkin.additionalInformation.forms.errors.howDidYouFindYoghaAswer') });
      }
      if ((data.reasonToTrip.value === 'Outro' || data.reasonToTrip.value === 'Other') && !data.reasonToTripAnswer) {
        errors.push({ path: ['reasonToTripAnswer'], message: t('checkin.additionalInformation.forms.errors.reasonOfTheTripAswer') });
      }
      if ((data.accept === false)) {
        errors.push({ path: ['accept'], message: t('checkin.additionalInformation.forms.errors.acceptTerms') });
      }

      errors.forEach(({ path, message }) => {
        context.addIssue({
          path,
          code: z.ZodIssueCode.custom,
          message,
        });
      });
    }
    );


    return schema;
  };
