import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const InputFileContainer = styled.label`
    display: flex;

    position: relative;

    width: calc(419px - 38px);

    min-height: 286px;

    padding: 0px 19px;

    flex-direction: column;

    justify-content: center;
    align-items: center;

    border-radius: 10px;
    border: 2px dashed ${PRIMARY_COLORS.MEDIUM};

    background: var(--Cor-Pirmary-100, #FFF7F2);

    input{
        display: none;
        z-index: -1;
    }

    cursor: pointer;

    @media (max-width: 768px) {
        width: calc(100%);
        min-height: 243px;
    }
    `

export const InputFileIcon = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    padding: 10px;

    border-radius: 999px;

    img{
        width: 24px;
        height: 24px;
    }

    background-color: ${PRIMARY_COLORS.MEDIUM};
`

export const InputFileContent = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    gap: 16px;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }
`

export const PreviewImage = styled.div`
    display: flex;

    position: relative;

    width: calc(419px - 38px);
    min-height: 286px;

    align-items: center;
    justify-content: center;

    border-radius: 10px;
    border: 2px solid ${NEUTRAL_COLORS.LIGHT_GRAY};

    padding: 0px 19px;

    img{
        width: 251px;
        height: 178px;
    }

        @media (max-width: 768px) {
        width: calc(100%);
        min-height: 243px;
    }
`

export const DeleteButton = styled.button`
    display: flex;

    position: absolute;

    align-items: center;
    justify-content: center;

    background-color: ${NEUTRAL_COLORS.WHITE};

    top: 10px;
    right: 10px;

    padding: 10px;

    border-radius: 999px;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};

    img{
        width: 24px;
        height: 24px;
    }

    cursor: pointer;
`;