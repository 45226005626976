import { ChangeEvent, useCallback, useEffect, useRef, useState, } from "react"
import * as S from "./styles"
import { useNavigate } from "react-router-dom"
import { useSpaProvider } from "hooks/spaRouterContext"
import { useAnyProvider } from "hooks/Context"
import { useDropdown } from "./useDropdown"
import { useSessionStorage } from "context/sessionStorage"

export type SelectOption = {
  label: string,
  value: string | number,
}

export type SelectPropsWithFilterAndMap = {
  filter(arg0: (item: any) => boolean): SelectOption,
  map(arg0: (option: any) => JSX.Element): JSX.Element
} & SelectOption

type SelectProps = {
  disabled?: boolean
  options: SelectOption[]
  value?: SelectOption
  optionLabel?: string
  type?: 'dropdown' | 'input'
  placeholder?: string
  onChange: (value: SelectOption | undefined) => void
  widthDrop: number | string
  setSearch?: any
  search?: any
  hasResult?: any
  cleanInput?: boolean
  expanded?: boolean
  guest?: any
  shouldFocus?: boolean
  setShouldFocus?: (arg0: boolean) => void
  getProperties?: (arg0: number) => void
}

const mapPin = require("../../assets/svg/MapPin.svg").default

export function Dropdown({ value, options, disabled = false, optionLabel, type, placeholder, onChange, widthDrop, search, setSearch, hasResult = null, cleanInput, expanded, guest, shouldFocus, setShouldFocus, getProperties }: SelectProps) {
  const [focusedOption, setFocusedOption] = useState(0);
  const [isOpen, setIsOpen] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [selectedOption, setSelectedOption] = useState(guest ? guest : '')
  const [itemSelected, setItemSelected] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const childRef = useRef<HTMLLIElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);

  const { setExpanded } = useSpaProvider()

  const navigate = useNavigate()
  const { saveToSessionStorage } = useSessionStorage()
  const pathname = window.location.pathname

  const { setSearchType } = useAnyProvider()

  const dropdownItemsRef = useRef<(HTMLLIElement | null)[]>([]);

  const glass = require("assets/svg/BlackMagnifyingGlass.svg").default

  const { isLoading, noResultsFound, results } = useDropdown({ inputRef: inputRef, getProperties: getProperties, isOpen: isOpen, search: search, shouldFocus: shouldFocus, setShouldFocus: setShouldFocus })
  const pressEnterSelectOption = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && search?.length > 0) {
      if (results.length > 0) {
        inputRef.current?.blur()
        setIsOpen(false)
        setExpanded(true)
        setSelectedOption(results[0].name);
        setSearch(results[0].name);
        setSearchType(results[0].type);
        setIsSelected(true);
        selectOption(results[0]);
        navigate('/properties?search=')
        if (pathname.includes('/properties') && getProperties) {
          getProperties(1)
        }
      } else {
        setIsSelected(false);
      }
    }
  };

  const cleanDropdown = useCallback(() => {
    setSelectedOption('')
    setIsSelected(false)
    return null
  }, [setSelectedOption])


  const carret = require("../../assets/svg/carret.svg").default

  function isOptionSelected(option: SelectOption) {
    return option === value
  }

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLUListElement>) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (focusedOption > 0) {
          setFocusedOption(focusedOption - 1);
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (focusedOption < options.length - 1) {
          setFocusedOption(focusedOption + 1);
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (options[focusedOption]) {
          setSelectedOption(options[focusedOption].label);
          setSearch(options[focusedOption].label);
          selectOption(options[focusedOption]);
          setIsSelected(true);
        }
        break;
      default:
        break;
    }
  }, [focusedOption, options, setSearch]);
  const sessionStorageGuest = (guest: string) => {
    setSelectedOption(guest)
    const numero = parseInt(guest, 10);
    saveToSessionStorage('guest', numero)

  }
  useEffect(() => {
    const option = document.querySelector(`li[data-focus="${focusedOption}"]`);
    option?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [focusedOption]);

  const resultLI = (hasResult: any) => {
    if (hasResult !== null)
      return (<S.ResultLi> <img src={mapPin} style={{ marginTop: '-3px', width: 24, height: 24, rotate: "360deg" }} alt="" /> Resultado</S.ResultLi>)
  }

  let inputDropdownList = null;

  if (isLoading) {
    inputDropdownList = <li style={{ fontSize: 14 }}>Carregando...</li>;
  } else if (search?.length <= 0) {
    inputDropdownList = <li style={{ display: 'flex', fontSize: 14, paddingLeft: 18, alignItems: 'center', height: 'fit-content' }}> <img style={{
      width: 24,
      height: 24,
      marginRight: 12,
      rotate: "0deg",
      marginTop: '-3px'
    }} src={glass} alt="" />  Digite sua busca</li>;
  } else if (noResultsFound) {
    inputDropdownList = <li style={{ fontSize: 14 }}>Não encontramos o que busca</li>;
  } else {
    inputDropdownList = [resultLI(hasResult), results.map((option: any, index: number) => {
      return (
        <li
          ref={childRef}
          key={index}
          className={isOptionSelected(option.name) ? "selected" : ""}
          onClick={(e) => {


            setSelectedOption(e.currentTarget.innerHTML);
            setSearch(e.currentTarget.innerHTML)
            setSearchType(option.type)
            selectOption(option.name);
            setIsSelected(true);
          }}
        >
          {option.name}
        </li>
      );
    })]
  }

  const commonDropdownList = options.map((option, index) => (
    <li style={{ paddingLeft: 16 }}
      ref={(el) => (dropdownItemsRef.current[index] = el)}
      value={option.value}
      onClick={e => {
        sessionStorageGuest(e.currentTarget.innerHTML)
        selectOption(option)
        setIsSelected(true)
        setItemSelected(true)
      }}
      key={option.value}
      data-focus={index}
      className={`option ${isOptionSelected(option) ? "option selected" : ""} ${focusedOption === index ? "option focused" : ""}`}
    >
      {option.label}
    </li>
  ))

  const selectOption = (option: SelectOption) => {
    if (option !== value) onChange(option)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (search?.length > 0) {
      setIsOpen(true)
    }
    setSearch(e.target.value)
  }


  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <S.DropdownContainer widthDrop={widthDrop}
      isSelected={isSelected}
      itemSelected={itemSelected}
      disabled={disabled}
      isOpen={isOpen}
      ref={containerRef}
      onClick={() => {
        if (expanded === false)
          setIsOpen(prev => !prev)
      }}
      tabIndex={0}
      onBlur={() => setIsSelected(false)}
    >
      {type === 'dropdown' ?
        <>
          {guest === null || guest === undefined ? <span>{selectedOption?.length > 0 ? selectedOption : placeholder}</span> :
            (
              <span>{selectedOption?.length > 0 ? `Hóspedes ${guest}` : placeholder}</span>
            )
          }
          <img src={carret} alt="" />
        </>
        :
        <input ref={inputRef} value={search} onKeyUp={pressEnterSelectOption
        } onFocus={() => setIsSelected(true)} onBlur={() => {
          if (search?.length === 0)
            setIsSelected(false)
        }} placeholder={placeholder} onChange={handleChange} />
      }
      <ul ref={ulRef} tabIndex={0} className={`options ${isOpen ? "options show" : ""}`} onKeyDown={handleKeyDown}>
        <ol>
          {type === 'input' ?
            inputDropdownList : commonDropdownList}
        </ol>
      </ul>
      {cleanInput === true && cleanDropdown()}
    </S.DropdownContainer>
  )
}
