import React, { useEffect, useState } from 'react';
import { TileLayer, Marker } from 'react-leaflet'
import * as L from "leaflet";
import 'leaflet/dist/leaflet.css';
import * as S from './styles';
import { set } from 'date-fns';
import { useScreenWidth } from 'utils/useScreenWidth';

interface MapCurrentLocalProps {
  locationRef: React.RefObject<HTMLDivElement>;
  latitude: number;
  longitude: number;
  address: {
    street: string;
    streetNumber: string;
    city: string;
    state: string;
    stateCode: string;
    region: string;
    zip: string;
  }
}

export function MapCurrentLocal({ locationRef, latitude, longitude, address }: MapCurrentLocalProps) {
  const [mapInstance, setMapInstance] = useState<L.Map | null>(null);
  const [zoomLevel, setZoomLevel] = useState(14);
  // const [markerZoomLevel, setMarkerZoomLevel] = useState(16);

  const [selectedPin, setSelectedPin] = useState(false)
  // const [selectedId, setSelectedId] = useState('')
  const [center, setCenter] = useState<L.LatLngTuple>([-25.44538578640348, -49.283599230713705])
  const [markersData, setMarkersData] = useState<any>([])

  const breakpoint = useScreenWidth()

  const centerAccommodations: L.LatLngTuple = [latitude, longitude];

  const pinMapIcon = require('assets/svg/product/PinMap.svg').default;

  const arr = [
    {
      "id_unit": 4,
      "name_unit": "Av. Silva Jardim, 2424 - HSB007",
      "latitude": latitude,
      "address": 'Rua Riachuelo, 110 - AYN045 - Curitiba, PR',
      "longitude": longitude,
      "units": 1
    },
  ]

  useEffect(() => {
    setMarkersData(arr);
    setCenter(centerAccommodations);
  }, []);


  // const handleSelect = (item: any) => {
  //   if (item) {
  //     setCenter([item.latitude, item.longitude]);
  //     setSelectedId(item.id_luminaria);
  //     setZoomLevel(markerZoomLevel);
  //   }
  // };


  useEffect(() => {
    setCenter(centerAccommodations);
    mapInstance?.flyTo(centerAccommodations, zoomLevel, {
      animate: true,
      duration: 2,
      easeLinearity: 0.25,
    });
  }, [latitude, longitude,])

  // const PinMap = () => {
  // return <img src={pinMapIcon} alt="" />
  // }

  return (
    <S.Container id='locationRef' ref={locationRef} >
      <h1>Localização</h1>
      {breakpoint ? null : <S.CustomMapContainer ref={setMapInstance} selected={selectedPin} zoomControl={false} minZoom={14} maxZoom={18} boundsOptions={{
        padding: [100, 100],
        animate: true,
        duration: 2,
        easeLinearity: 0.25,
      }} zoomAnimation={true} markerZoomAnimation={true}
        center={center} zoom={16} scrollWheelZoom={false}>

        <TileLayer
          eventHandlers={{
            load: () => {
            },
          }}
          url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        {markersData.map((item: any) => (
          <Marker key={item.latitude} autoPanOnFocus={true} eventHandlers={{
            // click:(e) => {
            //   handleSelect(item)
            //   setSelectedPin(true)
            // },
          }} position={[latitude, longitude]}
            icon={L.icon({
              iconUrl: pinMapIcon,
            })
            }
          >
          </Marker>
        ))}
        <S.CustomZoomControl position="topright" />
      </S.CustomMapContainer>}
      <S.Text>
        {address.street} {address.streetNumber} - {address.region}, {address.city} - {address.state}
      </S.Text>
    </S.Container>


  );
}



