import { useEffect, useState } from "react";

export const useScreenSkeleton = () => {
    const [widthCardSkeleton, setWidthCardSkeleton] = useState(0);
    const [heigthScaleImage, setHeightScale] = useState(0)

    useEffect(() => {
      const updateWidth = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth <= 1439) {
          setWidthCardSkeleton(316);
          setHeightScale(219)
        } else if (screenWidth >= 1440 && screenWidth < 1920) {
          setWidthCardSkeleton(320);
          setHeightScale(284)
        } else if (screenWidth >= 1920 && screenWidth < 2560) {
          setWidthCardSkeleton(440);
          setHeightScale(284)
        } else {
          setWidthCardSkeleton(392);
          setHeightScale(284)
        }
      };

      // Initial set
      updateWidth();

      // Update when the window resizes
      window.addEventListener('resize', updateWidth);

      // Cleanup
      return () => {
        window.removeEventListener('resize', updateWidth);
      };
    }, []);

    return {widthCardSkeleton,heigthScaleImage, widthScreen: window.innerWidth};
  };

