import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;

    padding: 16px;

    width: fit-content;

    gap: 88px;

    p{
        color: ${NEUTRAL_COLORS.WHITE} !important;
        white-space: nowrap;
    }


    border-radius: 108px;
    border: 1px solid ${NEUTRAL_COLORS.WHITE};

    @media (max-width: 768px) {
        .developer{
            p{
                ${FONT.CAPTION.CAPTION_SEMI_REGULAR};

            }
        }
        p{
            ${FONT.BODY.BODY_SMALL[400]};
        }
        padding: 8px 12px;
    }
`;