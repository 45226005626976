import { t } from 'i18next'
import * as S from './styles'
import { Controller } from 'react-hook-form'
import { NewInput } from 'components/NewInput'
import { IMaskInput } from 'react-imask'
import { FooterWithButtons } from 'pages/Checkin/components/FooterWithButtons'
import { Checkbox } from 'components/Input/Checkbox'
import { useArrivalAndDepartureTime } from './useArrivalAndDepartureTime'

export const ArrivalAndDepartureTime = () => {

    const { arrivalDate, control, departureDate, errors, handleBackPage, handleSubmit, onSubmit, trigger, watch } = useArrivalAndDepartureTime()

    return (
        <S.ContainerArrivalAndDepartureTime>
            <h3>{t('checkin.arrivalAndDepartureTime.title')}</h3>
            <h5>{t('checkin.arrivalAndDepartureTime.subtitle')}</h5>
            <S.FormArrivalAndDeparture>
                <S.HoursContainerInput>
                    <S.HoursContainerLabel>
                        <strong>{t('checkin.arrivalAndDepartureTime.arrival')}</strong>
                        <p>{arrivalDate}</p>
                    </S.HoursContainerLabel>
                    <Controller
                        name="arrivalHour"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <NewInput width={'100%'} mask={''} id="arrivalHour" name="arrivalHour" htmlFor="arrivalHour" placeholder={"00:00"} title={t('checkin.arrivalAndDepartureTime.forms.label.checkinTime')} type="text" errorText={errors.arrivalHour?.message}>
                                <IMaskInput
                                    mask={'00:00'}
                                    radix="."
                                    unmask={true} // true|false|'typed'
                                    value={value}
                                    onAccept={(value, mask) => {
                                        onChange(value)
                                    }}
                                    placeholder={"00:00"}
                                    onBlur={() => trigger('arrivalHour')}
                                />
                            </NewInput>
                        )}
                    />
                </S.HoursContainerInput>
                <S.HoursContainerInput>
                    <S.HoursContainerLabel>
                        <strong>{t('checkin.arrivalAndDepartureTime.departure')}</strong>
                        <p>{departureDate}</p>
                    </S.HoursContainerLabel>
                    <Controller
                        name="departureHour"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <NewInput width={'100%'} mask={''} id="departureHour" name="departureHour" htmlFor="departureHour" placeholder={"00:00"} title={t('checkin.arrivalAndDepartureTime.forms.label.checkoutTime')} type="text" errorText={errors.departureHour?.message}>
                                <IMaskInput
                                    mask={'00:00'}
                                    radix="."
                                    unmask={true} // true|false|'typed'
                                    value={value}
                                    onAccept={(value, mask) => {
                                        onChange(value)
                                    }}
                                    placeholder={"00:00"}
                                    onBlur={() => trigger('departureHour')}
                                />
                            </NewInput>
                        )}
                    />
                </S.HoursContainerInput>
            </S.FormArrivalAndDeparture>
            <S.Warningmessage>
                <Controller
                    name="accept"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <Checkbox htmlFor="accept" onChange={onChange} placeholder='' title='' value={value} />)}
                />
                <p>{t('checkin.arrivalAndDepartureTime.warningText')}</p>
            </S.Warningmessage>
            <FooterWithButtons disableContinueButton={watch('accept') === false} handleBackStep={handleBackPage} handleNextStep={handleSubmit(onSubmit)} />
        </S.ContainerArrivalAndDepartureTime>
    )
}