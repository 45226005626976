import { useState, useEffect } from "react"

export const useScreenWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)
    const breakpoint = 768


    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth)
        window.addEventListener("resize", handleWindowResize)
        return () => window.removeEventListener("resize", handleWindowResize)
    }, [])


    return width < breakpoint
}