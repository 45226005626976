import styled from "styled-components";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "../../constants/styleConstants";
import { TextAreaProps } from "./types";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    width: 100%;
`;

export const TextArea = styled.textarea<TextAreaProps>`
border: 1px solid ${props =>  props.disabled ? STATUS_COLORS.DISABLED : props.errorMessage ? STATUS_COLORS.ERROR : NEUTRAL_COLORS.DARK_GRAY};
line-height: 1.4;
border-radius: 8px;

${FONT.BODY.BODY_SMALL[400]};

padding: 1rem;

height: 156px;

color: ${props => props.disabled  ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK };

::placeholder {
    color: ${NEUTRAL_COLORS.DARK_GRAY};
}

:focus{
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    outline: none;
    border: 1px solid ${PRIMARY_COLORS.MEDIUM};

}

:disabled{
    color: ${STATUS_COLORS.DISABLED};
    border: 1px solid ${STATUS_COLORS.DISABLED};
}
resize: none;
`;

export const Label = styled.label`
    ${FONT.BODY.BODY_SMALL[500]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Error = styled.div`
    display: flex;
    flex-direction: row;

    margin-top: -4px;

    gap: 6px;

    span{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${STATUS_COLORS.ERROR};
    }
`;