import styled from "styled-components";
import { FONT_SIZE,FONT_WEIGTH } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../constants/styleConstants";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto 0px auto;
  height:420px;
  gap: 64px;
  padding: 32px 228px 32px 80px;

  @media (max-width: 768px) {
    width: calc(100% - 40px);

    flex-direction: column;

    padding: unset;

    height: unset;

    gap: 16px;
    padding-bottom: 42px;


    img{
      max-height: 200px;
      height: 100% !important;
      object-fit: contain;
    }
  }


`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 381px;
  row-gap: 16px;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    width: 100%;
  }
  `;

export const RightContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
height: 356px;
width: 100%;

@media (max-width: 768px) {
  width: 100%;
  height: unset;
}
`;

export const Title = styled.p`
color: ${PRIMARY_COLORS.MEDIUM};
font-size: ${FONT_SIZE.SMALL};
font-weight: ${FONT_WEIGTH.HUGE};
line-height: 1.4;
`;

export const Subtitle = styled.h1`
color: ${NEUTRAL_COLORS.HIGH_DARK};
font-size: ${FONT_SIZE.HUGE};
font-weight: ${FONT_WEIGTH.HUGE};
line-height: 1.4;
`;

export const Text = styled.h5`
color: ${NEUTRAL_COLORS.DARK_GRAY};
font-size: ${FONT_SIZE.SMALL};
font-weight: ${FONT_WEIGTH.MEDIUM};
line-height: 1.4;
`;
