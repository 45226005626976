import { FONT } from "constants/fontConstants";
import { PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  align-items: center;

  row-gap: 64px;

@media (max-width: 768px) {
   width: calc(100% - 40px);
}

  `;

export const Title = styled.h1`
  @media (max-width: 768px) {
    display: none;
  }
  `;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    width: 100%;

    place-items: center;

    gap: 64px 32px;

    @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    place-content: center;
    place-items: center;
    gap: 32px;
    }
  `;


export const ButtonSeeMore = styled.button`
    display: flex;

    align-items: center;
    justify-content: center;

    background-color: transparent;

    border: none;

    cursor: pointer;

    width: 380px;

    padding: 16px 0px;

    p{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${PRIMARY_COLORS.MEDIUM};
    }

    margin-top: 32px;

    gap: 10px;

    @media (max-width: 768px) {
      width: 100%;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    row-gap: 32px;

`;