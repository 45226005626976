import { useEffect, useState, useCallback } from 'react';
import * as S from './styles';
import Draggable from 'react-draggable';

export type CommentProps = {
  id: string;
  author: string;
  comment: string;
  type: string;
};


export type SliderSessionProps = {
  comments: CommentProps[];
};

const leftIcon = require('assets/svg/owner/CaretLeft.svg').default
const rightIcon = require('assets/svg/owner/CaretLeft.svg').default


export const SliderSession = (props: SliderSessionProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dragX, setDragX] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const { comments } = props;

  const updateSlide = useCallback(
    (newSlide: any) => {
      setCurrentSlide(newSlide);
      setDragX(-newSlide * 1208);
      setIsTransitioning(true);
      setTimeout(() => setIsTransitioning(false), 300);
    },
    [setCurrentSlide, setDragX, setIsTransitioning]
  );

  const onDragStop = (e: any, data: any) => {
    const delta = data.x - dragX;
    const newSlide = currentSlide - Math.round(delta / 1208);

    const maxX = -((Math.min(comments.length, 3) - 1) * 1208);
    const newX = Math.min(0, Math.max(data.x, maxX));
    setDragX(newX);

    if (newSlide < 0) {
      updateSlide(0);
    } else if (newSlide > Math.min(comments.length, 3) - 1) {
      updateSlide(Math.min(comments.length, 3) - 1);
    } else {
      updateSlide(newSlide);
    }
  };

  const goBackward = () => {
    const newSlide = Math.max(currentSlide - 1, 0);
    updateSlide(newSlide);
  };

  const goForward = () => {
    const newSlide = Math.min(currentSlide + 1, Math.min(comments.length, 3) - 1);
    updateSlide(newSlide);
  };

  const maxDragWidth = comments.length  * 1208;

  return (
    <S.Container>
        <S.PortraitCarousel>
          <Draggable
            axis="x"
            bounds={{ left: -maxDragWidth, right: 0 }}
            position={{ x: dragX, y: 0 }}
            onStop={onDragStop}
          >
            <S.CarouselContent
              style={{
                transform: `translateX(${dragX}px)`,
                transition: isTransitioning ? 'transform 300ms' : 'none',
              }}
            >
              {comments.slice(0, 3).map((comment, i: any) => (
                <S.CarouselItem key={comment.id}>
                <S.BackgroundContainer>
                  <h3>{comment.comment}</h3>
                  <S.UserInfo>
                  <p>{comment.author}</p>
                  <strong>                    {comment.type}
                  </strong>
                  </S.UserInfo>
                </S.BackgroundContainer>
                </S.CarouselItem>
              ))}
            </S.CarouselContent>
          </Draggable>
          <S.BorderRight/>
          <S.BorderLeft/>
        </S.PortraitCarousel>
        <S.ButtonContainer>
          <S.ButtonCarouselLeft onClick={goBackward}>
            <img src={leftIcon} alt="" />
          </S.ButtonCarouselLeft>
          {comments.slice(0, 3).map((_: any, i: number) => {
            return <S.Bullet key={i} active={currentSlide === i ? true : false} />;
        })}
        <S.ButtonCarouselRight onClick={goForward}><img src={rightIcon} alt="" /></S.ButtonCarouselRight>
        </S.ButtonContainer>
        </S.Container>
        );
        };
