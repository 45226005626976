import { useSessionStorage } from 'context/sessionStorage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SESSION_KEY = 'user_session';
const SESSION_DURATION = 30 * 60 * 1000; // 30 minutos em milissegundos
const sessionStorages = ['checkin-details', 'accompanying','address', 'back', 'front', 'selfie', 'personal-info', 'arrival-and-departure-time'];


export const startSession = () => {
  const sessionStart = new Date().getTime();
  localStorage.setItem(SESSION_KEY, sessionStart.toString());
};

const useSession = () => {
  const navigate = useNavigate();

    const { removeFromSessionStorage } = useSessionStorage()

  const pathname = window.location.pathname

  const checkinToken = pathname.split('/')[2]


  useEffect(() => {
    if (pathname.includes('check-in')) {
      const checkSession = () => {
        const sessionStart = localStorage.getItem(SESSION_KEY);
        if (sessionStart) {
          const currentTime = new Date().getTime();
          const sessionTime = parseInt(sessionStart, 10);

          if (currentTime - sessionTime >= SESSION_DURATION) {
            endSession();
          } else {
            const remainingTime = SESSION_DURATION - (currentTime - sessionTime);
            setTimeout(endSession, remainingTime);
          }
        } else {
          startSession();
          setTimeout(endSession, SESSION_DURATION);
        }
      };

      const endSession = () => {
        sessionStorages.forEach((storage) => {
          removeFromSessionStorage(storage)
        });
        localStorage.removeItem(SESSION_KEY);
        navigate(`/check-in/${checkinToken}`);
      };

      checkSession();
    }
  }, [navigate]);
};

export default useSession;
