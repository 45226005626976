import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled, { css } from "styled-components";


export const DropdownContainer = styled.div<{isOpen:boolean}>`
  position: relative;
  width: 100%;
  height:${props=>props.isOpen?'242px':'64px'};
  ::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};

  }
  :nth-child(1) {
    ::before{
      display: none;
    }
  }

  transition: 0.2s;
`;

export const DropdownHeadline = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-height: 64px;
  height: 100%;

`;

export const CarretIcon = styled.img<{isOpen:boolean}>`
  ${({isOpen}) => isOpen ? css`
  padding-right: 16px;
  rotate: 0deg;
  `: css`
  padding-left: 16px;
  rotate: 180deg;
  `};

  transition: 0.3s;

`;

export const LeftColumnDropdown = styled.div<{isOpen:boolean}>`
    display: flex;

    align-items: center;

    padding: 16px;
    padding-left: ${({isOpen}) => isOpen ? '32px' : "16px"};

    strong{
        padding-left: 16px;
        ${FONT.BODY.BODY_MEDIUM[400]}
        max-width: ${({isOpen}) => isOpen ? '330px' : "356px"};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: 768px) {
      padding: 0;
      padding-left: ${({isOpen}) => isOpen ? '12px' : "12px"};

      width: calc(100% - 70px);

      strong {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }

      padding-right: 0;
    }
`;

export const DivInfos = styled.div`
  width: 100%;
  border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;

    p{
        color: #818181;
        padding-left: 32px;
    }

    @media (max-width: 768px) {
        p{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 84px);
        }
    }
`;