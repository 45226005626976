import styled from "styled-components";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "../../../../constants/styleConstants";

export const Container = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    @media (max-width: 768px) {
        display: flex;
        justify-content: flex-start;
    }

`;

export const Modal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: calc(667px - 64px);
    height: calc(100% - 135px);
    background-color: ${NEUTRAL_COLORS.WHITE};
    border-radius: 10px;
    padding: 0px 32px;
    margin-top: calc(0% + 110px);

    @media (max-width: 768px) {
        width: calc(100vw - 40px);
        height: calc(100% - 135px);

        margin-top: 40px;
        padding: 0px 20px;

        z-index: 1000;
    }

/*         @media (max-width: 1360px) {
    height: 496px;
    }
        @media (max-width: 1280px) {
    height: 466px;
    } */
`;

export const TitleFilter = styled.div`
display: flex;
align-items: center;
position: relative;
width: 100%;
height:56px;
justify-content: space-between;
padding: 0px 24px;
color: ${NEUTRAL_COLORS.HIGH_DARK};
font-weight:${FONT_WEIGTH.HUGE};
font-size: ${FONT_SIZE.SMALL};
button{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -12px;
}
&::after{
    content: '';
    width: calc(100% + 16px);
    height: 1.5px;
    background-color: ${NEUTRAL_COLORS.GRAY};
    position: absolute;
    margin-top: 45px;
    margin-left: calc(0% - 32px);
}
`;

export const ValueRow = styled.div`
    display: flex;
    height: 48px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
    margin-bottom: 32px;
    `;

export const ValueText = styled.p`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    color: ${NEUTRAL_COLORS.DARK_GRAY};
`;


export const SubTitleFilter = styled.p`
color: ${NEUTRAL_COLORS.HIGH_DARK};
font-weight:${FONT_WEIGTH.HUGE};
align-self: flex-start;
font-size: ${FONT_SIZE.TINY};
margin-top: 8px;
`;

export const FilterAmenitiesTitle = styled.p`
${FONT.BODY.BODY_SMALL[600]};
color: ${NEUTRAL_COLORS.HIGH_DARK};
align-self: flex-start;
margin-top: 8px;
margin-bottom: 16px;
`;

export const ModalContent = styled.div`
    display: inherit;
    flex-direction: inherit;
    height: 100%;
    width: 100%;
    overflow:auto;
    padding: 0px 22px;

    @media (max-width: 768px) {
        width: calc(100%);
    }

    .ant-slider
 .ant-slider
-handle::after{
        margin-top: -5px;
        box-shadow: 0 0 0 2px #000;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${NEUTRAL_COLORS.WHITE};
        &:hover{

        }
    }


.ant-slider .ant-slider-handle:hover::before,
.ant-slider .ant-slider-handle:active::before,
.ant-slider .ant-slider-handle:focus::before {
    inset-inline-start: 0px !important;
    inset-block-start: 0px !important;
    width: 20px;
    height: 20px;
}

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after {
    box-shadow: 0 0 0 3px #000;
    width: 10px;
    height: 10px;
    inset-inline-start: -1px;
    inset-block-start: -1px;
}

/* .ant-slider .ant-slider-track {
    position: absolute;
    background-color: #91caff;
    border-radius: 2px;
    transition: background-color 0.2s;
}

.ant-slider:hover .ant-slider-rail {
    background-color: rgba(0, 0, 0, 0.06);
}

.ant-slider:hover .ant-slider-track {
    background-color: #69b1ff;
}

.ant-slider:hover .ant-slider-dot {
    border-color: rgba(0, 0, 0, 0.15);
}

.ant-slider:hover .ant-slider-handle::after {
    box-shadow: 0 0 0 2px #69b1ff;
}

.ant-slider:hover .ant-slider-dot-active {
    border-color: #1677ff;
}

.ant-slider .ant-slider-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    outline: none;
}

.ant-slider .ant-slider-handle .ant-slider-dragging {
    z-index: 1;
}

.ant-slider .ant-slider-handle::before {
    content: "";
    position: absolute;
    inset-inline-start: -2px;
    inset-block-start: -2px;
    width: 14px;
    height: 14px;
    background-color: transparent;
}

.ant-slider .ant-slider-handle::after {
    content: "";
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 0 2px #91caff;
    border-radius: 50%;
    cursor: pointer;
    transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s, height 0.2s, box-shadow 0.2s;
}



.ant-slider .ant-slider-mark {
    position: absolute;
    font-size: 14px;
}

.ant-slider .ant-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
    word-break: keep-all;
    cursor: pointer;
    user-select: none;
}

.ant-slider .ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.88);
}

.ant-slider .ant-slider-step {
    position: absolute;
    background: transparent;
    pointer-events: none;
}

.ant-slider .ant-slider-dot {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #ffffff;
    border: 2px solid #f0f0f0;
border-radius: 50%;
cursor: pointer;
transition: border-color 0.3s;
pointer-events: auto;
}

.ant-slider .ant-slider-dot-active {
border-color: #91caff;
}

.ant-slider.ant-slider-disabled {
cursor: not-allowed;
}

.ant-slider.ant-slider-disabled .ant-slider-rail {
background-color: rgba(0, 0, 0, 0.06) !important;
}

.ant-slider.ant-slider-disabled .ant-slider-track {
background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-slider.ant-slider-disabled .ant-slider-dot {
background-color: #ffffff;
border-color: rgba(0, 0, 0, 0.25);
box-shadow: none;
cursor: not-allowed;
}

.ant-slider.ant-slider-disabled .ant-slider-handle::after {
background-color: #ffffff;
cursor: not-allowed;
width: 10px;
height: 10px;
box-shadow: 0 0 0 2px #bfbfbf;
inset-inline-start: 0;
inset-block-start: 0;
}

.ant-slider.ant-slider-disabled .ant-slider-mark-text,
.ant-slider.ant-slider-disabled .ant-slider-dot {
cursor: not-allowed !important;
}

.ant-slider-horizontal {
padding-block: 4px;
height: 12px;
}

.ant-slider-horizontal .ant-slider-rail {
width: 100%;
height: 4px;
}

.ant-slider-horizontal .ant-slider-track {
height: 4px;
}

.ant-slider-horizontal .ant-slider-handle {
inset-block-start: 1px;
}

.ant-slider-horizontal .ant-slider-mark {
inset-inline-start: 0;
top: 10px;
width: 100%;
}

.ant-slider-horizontal .ant-slider-step {
inset-inline-start: 0;
top: 4px;
width: 100%;
height: 4px;
}

.ant-slider-horizontal .ant-slider-dot {
position: absolute;
inset-block-start: -2px;
}

.ant-slider-horizontal.ant-slider-with-marks {
margin-bottom: 30px;
}

.ant-slider-vertical {
padding-inline: 4px;
width: 12px;
height: 100%;
}

.ant-slider-vertical .ant-slider-rail {
height: 100%;
width: 4px;
}

.ant-slider-vertical .ant-slider-track {
width: 4px;
}

.ant-slider-vertical .ant-slider-handle {
inset-inline-start: 1px;
}

.ant-slider-vertical .ant-slider-mark {
inset-inline-start: 10px;
top: 0;
height: 100%;
}

.ant-slider-vertical .ant-slider-step {
inset-inline-start: 4px;
top: 0;
height: 100%;
width: 4px;
}

.ant-slider-vertical .ant-slider-dot {
position: absolute;
inset-inline-start: -2px;
} */

`;


export const RowModal = styled.div`
    display: flex;
    flex-direction: row;
    width:100%;
    align-items: center;
    padding: 25px 0px;
    justify-content: space-between;
    border-bottom: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};

    &:nth-child(6){
        border-bottom: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    }
    ${SubTitleFilter}{
        padding-bottom: 32px;
    }

    @media (max-width: 768px) {
        width: calc(100vw - 50px);
        flex-direction: column;
        height: max-content;
    }
`;

export const Separator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 99%;
    height: 1px;
    margin: 32px 0px;
    background: purple;
    border: 0.5px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    `;

export const LabelInput = styled.label`
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_WEIGTH.MEDIUM};
`;

export const ContainerInput = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    outline-color: ${PRIMARY_COLORS.MEDIUM};
    border-radius: 8px;
    font-weight: ${FONT_WEIGTH.LARGE};
    line-height: 1.4;
    label{
        font-size: ${FONT_SIZE.TINY};
        padding-left:8px;
    }
    `;

export const InputNumberValues = styled.input`
    font-weight: ${FONT_WEIGTH.LARGE};
    border: none;
    outline: none;
    border-radius: 8px;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
    -moz-appearance: textfield;
    padding: 8px 8px;
    font-weight: ${FONT_WEIGTH.LARGE};
`;

export const RowValue = styled.div`
    display: flex;
    flex-direction: column;
    height: 70px;
    width: 302px;
    row-gap: 6px;
`;

export const RowOption = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
    justify-content: center;
    gap: 16px;

    @media (max-width: 768px) {
        gap: 8px;
    }

    `;

export const FilterRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &:nth-last-child(1){
        padding-bottom: 32px;
    }
`;

export const Divisor = styled.div`
    height: 1px;
    width: calc(100% + 64px);
    background-color: ${NEUTRAL_COLORS.GRAY};
`;

export const FilterColumn = styled.div`
    display: grid;
    grid-template-columns: 302px 302px;
    gap: 16px 0px;


    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 16px 0px;
    }

`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 77px;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 0px;
    gap:32px;
    `;

export const ButtonFooter = styled.button`
    color: ${NEUTRAL_COLORS.WHITE};
    background-color: ${PRIMARY_COLORS.MEDIUM};
    height: 44px;
    width: 128px;
    cursor: pointer;
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.HUGE};
    border-radius: 10px;
    border: 1px solid ${PRIMARY_COLORS.MEDIUM};
    justify-content: center;
    display: flex;
    align-items: center;
    &:hover {
    background-color: ${PRIMARY_COLORS.DARK};
    }
    gap: 10px;
`;
export const ButtonClean = styled(ButtonFooter)`
    background: transparent;
    border: none;
    color: ${PRIMARY_COLORS.MEDIUM};
 &:hover {
    background-color: transparent;
    }
`;
