import { RegisterNewsletter } from 'pages/Blog/RegisterNewsletter'
import * as S from './styles'
import { useEffect, useState } from 'react'
import { Input } from 'components/Input'
import { Button } from 'components/Button'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import fetchAxios, { fetchAxiosBlog } from 'services/http'
import { useLocation } from 'react-router-dom'
import { readingTime, transformSections } from 'utils/readingTime'
import { SlideBlogProps } from 'pages/Blog/components/SlideBlog'
import useAlert from 'components/Notification'
import { useScreenWidth } from 'utils/useScreenWidth'
import { useContainer } from 'hooks/useBlogRef'


type Paragraph = {
    id: number,
    text: string
};

type Article = {
    id: number,
    title: string | null,
    paragraph: Paragraph[]
};

type DataProps = {
    title: any,
    author: any,
    paragraph: Article[],
    datePublication: '',
    readingTime: 0
}

export const Article = () => {

    const path = useLocation().pathname

    const breakpoint = useScreenWidth()

    const slugArticle = path.split('/')[3]

    const [data, setData] = useState<DataProps>({
        title: '',
        author: '',
        paragraph: [],
        datePublication: '',
        readingTime: 0
    })
    const [hero, setHero] = useState('')

    const [loading, setLoading] = useState(false)
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })


    const addBaseURLToLinks = (articles: Article[] | undefined): [] | Article[] => {
        if (articles) {
            return articles.map(article => {
                return {
                    ...article,
                    paragraph: article.paragraph.map(paragraph => {
                        return {
                            ...paragraph,
                            text: paragraph.text.startsWith('![') ? paragraph.text.replace(/\]\(/, `](https://blog.yogha.com.br`) : paragraph.text
                        };
                    })
                };
            });
        } else {
            return []
        }

    };

    const handleCreate = async () => {
        if (!email.includes('@')) {
            return showAlert({ active: true, title: 'Esse e-mail é inválido. tente usar assim: exemplo@email.com', type: "ERROR" })
        }
        try {
            const { data } = await fetchAxios.post('/land/newsletter', {
                "email": email,
            })
            if (data.code === 200) {
                showAlert({ active: true, title: 'Email cadastrado com sucesso', type: "SUCCESS" })
                setEmail('')
            } else {
                showAlert({ active: true, title: data.message, type: "ERROR" })

            }



        } catch (error) {
            console.error(error)
        }
    }

    const getArticle = async () => {
        setLoading(true)
        try {
            const response = await fetchAxiosBlog.get(`/api/articles?&populate=deep&filters[slug][$eq]=${slugArticle}`)
            if (response.data.data.length > 0) {
                const dataWithReadingTime = response.data.data.map((obj: SlideBlogProps) => {
                    const sectionString = transformSections([obj])[0]; // Transforma a seção em string
                    const readTime = readingTime(sectionString); // Calcula o tempo de leitura
                    return {
                        ...obj,
                        readingTime: readTime, // Adiciona a chave 'readingTime' ao objeto
                    };
                });
                const updatedArticles: Article[] = addBaseURLToLinks(dataWithReadingTime[0].attributes.section);
                setData({
                    author: dataWithReadingTime[0].attributes.author,
                    title: dataWithReadingTime[0].attributes.title,
                    paragraph: updatedArticles,
                    datePublication: dataWithReadingTime[0].attributes.datePublication,
                    readingTime: dataWithReadingTime[0].readingTime
                })
                setHero(`https://blog.yogha.com.br${dataWithReadingTime[0].attributes.thumbnail.data.attributes.url}`)
                setLoading(false)
            }
        } catch (error) {

        }

    }

    const { current } = useContainer()
    useEffect(() => {
        getArticle()
        current?.scrollTo(0, 0)
    }, [])

    const [email, setEmail] = useState('')

    return (
        <> {
            !loading && <>

                <S.Container>
                    <S.Title>{data.title} </S.Title>
                    <S.HeaderInfo>
                        <div>
                            <p>Autor(a): {data.author}</p>
                        </div>
                        <S.CollumnArticleInfo>
                            <p>{data.datePublication}</p>
                            <p>|</p>
                            <p>Leitura: {data.readingTime} min</p>
                        </S.CollumnArticleInfo>
                    </S.HeaderInfo>
                    <S.Hero src={hero} alt="Imagem da notícia" />
                    <S.Content>
                        <S.TextContentDefault>
                            {data.paragraph?.map((paragraph: any) => (<S.TextContent key={paragraph.id}>
                                <span>{paragraph.title}</span>
                                {paragraph.paragraph.map((text: any) => {
                                    return (
                                        <ReactMarkdown key={text.id}>
                                            {text.text}
                                        </ReactMarkdown>

                                    )
                                })}

                            </S.TextContent>
                            ))}
                        </S.TextContentDefault>
                        {!breakpoint && <S.Sidebar>
                            <h1>Assine a nossa newsletter e receba os conteúdos Yogha em primeira mão pelo seu
                                e-mail. </h1>
                            <Input placeholder="Digite seu e-mail" type="email" htmlFor='email' onChange={(e) => setEmail(e.currentTarget.value)} value={email} title='E-mail' />
                            <div><Button title='Inscreva-se' size='large' type='primary' onClickButton={handleCreate} />
                                <p>*Ao assinar a newsletter, declaro que conheço a Política de Privacidade e autorizo a utilização das minhas informações.</p></div>

                        </S.Sidebar>}
                    </S.Content>
                </S.Container>
                <Alert />

                <RegisterNewsletter />
            </>}
        </>
    )
}