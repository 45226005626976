import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { Container } from './styles';
import { Body } from './components/Body';
import { useState, useEffect } from 'react';
import fetchAxios, { fetchAxiosAdmin } from 'services/http';
import { mapBuilding } from 'utils/formatterArray';
import useIsMobile from 'pages/Mobile/useMobile';
import { HeaderMobile } from 'components/HeaderMobile';
import { MobileNavigation } from 'components/MobileNavigation';
import { useSessionStorage } from 'context/sessionStorage';
import { useAnyProvider } from 'hooks/Context';
import axios from 'axios';
import { useQueries } from 'react-query';
import { fetchAccommodations } from 'services/accommodation';

const fetchBuildingData = async () => {
  const { data } = await fetchAxiosAdmin.get(`buildings/list`);

  return data;
};

const useFetchData = () => {
  return useQueries([
    { queryKey: ['fetchBuilding'], queryFn: fetchBuildingData },
    { queryKey: ['fetchAccommodations'], queryFn: fetchAccommodations }
  ]);
}

export const Home = () => {

  document.title = "Yogha | Viva à vontade";

  const [units, setUnits] = useState<any>([]);
  const [promotinalUnits, setPromotinalUnits] = useState<any>([]);
  const [buildings, setBuildings] = useState<any>([]);
  const [blogPost, setBlogPost] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const result = useFetchData();

  const isMobile = useIsMobile()

  useEffect(() => {
    const getAccommodations = async () => {
      const { data } = await fetchAxios.get('/accommodations/empashys');
    }
    getAccommodations();
  }, [])


  const order = [
    "Helbor Stay Batel",
    "All You Need",
    "7Th Avenue Live",
    "Studio do Bosque",
    "CWBe",
    "Vivance Batel",
    "BFF2211",
    "Hyde Park",
    "Edifício Milena",
  ];

  const getPostBlog = async () => {
    try {
      const { data } = await axios.get(`https://blog.yogha.com.br/wp-json/wp/v2/posts?per_page=9`);
      setBlogPost(data);
    } catch (error) {

    }

  }
  useEffect(() => {
    getPostBlog()
    // Certifique-se de que ambas as requisições foram concluídas
    if (result[0].isSuccess && result[1].isSuccess) {

      // Processar e atualizar os edifícios
      const sortedBuildingData = mapBuilding(result[0].data).sort((a, b) => {
        const indexA = order.indexOf(a.title);
        const indexB = order.indexOf(b.title);

        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      });

      const removePrincess = sortedBuildingData.filter((item) => item.title !== 'Edifício Princess')
      setBuildings(removePrincess);
      // Processar e atualizar acomodações
      setPromotinalUnits(result[1].data.promotionalUnits);
      setUnits(result[1].data.units);
      setIsLoading(false);
    }
    // Lidar com erros e estados de loading aqui, se necessário
  }, [result[0].isSuccess, result[1].isSuccess]);

  const { getFromSessionStorage } = useSessionStorage()

  const { setDate } = useAnyProvider();
  useEffect(() => {


    const getStorage = () => {
      const headerDate = getFromSessionStorage('dateHeader')
      const dateHeaderExist = JSON.stringify(headerDate)

      if (dateHeaderExist !== 'null') {

        const dayCheckIn = headerDate.check_in;
        const dayCheckOut = headerDate.check_out;
        setDate([dayCheckIn, dayCheckOut])
      }


    }
    getStorage()
  }, []);

  return (
    <>
      <Container>
        {isMobile ? <HeaderMobile /> : <Header />}
        <Body isFetching={result[1].isLoading} blog={blogPost} buildings={buildings} accommodations={units} />
        <div style={{
          width: '100%',
        }}>
          <Footer />
        </div>
        <MobileNavigation />
      </Container>
    </>
  );
};
