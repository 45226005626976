import { FONT } from 'constants/fontConstants';
import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
width: 100vw;
justify-content: center;

margin-top: 94px;

@media (max-width: 768px) {
margin-top: 0px;

}
`;

export const TitleCarrousel = styled.h6`
    width: calc(100% - 40px);
    padding: 0px 20px;
    ${FONT.BODY.BODY_LARGE[600]}
`;

export const CarouselContainer = styled.div`
display: flex;
align-self: center;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
    }
`;