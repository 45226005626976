import React, { createContext, useContext, RefObject, ReactNode } from 'react';

const ContainerContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const useContainer = () => {
    const context = useContext(ContainerContext);
    if (!context) {
        throw new Error('useContainer must be used within a ContainerProvider');
    }
    return context;
};

interface ContainerProviderProps {
    containerRef: RefObject<HTMLDivElement>;
    children: ReactNode;
}

export const ContainerProvider: React.FC<ContainerProviderProps> = ({ containerRef, children }) => {
    return (
        <ContainerContext.Provider value={containerRef}>
            {children}
        </ContainerContext.Provider>
    );
};
