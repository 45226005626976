import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { FooterWithButtons } from "pages/Checkin/components/FooterWithButtons";
import DropdownCheckin from "pages/Checkin/components/Dropdown";
import { useAccompanying } from "./useAccompanying";

const PlusIcon = require("assets/svg/Plus.svg").default

const editIcon = require("assets/svg/spaceUser/PencilSimple.svg").default
const deleteIcon = require("assets/svg/spaceUser/TrashRed.svg").default

export const Accompanying = () => {
    const { t } = useTranslation();

    const { handleAddAccompanying, handleBackStep, handleDeleteAccompanying, handleEditAccompanying, handleNextStep, maxAccompanying, users } = useAccompanying()

    return (
        <S.AccompanyingContainer>
            <h1>{t('checkin.accompanying.title')}</h1>
            <S.ExplainContainer>
                <strong>{t('checkin.accompanying.subtitle')}</strong>

            </S.ExplainContainer>
            <S.ContainerDropDownHeadline>
                <strong>
                    {t('checkin.accompanying.accompanyingLabel')}
                </strong>
                <p>
                    {t('checkin.accompanying.maxPeople')}: {maxAccompanying} {maxAccompanying === 1 ? t('checkin.accompanying.person') : t('checkin.accompanying.people')}
                </p>
            </S.ContainerDropDownHeadline>
            <S.ContainerDropDown>
                {users.length > 0 ? (
                    <S.ContainerAccompanyingAdd >
                        {users.map((user: any, index) => (
                            <S.ContainerContentAccompanying key={index}>
                                <DropdownCheckin
                                    key={index}
                                    {...user}
                                >
                                    <S.ActionButtons>
                                        <S.EditButton onClick={() => handleEditAccompanying(index)}>
                                            <img src={editIcon} alt="" />
                                        </S.EditButton>
                                        <S.DeleteButton onClick={() => handleDeleteAccompanying(index)}>
                                            <img src={deleteIcon} alt="" />
                                        </S.DeleteButton>
                                    </S.ActionButtons>
                                </DropdownCheckin>


                            </S.ContainerContentAccompanying>
                        ))}
                    </S.ContainerAccompanyingAdd>
                ) : (
                    <S.NoHasCompanion>
                        <p>{t('checkin.accompanying.noHasCompanion')}</p>
                    </S.NoHasCompanion>
                )}
                {users.length < maxAccompanying &&
                    <S.DropdownContainer onClick={handleAddAccompanying}>
                        <div>
                            <img src={PlusIcon} alt="" width={24} height={24} />
                            <p>
                                {t('checkin.accompanying.buttonAdd')}
                            </p>
                        </div>
                    </S.DropdownContainer>
                }
            </S.ContainerDropDown>
            <FooterWithButtons handleNextStep={handleNextStep} handleBackStep={handleBackStep} />
        </S.AccompanyingContainer >
    )
}