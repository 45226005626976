import { Button } from "components/Button";
import { ProductCard } from "components/ProductCard";
import { Link } from "react-router-dom";
import { useScreenWidth } from "utils/useScreenWidth";

type BlockedUserAlertProps = {
    blockedUser: boolean;
    setIsBlockedUser: (value: boolean) => void;
}

export const BlockedUserAlert = ({ blockedUser, setIsBlockedUser }: BlockedUserAlertProps) => {
    const breakpoint = useScreenWidth()
    return (
        <>
            {blockedUser && <ProductCard width={breakpoint ? 'calc(100% - 40px)' : ''} bottomLine isOpen={blockedUser} setIsOpen={() => setIsBlockedUser(false)} title="Conta com pendências" >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 24,
                    marginTop: 8,
                    textAlign: 'center'
                }}>
                    <p>Parece que há algumas pendências em sua conta. Por favor, entre em contato com nosso suporte ao usuário para que possamos resolver isso juntos.</p>
                    <div style={{
                    }}>
                        <Link to="https://api.whatsapp.com/send?phone=5541996941949&text=Ola!%20Tentei%20fazer%20uma%20reserva%20no%20site%20e%20apresentou%20pend%C3%AAncia%20poderia%20me%20ajudar?%20" target="_blank" style={{
                            textDecoration: 'none'
                        }}>
                            <Button onClickButton={() => { }} size="large" title="Entrar em contato com suporte" type="primary" widthSize={'100%'} />
                        </Link>
                    </div>
                </div>
            </ProductCard>}
        </>
    );
}