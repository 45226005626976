import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { FooterWithButtons } from "pages/Checkin/components/FooterWithButtons";
import { useState } from "react";
import { useSessionStorage } from "context/sessionStorage";
import { useNavigate } from "react-router-dom";
import { InputFile } from "pages/Checkin/components/InputFile";
import { uploadFile } from "pages/Checkin/utils/uploadFile";
import { Loading } from "pages/Payment/components/Loading";
import useScrollToTopByState from "hooks/useScrollToTopByState";

const identifyDocumentSvg = require('../../../../assets/svg/checkin/IdentifyDocument.svg').default
const selfiePhotoSvg = require('../../../../assets/svg/checkin/SelfiePhoto.svg').default
const referenceCpfPhoto = require('../../../../assets/image/checkin/ReferencePassport.png')


export const VerifyDocumentPassport = () => {
    const { t } = useTranslation();
    const [innerStep, setInnerStep] = useState(0);
    const { getFromSessionStorage } = useSessionStorage();
    const navigate = useNavigate();
    const [frontSide, setFrontSide] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false)

    const pathnameActual: string = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));

    useScrollToTopByState('.main', innerStep)

    const handleNextStep = async () => {
        const dataCheckinDetails = getFromSessionStorage('checkin-details')
        if (innerStep === 0) {
            setInnerStep(1);
        }
        else if (innerStep === 2 && frontSide !== null) {
            setIsLoading(true)
            try {
                await uploadFile(frontSide, dataCheckinDetails.checkinId, 'front')
                navigate(`${pathnameActual}/selfie-validation`, { replace: true })
            } catch (error) {
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        } else {
            setInnerStep(innerStep + 1);
        }
    }

    const handleBackStep = () => {
        if (innerStep === 0) {
            navigate(`${pathnameActual}/address`)
        } else {
            setInnerStep(innerStep - 1);
        }
    }

    const handleFileChangeFrontSide = (file: File | null) => {
        setFrontSide(file);
    };

    return (
        <S.VerifyDocumentContainer>
            <h1>{t('checkin.verifyDocument.title')}</h1>
            <S.ExplainContainer visible={innerStep === 0}>
                <strong>{t('checkin.verifyDocument.passport.subtitle')}</strong>
                <S.ExplainContent>
                    <img src={identifyDocumentSvg} alt="" />
                    <p>{t('checkin.verifyDocument.documentIdentify')}</p>
                </S.ExplainContent>
                <S.ExplainContent>
                    <img src={selfiePhotoSvg} alt="" />
                    <p>{t('checkin.verifyDocument.takeASelfie')}</p>
                </S.ExplainContent>
                <small>{t('checkin.verifyDocument.footer')}</small>
            </S.ExplainContainer>
            <S.GuideContent visible={innerStep === 1}>
                <p>{t('checkin.verifyDocument.passport.subtitle')}</p>
                <img src={referenceCpfPhoto} alt="" />
                <ul>
                    <li>{t('checkin.verifyDocument.cpf.documentWithPhoto')}</li>
                    <li>{t('checkin.verifyDocument.cpf.documentHasntExpiration')}</li>
                    <li>{t('checkin.verifyDocument.cpf.documentOriginal')}</li>
                    <li>{t('checkin.verifyDocument.cpf.removeCase')}</li>
                    <li>{t('checkin.verifyDocument.cpf.putDocument')}</li>
                </ul>
            </S.GuideContent>
            <S.SendFilesContainer visible={innerStep === 2}>
                <p>{t('checkin.verifyDocument.passport.subtitle')}</p>
                <S.ColumnInputFiles>
                    <S.ContainerInputFiles>
                        <p>{t('checkin.verifyDocument.cpf.frontSide')}</p>
                        <InputFile onFileChange={handleFileChangeFrontSide} description={t('checkin.verifyDocument.inputFileLabel')} name="frontSide" />
                    </S.ContainerInputFiles>
                </S.ColumnInputFiles>
            </S.SendFilesContainer>
            <FooterWithButtons disableContinueButton={innerStep === 2 && frontSide === null} handleNextStep={handleNextStep} handleBackStep={handleBackStep} />
            {isLoading && <Loading text={t('checkin.verifyDocument.passport.waitingSendFile')} />}
        </S.VerifyDocumentContainer>
    )
}