import * as S from "./styles"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { Navegation } from "components/Navegation"
import { Button } from "components/Button"
import { Collection } from "./components/Collection"
import { CardResult } from "components/CardResult"
import { ProductCard } from "components/ProductCard"
import { Input } from "components/Input"

export const Favorites = () => {
    const location = useLocation();

    const { search } = location;

    const collectionName = search.split(':')[1] === '1' ? 'Melhores Lugares' : search.split(':')[1] === '2' ? 'Apartamentos' : search.split(':')[1] === '3' ? 'Litoral' : search.split(':')[1] === '4' ? 'Camping' : 'Favoritos';

    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [optionsOpen, setOptionsOpen] = useState(false);





    const results = [
        {
            cardType: 'search',
            title: 'Rua Riachuelo, 110 - AYN055',
            images: [require('assets/image/oportunitys/oportunity4.png'), require('assets/image/oportunitys/oportunity2.png')],
            location: 'Centro - Curitiba, PR',
            address: '',
            price: 154,
            description: `1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
            gadget: 'Anúncio novo'
        },
        {
            cardType: 'search',
            title: 'Rua Riachuelo, 110 - AYN055',
            images: [require('assets/image/oportunitys/oportunity4.png'), require('assets/image/oportunitys/oportunity2.png'), require('assets/image/oportunitys/oportunity3.png'),],
            location: 'Centro - Curitiba, PR',
            address: '',
            price: 154,
            description: `1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
            gadget: 'Anúncio novo'
        },
        {
            cardType: 'search',
            title: 'Rua Riachuelo, 110 - AYN055',
            images: [require('assets/image/oportunitys/oportunity4.png'), require('assets/image/oportunitys/oportunity2.png'), require('assets/image/oportunitys/oportunity3.png'), require('assets/image/oportunitys/oportunity1.png'),],
            location: 'Centro - Curitiba, PR',
            address: '',
            price: 154,
            description: `1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
            gadget: 'Anúncio novo'
        },
        {
            cardType: 'search',
            title: 'Rua Riachuelo, 110 - AYN055',
            images: [require('assets/image/oportunitys/oportunity4.png'), require('assets/image/oportunitys/oportunity2.png'), require('assets/image/oportunitys/oportunity3.png'), require('assets/image/oportunitys/oportunity1.png'),],
            location: 'Centro - Curitiba, PR',
            address: '',
            price: 154,
            description: `1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
            gadget: 'Anúncio novo'
        },
        {
            cardType: 'search',
            title: 'Rua Riachuelo, 110 - AYN055',
            images: [require('assets/image/oportunitys/oportunity4.png'), require('assets/image/oportunitys/oportunity2.png'), require('assets/image/oportunitys/oportunity3.png'), require('assets/image/oportunitys/oportunity1.png'),],
            location: 'Centro - Curitiba, PR',
            address: '',
            price: 154,
            description: `1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
            gadget: 'Anúncio novo'
        },
        {
            cardType: 'search',
            title: 'Rua Riachuelo, 110 - AYN055',
            images: [require('assets/image/oportunitys/oportunity4.png'), require('assets/image/oportunitys/oportunity2.png'), require('assets/image/oportunitys/oportunity3.png'), require('assets/image/oportunitys/oportunity1.png'),],
            location: 'Centro - Curitiba, PR',
            address: '',
            price: 154,
            description: `1 quarto(s)  33m²  2 pessoa(s)  \nNão aceita pets`,
            gadget: 'Anúncio novo'
        },
    ]

    // const changeCollectionName = (e: React.ChangeEvent<HTMLTextAreaElement> | React.FormEvent<HTMLInputElement>) => {
    //     if((e.target as HTMLInputElement).value.length > 0){
    //         setNameCollection((e.target as HTMLInputElement).value);
    //     }
    //   }


    const isUserPage = location.pathname === '/minha-conta/favorites';

    const ojb = [
        {
            "id": 1,
            "name": "Melhores Lugares",
            "ammount": 4,
            "images": [
                require("assets/image/collection/collection1.png"),
                require("assets/image/collection/collection2.png"),
                require("assets/image/collection/collection3.png"),
            ]
        },
        {
            "id": 2,
            "name": "Apartamentos",
            "ammount": 3,
            "images": [
                require("assets/image/collection/collection1.png"),
                require("assets/image/collection/collection2.png"),
                require("assets/image/collection/collection3.png"),
            ]
        },
        {
            "id": 3,
            "name": "Litoral",
            "ammount": 2,
            "images": [
                require("assets/image/collection/collection1.png"),
                require("assets/image/collection/collection2.png"),
            ]
        },
        {
            "id": 4,
            "name": "Camping",
            "ammount": 1,
            "images": [
                require("assets/image/collection/collection2.png"),
            ]
        },
    ]

    const returnAmmount = () => {
        if (collectionName === 'Melhores Lugares') {
            return 4
        } else if (collectionName === 'Apartamentos') {
            return 3
        } else if (collectionName === 'Litoral') {
            return 2
        } else if (collectionName === 'Camping') {
            return 1
        }
    }

    const dotsThreeVertical = require('assets/svg/spaceUser/DotsThreeVertical.svg').default;
    const trashIcon = require('assets/svg/spaceUser/Trash.svg').default;
    const pencilIcon = require('assets/svg/spaceUser/PencilSimple.svg').default;

    return (<> {isUserPage ?
        <S.Container>
            <Navegation actualPage="Favoritos" />
            {ojb.length === 0 && <S.ContainerNoFavorites>
                <S.ContentNoFavorites>
                    <S.Title>Favoritos</S.Title>
                    <p>Aqui você encontrará as acomodações que você adicionou aos seus favoritos. Para adicionar uma acomodação aos seus favoritos, clique no botão com o desenho de um coração quando estiver buscando um apartamento.</p></S.ContentNoFavorites>
                <Button disabled={false} icon="" onClickButton={() => { }} size="medium" widthSize={'194px'} title="Explorar novos lugares" type="primary" />
            </S.ContainerNoFavorites>}
            {ojb.length !== 0 &&
                <S.ContainerFavorites>
                    <S.ContainerTitle>Favoritos</S.ContainerTitle>
                    <S.Collections>
                        {ojb.map((item) => {
                            return <Collection id={item.id} images={item.images} key={item.id} name={item.name} ammount={item.ammount} />
                        })
                        }
                    </S.Collections>
                </S.ContainerFavorites>}
        </S.Container>
        :
        <S.Container>
            <Navegation lastPage="Favoritos" linkLastPage="/minha-conta/favorites" actualPage={collectionName} />
            <S.ContainerFavorites>
                <S.ContainerTitle>{collectionName}</S.ContainerTitle>
                <S.CollectionCards>
                    {results.slice(0, returnAmmount()).map((item: any) => {
                        return (
                            <S.ContainerCard>
                                <CardResult width="277px" {...item} />
                            </S.ContainerCard>)
                    })}
                </S.CollectionCards>
                <S.EditButton onClick={() => setOptionsOpen(true)} >
                    <img src={dotsThreeVertical} alt="Editar" />
                </S.EditButton>
                {
                    optionsOpen && <S.OptionsContainer onMouseLeave={() => setOptionsOpen(false)}>
                        <S.Option onClick={() => setEditOpen(true)}>
                            <img src={pencilIcon} alt="" />
                            <p>Editar coleção</p>
                        </S.Option>
                        <S.Option onClick={() => setDeleteOpen(true)}>
                            <img src={trashIcon} alt="" />
                            <p>Excluir coleção</p>
                        </S.Option>
                    </S.OptionsContainer>
                }
                <ProductCard width="391" isOpen={editOpen} setIsOpen={setEditOpen} title="Alterar nome da coleção" bottomLine transparent >
                    <S.ContainerInput>
                        <Input width={'100%'} htmlFor="name" onChange={() => { }} placeholder="" title="Nome da coleção" type="text" value={collectionName} />
                    </S.ContainerInput>
                    <S.ContainerButton>
                        <Button onClickButton={() => setEditOpen(false)} size="medium" widthSize={'100%'} title="Salvar alteração" type="primary" />
                    </S.ContainerButton>
                </ProductCard>
                <ProductCard width="355" isOpen={deleteOpen} setIsOpen={setDeleteOpen} title="Alterar nome da coleção" bottomLine transparent >
                    <S.ContainerInput>
                        <S.Text>
                            Tem certeza que deseja excluir a coleção Melhores lugares ?
                        </S.Text>
                    </S.ContainerInput>
                    <S.ButtonExcludeContainer>
                        <Button onClickButton={() => setDeleteOpen(false)} size="medium" widthSize={'98px'} title="Cancelar" type="secondary" />
                        <Button onClickButton={() => setDeleteOpen(false)} size="medium" widthSize={'144px'} title="Sim, excluir" type="confirm" />
                    </S.ButtonExcludeContainer>
                </ProductCard>
            </S.ContainerFavorites>
        </S.Container>}
    </>
    )
}