import { useState, useEffect } from 'react';
import * as S from './styles';
import ReactDOM from 'react-dom';


export interface AlertProps {
    /**
         * Define the title of the alert
         *
         * @default ''
          */
    title: string
    /**
     * Define your alert type
     *
     * @default SUCCESS
      */
    type: 'SUCCESS' | 'ERROR' | 'WARNING'
    /**
     * Boolean used to display the alert
     *
     *  @default false
      */
    active: boolean
}

/**
 * A React hook to handle alerts in your application.
 *
 * This hook uses the `AlertProps` to create an alert message and an Alert component.
 * The alert message is initially set by the `initialAlerts` parameter, and
 * can be updated using the `showAlert` function returned by the hook.
 *
 * The `Alert` component is a function that returns a styled Alert component,
 * which displays the alert message. The alert is displayed if the `active` property
 * of the alert is `true` and it automatically disappears after 3 seconds.
 *
 * @example
 * const { Alert, showAlert } = useAlert({ title: '', type: 'SUCCESS', active: false });
 *
 * // Use showAlert to display an alert.
 * showAlert({ title: 'Error fetching data', type: 'ERROR', active: true });
 *
 * // Use Alert in your JSX.
 * return (
 *     <div>
 *         {\/* Other components *\/}
 *         <Alert />
 *     </div>
 * );
 *
 * @param initialAlerts - An object that specifies the initial state of the alert. It includes the `title`, `type`, and `active` status of the alert.
 * @returns An object with the `Alert` component and `showAlert` function.
 */

const useAlert = (initialAlerts: AlertProps) => {
    const [visible, setVisible] = useState(false);
    const [alerts, setAlerts] = useState(initialAlerts);

    const iconClose = require('assets/svg/CloseAlert.svg').default
    const iconChecked = require('assets/svg/alert/CheckAlert.svg').default
    const iconError = require('assets/svg/alert/ErrorAlert.svg').default
    const iconWarning = require('assets/svg/alert/WarningAlert.svg').default

    const typeAlert = (type: string) => {
        if (type === 'SUCCESS') {
            return iconChecked
        }
        if (type === 'ERROR') {
            return iconError
        }
        if (type === 'WARNING') {
            return iconWarning
        } else {
            return null
        }
    }

    useEffect(() => {
        if (alerts.active) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
            }, 3000);
        }
    }, [alerts]);

    const showAlert = (newAlerts: AlertProps) => {
        setAlerts({ ...newAlerts, active: true });
    };

    const CloseIcon = () => <img src={iconClose} alt='' />

    const Alert = () => {
        const alertRoot = document.getElementById('alert-root');

        return alertRoot && visible
            ? ReactDOM.createPortal(
                (
                    <S.Container type={alerts.type}>
                        <S.Content>
                            <S.Row>
                                <img src={typeAlert(alerts.type)} alt="" />
                                <p>{alerts.title}</p>
                            </S.Row>
                        </S.Content>
                        <S.ClosedButton>
                            <CloseIcon />
                        </S.ClosedButton>
                    </S.Container>
                ),
                alertRoot
            )
            : null;
    };

    return { Alert, showAlert };
};

export default useAlert;
