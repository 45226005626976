import { ReserveDetails } from 'pages/Payment/components/PaymentCard/types';
import { AccommodationExpenses } from 'pages/Product/components/SessionItens';
import React, { createContext, useContext, useState } from 'react';

export type CreditCard = {
  token: string;
  brand: string;
  lastNumbers: string;
  cardId: string;
  validated: boolean;
}


export type PaymentContextType = {
  user: string;
  email: string;
  token: string;
  userImage?: string;
  creditCard: CreditCard;
  setUser: (newState: string) => void;
  setEmail: (newState: string) => void;
  setToken: (newState: string) => void;
  setUserImage?: (newState: string) => void;
  setCreditCard: (newState: CreditCard) => void;
  isLoading: boolean;
  setIsLoading: (newState: boolean) => void;
  expensesValues: AccommodationExpenses;
  setExpensesValues: React.Dispatch<React.SetStateAction<AccommodationExpenses>>;
  totalValue: number;
  setTotalValue: (newState: number) => void;
  creditCardToken: string;
  setCreditCardToken: (newState: string) => void;
  reserveDetails: ReserveDetails;
  setReserveDetails: (newState: ReserveDetails) => void;
};


export const PaymentContext = createContext<PaymentContextType | null>(null);

export const PaymentProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [user, setUser] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');
  const [userImage, setUserImage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expensesValues, setExpensesValues] = useState<AccommodationExpenses>({
    id: '',
    uniqueValue: 0,
    condominiumValue: 0,
    iptuValue: 0,
    cleaningValue: 0,
    yoghaPackageTax: 0,
    monthlyValue: 0,
    definedDailyValue: 0,
    midStay: 0,
    longStay: 0
  });
  const [totalValue, setTotalValue] = useState<number>(0);
  const [reserveDetails, setReserveDetails] = useState<ReserveDetails>({
    id: '',
    accommodationName: '',
    address: {
      city: '',
      area: '',
      stateCode: '',
      street: ''
    },
    mainImage: '',
    checkIn: '',
    checkOut: ''
  });
  const [creditCardToken, setCreditCardToken] = useState<string>('');
  const [creditCard, setCreditCard] = useState<CreditCard>({
    token: '',
    brand: '',
    lastNumbers: '',
    cardId: '',
    validated: false
  });

  const contextValue: PaymentContextType = {
    user,
    setUser,
    email,
    setEmail,
    token,
    setToken,
    userImage,
    setUserImage,
    isLoading,
    setIsLoading,
    expensesValues,
    setExpensesValues,
    totalValue,
    setTotalValue,
    creditCardToken,
    setCreditCardToken,
    creditCard,
    setCreditCard,
    reserveDetails,
    setReserveDetails
  };

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentProvider = (): PaymentContextType => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePaymentProvider must be used within a UserProvider');
  }
  return context;
};
