import { useEffect, useState } from 'react';

// Custom Hook para verificar se o usuário está usando um dispositivo móvel
const useIsMobile = (): boolean => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        // Função para detectar se o dispositivo é móvel
        const checkIsMobile = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            const mobileDevices = ['android', 'iphone', 'symbian', 'windows phone', 'ipad', 'ipod'];

            setIsMobile(mobileDevices.some(device => userAgent.includes(device)));
        };

        // Verificar imediatamente ao carregar o componente
        checkIsMobile();

        // Adicionar listener para o evento de redimensionamento da janela
        window.addEventListener('resize', checkIsMobile);

        // Limpar o listener quando o componente for desmontado
        return () => {
            window.removeEventListener('resize', checkIsMobile);
        };
    }, []);

    return isMobile;
};

export default useIsMobile;
