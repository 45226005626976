import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import { useUserProvider } from '../../hooks/userContext'
import { SpaceCard, SpaceCardProps } from './components/SpaceCard'
import * as S from './styles'
import { Footer } from '../../components/Footer';
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import fetchAxios, { fetchAxiosPayment } from 'services/http';
import { ProductCard } from 'components/ProductCard';
import useAlert from 'components/Notification';
import { MobileNavigation } from 'components/MobileNavigation';
import { useMobileNavigation } from 'components/MobileNavigation/useMobileNavigation';
import { useScreenWidth } from 'utils/useScreenWidth';

export const SpaceUser: React.FC = () => {

    document.title = 'Minha conta | Yogha'

    const [visibleNavigation, setVisibleNavigation] = useState<boolean>(true);

    const { pathname } = useLocation();
    const breakpoint = useScreenWidth()

    const { userName, userAvatar, token, customer, setUserAvatar } = useUserProvider();

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const validBreakpoint = () => {
        const regex = /minha-conta\//; // Note que estamos usando uma barra normal aqui
        if (breakpoint && regex.test(pathname)) {
            return true;
        } else {
            return false;
        }
    };

    const cameraIcon = require('assets/svg/spaceUser/Camera.svg').default;
    const arrowBack = require('assets/svg/LeftArrow.svg').default;
    const trashIcon = require('assets/svg/spaceUser/TrashRed.svg').default;
    const imageIcone = require('assets/svg/spaceUser/Image.svg').default;

    const inputRef = useRef<HTMLInputElement>(null);

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            if (!file.type.match('image/jpeg') && !file.type.match('image/jpg')) {
                showAlert({ active: true, title: 'Tipo de arquivo inválido. Por favor, selecione uma imagem JPEG ou JPG.', type: 'ERROR' });
                setIsOpen(false);
            } else {
                setSelectedFile(file);
                await onFileUpload(file);
            }
        }
    };

    const checkImage = () => {
        if (userAvatar.length > 0 && inputRef) {
            setIsOpen(true);
        } else {
            inputRef.current?.click();
        }
    }

    const onFileUpload = async (file: File) => {
        const formData = new FormData();
        formData.append('avatar', file);
        formData.append('token', '123');
        formData.append('id', customer);

        try {
            const data = await fetchAxiosPayment.post('/edit-customer', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setUserAvatar(data.data.link)
            setIsOpen(false);
            showAlert({ active: true, title: 'Imagem alterada com sucesso', type: 'SUCCESS' })
        } catch (error) {
            showAlert({ active: true, title: 'Erro ao alterar imagem', type: 'ERROR' })
            setIsOpen(false);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = async () => {

        };
    };

    const handleRemovePhoto = async () => {
        try {
            const data = await fetchAxios.get('users/deleteImg',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
            setUserAvatar('')
            setIsOpen(false);
            showAlert({ active: true, title: 'Imagem removida com sucesso', type: 'SUCCESS' })
        } catch (error) {
            showAlert({ active: true, title: 'Erro ao remover imagem', type: 'ERROR' })
            setIsOpen(false);
        }
    }

    const navigate = useNavigate();

    const location = useLocation();

    const isUserPage = location.pathname === '/minha-conta';

    const Logo = require('assets/image/logo.png')

    const cardObject: SpaceCardProps[] = [
        {
            title: 'Minhas reservas',
            description: 'Veja todas as suas reservas passadas e futuras e gerencie facilmente sua agenda de viagens.',
            icon: require('assets/svg/spaceUser/CalendarCheck.svg').default,
            route: '/minha-conta/reservas/',
        },
        {
            title: 'Informações Pessoais',
            description: 'Gerencie suas informações pessoais aqui. Adicione ou atualize seus dados de contato',
            icon: require('assets/svg/spaceUser/IdentificationCard.svg').default,
            route: '/minha-conta/informacoes/',
        },
        {
            title: 'Segurança e senhas',
            description: 'Gerencie suas informações pessoais aqui. Adicione ou atualize seus dados de contato',
            icon: require('assets/svg/spaceUser/Lock.svg').default,
            route: '/minha-conta/alterar-senha/',
        }, {
            title: 'Informações de pagamento',
            description: 'Adicione, atualize ou remova suas opções de pagamento.',
            icon: require('assets/svg/spaceUser/CreditCard.svg').default,
            route: '/minha-conta/informacoes-de-pagamento/',
        }
        /* , {
            title: 'Favoritos',
            description: 'Acesse rapidamente seus lugares favoritos e Nunca perca aqueles lugares que você ama.',
            icon: require('assets/svg/spaceUser/Star.svg').default,
            route: '/minha-conta/favorites',
        } */
    ]

    const avatarDefault = require('assets/svg/AvatarDefault.svg').default;

    const { elementLoaded } = useMobileNavigation({ isLoadingOrIsFetching: false, setVisibleNavigation });

    const elementScrollable = useRef(null);

    useEffect(() => {
        if (elementScrollable.current) {
            elementLoaded(elementScrollable.current);  // Passa o elemento para elementLoaded
        }
    }, []);

    return (
        <S.Container ref={elementScrollable}>
            <MobileNavigation isLoadingOrIsFetching={false} />
            <S.Header>
                <S.HeaderContent>
                    <S.BackArrow src={arrowBack} alt="" onClick={() => navigate(-1)} />
                    <S.Logo onClick={() => navigate('/')} src={Logo} alt="" />
                </S.HeaderContent>
            </S.Header>
            <S.Content>
                {isUserPage ?
                    <>
                        <S.UserContainer>
                            <S.UserImage>
                                <img src={userAvatar.length > 0 ? userAvatar : avatarDefault} alt="" />
                                <S.ContainerInputPhoto>
                                    <label onClick={checkImage}><img src={cameraIcon} alt="" /></label>
                                    <input ref={inputRef} accept=".jpeg, .jpg" type="file" onChange={onFileChange} />
                                </S.ContainerInputPhoto>
                            </S.UserImage>
                            <S.UserName>
                                <h1>Olá,</h1>
                                <h1>{userName}</h1>
                            </S.UserName>
                        </S.UserContainer>

                        <S.UserActions>
                            <h2>Minha conta</h2>
                            <S.GridCard>
                                {cardObject.map((card, index) => (
                                    <SpaceCard key={card.title + index} {...card} />
                                ))}
                            </S.GridCard>
                        </S.UserActions>
                        {/* <MobileNavigation /> */}
                    </> : <Outlet />}
            </S.Content>
            {validBreakpoint() ? null : <Footer />}
            <ProductCard bottomLine isOpen={isOpen} setIsOpen={setIsOpen} title='Foto de perfil' >
                <S.ButtonAddPhoto>
                    <label htmlFor="file"><img src={imageIcone} alt="" />Escolhar na biblioteca</label>
                    <input id='file' type="file" onChange={onFileChange} />
                </S.ButtonAddPhoto>
                <S.ButtonDeletePhoto>
                    <label onClick={handleRemovePhoto} htmlFor=""><img src={trashIcon} alt="" /> Remover foto atual</label>
                </S.ButtonDeletePhoto>
            </ProductCard>
            <Alert />
        </S.Container>
    )
}