import Skeleton from 'react-loading-skeleton';
import * as S from './styles';

export type AmenitiesProps = {
  maxGuests?: number;
  beds?: number;
  baths?: number;
  rooms?: number;
  pet?: boolean;
  isLoading?: boolean;
  garage?: boolean;
  meters?: number;
};

export const Amenities = ({ garage, meters, ...amenities }: AmenitiesProps) => {
  const arr: any = [
    {
      id: 1,
      icon: require('assets/svg/amenities/Users.svg').default,
      number: amenities.maxGuests,
      type: 'pessoas'
    },
    {
      id: 2,
      icon: require('assets/svg/amenities/Bed.svg').default,
      number: amenities.beds,
      type: amenities.beds && amenities.beds > 1 ? 'camas' : 'cama'
    },
    {
      id: 3,
      icon: require('assets/svg/amenities/Shower.svg').default,
      number: amenities.baths === 0 ? null : amenities.baths,
      type: amenities.baths && amenities.baths > 1 ? 'banheiros' : amenities.baths === 0 ? 'Sem banheiro' : 'banheiro'
    },
    {
      id: 4,
      icon: require('assets/svg/amenities/Car.svg').default,
      number: garage ? '1' : 'Sem',
      type: 'vaga'
    },
    {
      id: 5,
      icon: require('assets/svg/amenities/Ruler.svg').default,
      number: meters,
      type: 'm²'
    },
    {
      id: 6,
      icon: require('assets/svg/amenities/PawPrint.svg').default,
      number: '',
      type: 'Não aceita'
    }
  ];

  return (
    <>
      {amenities.isLoading ? (
        <S.Container size={6}>
          {Array.from({ length: 6 }).map((_, index: number) => (
            <Skeleton key={index} borderRadius={8} width={651 / 3} height={58} />
          ))}
        </S.Container>
      ) : (
        <S.Container size={arr.length}>
          {arr.map((item: any) => {
            return (
              <S.Amenity key={item.id}>
                <img src={item.icon} alt="" />
                <S.AmenityText>
                  {' '}
                  <p>{item.number}</p> <p> {item.type}</p>
                </S.AmenityText>
              </S.Amenity>
            );
          })}
        </S.Container>
      )}
    </>
  );
};
