import styled from "styled-components";
import { FONT } from "../../constants/fontConstants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100vh;

    align-items: center;
    justify-content: start;

`;

interface SendEmailProps {
    sendEmail: boolean;
}

export const Content = styled.div<SendEmailProps>`
    display: flex;
    flex-direction: column;

    margin-top: 64px;

    row-gap: 32px;

    max-width: ${({sendEmail}) => sendEmail ? '319px' : '302px'};
    width: 100%;

    align-items: ${({sendEmail}) => sendEmail && 'center' };
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    align-items: center;
    justify-content: center;
`;

export const Title = styled.h1`
    ${FONT.HEADLINES.H5}
`;

export const SendEmailText = styled.h2`
    ${FONT.BODY.BODY_LARGE[600]}
`;

export const Subtitle = styled.h2`
    ${FONT.BODY.BODY_MEDIUM[400]}
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 8px;
`;

export const SendEmailContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 16px;
    row-gap: 16px;

    align-items: center;
    justify-content: center;

    text-align: center;

    width: 100%;
`;
