import { useEffect, useState } from "react";
import { AddAccompanyingProps } from "./types";
import { useSessionStorage } from "context/sessionStorage";
import { useNavigate } from "react-router-dom";

export const useAccompanying = () => {
    const [maxAccompanying, setMaxAccompanying] = useState(0);

    const [users, setUsers] = useState<AddAccompanyingProps[]>([])

    const pathnameActual: string = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));

    const { getFromSessionStorage, saveToSessionStorage } = useSessionStorage()

    useEffect(() => {
        const maxAccompanyingData = getFromSessionStorage('checkin-details')
        const data = getFromSessionStorage('accompanying')
        if (data) {
            setUsers(data)
        }
        if (maxAccompanyingData) {
            setMaxAccompanying(Number(maxAccompanyingData.max_accompanying))
        }
    }, [])

    const navigate = useNavigate();

    const handleNextStep = () => {
        navigate(`${pathnameActual}/arrival-and-departure`,{replace: true})
    }

    const handleBackStep = () => {
        navigate(`${pathnameActual}/selfie-validation`)
    }

    const handleAddAccompanying = () => {
        if (maxAccompanying > users.length) {
            navigate(`${pathnameActual}/add-accompanying`,{replace: true})
        }
    }

    const handleDeleteAccompanying = (index: number) => {
        const newUsers = users.filter((user, i) => i !== index)
        setUsers(newUsers)
        saveToSessionStorage('accompanying', newUsers)
    }

    const handleEditAccompanying = (index: number) => {
        navigate(`${pathnameActual}/edit-accompanying/${index + 1}`)
    }

    return{
        maxAccompanying,
        users,
        handleNextStep,
        handleBackStep,
        handleAddAccompanying,
        handleDeleteAccompanying,
        handleEditAccompanying
    }
}