import { useEffect, useState } from 'react';
import * as S from './styles';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { HeaderAlt } from '../../components/HeaderAlt';
import { useNavigate } from 'react-router-dom';
import fetchAxios from 'services/http';
import { useLocation } from 'react-router-dom';
import useAlert from 'components/Notification';

export const NewPassword = () => {

    const navigate = useNavigate();

    const [passwordRecover, setPasswordRecover] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [erroPasswordRecover, setErroPasswordRecover] = useState<string>('');
    const [erroPasswordConfirm, setErroPasswordConfirm] = useState<string>('');
    const { Alert, showAlert } = useAlert({ type: 'ERROR', title: 'Erro', active: false })

    const pathname = useLocation().pathname
    const userId = pathname.split('/')[3]
    const token = pathname.split('/')[2]


    const changePassword = async () => {
        try {
            const { data } = await fetchAxios.post(`/users/verifyToken/${userId}`, {
                tokenPassword: token
            })
            if (data.code === 200) {
                setIsValid(true)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        changePassword()
    }, [])

    const [isValid, setIsValid] = useState<boolean>(false);

    const handleNewPassword = async () => {
        if (passwordRecover === '') {
            setErroPasswordRecover('Digite a sua nova senha.');
            return
        }
        if (passwordConfirm === '') {
            setErroPasswordConfirm('Digite a confirmação da sua nova senha.');
            return
        }
        if (passwordRecover !== passwordConfirm) {
            setErroPasswordConfirm('As senhas não são iguais. Tente novamente.');
            return
        }
        try {
            const data = await fetchAxios.post(`users/newPassword/${userId}`, {
                tokenPassword: token,
                password: passwordConfirm,
            })
            showAlert({ type: 'SUCCESS', active: true, title: 'Senha alterada com sucesso!' })
            navigate('/login')

        } catch (error) {
            console.error(error)
            showAlert({ type: 'ERROR', active: true, title: 'Erro ao alterar a senha!' })
        }
    }

    return (
        <S.Container>
            <Alert />
            <HeaderAlt />
            <S.Content isValid={isValid}>
                {isValid ?
                    <>
                        <S.Header>
                            <S.Title>Criar nova senha</S.Title>
                        </S.Header>
                        <S.InputContainer>
                            <Input htmlFor="password" onChange={(e) => setPasswordRecover(e.currentTarget.value)} placeholder="Nova senha" title="Nova senha" type="password" value={passwordRecover} showPassword errorText={erroPasswordRecover} width={'100%'} />
                            <Input htmlFor="password" onChange={(e) => setPasswordConfirm(e.currentTarget.value)} placeholder="Confirmar senha" showPassword title="Confirmar senha" type="password" value={passwordConfirm} errorText={erroPasswordConfirm} width={'100%'} />
                        </S.InputContainer>
                        <Button disabled={false} icon="" onClickButton={handleNewPassword} size="medium" title="Confirmar alteração" type="primary" widthSize={'302px'} />
                    </>
                    :
                    <>
                        <S.Header>
                            <S.Title>Este link expirou</S.Title>
                            <S.Subtitle>Você precisa solicitar o link novamente</S.Subtitle>
                        </S.Header>
                        <Button disabled={false} icon='' onClickButton={() => navigate('/forgotpassword')} size='medium' title='Solicitar um link novo' type='primary' widthSize={'256px'} />

                    </>
                }
            </S.Content>
        </S.Container>

    );
}