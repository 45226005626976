import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled, { css } from "styled-components";

export const gridStyles = (size:number) => {
    switch (size) {
      case 3:
        return css`
        .grid0 {
            grid-column-start: 1;
            grid-column-end: 2;

            grid-row-start: 1;
            grid-row-end: 3;

            width: 199px;
            height: 100%;

            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

        }

        .grid1 {
            grid-column-start: 2;
            grid-column-end: 3;

            grid-row-start: 1;
            grid-row-end: 2;

            width: 87px;
            height: 100%;

            border-top-right-radius: 10px;
        }

        .grid2 {
            grid-column-start: 2;
            grid-column-end: 3;

            grid-row-start: 2;
            grid-row-end: 3;

            width: 87px;
            height: 100%;

            border-bottom-right-radius: 10px;
        }
        `;
      case 2:
        return css`.grid0 {
            grid-column-start: 1;
            grid-column-end: 2;

            grid-row-start: 1;
            grid-row-end: 3;

            width: 145px;
            height: 100%;

            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

        }

        .grid1 {
            grid-column-start: 2;
            grid-column-end: 3;

            grid-row-start: 1;
            grid-row-end: 3;

            width: 145px;
            height: 100%;

            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }`;
      case 1:
        return css`
            .grid0 {
      grid-column: 1 / 3;
      grid-row: 1 / 3;

      width: 100%;
      height: 100%;

        border-radius: 10px;
    }
        `;
      default:
        return css``;
    }
  };

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    max-width: 290px;
    width: 100%;

    row-gap: 8px;
`;

interface GridContainerProps {
    size: number;
}

export const GridContainer = styled.div<GridContainerProps>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    width: 100%;
    max-height: 199px;
    height: 100%;

    gap: 8px;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${({size}) => gridStyles(size)}
`;

export const RowText = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 4px;
`;

export const Title = styled.h1`
    ${FONT.BODY.BODY_MEDIUM[500]};
`;

export const Subtitle = styled.h2`
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${NEUTRAL_COLORS.DARK_GRAY};
`