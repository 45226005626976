import { Button } from '../Button';
import { Reserve } from './Reserve';
import { Post } from './Post';
import { Default } from './Default';
import { Icons } from '../Icons';
import { GlobalContainer, LinkContainer, LinkText } from './styles';
import { BlogCard, CardKeys, CardProps, LinkCard, PostCard } from './types';
import { Blog } from './Blog';
import { ReservesProps } from 'pages/SpaceUser/pages/Reservations';




export const Link: React.FC<LinkCard> = ({ ...card }) => {

  return (
    <GlobalContainer>
      <>
        <LinkContainer size={card.size}>
          <Icons icon={card.image} width={80} height={80} />
          <LinkText>{card.linkText}</LinkText>
          <div style={{ marginTop: '2px', marginBottom: '-20px', width: '90%' }}>
            <Button onClickButton={card.onClick} title={card.buttonText as string} icon={''} size='small' widthSize={"100%"} disabled={false} type='primary' />
          </div>
        </LinkContainer>
      </>
    </GlobalContainer>
  )

}

type CardPropsByType = {
  Blog: BlogCard;
  Default: CardProps;
  Link: LinkCard;
  Post: PostCard;
  Reserve: ReservesProps;
};

export function getCardItem<T extends CardKeys>(cardType: T, cardProps: CardPropsByType[T]) {
  const Card = {
    Blog,
    Default,
    Link,
    Post,
    Reserve
  };

  const CardComponent = Card[cardType];

  return <CardComponent {...(cardProps as any)} />;

}


export const Card = {
  Blog,
  Default,
  Link,
  Post,
  Reserve
}