import { Card } from "components/Card";
import { ButtonSeeMore, GridContainer } from "pages/Blog/SessionArticles/styles";
import * as S from "./styles"
import { FilterCategory } from "pages/Blog/components/FilterCategory"
import { useEffect, useState } from "react"
import { BlogCard } from "components/Card/types";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchAxiosBlog } from "services/http";
import { parseISO } from "date-fns";
import { readingTime, transformSections } from "utils/readingTime";
import { SlideBlogProps } from "pages/Blog/components/SlideBlog";
import { useContainer } from "hooks/useBlogRef";

export const Search = () => {

    document.title = "Todos os artigos | Blog da Yogha";

    const [search, setSearch] = useState('');
    const [articlesType, setArticlesTypes] = useState(0)
    const [limit, setLimit] = useState(6)
    const [articlesCards, setArticlesCards] = useState<any[]>([])

    const navigate = useNavigate()

    const { current } = useContainer();

    const scrollToTop = () => {
        const container = current;
        if (container) {
            container
                .scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
        };
    };

    const arrowDown = require('assets/svg/buttons/ArrowDownOrange.svg').default

    useEffect(() => {

        const filterArticles = async () => {
            if (articlesType <= 0) {
                try {
                    const { data } = await fetchAxiosBlog.get(`api/articles?&populate=deep`, {
                    })
                    const sortedData = data.data.sort((a: SlideBlogProps, b: SlideBlogProps) => {
                        const dateA = parseISO(a.attributes.datePublication);
                        const dateB = parseISO(b.attributes.datePublication);
                        return dateB.getTime() - dateA.getTime();
                    });
                    const sortedDataWithReadingTime = sortedData.map((obj: SlideBlogProps) => {
                        const sectionString = transformSections([obj])[0]; // Transforma a seção em string
                        const readTime = readingTime(sectionString); // Calcula o tempo de leitura
                        return {
                            ...obj,
                            readingTime: readTime, // Adiciona a chave 'readingTime' ao objeto
                        };
                    });
                    setArticlesCards(sortedDataWithReadingTime);
                } catch (error) {
                }
            } else {
                try {
                    const { data } = await fetchAxiosBlog.get(`api/articles?filters[category][id][$eq]=${articlesType}&populate=deep,3`, {
                    })
                    const sortedData = data.data.sort((a: SlideBlogProps, b: SlideBlogProps) => {
                        const dateA = parseISO(a.attributes.datePublication);
                        const dateB = parseISO(b.attributes.datePublication);
                        return dateB.getTime() - dateA.getTime();
                    });
                    const sortedDataWithReadingTime = sortedData.map((obj: SlideBlogProps) => {
                        const sectionString = transformSections([obj])[0];
                        const readTime = readingTime(sectionString);
                        return {
                            ...obj,
                            readingTime: readTime,
                        };
                    });
                    setArticlesCards(sortedDataWithReadingTime)
                } catch (error) {
                }
            }


        }

        filterArticles()
        scrollToTop()
    }, [articlesType])

    const getMoreArticles = () => {
        const newLimite = limit + 9
        setLimit(newLimite)
    }

    const location = useLocation();

    const isSearching = location.pathname === '/pesquisar/?q=' ? true : false

    const imageUrl = 'https://blog.yogha.com.br'

    return (
        <>
            {isSearching ?
                null
                :
                <S.Container>
                    <S.Title>Últimos artigos publicados</S.Title>
                    <S.Content>
                        <FilterCategory articles={articlesType} setArticles={setArticlesTypes} />
                        <GridContainer>
                            {articlesCards.slice(0, limit).map((article, index) => (
                                <Card.Blog key={index} category={article?.attributes.category.data.attributes.title} createdAt={article?.attributes.datePublication} description={article?.attributes.title} id={article?.attributes.id} image={imageUrl + article?.attributes.thumbnail.data.attributes.url} readingTime={article?.readingTime} onClick={() => navigate('/blog/article/' + article?.attributes.slug)} />
                            ))}
                        </GridContainer>
                    </S.Content>
                    {limit >= articlesCards.length ? null : <ButtonSeeMore onClick={getMoreArticles}><p>Carregar mais</p> <img src={arrowDown} alt="" /></ButtonSeeMore>}
                </S.Container>}</>

    )
}