import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

const teste = require("assets/image/developers/background/backteste.png");
const background2560 = require("assets/image/developers/background/Background1x2560x1080.png");
const backgroundMobile = require("assets/image/developers/background/BackgroundMobile.jpg");
const background1920 = require("assets/image/developers/background/Background1x1920x1080.png");
const background1280 = require("assets/image/developers/background/Background1x1280x720.png");
const effectBackground = require("assets/image/developers/backgroundEffect.png");

export const MobileContainer = styled.div`
    display: flex;
    position: relative;

    width: calc(100vw - 6px);
    background-repeat: space;
    background-size: cover;
    background-position: center;
    background-image: url(${backgroundMobile});
    min-height: 637px;

    align-items: center;
    justify-content: center;
    padding-bottom: 64px;



`;

export const CollumText = styled.div`
    display: flex;

    position: absolute;
    bottom: 0;
    left: 0;

    background-image: linear-gradient(
        to bottom,
        #00000000 0%,
        #191919 100%
    );

    min-height: 383px;

    align-items: center;
    justify-content: center;

    width: calc(100% - 40px);
    padding: 0 20px;
`;

export const Container = styled.div `
    display: grid;
    min-height: 85dvh;
    width: 100%;
    background-repeat: space;
    background-size: cover;
    background-position: center;
    background-image: url(${background1280});

    align-items: center;
    justify-content: center;

    @media (min-width: 1920px) {
        background-image: url(${teste});
        background-position: bottom;
        /* min-height: 95dvh; */
    }

    @media (max-width: 1920px) {
        background-image: url(${background2560});
        background-position: top;
    }
    @media (max-width: 1280px) {
        background-image: url(${teste});
        background-position: top;
    }
`;

export const Content = styled.div`
    display: grid;

    grid-template-columns: 1fr 1fr;

    max-width: 1366px;
    width: 100%;
    height: 100%;

    justify-content: start;
    align-items: center;
`;

export const CollumLeft = styled.div`
    display: flex;
    flex-direction: column;

    margin-right: 64px;

    @media (max-width: 768px) {
        width: calc(100%);
        margin-right: 0px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: start;

    max-width: 500px;

    row-gap: 8px;

    h1{
        ${FONT.HEADLINES.H4};
        color:${NEUTRAL_COLORS.WHITE};
        padding-right: 10px;
    }
    p{
        color:${NEUTRAL_COLORS.WHITE};
        ${FONT.BODY.BODY_MEDIUM[400]};
        max-width: 430px;
    }

    margin-block: 16px 54px;

    @media (max-width: 768px) {
        width: 100%;

        max-width: unset;

        h1{
            ${FONT.BODY.BODY_LARGE[600]};
            padding-right: 0px;

        }
        p{
            ${FONT.BODY.BODY_MEDIUM[400]};
            max-width: unset;
        }

        margin-block: 16px 0px;
        margin-bottom:48px;
    }
`;

export const Logo = styled.div`
    display: flex;

    justify-content: start;
    align-items: center;
    h3{
        margin-top: -10px;
        ${FONT.HEADLINES.H2};
        color:${NEUTRAL_COLORS.WHITE};
        font-weight:400;
    }
    gap: 8px;

    @media (max-width: 768px) {
        h3{
            ${FONT.HEADLINES.H4};
        font-weight:400;
        }
    }
`;

export const Hero = styled.div`
    border: 2px solid white;
    height: 310px;
    width: 510px;
    `;

export const HeroEffect = styled(Hero)`
    z-index: 1;
    background: transparent;
    backdrop-filter: unset;
    background: url(${effectBackground}) repeat center;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: repeat;
    opacity: 0.25;
    position: absolute;
`;

export const Building = styled.div`
`;

export const HeroContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 82px;

    max-width: 1366px;
    width: 100%;
    height: 100%;

`;