import styled from "styled-components";


export const ResultRow = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-start;
width: 100%;
@media (max-width: 850px) {
    justify-content: flex-start;
    align-items: flex-start;
}
`;