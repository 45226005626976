import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";
import { ButtonGradient } from "../ButtonGradient";

const background = require("assets/image/owner/backgroundmarks.png");

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 1366px;

    margin: 0 auto;

    align-items: center;

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    @media (max-width: 768px) {
    max-width: calc(100% - 40px);
    padding: 0px 20px;
    }
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 750px;

    text-align: center;

    margin-block: 80px;

    row-gap: 16px;

    h1{
    ${FONT.HEADLINES.H3};
    color: ${NEUTRAL_COLORS.HIGH_DARK};}
    span{
        color:${PRIMARY_COLORS.MEDIUM}
    } ;

    @media (max-width: 768px) {

    max-width: unset;

    h1{
            ${FONT.BODY.BODY_LARGE[600]};
    }
    }
`;

export const CardServices = styled.div `
    display: flex;
    flex-direction: column;

    gap:16px;

    padding: 16px;

    width: 244px;
    height: 228px;

    text-align: center;

    align-items: center;

    border-radius: 16px;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.10);

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    @media (max-width: 768px) {
        width: calc(100% - 32px);
    }

`;

export const CardServicesContainer = styled.div `
    display: flex;

    gap: 32px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
    }
`;

export const InfoManagement = styled.div`
    display: grid;

    grid-template-columns: 584px 425px;
    place-content: center;
    align-items: center start;

    width: 100%;

    span{
        max-width: 397px;
    }

    gap: 32px;

    img{
        width: 584px;
        height: 338px;

        border-radius: 16px;
        object-fit: cover;
    }

    h5{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: ${PRIMARY_COLORS.MEDIUM};
    }
    span{
        ${FONT.HEADLINES.H5};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    strong{
        color: ${PRIMARY_COLORS.MEDIUM};
    }

    button{
        p{
            ${FONT.BODY.BODY_MEDIUM[600]};
        }
    }

    margin-block:192px;

    @media (max-width: 768px) {
    grid-template-columns: 1fr;
        img{
            width: 100%;
            height: 211px;
            object-fit: cover;
        }
        span{
        max-width: unset;
    }
    margin-block: 80px;
    }
`;

export const InfoManagementCollumnText = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    text-align: left;
    h4{
        ${FONT.BODY.BODY_MEDIUM[400]};
    }

    strong{
        ${FONT.HEADLINES.H5};
    }

    @media (max-width: 768px) {
    gap: 8px;
        /* padding-top: 32px; */
        strong{
            margin-bottom: 8px;
        }
        h4{
            margin-bottom: 24px;
        }

    }
`;

export const CardInformation = styled.div`
    display: flex;
    flex-direction: column;

    width: calc(485px - 70px);
    height: calc(230px - 62px);

    padding: 31px 30px 31px 39px;

    align-items: flex-start;
    gap: 20px;

    text-align: left;

    border-radius: 16px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

    @media (max-width: 768px) {
        width: calc(100% - 64px);
        height: calc(253px - 62px);

    }
`;

export const CardInformationRow = styled.div`
    display: flex;

    gap: 20px;

    span{
        ${FONT.BODY.BODY_LARGE[600]};
    }

    align-items: flex-end;
`;

export const CardInformationContainer = styled.div`
    display:grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 30px;

    @media (max-width: 768px) {
        grid-template-columns: auto;
        grid-template-rows: auto;
    }
`;

export const ContainerPubs = styled.div`
    display: grid;
    grid-template-columns: fit-content(100%) ;
    grid-template-rows: repeat(2, fit-content(100%));

    width: calc(1206px - 380px);
    height: 579px;

    gap: 88px;

    place-items: center;
    place-content: center;

    text-align: center;

    padding: 0px 190px;

    margin-top: 160px;

    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;
    border-radius: 64px;

    h1{
        ${FONT.HEADLINES.H4};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    @media (max-width: 768px) {
        width: calc(100% - 16px);

        margin-top: 80px;

        padding: 64px 28px;

        border-radius: 0px;
        background-position: center;
        height: max-content;

        place-content: center;

        h1{
            ${FONT.BODY.BODY_LARGE[600]};
        }

        gap: 32px;
    }

`;

export const Pubs = styled.div`
    display: flex;

    gap:8px;

    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 24px;
    }
`;

export const PubsCollumn = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 24px;

    @media (max-width: 768px) {
        flex-direction: row;
        gap: 8px
    }
`;

export const CardPubs = styled.div`
    display: flex;

    padding: 16px;

    width: fit-content;

    gap: 88px;

    p{
        color: ${NEUTRAL_COLORS.WHITE};
        white-space: nowrap;
    }


    border-radius: 108px;
    border: 1px solid ${NEUTRAL_COLORS.WHITE};
`;

export const Features = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    align-items: center;
    justify-content: center;

    gap: 32px;

    margin-bottom: 192px;

    @media (max-width: 768px) {
        width: calc(100% + 40px);

        margin-bottom: 80px;

        gap: 16px;
    }
`;

export const CardFeature = styled.div`
    display: flex;

    padding: 16px;

    gap:16px;

    max-width: 277px;
    width: 100%;

    align-items: center;

    border-radius: 8px;

    background: ${NEUTRAL_COLORS.WHITE};

    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.10);

    div{
        display: flex;

        background-color: ${PRIMARY_COLORS.MEDIUM};

        border-radius: 8px;

        align-items: center;
        justify-content: center;

        min-width: 71px ;
        min-height: 71px;
    }

    @media (max-width: 768px) {
        max-width: unset;
        width: calc(100% - 72px);
    }

`;

export const FeatureRow = styled.div`
    display: flex;

    gap: 32px;

    @media (max-width: 768px) {
        width: 100%;

        align-items: center;

        flex-direction: column;

        gap: 16px;
    }
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    align-items: center;

    margin-top: 180px;
    padding-bottom: 64px;

    background-color: ${NEUTRAL_COLORS.HIGH_DARK};

    @media (max-width: 768px) {
    margin-top: 0px;

    }
`;

export const FooterContent = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;

    max-width: 1366px;
    width: 100%;

    h1{
        margin-block: 120px 64px;
        ${FONT.HEADLINES.H4};
        color:${NEUTRAL_COLORS.WHITE}
    }
    span{
        color: ${PRIMARY_COLORS.MEDIUM};
    }

    @media (max-width: 768px) {
    max-width: unset;

    h1{
        ${FONT.BODY.BODY_LARGE[600]};
        margin-block: 64px 60px;
    }
    }
`;

export const InputContainer = styled.div`
    width: 380px;
    @media (max-width: 768px) {
        width: calc(100% + 40px) ;
    }
`;

export const FooterCollumn = styled.div`
    display: grid;
    grid-template-columns: auto auto;

    gap: 32px;

`;

export const HeaderCarousel = styled.div`
    display: flex;

    width: 558px;
    text-align: center;

    h1{
        ${FONT.HEADLINES.H3};
    }
    span{
        color: ${PRIMARY_COLORS.MEDIUM};
    }
    margin-bottom: 40px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);

        h1{
            ${FONT.BODY.BODY_LARGE[600]};
        }
    }
`;

export const Footerbaseboard = styled.ul`
    display: flex;
    flex-direction: column;

    margin-block: 32px 64px;

    margin-left: -64px;

    list-style: circle;
    color: ${NEUTRAL_COLORS.WHITE};

    li{
        list-style: disc;
        ${FONT.BODY.BODY_SMALL[400]};
    color: ${NEUTRAL_COLORS.WHITE};

    }

    span{
        color: ${PRIMARY_COLORS.MEDIUM};
    }

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        margin:0px;

        margin-right: -20px;
        margin-left: 12px;

        margin-block: 32px 64px;
    }

    `;

    export const ButtonContainer = styled.div`
    width: 278px;

    @media (max-width: 768px) {
        width: 100%;
    }
    `;