import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 290px;
`;

export const Slide = styled.img<{ active: boolean }>`
  position: absolute;
  width: 100%;
  height: 237px;
  object-fit: cover;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: opacity 0.5s;
`;

export const Button = styled.button`
  position: absolute;
  bottom: 10px;
  background-color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
`;

export const NextButton = styled(Button)`
  right: 10px;
`;

export const PrevButton = styled(Button)`
  left: 10px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: space-between;
    align-items: center;

    top: 30%;

    position: absolute;
    `;

export const TotalCount = styled.span`

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${NEUTRAL_COLORS.HIGH_DARK};
    border-radius: 16px;

    padding: 4px 8px;

    position: absolute;
    top: 10px;
    right: 10px;

    color: ${NEUTRAL_COLORS.LIGHT_GRAY};

    ${FONT.BODY.BODY_SMALL[500]}

    `;

export const AccommodationName = styled.h1`
  margin-top: 260px;
  position: relative;
  text-align: left;
  width: calc(100% - 40px);
  padding: 0px 20px;
  ${FONT.BODY.BODY_LARGE[600]};
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  bottom: 0px;
`;

export const GalleryContainer = styled.div`
  display: flex;
  position: fixed;

  flex-direction: column;

  top: 0;
  left: 0;

  min-width: 100vw;
  min-height: 100vh;

  background-color: ${NEUTRAL_COLORS.WHITE};

  z-index: 3000;
`;

export const GalleryHeader = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  padding: 20px;

  padding-top: 32px;
  padding-bottom: 28px;

  align-items: center;

  gap: 27px;

  p{
    ${FONT.BODY.BODY_LARGE[600]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
  }
`;

export const CloseButton = styled.button`

  background-color: transparent;
  border: none;

  padding: 10px 8px;
`;