export function formatDate(dataStr: string) {
    try {
      const dataObj = new Date(dataStr);

      const ano = dataObj.getUTCFullYear();
      const mes = String(dataObj.getUTCMonth() + 1).padStart(2, '0');
      const dia = String(dataObj.getUTCDate()).padStart(2, '0');

      // Formatar a data no padrão "YYYY-MM-DD"
      const dataFormatada = `${ano}-${mes}-${dia}`;
      return dataFormatada;
    } catch (error) {
      console.error("Erro: Formato de data inválido.");
      return null;
    }
  }