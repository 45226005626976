import styled from 'styled-components';
import { FONT } from 'constants/fontConstants';
import { NEUTRAL_COLORS } from 'constants/styleConstants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const Header = styled.header`
display: flex;

position: relative;

width: 100%;
height: 83px;

align-items: center;
justify-content: center;

background: transparent;
box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
`;

export const NavigateButton = styled.button`
  display: flex;

  position: absolute;

  width: 40px;
  height: 40px;

  border: none;

  background-color: transparent;

  align-items: center;
  justify-content: center;

  left: 32px;

  img{
    width: 18px;
    height: 18px;
  }

  justify-self: flex-start;
`;

export const LogoContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  img{
        width: 130px;
        height: 45px;
    }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 304px;
  height: 100%;

  row-gap: 16px;

  margin-top: 40px;
`;

export const TitleContainer = styled.div`
  display: flex;

  width: 320px;

  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  ${FONT.HEADLINES.H5}
`;

export const SocialContainer = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    width: 100%;

    align-items: center;
    justify-content: center;

`;

export const RegisterButton = styled.button`
    display: flex;
    flex-direction: row;

    width: 302px;
    height: 54px;

    border-radius: 8px;

    align-items: center;
    justify-content: center;

    border:1px solid ${NEUTRAL_COLORS.GRAY};

    background-color: ${NEUTRAL_COLORS.WHITE};

    gap: 8px;

    p{
        ${FONT.BODY.BODY_SMALL[400]}
    }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  row-gap:24px;
  `;

export const Separator = styled.div`
  display: flex;

  width: 100%;

  align-items: center;
  justify-content: center;

  gap: 8px;

  ::after{
    content: '';
    flex: 1;
    height: 1px;

    background: ${NEUTRAL_COLORS.GRAY};

  }

  ::before{
    content: '';
    flex: 1;
    height: 1px;

    background: ${NEUTRAL_COLORS.GRAY};

  }

`;

export const ForgotPassword = styled.div`
  display: flex;

  width: 100%;

  align-items: flex-start;
  justify-content: flex-start;

  margin-top: -16px;
`;