import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: -64px;
  margin-bottom: -64px;
  width: 100%;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(493px - 64px);
`;

export const Slide = styled.div<{ active: boolean }>`
  position: absolute;
  width: 100%;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: opacity 0.5s;
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
`;

export const SlideContent = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    width: 100%;
`;

export const SlideImage = styled.img`
    width: 100%;
    height: 212px;
    object-fit: cover;

    border-radius: 16px;
`;

export const SlideTextContent = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    gap: 8px;

    h2{
        ${FONT.CAPTION.CAPTION_SEMI_BOLD};
        color: ${PRIMARY_COLORS.MEDIUM};
    }
    h4{
        ${FONT.BODY.BODY_LARGE[600]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const FooterSlide = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    margin-top: 8px;

    gap: 10px;

    p{
      ${FONT.BODY.BODY_SMALL[400]};
    }

`;

export const Button = styled.button`
  position: absolute;
  bottom: 10px;
  background-color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
`;

export const NextButton = styled(Button)`
  right: 10px;
`;

export const PrevButton = styled(Button)`
  left: 10px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 54px;
    `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;