import { FONT } from "constants/fontConstants";
import styled from "styled-components";

export const ContainerInputs = styled.div`
    display: grid;
    grid-template-areas:
        "username surname"
        "email phone"
        "birthday identification";

    grid-template-columns: repeat(2, fit-content(100%));
    grid-template-rows: repeat(2, fit-content(100%));

    place-content: start start;
    place-items: start start;

    width: 100%;

    gap: 24px;

    & :has(#username) {
        grid-area: username;
    }

    & :has(#surname) {
        grid-area: surname;
    }

    & :has(#birthday) {
        grid-area: birthday;
    }

    & :has(#phone) {
        grid-area: phone;
    }

    & :has(#email) {
        grid-area: email;
    }

    & :has(#identification) {
        grid-area: identification;
    }

    @media (width < 768px) {
        grid-template-areas:
        "username"
        "surname"
        "email"
        "phone"
        "birthday"
        "identification";
        grid-template-columns: repeat(1, fit-content(100%));
        grid-template-rows: repeat(1, fit-content(100%));
        place-content: center ;
        place-items: center ;
    }
`;

export const TitlePage = styled.h1`
display: none;
@media (width < 768px) {
    display: block;
    ${FONT.BODY.BODY_LARGE[600]}
    }

`;


export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 158px;
    align-self: flex-start;
    @media (width < 768px) {
        width: 100%;
        align-self: inherit;
        padding-bottom: 160px;
    }
    `
