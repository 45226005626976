import { FONT } from "constants/fontConstants";
import { PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container  = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: start;

    max-width: 100svw;
    height: 100svh;

    overflow: auto;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: calc(1366px - (183px * 2));

    padding: 0px 183px;

    align-items: center;
    justify-content: center;

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: #000000;
    }

    margin-top: 64px;

    row-gap: 64px;

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        padding: 0px 16px;
    }
    `;

export const Row = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    justify-content: flex-start;

    row-gap: 16px;

    @media (max-width: 768px) {
        .title{
            ${FONT.BODY.BODY_LARGE[600]}
        }

        p{
            ${FONT.BODY.BODY_SMALL[500]}
        }
    }
`;

export const ScrollContainer = styled.div`
    display: flex;

    width: 100%;
    max-width: 1366px;

    justify-content: flex-end;
`;

export const EndText = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    align-items: center;
    justify-content: center;

    margin-top: 32px;
    margin-bottom: 6px;

    span{
        ${FONT.BODY.BODY_MEDIUM[600]};
        color: #000000;
    }
`;

export const ScrollToTopButton = styled.button`
    display: flex;

    align-self: flex-end;

    align-items: center;
    justify-content: center;

    min-width: 40px;
    min-height: 40px;

    border-radius: 8px;
    border: none;

    background-color: ${PRIMARY_COLORS.MEDIUM};

    margin-bottom: 16px;
    margin-right: 40px;
`;
