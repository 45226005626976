
import { useEffect, useState } from 'react';
import * as S from './styles';
import { fetchAxiosAdmin } from 'services/http';
import { ArrowLeftBack } from 'assets/svg/ArrowLeftBack';

const arrowLeftVisualizer = require('assets/svg/product/ArrowLeftVisualizer.svg').default

type VisualizeModalProps = {
    setOpenVisualizer: (value: boolean) => void;
    setCurrentImageIndex: (value: number) => void;
    currentImageIndex: number;
    accommodationId: string | number | undefined;
};

export const MobileVisualizeModal = ({ setOpenVisualizer, setCurrentImageIndex, currentImageIndex, accommodationId }: VisualizeModalProps) => {

    const [images, setImages] = useState([]);

    const totalImages = images.length

    const getImagesGallery = async (idAccomodation: string | number | undefined) => {
        try {
            const linkImage = 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/1200x900/filters:quality(65)/'
            const { data } = await fetchAxiosAdmin.get(`avantio/get-image-links/${idAccomodation}/all`,);
            const mappedImages = data.map((item: any) => {
                return linkImage + item.image_path
            })
            setImages(mappedImages)
        } catch (error) {
            console.error(error);
            throw new Error('error')
        }
    }

    useEffect(() => {
        getImagesGallery(accommodationId)
    }, [])

    const advanceImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
    };

    const regressImage = () => {
        setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
    };

    return (
        <S.ModalVisualizer>
            <S.ContentVisualizer>
                <S.HeaderVisualizer>
                    <S.CloseVisualizer onClick={() => setOpenVisualizer(false)}>
                        <ArrowLeftBack color='white' />
                    </S.CloseVisualizer>
                </S.HeaderVisualizer>
                <S.InteractionVisualizerContainer>
                    {/*  <S.FavButton active={favorite} visualizer={true} onClick={(e) => setFavorite(!favorite)}>
    {<FavIcon />}
    <p>Favoritar</p>
</S.FavButton>*/}
                </S.InteractionVisualizerContainer>
                <S.VisualizerIndividual>
                    {images.map((image: any, index: number) => {
                        return index === currentImageIndex && <S.ImageVisualizer loading='lazy' key={image + index} src={image} alt="" />
                    })}
                    <S.DirectionalButtonsContainer>
                        <S.DirectionalButton direction="left" onClick={regressImage} disabled={currentImageIndex === 0} >
                            <img src={arrowLeftVisualizer} alt="" />
                        </S.DirectionalButton>
                        <S.DirectionalButton direction="right" onClick={advanceImage} disabled={currentImageIndex === images.length - 1} >
                            <img src={arrowLeftVisualizer} alt="" />
                        </S.DirectionalButton>
                    </S.DirectionalButtonsContainer>
                    <S.TotalImagesVisualizer>
                        {images.map((image: any, index: number) => {
                            return index === currentImageIndex && <p style={{ color: 'white' }}>
                                {index + 1} / {totalImages}</p>
                        })}
                    </S.TotalImagesVisualizer>
                </S.VisualizerIndividual>
            </S.ContentVisualizer>
        </S.ModalVisualizer>
    )
}


