import { PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    max-width: 100vw;
    height: 100svh;

    align-items: center;

    overflow: auto;

`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    width: 1366px;

    align-items: center;
    justify-content: flex-start;

    row-gap: 96px;

    margin-top: calc(86px + 64px);

    @media (max-width: 768px) {
        width: calc(100% - 40px);

    }
`;

export const ScrollToTopButton = styled.button`
    display: flex;

    align-self: flex-end;

    align-items: center;
    justify-content: center;

    min-width: 40px;
    min-height: 40px;

    border-radius: 8px;
    border: none;

    background-color: ${PRIMARY_COLORS.MEDIUM};

    margin-bottom: 16px;
    margin-right: 40px;

    @media (max-width: 768px) {
        margin-top: calc(-96px + 8px);
        margin-right: 0px;
    }
`;

export const NewsLetterContainer = styled.div`

    padding: 80px 0px;

    @media (max-width: 768px) {
        padding-top: 0px;
        margin-bottom: -16px;
    }
`;