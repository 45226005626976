import { StateCreator, create } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

export type AccommodationExpenses = {
    id: string;
    uniqueValue: number;
    condominiumValue: number;
    iptuValue: number;
    yoghaPackageTax: number;
    cleaningValue: number;
    monthlyValue: number;
    definedDailyValue: number;
    midStay: number;
    longStay: number;
    moreThanMidStay?: number;
};

interface AccommodationExpensesState {
    expensesData: AccommodationExpenses;
    setExpensesData: (data: AccommodationExpenses) => void;
    clearExpensesData: () => void;
}

// Definição do tipo para persistência
type SessionStoragePersist = (
    config: StateCreator<AccommodationExpensesState>,
    options: PersistOptions<AccommodationExpensesState>
) => StateCreator<AccommodationExpensesState>;

// Estado inicial padrão
const initialState: AccommodationExpenses = {
    id: '',
    uniqueValue: 0,
    condominiumValue: 0,
    iptuValue: 0,
    yoghaPackageTax: 0,
    cleaningValue: 0,
    monthlyValue: 0,
    definedDailyValue: 0,
    midStay: 0,
    longStay: 0,
    moreThanMidStay: 0,
};

const useExpensesStore = create<AccommodationExpensesState>(
    (persist as SessionStoragePersist)(
        (set) => ({
            expensesData: initialState,
            setExpensesData: (data: AccommodationExpenses) => set({ expensesData: data }),
            clearExpensesData: () => set({ expensesData: initialState }),
        }),
        {
            name: 'expenses-storage', // Nome da chave em sessionStorage
            getStorage: () => sessionStorage, // Utilizando sessionStorage
        }
    )
);

export default useExpensesStore;
