import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa as traduções
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';

// Os recursos de tradução
const resources = {
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationPT,
  },
};

i18n
  .use(initReactI18next) // Passa o i18n para o react-i18next.
  .init({
    resources,
    lng: "pt", // Idioma padrão
    fallbackLng: "en", // Idioma de reserva

    interpolation: {
      escapeValue: false, // Escapa os valores de tradução de HTML
    },
  });

export default i18n;
