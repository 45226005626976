import { ContentResult, InfoSearch, ResultContent, ResultRow, ResultsHeader, SkeletonContainer } from "pages/Results/styles";
import { useScreenSkeleton } from "../../../../hooks/useScreenSkeleton";
import Skeleton from "react-loading-skeleton";
import * as S from "./styles";

export const SkeletonResults = () => {
    const { widthCardSkeleton, heigthScaleImage } = useScreenSkeleton();
    return (
        <>
            <ResultsHeader>
                <S.ResultRow>
                    <ContentResult>
                        <InfoSearch>
                            <Skeleton width={170} height={13} borderRadius={8} />
                            <div>
                                <Skeleton width={230} height={13} borderRadius={8} />
                                <Skeleton width={145} height={13} borderRadius={8} />
                            </div>
                        </InfoSearch>
                    </ContentResult>
                    <Skeleton
                        width={106}
                        height={40}
                        borderRadius={8}
                        style={{
                            marginRight: 36,
                            marginTop: 28
                        }}
                    />
                </S.ResultRow>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0px 32px'
                    }}
                >
                    <ResultContent>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <SkeletonContainer key={index}>
                                <Skeleton width={widthCardSkeleton} height={heigthScaleImage} borderRadius={8} />
                                <Skeleton
                                    width={228}
                                    height={13}
                                    style={{
                                        marginTop: 20,
                                        marginBottom: 12
                                    }}
                                />
                                <Skeleton width={142} height={15} />
                                <Skeleton
                                    width={178}
                                    height={11}
                                    style={{
                                        marginTop: 16,
                                        marginBottom: 6
                                    }}
                                />
                                <Skeleton width={95} height={11} />
                            </SkeletonContainer>
                        ))}
                    </ResultContent>
                </div>
            </ResultsHeader>
        </>
    )
}
