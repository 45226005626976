import { Navegation } from "components/Navegation"
import * as S from './styles'
import { useEffect, useState } from "react"
import fetchAxios from "services/http"
import { useUserProvider } from "hooks/userContext"
import { Inputs } from "components/Input"
import { RegisterCreditCard } from "pages/Payment/components/RegisterCreditCard"
import { PaymentTypeState } from "pages/Payment"
import { Loading } from "pages/Payment/components/Loading"
import { usePaymentProvider } from "context/paymentContext"
import { useScreenWidth } from "utils/useScreenWidth"
import { useNavigate } from "react-router-dom"
import useIsMobile from "pages/Mobile/useMobile"
import { BlockedUserAlert } from "pages/Payment/components/BlockedUserAlert"

export const PaymentInfo = () => {

    document.title = "Informações de pagamento | Yogha";

    const [cards, setCards] = useState<any[]>([])
    const [paymentType, setPaymentType] = useState<PaymentTypeState>({ cardId: '', type: '' })
    const [openRegisterCard, setOpenRegisterCard] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(false)
    const [loadingMessage, setLoadingMessage] = useState<string>('')
    const [isBlocked, setIsBlocked] = useState<boolean>(false)



    const navigate = useNavigate()

    const isMobile = useIsMobile()

    const { token, statusAccount } = useUserProvider()

    const { isLoading } = usePaymentProvider()

    useEffect(() => {
        const getCreditCards = async () => {
            try {
                const res = await fetchAxios.get('payment/cards', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                })
                setCards(res.data)
            } catch (error) {
            }

        }
        getCreditCards()
    }, [reload, openRegisterCard])

    const handleReload = () => {
        setTimeout(() => {
            setReload(!reload)
        }, 3000);
    }

    const handleRegisterCard = () => {
        if (statusAccount === "Bloqueado") {
            setIsBlocked(true)
        } else if (isMobile) {
            navigate('/register-card')
        } else {
            setOpenRegisterCard(true)
        }
    }

    const plusIcon = require('assets/svg/payment/Plus.svg').default

    return (
        <>
            <S.Container>
                <Navegation actualPage="Informações de pagamento" />
                <S.Title>Informações de pagamento</S.Title>
                <S.CardContainer>
                    <S.Column>
                        <strong>Cartão de crédito</strong>
                        {cards?.length > 0 ? null : <h4>Você não possui cartões cadastrados</h4>}
                        <S.ContainerInput>
                            {cards?.map((card) => (
                                <S.InputPayment key={card.id} onClick={() => setPaymentType(card.id)}>
                                    <Inputs.Radio loadingMessage={setLoadingMessage} setReload={setReload} onClick={handleReload} verify={card.validated} lastNumber={card.last_numbers} cardId={card.id} token={card.token} brand={card.brand} type='CREDIT_CARD' paymentType={paymentType} setPaymentType={setPaymentType} />
                                </S.InputPayment>
                            ))}

                            {cards && cards.length < 5 ? <S.InputPayment onClick={handleRegisterCard}>
                                <img src={plusIcon} alt="" />
                                <p>Adicionar cartão de crédito</p>
                            </S.InputPayment>
                                : null}

                        </S.ContainerInput>
                    </S.Column>
                </S.CardContainer>
            </S.Container>
            {openRegisterCard && <RegisterCreditCard setReload={setReload} open={openRegisterCard} setOpen={setOpenRegisterCard} />}
            {isLoading ? <Loading text={loadingMessage} /> : null}
            <BlockedUserAlert blockedUser={isBlocked} setIsBlockedUser={setIsBlocked} />
        </>
    )
}