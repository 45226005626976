import { Input } from 'components/Input'
import * as S from './styles'
import { useState } from 'react'
import { Button } from 'components/Button'
import fetchAxios from 'services/http'
import useAlert from 'components/Notification'
import { useScreenWidth } from 'utils/useScreenWidth'

export const RegisterNewsletter = () => {

    const image = require('assets/image/proprietary.webp')

    const [email, setEmail] = useState('')
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const handleCreate = async () => {
        if (!email.includes('@')) {
            return showAlert({ active: true, title: 'Esse e-mail é inválido. tente usar assim: exemplo@email.com', type: "ERROR" })
        }
        try {
            const { data } = await fetchAxios.post('/land/newsletter', {
                "email": email,
            })
            if (data.code === 200) {
                showAlert({ active: true, title: 'Email cadastrado com sucesso', type: "SUCCESS" })
                setEmail('')
            } else {
                showAlert({ active: true, title: data.message, type: "ERROR" })

            }



        } catch (error) {
            console.error(error)
        }
    }

    const breakpoint = useScreenWidth()

    const articlePath = window.location.pathname.includes('article')

    return (
        <S.Container style={{
            marginBottom: articlePath ? '64px' : '',
            marginTop: articlePath ? '-16px' : '',
        }}>
            <S.LeftCollumn>
                <S.Header>
                    <h1>Inscreva-se em nosso Blog</h1>
                    <p>Receba, em primeira mão, nossos principais conteúdos em seu email e participe da comunidade Yogha.</p>
                </S.Header>
                <S.Form>
                    <S.InputContainer>
                        <Input width='100%' type="text" placeholder="Digite seu e-mail" value={email} onChange={(e) => setEmail(e.currentTarget.value)} htmlFor='email' title='E-mail' />
                    </S.InputContainer>
                    <Button widthSize={breakpoint ? '100%' : ''} onClickButton={handleCreate} size='large' title='Inscreva-se' type='primary' />
                </S.Form>
                <S.Footer>
                    <p>*Ao assinar a newsletter, declaro que conheço a Política de Privacidade e autorizo a utilização das minhas informações.</p>
                </S.Footer>
            </S.LeftCollumn>
            <S.Image src={image} alt="" />
            <Alert />
        </S.Container>
    )
}