import { useState } from "react"
import * as S from "./styled"
import { useNavigate } from "react-router-dom"
import Skeleton from "react-loading-skeleton"
import { ModalGallery } from "../ModalGallery"
import { VisualizeModal } from "../VisualizeModal"

interface ImageGalleryProps {
    imagesRef: React.RefObject<HTMLDivElement>
    name: string
    city: string
    isLoading: boolean
    accommodationId: string | undefined
    previwImages: string[]
}

export const ImageGallery = ({ ...image }: ImageGalleryProps) => {
    // const [favorite, setFavorite] = useState(false)
    const [images, setImages] = useState<string[]>([])
    const [galleryIsOpen, setGalleryIsOpen] = useState(false)
    const [openVisualizer, setOpenVisualizer] = useState(false)
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const navigate = useNavigate()

    const galleryIcon = require('assets/svg/product/DotsNine.svg').default
    const firstCaret = require('assets/svg/product/CaretRightGray.svg').default
    const secondCaret = require('assets/svg/product/CaretRightDarkGray.svg').default

    // const FavIcon = () => {
    //     return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M11.6002 5.5399L12 6.07243L12.3998 5.5399C13.3554 4.26723 14.8975 3.50008 16.6872 3.5C18.0957 3.50166 19.4461 4.06193 20.4421 5.05791C21.4381 6.05396 21.9984 7.40444 22 8.81306C21.9997 11.8601 19.7233 14.6991 17.2536 16.8619C14.8102 19.0017 12.3116 20.3636 12.1214 20.4643L12.1184 20.4659C12.082 20.4855 12.0413 20.4958 12 20.4958C11.9587 20.4958 11.918 20.4855 11.8816 20.4659L11.8786 20.4644C11.6884 20.3636 9.18979 19.0017 6.74637 16.8619C4.27664 14.699 2.00014 11.86 2 8.81277C2.00166 7.40425 2.56193 6.05389 3.55791 5.05791C4.55389 4.06193 5.90425 3.50166 7.31277 3.5C9.10248 3.50008 10.6446 4.26723 11.6002 5.5399Z" fill="#ED374C" stroke="white" />
    //     </svg>

    // }

    return (
        <>
            {image.isLoading ? (
                <S.Container>
                    <Skeleton width={268} height={30} />
                    <Skeleton width={461} height={16} style={{
                        marginTop: 4
                    }} />
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: '635px calc((277px * 2) + 8px)',
                        gap: 8,
                        marginTop: 8
                    }}>
                        <Skeleton width={635} height={390} borderRadius={10} />
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2,277px)',
                            gridTemplateRows: 'repeat(2,auto-fit)',
                            gap: 8
                        }}
                        > {Array.from({ length: 4 }).map((_, index) => (
                            <Skeleton key={index} width={277} height={189} borderRadius={10} />
                        ))}
                        </div>
                    </div>
                </S.Container>
            ) : (
                <S.Container id='Imagens' ref={image.imagesRef}>
                    <S.Title>{image.name}</S.Title>
                    <S.Header>
                        <S.GuideContainer>
                            <S.GuideTextPass onClick={() => navigate('/')}>Inicio</S.GuideTextPass>
                            <img src={firstCaret} alt="" />
                            <S.GuideTextPass>{image.city}</S.GuideTextPass>
                            <img src={secondCaret} alt="" />
                            <S.GuideTextActual>{image.name}</S.GuideTextActual>
                        </S.GuideContainer>
                        {/* <S.FavButton active={favorite} onClick={(e) => setFavorite(!favorite)}>
                    <FavIcon />
                    <p>Favoritar</p>
                </S.FavButton> */}
                    </S.Header>

                    <S.GridImages onClick={() => setGalleryIsOpen(true)}>
                        {image.previwImages.map((image) => {
                            return <S.Image loading='lazy' key={image} src={image} alt="" />
                        })}
                    </S.GridImages>

                    <S.ButtonGallery onClick={() => setGalleryIsOpen(true)}><img src={galleryIcon} alt="" /><p>Ver todas as fotos</p> </S.ButtonGallery>
                    {galleryIsOpen && <ModalGallery accommodationId={image.accommodationId} setCurrentImageIndex={setCurrentImageIndex} setGalleryIsOpen={setGalleryIsOpen} setOpenVisualizer={setOpenVisualizer} />}

                    {openVisualizer && <VisualizeModal accommodationId={image.accommodationId} currentImageIndex={currentImageIndex} setCurrentImageIndex={setCurrentImageIndex} setOpenVisualizer={setOpenVisualizer} />
                    }
                </S.Container>
            )}

        </>
    )
}