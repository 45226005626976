import styled from "styled-components";
import { NEUTRAL_COLORS } from "../../constants/styleConstants";

export const Container = styled.button<{ size: number, disabled:boolean }>`
  cursor: pointer;
  visibility: ${props => props.disabled ? "hidden" : "visible"};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: 40px;
  background: ${NEUTRAL_COLORS.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 29px;
  border: none;

  @media (max-width: 768px) {
    z-index: 3;
    pointer-events: auto;
  }
`;
export const Icon = styled.img<{ iconSize:number }>`
    width:  ${props => props.iconSize}px;
    height:  ${props => props.iconSize}px;
`;