import { ButtonGradient } from "pages/Owner/components/ButtonGradient";
import * as S from "./styles"
import { Tag } from "components/Tag";
import { Forms } from "components/Forms";
import { Input } from "components/Input";
import { useState } from "react";
import { Footer } from "components/Footer";
import useAlert from "components/Notification";

import { CommentDevelopersProps, SliderTransparent } from "pages/Owner/components/SliderTransparent";
import fetchAxios from "services/http";
import { useScreenWidth } from "utils/useScreenWidth";
import MobileCarouselCommentsDeveloper from "components/MobileCarouselCommentsDeveloper";
import { useNavigate } from "react-router-dom";

type CardProjectProps = {
    title: string;
    image: string;
    description: string;
}

const CardProjectObj: CardProjectProps[] = [
    {
        description: 'Serviços digitais de marcas especializadas, destinados a descomplicar a experiência de moradia.',
        image: require('assets/svg/developers/project/DeviceMobileCamera.svg').default,
        title: 'Yogha Digital'
    },
    {
        description: 'Gestão e operação fácil e transparente das locações, com rentabilidade maximizada.',
        image: require('assets/svg/developers/project/RocketLaunch.svg').default,
        title: 'Yogha Gestão'
    },
    {
        description: 'Serviços adicionais (implantados mediante disponibilidade na região) que proporcionam mais comodidade aos moradores.',
        image: require('assets/svg/developers/project/Package.svg').default,
        title: 'Serviços On Demand'
    },
    {
        description: 'Solução para deixar o seu apartamento pronto para ocupar e rentabilizar.',
        image: require('assets/svg/developers/project/PaintBrush.svg').default,
        title: 'Yogha Design'
    },
]


const CardProject = ({ description, image, title }: CardProjectProps) => {
    return (
        <S.CardProject>
            <img src={image} alt="" />
            <span>{title}</span>
            <p>{description}</p>

        </S.CardProject>
    )

}

type CardEcosystemProps = {
    image: string;
    description: string;
}

const buildingImage = require('assets/image/developers/Objects.png')

const ecosystemObj: CardEcosystemProps[] = [
    {
        image: require('assets/svg/developers/Graph.svg').default,
        description: 'Leve maior valor agregado ao seu empreendimento com a Inteligência Yogha.'
    },
    {
        image: require('assets/svg/developers/Smiley.svg').default,
        description: 'Soluções de IoT e Serviços On Demand melhorando a experiência de moradores e investidores.'
    },
    {
        image: require('assets/svg/developers/Dollar.svg').default,
        description: 'Venda mais e melhor com a tecnologia da Yogha.'
    },
]

const item: ItensEnterpriseProps[] = [
    {
        title: 'Assessoria em marketing',
        image: require('assets/svg/developers/itens/Marketing.svg').default,
    },
    {
        title: 'Aceleração comercial',
        image: require('assets/svg/developers/itens/Comercial.svg').default,
    },
    {
        title: 'Troca de enxovais',
        image: require('assets/svg/developers/itens/Enxovais.svg').default,
    },
    {
        title: 'Fechaduras eletrônicas',
        image: require('assets/svg/developers/itens/Fechaduras.svg').default,
    },
    {
        title: 'Assessoria de projetos',
        image: require('assets/svg/developers/itens/Projetos.svg').default,
    },
    {
        title: 'Yogha Design',
        image: require('assets/svg/developers/itens/Design.svg').default,
    },
    {
        title: 'Lavanderia OMO',
        image: require('assets/svg/developers/itens/Lavanderia.svg').default,
    },
    {
        title: 'Limpeza',
        image: require('assets/svg/developers/itens/Limpeza.svg').default,
    },
    {
        title: 'Yogha gestão',
        image: require('assets/svg/developers/itens/Gestao.svg').default,
    },
    {
        title: 'Kuritibike',
        image: require('assets/svg/developers/itens/Kuritibike.svg').default,
    },
]

type ItensEnterpriseProps = {
    title: string;
    image: string;
};

const ItensEnterprise = ({ image, title }: ItensEnterpriseProps) => {
    return (
        <S.ItensEnterprise>
            <div>
                <img src={image} alt="" />
            </div>
            <p>{title}</p>
        </S.ItensEnterprise>
    )
}

const CardEcosystem = ({ description, image }: CardEcosystemProps) => {
    return (
        <S.CardEcosystem>
            <img src={image} alt="" />
            <p>{description}</p>
        </S.CardEcosystem>
    )
}

type CardExpertiseProps = {
    title: string;
    description: string;
}

const expertiseObj: CardExpertiseProps[] = [
    {
        title: '220+',
        description: 'Apartamentos administrados'
    },
    {
        title: '20+',
        description: 'Incorporadoras parceiras'
    },
    {
        title: '38k+',
        description: 'Clientes'
    },
]

const developersObj = ['Assessoria de Projetos', 'Bike Sharing com desconto', 'Assessoria de Convenção Condominial', 'Assessoria de Marketing', 'Mini markets com desconto', 'Lavanderias inteligentes com desconto', 'Cálculos de Rentabilidade', 'Fechadura digital com desconto', 'Treinamentos Comerciais', 'Controle de acessos inteligente', 'Mídia Kits', 'Experiências Yogha'
]

const developersObjMobile = ['Assessoria de Projetos', 'Assessoria de Marketing', 'Assessoria de Convenção Condominial', 'Experiências Yogha', 'Cálculos de Rentabilidade', 'Mídia Kits', 'Treinamentos Comerciais', 'Controle de acessos inteligente', 'Bike Sharing', 'Lavanderias inteligentes', 'Fechadura digital', 'Mini markets']

const CardExpertise = ({ title, description }: CardExpertiseProps) => {

    return (
        <S.CardExpertise>
            <div>
                <span>{title}</span>
            </div>
            <p>{description}</p>
        </S.CardExpertise>
    )
}

const commentsObj: CommentDevelopersProps[] = [
    {
        author: 'Thiago Hauagge Gadens',
        comment: '“Tivemos uma surpresa muito boa com o Morá, que vendeu cerca de 100 apartamentos já no primeiro mês de lançamento, representando 44% do empreendimento, a grande maioria para proprietários que irão alugar. Este certamente é um mercado em franca expansão.”',
        id: '1',
        logo: require('assets/svg/developers/enterprises/grupogadens.svg').default,
        developer: 'Gadens Incorporadora',
    },
    {
        author: 'Adriana Luiza Macedo Perin',
        comment: '“Tivemos uma grata surpresa com o lançamento do Hall Design, onde vendemos 33% no lançamento. A parceria com a Yogha aconteceu pela comodidade que a empresa pode proporcionar aos nossos clientes. Além disso, a Yogha é referência em operações do segmento de moradias flexíveis, sendo assim acreditamos que seria a empresa ideal para entrar no nosso produto”',
        id: '2',
        logo: require('assets/svg/developers/enterprises/vincere.svg').default,
        developer: 'Gadens Incorporadora',
    },
    {
        author: 'Thiago Hauagge Gadens',
        comment: '“Tivemos uma surpresa muito boa com o Morá, que vendeu cerca de 100 apartamentos já no primeiro mês de lançamento, representando 44% do empreendimento, a grande maioria para proprietários que irão alugar. Este certamente é um mercado em franca expansão.”',
        id: '1',
        logo: require('assets/svg/developers/enterprises/grupogadens.svg').default,
        developer: 'Gadens Incorporadora',
    },
]

export const SessionEnterprise = () => {
    const navigate = useNavigate();

    const [form, setForm] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        message: '',
        developerName: '',
        city: '',
        state: '',
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setForm(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleCreate = async () => {

        if (form.name === '' || form.surname === '' || form.developerName === '' || form.email === '' || form.phone === '' || form.state === '') {

            return showAlert({ active: true, title: 'Preencha todos os campos', type: "ERROR" })
        }
        if (!form.email.includes('@')) {
            return showAlert({ active: true, title: 'Esse e-mail é inválido. tente usar assim: exemplo@email.com', type: "ERROR" })
        }
        try {
            const { data } = await fetchAxios.post('/land/developer', {
                "name": form.name,
                "email": form.email,
                "phone": form.phone,
                "uf": form.state,
                "city": form.city,
                "surname": form.surname,
                "name_developer": form.developerName,
            })
            if (data.code === 200) {
                showAlert({ active: true, title: 'Formulário enviado com sucesso', type: "SUCCESS" })
                setForm({
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    message: '',
                    developerName: '',
                    city: '',
                    state: '',
                })
                navigate('/incorporadores/obrigado/');
            } else {
                showAlert({ active: true, title: data.message, type: "ERROR" })

            }



        } catch (error) {
            console.error(error)
        }
    }
    const handleButtonClick = () => {
        const element = document.getElementById('Forms');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const breakpoint = useScreenWidth()

    return (
        <S.Container>
            <S.Content>
                <S.Ecossystem>
                    <S.EcossystemContent>
                        <S.EcossystemHeader>
                            <h1>Transforme o seu empreendimento em um prédio inteligente com o ecossistema Yogha.</h1>
                            <p>O Yogha AppSuite conecta o seu imóvel a serviços, marcas e tecnologias que o levarão ao novo paradigma de moradia. Conheça os benefícios:</p>
                        </S.EcossystemHeader>
                        <S.CardEcosystemContainer>
                            {ecosystemObj.map((item, index) => (
                                <CardEcosystem key={index} image={item.image} description={item.description} />
                            ))}
                        </S.CardEcosystemContainer>
                    </S.EcossystemContent>
                </S.Ecossystem>
                <S.Enterprise>
                    <S.EnterpriseCollumnText>
                        <S.HeaderEnterprise>
                            <h1>Transforme o seu empreendimento em um prédio inteligente com o ecossistema Yogha.</h1>
                            <p>O Yogha AppSuite conecta o seu imóvel a serviços, marcas e tecnologias que o levarão a um novo paradigma.</p>
                        </S.HeaderEnterprise>
                        <S.GridEnterprise>
                            {item.map((item, index) => (
                                <ItensEnterprise key={index} image={item.image} title={item.title} />
                            ))}
                        </S.GridEnterprise>
                    </S.EnterpriseCollumnText>
                    <S.EnterpriseCollumnImage>
                        <img src={buildingImage} alt="" />
                    </S.EnterpriseCollumnImage>
                </S.Enterprise>
                <S.Projects>
                    <S.LinearGradient />
                    <S.HeaderProject>
                        <h1>Você tem o projeto e a Yogha, a expertise</h1>
                        <p>A Yogha é a única solução que cuida de tudo para maximizar seus resultados. Conheça as soluções personalizadas do AppSuite para o seu prédio:</p>
                    </S.HeaderProject>
                    <h5>Para o <span>proprietário</span> ou  <span>morador</span></h5>
                    <S.GridProjects>
                        {CardProjectObj.map((item, index) => (
                            <CardProject key={index} image={item.image} title={item.title} description={item.description} />
                        ))}
                    </S.GridProjects>
                </S.Projects>
                <S.Developers>
                    <S.LinearGradientDevelopers />
                    <S.DevelopersContent>
                        <h2>Para o <span>Incorporador</span></h2>
                        <S.DevelopersHeader>
                            <h3>Inteligência Yogha</h3>
                            <p>Serviços para aprimorar cada etapa da incorporação</p>
                        </S.DevelopersHeader>
                        {breakpoint ? (
                            <S.ColumnDevelopers>
                                <S.RowDevelopers className="developer">
                                    {developersObjMobile.map((item, index) => (
                                        <Tag key={index + item} title={item} />
                                    ))}
                                </S.RowDevelopers>
                            </S.ColumnDevelopers>
                        ) : (
                            <S.ColumnDevelopers>
                                <S.RowDevelopers>
                                    {developersObj.slice(0, 3).map((item, index) => (
                                        <Tag key={index + item} title={item} />
                                    ))}
                                </S.RowDevelopers>
                                <S.RowDevelopers>
                                    {developersObj.slice(3, 6).map((item, index) => (
                                        <Tag key={index + item} title={item} />
                                    ))}
                                </S.RowDevelopers>
                                <S.RowDevelopers>
                                    {developersObj.slice(6, 9).map((item, index) => (
                                        <Tag key={index + item} title={item} />
                                    ))}
                                </S.RowDevelopers>
                                <S.RowDevelopers>
                                    {developersObj.slice(9, 12).map((item, index) => (
                                        <Tag key={index + item} title={item} />
                                    ))}
                                </S.RowDevelopers>
                            </S.ColumnDevelopers>
                        )}
                        <ButtonGradient width="309px" title="Quero saber mais" onClick={handleButtonClick} />
                    </S.DevelopersContent>
                </S.Developers>
                <S.Expertise>
                    <S.LinearGradientExpertise />
                    <S.ExpertiseContent>
                        <S.ExpertiseHeader>
                            <h1> A <span>expertise da Yogha</span> aumenta a cada dia</h1>
                            <p>Pois nutrimos relações de valor entre Incorporadoras, Proprietários e Hóspedes.</p>
                        </S.ExpertiseHeader>
                        <S.GridExpertise>
                            {expertiseObj.map((item, index) => (
                                <CardExpertise key={index} title={item.title} description={item.description} />
                            ))}
                        </S.GridExpertise>
                    </S.ExpertiseContent>
                </S.Expertise>
                <S.Depositions>
                    <S.DepositionsHeader>
                        <h5>Depoimentos de Incorporadores</h5>
                    </S.DepositionsHeader>
                    {breakpoint ? <MobileCarouselCommentsDeveloper comments={commentsObj} /> : <SliderTransparent comments={commentsObj} />}
                </S.Depositions>
                <S.Forms id="Forms">
                    <S.FormsHeader>
                        <h1>Leve as <span>Soluções Yogha</span> para
                            o seu empreendimento</h1>
                    </S.FormsHeader>
                    <S.FormsContent>
                        <Forms>
                            <S.InputContainer>
                                <Input theme="dark" htmlFor="name" width={'100%'} onChange={handleChange} placeholder="Nome" title="Nome" type="" value={form.name} />
                            </S.InputContainer>
                            <S.InputContainer>
                                <Input theme="dark" htmlFor="surname" width={'100%'} onChange={handleChange} placeholder="Sobrenome" title="Sobrenome" type="" value={form.surname} />
                            </S.InputContainer>
                            <S.InputContainer>
                                <Input theme="dark" htmlFor="developerName" width={'100%'} onChange={handleChange} placeholder="Nome da incorporadora" title="Nome da incorporadora" type="" value={form.developerName} />
                            </S.InputContainer>
                            <S.InputContainer>
                                <Input theme="dark" htmlFor="email" width={'100%'} onChange={handleChange} placeholder="E-mail" title="E-mail" type="" value={form.email} />
                            </S.InputContainer>
                            <S.InputContainer>
                                <Input theme="dark" htmlFor="phone" width={'100%'} onChange={handleChange} placeholder="Telefone" title="Telefone (Celular)" type="" value={form.phone} />
                            </S.InputContainer>
                            <S.InputContainer>
                                <Input theme="dark" htmlFor="city" width={'100%'} onChange={handleChange} placeholder="Cidade do empreendimento" title="Cidade do empreendimento (Opcional)" type="" value={form.city} />
                            </S.InputContainer>
                            <S.InputContainer>
                                <Input theme="dark" htmlFor="state" width={'100%'} onChange={handleChange} placeholder="PR" title="UF do Imóvel" type="" value={form.state} />
                            </S.InputContainer>
                        </Forms>
                        <S.Footerbaseboard>
                            <li>Ao preencher o formulário, concordo * em receber comunicações de acordo com meus interesses. </li>
                            <li>Ao informar meus dados, eu concordo com a nossa <span>Política de privacidade.</span></li>
                            <li>Você pode alterar suas permissões de comunicação a qualquer memento.</li>
                        </S.Footerbaseboard>
                    </S.FormsContent>
                    <ButtonGradient width="309px" onClick={handleCreate} title="Quero saber mais" />
                </S.Forms>
            </S.Content>
            <Footer theme='dark' />
            <Alert />

        </S.Container>
    )
}