import Skeleton from 'react-loading-skeleton';
import { GridGallery } from '../ImageGallery/styled';
import * as S from './styles';
import { useEffect, useRef, useState } from 'react';
import { fetchAxiosAdmin } from 'services/http';
import { ArrowLeftBack } from 'assets/svg/ArrowLeftBack';

type ModalGalleryProps = {
    setGalleryIsOpen: (value: boolean) => void;
    accommodationId: string | number | undefined;
    setOpenVisualizer: (value: boolean) => void;
    setCurrentImageIndex: (value: number) => void;
};

export const MobileModalGallery = ({ setGalleryIsOpen, accommodationId, setOpenVisualizer, setCurrentImageIndex }: ModalGalleryProps) => {

    const [images, setImages] = useState<string[]>([])

    const [allImagesLoaded, setAllImagesLoaded] = useState(false);

    useEffect(() => {
        let loadedCount = 0;
        const imgElements: HTMLImageElement[] = [];

        images.forEach((url) => {
            const img = new Image();
            img.src = url;

            img.onload = () => {
                loadedCount++;
                if (loadedCount === images.length) {
                    setAllImagesLoaded(true);
                }
            };

            imgElements.push(img);
        });

        return () => {
            // Limpa os eventos onload para evitar atualizações de estado em componentes desmontados
            imgElements.forEach((img) => {
                img.onload = null;
            });
        };
    }, [images]);

    const getImagesGallery = async (idAccomodation: string | number | undefined) => {
        try {
            const linkImage = 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/1200x900/filters:quality(65)/'
            const { data } = await fetchAxiosAdmin.get(`avantio/get-image-links/${idAccomodation}/all`,);
            const mappedImages = data.map((item: any) => {
                return linkImage + item.image_path
            })
            setImages(mappedImages)
        } catch (error) {
            console.error(error);
            throw new Error('error')
        }
    }

    useEffect(() => {
        getImagesGallery(accommodationId)
    }, [])

    const closedIcon = require('assets/svg/product/ClosedIcon.svg').default

    function splitIntoParts(arr: any[], maxPartSize: number): any[][] {
        let result: any[][] = [];
        for (let i = 0; i < arr.length; i += maxPartSize) {
            result.push(arr.slice(i, i + maxPartSize));
        }
        return result;
    }

    const handleCloseGalleryOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // Verifica se o clique veio do elemento em si e não de um descendente
        if (e.target === e.currentTarget) {
            setGalleryIsOpen(false);
        }
    };

    const handleImageClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        e.stopPropagation(); // Impede que o evento se propague para o gridContainerRef
        setOpenVisualizer(true);
        setCurrentImageIndex(index);
    };

    const imageGroups = splitIntoParts(images, 9);

    const modalGalleryRef = useRef<HTMLDivElement>(null);
    const gridContentRef = useRef<HTMLDivElement>(null);
    const gridContainerRef = useRef<HTMLDivElement>(null);

    const gridStyles = {
        grid0: {
            minWidth: "calc(100% - 40px)",
        },
        grid1: {
            minWidth: "calc(100% - 40px)",

        },
        grid2: {
            minWidth: "calc(100% - 40px)",

        },
        grid3: {
            minWidth: "calc(100% - 40px)",

        },
        grid4: {
            minWidth: "calc(100% - 40px)",

        },
        grid5: {
            minWidth: "calc(100% - 40px)",

        },
        grid6: {
            minWidth: "calc(100% - 40px)",
        },
        grid7: {
            minWidth: "calc(100% - 40px)",
        },
        grid8: {
            minWidth: "calc(100% - 40px)",
        }
    };

    return (
        <S.ModalGallery ref={modalGalleryRef} onClick={handleCloseGalleryOnClick}>
            <S.HeaderGallery>
                <S.ContentHeader>
                    <S.ButtonClose onClick={() => setGalleryIsOpen(false)}><ArrowLeftBack /></S.ButtonClose>
                    <p>Galeria de imagens</p>
                </S.ContentHeader>
            </S.HeaderGallery>
            <S.ContentGallery onClick={handleCloseGalleryOnClick} ref={gridContentRef}>
                {
                    allImagesLoaded ? (
                        <S.GridContainer   >
                            {imageGroups.map((imageGroup, groupIndex) => (
                                <S.GridGallery onClick={handleCloseGalleryOnClick} ref={gridContainerRef} key={groupIndex} size={imageGroup.length} >
                                    {imageGroup.map((image, index) => {
                                        const totalIndex = groupIndex * 9 + index;
                                        return <S.ImageGallery
                                            loading="lazy"
                                            onClick={(e) => handleImageClick(e, totalIndex)}
                                            key={image}
                                            className={'grid' + index}
                                            src={image} alt="" />
                                    })}
                                </S.GridGallery>
                            ))}
                        </S.GridContainer>
                    ) : (
                        <S.GridContainer>
                            <div style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '16px',
                                width: '100%',
                                alignItems: 'start',
                                justifyContent: 'center'
                            }}>
                                <Skeleton style={gridStyles.grid0} />
                                <Skeleton style={gridStyles.grid1} />
                                <Skeleton style={gridStyles.grid2} />
                                <Skeleton style={gridStyles.grid3} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                    <Skeleton style={gridStyles.grid4} />
                                    <Skeleton style={gridStyles.grid5} />
                                </div>
                                <Skeleton style={gridStyles.grid6} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '16px',
                                }}>
                                    <Skeleton style={gridStyles.grid7} />
                                    <Skeleton style={gridStyles.grid8} />
                                </div>
                            </div>
                        </S.GridContainer>
                    )
                }

            </S.ContentGallery>
        </S.ModalGallery>
    )
}

