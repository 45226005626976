import styled from "styled-components";

export const RowOption = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media (max-width: 768px) {
    gap: 8px;
  }

  `;

export const ContainerOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;



  @media (max-width: 768px) {
    overflow: auto;
    width: calc(100vw - 46px);
    /* padding: 0px 20px; */

    .blog{
        label {
      padding:  6px 10px;
      }
    }
    padding-bottom: 8px;
    margin-bottom: -8px;

    /* Para navegadores Webkit como Chrome/Safari */
    &::-webkit-scrollbar {
      width: 0px !important;  // Define a largura como 0 para esconder a barra de rolagem
      background: transparent !important;  // Define o fundo como transparente
    }

    /* Para IE/Edge */
    scrollbar-width: none !important;  // Define a largura da barra de rolagem como nenhum para esconder a barra de rolagem
    -ms-overflow-style: none !important;  // Define o estilo de overflow como nenhum para esconder a barra de rolagem
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 999px;
    border: 6px solid rgba(0, 0, 0, 0);
}
`;
