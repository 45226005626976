import * as S from "./styles"

export interface OptionSelectProps {
    selected: boolean,
    onClick?: any;
    value: any;
    htmlFor: string;
    title: string;
    id: number | string;
}

export const OptionSelect = ({selected,onClick,htmlFor,value,title}:OptionSelectProps) => {
    return (
        <S.OptionSelectContainer title={title} value={value} selected={selected}>
            <S.Label htmlFor={htmlFor}>{title}</S.Label>
            <S.Input onClick={onClick} id={htmlFor} value={value} type="button" />
        </S.OptionSelectContainer>
    )
}