import * as S from './styles';

interface ButtonCarouselProps {
    direction: 'left' | 'right';
    onClick: any;
    size?: number;
    iconSize?: number;
    disabled?: boolean;
    onMouseDown?: () => void;
    onMouseLeave?: () => void;
    ref?: any;
}

export const ButtonCarousel = ({ onMouseDown, onMouseLeave, direction, onClick, size = 40, iconSize = 24, disabled = false, ref }: ButtonCarouselProps) => {

    const iconArrowLeft = require('../../assets/svg/arrowLeft.svg').default;
    const iconArrowRight = require('../../assets/svg/arrowRight.svg').default;

    return (
        <S.Container ref={ref} onMouseLeave={onMouseLeave} onMouseDown={onMouseDown} disabled={disabled} size={size} onClick={onClick}>
            {direction === 'left' && <S.Icon iconSize={iconSize} src={iconArrowLeft} alt="Botão com seta para esquerda" />}
            {direction === 'right' && <S.Icon iconSize={iconSize} src={iconArrowRight} alt="Botão com seta para direita" />}
        </S.Container>
    )
};