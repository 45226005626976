import { ChangeEvent, useState } from "react";
import { InputFileProps } from "./types";
import { AlertProps } from "components/Notification";


type TypeHookInputFile = Omit<InputFileProps, 'name' | 'description'> & {showAlert : (newAlerts: AlertProps ) => void}

export const useInputFile = ({onFileChange, showAlert}: TypeHookInputFile ) => {
    const [preview, setPreview] = useState<string | null | undefined>(null);


    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files ? e.target.files[0] : null;

        if (selectedFile) {
            // VerificaÃ§Ãµes do tipo de arquivo
            const fileType = selectedFile.type;
            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (!allowedTypes.includes(fileType)) {
                onFileChange?.(null);
                showAlert({ active: true, title: "Tipo de arquivo não permitido", type: "ERROR" });
                return;
            }

            onFileChange?.(selectedFile);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            // Limpa o estado e notifica o componente pai em caso de não seleÃ§Ã£o
            setPreview(null);
            onFileChange?.(null);
        }
    };

    const handleDelete = () => {
        setPreview(null);
        onFileChange?.(null); // Notifica o componente pai que não há arquivo
    };

    return {
        preview,
        handleFileChange,
        handleDelete
    }
}