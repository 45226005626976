import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
    display: none;
    @media (width < 785px) {
        display: flex;

        position: fixed;
        bottom: 0;
        left: 0;

        z-index: 2000;

        background-color: ${NEUTRAL_COLORS.WHITE};

        gap: 32px;

        align-items: center;
        justify-content: center;

        width: 100%;
        height: 49px;

        padding: 8px 0;

        border-top: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
        border-radius: 16px 16px 0px 0px;

        button{

            width: 64px;

            background: none;
            border: none;
            cursor: pointer;
            p{
                ${FONT.CAPTION.CAPTION_SEMI_BOLD};
                color: ${NEUTRAL_COLORS.DARK_GRAY};
            }
        }
    }

`;