import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100vw - 40px);
  padding: 0px 20px;
`;

export const CarouselContainer = styled.div<{card:string}>`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: ${(props) => props.card === 'unit' ? '330px' : props.card === 'building' ? '370px' : '350px'};
`;


export const Slide = styled.div<{ active: boolean }>`
  display: flex;
  position: absolute;
  width: 100%;
  max-height: 370px;
  object-fit: cover;
  opacity: ${(props) => (props.active ? '1' : '0')};
  transition: opacity 0.5s;
  z-index: ${(props) => (props.active ? '1' : '0')};
`;

export const Button = styled.button`
  position: absolute;
  bottom: 10px;
  background-color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 3;
`;

export const NextButton = styled(Button)`
  right: 10px;
`;

export const PrevButton = styled(Button)`
  left: 10px;
`;

export const ButtonContainer = styled.div<{card:string}>`
    display: flex;
    flex-direction: row;

    width: calc(100% - 40px);

    justify-content: space-between;
    align-items: center;
    position: absolute;

    top: ${(props) => props.card === 'unit' ? '30%' : props.card === 'building' ? '25%' : '30%'};
    transform: translateY(-50%);
    z-index: 2;

    pointer-events: none;
    `;

export const Bullet = styled.div`
width: 8px;
height: 8px;
border-radius: 10px;
transition: all 0.8s ease-in-out;
background:${(props: { active: any; }) => props.active ? PRIMARY_COLORS.MEDIUM : NEUTRAL_COLORS.GRAY};
&:hover {
    ${(props: { active: any; }) => props.active ? '4px' : '0px'};
    }
    box-shadow: 0.5px 1.5px 2px 0px rgba(0, 0, 0, 0.25);
`;