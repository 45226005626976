import * as S from "./styles"
import { Hero } from "./components/Hero"
import { SessionManagament } from "./components/SessionManagament"

export const Owner = () => {

    document.title = "Proprietários | Yogha";

    return (
        <S.Container>
            <Hero />
            <SessionManagament />
        </S.Container>
    )
}