import axios from "axios";

let baseURL;

if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:4002/";
  // baseURL = "https://dev-site.yogha.com.br/back/";
  // baseURL = "https://192.168.1.8:4002";
} else if (process.env.NODE_ENV === "production") {
  baseURL = process.env.REACT_APP_BASE_URL
} else {
  throw new Error("NODE_ENV not specified");
}

const fetchAxios = axios.create({
  baseURL
});

export const fetchAxiosAdmin = axios.create({

  baseURL: process.env.REACT_APP_ADMIN_BASE_URL
});

export const fetchAxiosPayment = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_BASE_URL + "site",
});

export const fetchAxiosBlog = axios.create({
  baseURL: "https://blog.yogha.com.br",
});

export default fetchAxios;
