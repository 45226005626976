import { Button } from "components/Button"
import * as S from "./styles"
import { useNavigate } from "react-router-dom"

export const OwnerThankYou = () => {

    const navigate = useNavigate()

    return (
        <S.Container>
            <S.FirstRow>
                <h1>Obrigado pelo seu interesse</h1>
                <p>Agora é so relaxar que, em breve, entraremos em contato com você. Fique de olho no seu e-mail. Não esqueça de verificar a sua caixa de spam.</p>
            </S.FirstRow>
            <S.SecondRow>
                <p>Enquanto isso, que tal conhecer mais sobre a Yogha visitando nosso blog ?</p>
                <Button onClickButton={() => navigate('https://blog.yogha.com.br/')} size="large" title="Ir para o Blog" type="primary" />
            </S.SecondRow>
        </S.Container>
    )
}