import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Navegation } from "components/Navegation"
import * as S from './styles'
import { Button } from "components/Button"
import { ProductCard } from "components/ProductCard"
import { TextArea } from "components/TextArea"
import { format, differenceInDays, parseISO } from 'date-fns';
import fetchAxios from "services/http"
import { useUserProvider } from "hooks/userContext"
import useAlert from "components/Notification"
import { ReservesProps } from "../Reservations"
import Skeleton from "react-loading-skeleton"
import { useQuery } from "react-query"
import { formatDate } from "utils/dateReserveFormatter"
import { useScreenSkeleton } from "hooks/useScreenSkeleton"

export const fetchReservations = async (token: string, idReserve: string): Promise<ReservesProps> => {
    const { data } = await fetchAxios.get(`/reservers/${idReserve}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const newReservationObject: ReservesProps[] = data.data.map((item: any) => {

        return {
            id: item.idBooking,
            address: {
                street: item.address,
                number: item.street_number,
                area: item.areaBuilding,
                town: item.town,
                state: 'PR'
            },
            accommodation: item.accommodation,
            initialDate: formatDate(item.arrival_date),
            finalDate: item.departure_date,
            type: item.booking_type === "PAID" ? "PAID" : item.booking_type === "UNPAID" ? "UNPAID" : item.booking_type === "CANCELLED" ? "CANCELLED" : item.statusPayment === "CONFIRMED" ? "PAID" : item.booking_type === "CONFIRMADA" ? "CONFIRMADA" : item.booking_type === "CANCELLED" ? "CANCELLED" : item.statusPayment,
            paymentType: item.type,
            image: 'https://d6ufmjskn3t3w.cloudfront.net/fit-in/1280x1080/filters:quality(65)/' + item.featured_image,
            guests: item.adults_number,
            totalValue: item.asaas_installments_value_total !== null ? item.asaas_installments_value_total : item.value_total,
            parcelValue: item.asaas_installments_value_total > 1 ? item.value_total : 0,
            totalParcells: item.asaas_installments_total !== null ? item.asaas_installments_total : 0,
            title: item.name_ad,
            streetNumbe: item.street_number,
            ref_stays: item.accRefStays,
            idBuilding: item.building_yogha,
            dateEdit: item.dateReserver,
            checkinCode: item.token,
            statusCheckin: item.statusCheckin,
            paymentId: item.id_asaas_charge,
            createReserver: item.createReserver
        }
    })

    const [filteredReserve] = newReservationObject.filter((item) => item.id === idReserve)
    return filteredReserve;
};

export const ReserveDetails = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [reasonCancel, setReasonCancel] = useState('')
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { widthScreen } = useScreenSkeleton()

    const { token } = useUserProvider()
    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })
    const navigate = useNavigate()

    const { pathname } = useLocation()
    const idReserve = pathname.split('/')[3]

    const { data: reservations, isLoading, isFetching, isError } = useQuery<ReservesProps>('reservations', () => fetchReservations(token, idReserve), {
        onError: () => {
            showAlert({ active: true, title: 'Erro ao listar reserva', type: 'ERROR' });
        },

    });

    useEffect(() => {
        const handleMouseEnter = () => {
            setTooltipOpen(true);
        };

        const handleMouseLeave = () => {
            setTooltipOpen(false);
        };

        const modalCancelledElement = document.getElementById('modal-cancelled');
        const tooltipElement = document.getElementById('tooltip');

        if (modalCancelledElement) {
            modalCancelledElement.addEventListener('mouseenter', handleMouseEnter);
            modalCancelledElement.addEventListener('mouseleave', handleMouseLeave);
        }

        if (tooltipElement) {
            tooltipElement.addEventListener('mouseenter', handleMouseEnter);
            tooltipElement.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (modalCancelledElement) {
                modalCancelledElement.removeEventListener('mouseenter', handleMouseEnter);
                modalCancelledElement.removeEventListener('mouseleave', handleMouseLeave);
            }

            if (tooltipElement) {
                tooltipElement.removeEventListener('mouseenter', handleMouseEnter);
                tooltipElement.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);

    const today = new Date();
    const todayFormatted = today.setHours(0, 0, 0, 0);

    const differenceDays = differenceInDays(parseISO(reservations && reservations.initialDate ? reservations.initialDate : new Date().toISOString()), todayFormatted);

    const totalDays = differenceInDays(parseISO(reservations && reservations.finalDate ? reservations.finalDate : new Date().toISOString()), parseISO(reservations && reservations.initialDate ? reservations.initialDate : new Date().toISOString()));

    const convertDateFormat = (dateStr: string) => {
        if (!dateStr) return;
        const dateObj = parseISO(dateStr);
        const convertedDate = format(dateObj, 'dd/MM/yyyy');
        return convertedDate;
    };

    const validatePaymentType = (paymentType: ReservesProps['paymentType']) => {
        switch (paymentType) {
            case "CREDIT_CARD":
                return 'Cartão de crédito'
            case 'PIX':
                return 'PIX'
            default:
                return 'Tipo de pagamento inválido'
        }
    }

    const question = require('assets/svg/spaceUser/Question.svg').default;

    const creditCardIcon = require('assets/svg/spaceUser/CreditCard.svg').default;
    const pixIcon = require('assets/svg/spaceUser/Pix.svg').default;

    const handlePaymentOrCheckin = () => {
        const link = window.location.origin + '/check-in/'
        if (!reservations) return;
        if (reservations.type === "RECEIVED" || reservations.type === "PAID") {
            window.open(link + reservations.checkinCode, '_blank')
        }
        if (reservations.type === 'UNPAID') {
            navigate(`/payment/PIX_CODE/${reservations.paymentId}`)
        }
    }

    const cancelReserve = async () => {
        if (!reservations) return;
        try {
            const { data } = await fetchAxios.post(`/reservers/changeStatus/${reservations.id}`, {
                status: 'CANCELLED',
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            showAlert({ active: true, title: 'Reserva cancelada com sucesso', type: 'SUCCESS' })
            setTimeout(() => {
                navigate('/minha-conta/reservas/')
            }, 3000);

        } catch (error) {
            showAlert({ active: true, title: 'Erro ao cancelar reserva', type: 'ERROR' })
        }

    }

    const handleConfirmCancel = () => {
        if (reasonCancel === null || reasonCancel.trim() === '') {
            setErrorMessage('Este campo é obrigatório')
        } else {
            cancelReserve()
            setIsOpen(false)
            setErrorMessage('')
        }
    }

    const GadgetType = (type: ReservesProps['type']): string => {
        switch (type) {
            case 'UNPAID':
                return 'Aguardando pagamento'
            case "RECEIVED":
                return 'Reservado'
            case "PAID":
                return 'Reservado'
            case 'CONFIRMADA':
                return 'Concluído'
            case 'CANCELLED':
                return 'Reserva cancelada'
            default:
                return 'Tipo de reserva inválida'
        }
    }

    const handleCancel = () => {
        if (widthScreen < 768) {
            navigate(`cancelar-reserva`)
        } else {
            setIsOpen(true)
        }
    }

    const arrowRight = require('assets/svg/navegation/CaretRight.svg').default;


    return (
        <>
            {isLoading || isFetching ? (
                <div>
                    <Skeleton width={407} height={20} style={{
                        alignSelf: 'flex-start',
                        marginBottom: 44
                    }} />
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2,1fr)',
                        gap: 40

                    }}>
                        <Skeleton width={582} height={396} borderRadius={16} />
                        <div>
                            <Skeleton height={20} width={141} />
                            <Skeleton height={20} width={337} style={{
                                marginTop: 40
                            }} />
                            <Skeleton height={20} width={379} style={{
                                marginTop: 32
                            }} />
                            <Skeleton height={20} width={336} style={{
                                marginTop: 23
                            }} />
                            <Skeleton height={20} width={181} style={{
                                marginTop: 32
                            }} />
                            <Skeleton height={20} width={36} style={{
                                marginTop: 32
                            }} />
                            <Skeleton height={20} width={156} style={{
                                marginTop: 18
                            }} />
                        </div>

                    </div>
                    <Skeleton height={36} width={225} borderRadius={8} style={{
                        marginTop: 16
                    }} />
                </div>
            ) : (
                <>
                    <Navegation lastPage="Minhas reservas" linkLastPage="/minha-conta/reservas/" actualPage="Detalhes da reserva" />
                    {reservations && reservations !== null && <S.Content>
                        <S.FirstCollumn>
                            <S.Image src={reservations.image} alt="" />
                            <Link style={{ textDecoration: 'none' }} to={`/property/${reservations.ref_stays}/${reservations.idBuilding}`}>
                                <S.ButtonSeeMore>
                                    <p>Ver anúncio completo</p>
                                    <img src={arrowRight} alt="" />
                                </S.ButtonSeeMore>
                            </Link>
                            <S.GadgetInImage type={reservations.type}>{GadgetType(reservations.type)}</S.GadgetInImage>
                        </S.FirstCollumn>
                        <S.SecondCollumn>
                            <S.Gadget type={reservations.type}>{GadgetType(reservations.type)}</S.Gadget>
                            <S.FirstRow>
                                <S.Title>{reservations.title}</S.Title>
                                <S.Address>
                                    Endereço:
                                </S.Address>
                                <S.Text>{reservations?.address?.street}, {reservations.streetNumbe} - {reservations.accommodation}, {reservations?.address?.area} - {reservations?.address?.town}, PR</S.Text>
                                {/* <S.ButtonMap>
                            <Button onClickButton={() => { }} size="small" title="Ver mapa" type="secondary" />
                        </S.ButtonMap> */}
                            </S.FirstRow>
                            <S.Date>
                                <S.RowDate>
                                    <S.SubTitle>Entrada</S.SubTitle>
                                    <S.Text>{convertDateFormat(reservations.initialDate)}</S.Text>
                                    {(reservations.type !== 'CONFIRMADA' && reservations.type !== 'CANCELLED') && <S.AdvertText>Entrada permitida a partir das 15:00h da tarde</S.AdvertText>}
                                </S.RowDate>
                                <S.RowDate>
                                    <S.SubTitle>Saída</S.SubTitle>
                                    <S.Text>{convertDateFormat(reservations.finalDate)}</S.Text>
                                    {(reservations.type !== 'CONFIRMADA' && reservations.type !== 'CANCELLED') && <S.AdvertText>Saída até às 11:00h da manhã</S.AdvertText>}
                                </S.RowDate>
                            </S.Date>
                            <S.Row>
                                <S.SubTitle>Fatura da hospedagem:</S.SubTitle>
                                <S.Text>{totalDays} noite{totalDays > 1 ? '(s)' : ''}</S.Text>
                            </S.Row>
                            <S.Value>
                                <S.TotalRow>
                                    <S.SubTitle>Total</S.SubTitle>
                                    <S.Text>R${Number(reservations.totalValue).toLocaleString(
                                        'pt-br',
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        },
                                    )}</S.Text>
                                </S.TotalRow>
                                <S.ValueRows>
                                    <S.SubTitle>Forma de pagamento</S.SubTitle>
                                    <S.PaymentType>
                                        {reservations.paymentType === "CREDIT_CARD"
                                            &&
                                            <>
                                                <img src={creditCardIcon} alt='' />
                                                <S.Text>{validatePaymentType(reservations.paymentType)}</S.Text>
                                            </>
                                        }
                                        {reservations.paymentType === 'PIX'
                                            &&
                                            <>
                                                <img src={pixIcon} alt='' />
                                                <S.Text>{validatePaymentType(reservations.paymentType)}</S.Text>
                                            </>
                                        }
                                    </S.PaymentType>
                                </S.ValueRows>
                                <S.ValueRows style={{
                                    marginTop: -16
                                }}>
                                    <S.SubTitle>{" "}</S.SubTitle>
                                    {reservations.paymentType === 'PIX' && <S.Text>À vista</S.Text>}
                                    {reservations.paymentType === 'CREDIT_CARD' && reservations.totalParcells === 0 ? <S.Text>À vista</S.Text> : reservations.paymentType === 'CREDIT_CARD' && reservations.totalParcells !== 1 && reservations.totalParcells !== null ? <S.Text>Em {reservations.totalParcells}x de R$ {Number(reservations.parcelValue).toLocaleString(
                                        'pt-br',
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        },
                                    )}</S.Text> : null}
                                </S.ValueRows>
                                {
                                    reservations.type === 'CANCELLED' && (
                                        <S.TotalRow>
                                            <S.SubTitle>Data de cancelamento</S.SubTitle>
                                            <S.Text>{convertDateFormat(reservations.dateEdit)}</S.Text>
                                        </S.TotalRow>
                                    )
                                }
                            </S.Value>

                            {(reservations.type === "RECEIVED" || reservations.type === "PAID" || reservations.type === 'UNPAID' || reservations.type === 'CONFIRMADA') && (
                                <S.ButtonRow>
                                    {reservations.statusCheckin !== 'validated' ? (
                                        <S.LeftButtonColumn>
                                            <Button onClickButton={handlePaymentOrCheckin} size="medium" title={reservations.type === "RECEIVED" || reservations.type === "PAID" ? "Fazer o check-in online" : 'Ir para o pagamento'} type="primary" widthSize={'100%'} />
                                        </S.LeftButtonColumn>
                                    ) : (
                                        <S.ReserveValidText>Check-in online realizado</S.ReserveValidText>
                                    )}
                                    {/* {(reservations.type === "RECEIVED" || reservations.type === "CONFIRMADA") && (
                                        <S.ButtonCancel onClick={handleCancel}>
                                            <p>Cancelar reserva</p>
                                        </S.ButtonCancel>
                                    )} */}
                                </S.ButtonRow>
                            )}
                        </S.SecondCollumn>
                        <ProductCard width="598" isOpen={isOpen} setIsOpen={setIsOpen} title="Cancelar reserva" >
                            <S.ModalCancel>
                                <S.ModalHeader>
                                    <img src={reservations.image} alt="" />
                                    <S.ModalHeaderRight>
                                        <S.SubTitle>{reservations.title}</S.SubTitle>
                                        <S.ModalRow>
                                            <S.Text>Entrada:</S.Text>
                                            <S.Text>{convertDateFormat(reservations.initialDate)}</S.Text>
                                        </S.ModalRow>
                                        <S.ModalRow>
                                            <S.Text>Saída</S.Text>
                                            <S.Text>{convertDateFormat(reservations.finalDate)}</S.Text>
                                        </S.ModalRow>
                                    </S.ModalHeaderRight>
                                </S.ModalHeader>
                            </S.ModalCancel>
                            <S.TextAreaContainer>
                                <TextArea placeholder="Esta informação é necessária para concluir o cancelamento" label="Nos informe o motivo do cancelamento" name="reasonCancel" value={reasonCancel} errorMessage={errorMessage} onChange={(e: any) => setReasonCancel(e.currentTarget.value)} />
                            </S.TextAreaContainer>
                            <S.CancelledSession>
                                <S.ModalCancelled >
                                    <S.SubTitle>Políticas de cancelamento</S.SubTitle>
                                    <img id="modal-cancelled" onMouseDown={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)} src={question} alt="" />
                                    {tooltipOpen && <S.Tooltip id="tooltip">
                                        <p>Cancelamentos feitos com pelo menos 7 dias de antecedência ao check-in terão reembolso integral, exceto para pagamentos via cartão de crédito, que terão uma taxa correspondente. Cancelamentos feitos dentro dos 7 dias anteriores ao check-in terão retenção de 20% do valor pago, além de possíveis taxas e despesas financeiras. Reservas com condições promocionais podem não ser reembolsáveis. O estorno pode acontecer em até duas faturas após o pedido de cancelamento.</p>
                                    </S.Tooltip>}
                                </S.ModalCancelled>
                                <S.Terms>
                                    <p> Saiba mais sobre as políticas de cancelamento acessando os nossos{' '}
                                        <S.LinkTerms to={'/termos-e-condicoes/'}>termos de uso</S.LinkTerms></p>
                                </S.Terms>
                            </S.CancelledSession>
                            <S.TaxCancelled>
                                <S.SubTitle>Custo do cancelamento</S.SubTitle>
                                {reservations.paymentType === 'PIX' && differenceDays >= 7 ? null : <S.TaxRow>
                                    <S.Text>Valor da reserva:</S.Text>
                                    {reservations.promotional ?
                                        <S.Text>Reserva promocional</S.Text>
                                        :
                                        <S.Text>R$ 225,00</S.Text>
                                    }
                                </S.TaxRow>}
                                {reservations.paymentType === 'PIX' && differenceDays <= 7 ? null : reservations.promotional ? null :
                                    <S.TaxRow>
                                        <S.Text>Taxa do cartão</S.Text>
                                        <S.Text>R$ 50,00</S.Text>
                                    </S.TaxRow>}
                                {reservations.promotional ? null : reservations.paymentType === "CREDIT_CARD" && differenceDays <= 7 ?
                                    <S.TaxRow>
                                        <S.Text>Taxa de retenção:</S.Text>
                                        <S.Text>R$ 75,00</S.Text>
                                    </S.TaxRow>
                                    : reservations.paymentType === 'PIX' && differenceDays <= 7 ? <S.TaxRow>
                                        <S.Text>Taxa de retenção:</S.Text>
                                        <S.Text>R$ 75,00</S.Text>
                                    </S.TaxRow> : null}
                                {reservations.paymentType === 'PIX' && differenceDays >= 7 ? null :
                                    <S.TaxRow>
                                        <S.Text>Total de reembolso:</S.Text>
                                        {reservations.promotional ? <S.Text invalid> Não aplicável </S.Text> : <S.Text>R$ 175,00</S.Text>}
                                    </S.TaxRow>}
                                {reservations.paymentType === 'PIX' && differenceDays >= 7 &&
                                    <S.TaxRow>
                                        <S.Text free >Gratuito</S.Text>
                                    </S.TaxRow>}
                            </S.TaxCancelled>
                            <S.ModalButtonsContainer>
                                <Button onClickButton={() => setIsOpen(false)} size="medium" title="Cancelar" type="secondary" widthSize='98px' />
                                <S.ConfirmCancelButton onClick={handleConfirmCancel} > <p>Confirmar cancelamento</p></S.ConfirmCancelButton>
                            </S.ModalButtonsContainer>
                        </ProductCard>
                    </S.Content>}
                    <Alert />
                </>
            )}
        </>
    )
}