// src/hooks/useAdjustPaddingForNotch.ts
import { useEffect } from 'react';

const isIOS = () => {
    return (
        /iPad|iPhone|iPod/.test(navigator.userAgent) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    );
};

const hasNotch = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return (height / width > 2 || width / height > 2);
};

const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
};

const useAdjustPaddingForNotch = (selector: string) => {
    useEffect(() => {
        const element = document.querySelector(selector);
        const headerHeight = document.querySelector('header')?.clientHeight || 0;
        if (element && isIOS() && hasNotch() && isMobile()) {
            (element as HTMLElement).style.paddingTop = `calc(env(safe-area-inset-top) + ${headerHeight}px + 20px)`; // Ajuste conforme necessário
        }
    }, [selector]);
};

export default useAdjustPaddingForNotch;
