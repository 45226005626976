import { mapGetProperties } from "utils/formatterArray";
import fetchAxios from "./http";

export const fetchAccommodations = async () => {
    const { data: lowPriceAccommodation } = await fetchAxios.get(`accommodations/promotion`);

    const { data: empashysAccommodation } = await fetchAxios.get(`/accommodations/empashys
    `,);

    const addUniqueValueAccommodation = empashysAccommodation.map((property: Record<string, any>) => {
        let uniqueValue = 0;
        if (property.emphasys) {
            uniqueValue = Number(property.uniqueValue);

        }
        return {
            ...property,
            uniqueValue,
            building_area: property.area,
            ref_stays: property.ref_staysAcc,
        }
    }
    );
    const addUniqueValueAccommodationPromotion = lowPriceAccommodation.data.filter((item: any) => item.uniqueValue > 90 && item.featured_image !== null).map((property: Record<string, any>) => {


        return {
            ...property,
            building_area: property.area,
            ref_stays: property.ref_staysAcc,
        }
    }
    );


    let mappedProperties = mapGetProperties(addUniqueValueAccommodationPromotion);
    let mappedPropertiesEmpashys = mapGetProperties(addUniqueValueAccommodation);

    return {
        promotionalUnits: mappedProperties.slice(0, 9),
        units: mappedPropertiesEmpashys.slice(0, 9),
    };
}