import styled from "styled-components";
import { FONT_SIZE, FONT_WEIGTH } from "constants/fontConstants";
import { NEUTRAL_COLORS } from "constants/styleConstants";
import { FooterProps } from ".";
import { Link as RouterLink } from "react-router-dom";

export const ContainerFooter = styled.div<FooterProps>`
  display: flex;
  flex-direction: column;

  width: calc(100vw - 6px);

  @media (max-width: 1080px) {
    padding-bottom: 124px;
    background-color: #f6f4f4;

    ${({ theme }) => theme === 'dark' && `
    background-color: ${NEUTRAL_COLORS.HIGH_DARK};
  `
  }

  }

`;

export const Container = styled.div<FooterProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: #f6f4f4;
  min-height: 227.6px;
  ${({ theme }) => theme === 'dark' && `
    background-color: ${NEUTRAL_COLORS.HIGH_DARK};
  `
  }

  @media (max-width: 1080px) {
    min-height: unset;
    flex-direction: column;
  padding-top: 52px;
  }
`;

export const InfoContainer = styled.div<FooterProps>`
 display: flex;
  width: 100%;
  justify-content: center;
  background-color: ${NEUTRAL_COLORS.WHITE};
  min-height:88px;
  ${({ theme }) => theme === 'dark' && `
    background-color: ${NEUTRAL_COLORS.DARK};
    p{
      color: ${NEUTRAL_COLORS.WHITE};
    }
  `
  }

@media (max-width: 1080px) {
    padding-top: 40px;

    min-height: unset;
    background-color: #f6f4f4;
    padding-bottom: 64px;

    align-items: center;
  ${({ theme }) => theme === 'dark' && `
    background-color: ${NEUTRAL_COLORS.HIGH_DARK};
  `
}
  }
  `;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 1366px;
  max-height: 218px;

  @media (max-width: 1080px) {
    width: 100%;
    max-height: unset;
  }
`;

export const UtilLinks = styled.div<FooterProps>`
  display:flex;
  align-items: center;
  justify-content: space-between;
  height: 218px;
  padding:51px 220px 51px 80px;

  p{
    ${({ theme }) => theme === 'dark' && `
    color: ${NEUTRAL_COLORS.WHITE};
  `
  }
  }

  @media (max-width: 1080px) {
    flex-direction: column;

    padding: 0px;
    height: unset;
    justify-content: center;

    gap: 40px;
    padding-top: 28px;
  }

`;

export const CollumContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, fit-content(100%));
  width: 485px;
  align-items: flex-start;
  justify-content: space-around;
  gap: 172px;

  @media (max-width: 1080px) {
    width: 100%;
    grid-template-columns: repeat(1, fit-content(100%));
    flex-direction: column;
    gap: 16px;
  }
`;

export const Collumns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
  gap: 12px;
  font-size: ${FONT_SIZE.TINY};
  font-weight: ${FONT_WEIGTH.LARGE};
  p{
    cursor: pointer;
 &:hover{
  text-decoration: underline;
  cursor: pointer;
 }
  };

  @media (max-width: 1080px) {
    align-items: center;
    text-align: center;
    gap: 16px;

    .wrap{
      max-width: 203px;
      width: 100%;
    }
  }
`;

export const StyledLink = styled(RouterLink)<FooterProps>`
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    cursor: pointer;
    text-decoration: none;
    &:hover{
        text-decoration: underline;
    }
    ${({ theme }) => theme === 'dark' && `
    color: ${NEUTRAL_COLORS.WHITE};
  `
  }
`;

export const FooterInfos = styled.div`
  display: flex;
  width: 1200px;
  align-items: center;
  gap: 190px;
  font-size: ${FONT_SIZE.TINY};
  font-weight: ${FONT_WEIGTH.MEDIUM};
  padding: 12px 0px;
  padding-bottom: 4px;

  @media (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    padding: 0px;
    text-align: center;

    .cnpj{
      max-width: 226px;
      width: 100%;
    }
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  gap: 50px;
  white-space: nowrap;

  @media (max-width: 1080px) {
    flex-direction: column;

    gap: 16px;
  }

`;

export const FooterIcons = styled.div`
  display: flex;
  gap: 31px;

  @media (max-width: 1080px) {
    align-items: center;
    justify-content: center;

    gap: 32px;
  }
`;