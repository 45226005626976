import React, { useState, useRef } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { ButtonCarousel } from 'components/ButtonCarousel';
import { CommentsUsers } from 'components/CommentsUsers';
import { CommentsUsersFlexHousing } from 'components/CommentsUsersFlexHousing';

export type Comments = {
    id: string;
    author: string;
    comment: string;
    logo: string;
    developer: string;
};

interface CarouselCommentsProps {
    comments: Comments[]

}

const ArrowIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.25 12H3.75" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.5 5.25L3.75 12L10.5 18.75" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

const MobileCarouselCommentsDeveloper: React.FC<CarouselCommentsProps> = ({ comments }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate()

    const arrowIcon = require('assets/svg/arrowComments.svg').default;


    const nextSlide = () => {
        if (currentIndex < comments.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };
    return (
        <S.Container>
            <S.CarouselContainer ref={slideRef}>
                {comments.slice(0, 3).map((comment, index: any) => (
                    <S.Slide
                        key={index}
                        active={index === currentIndex}>
                        <S.CarouselItem key={comment.id}>
                            <S.BackgroundContainer>
                                <h3>{comment.comment}</h3>
                                <S.UserInfo>
                                    <S.Author>
                                        <strong>{comment.author}</strong>
                                        <p>{comment.developer}</p>
                                    </S.Author>
                                    <S.Logo>
                                        <img src={comment.logo} alt="" />
                                    </S.Logo>
                                </S.UserInfo>
                            </S.BackgroundContainer>
                        </S.CarouselItem>
                    </S.Slide>
                ))}
            </S.CarouselContainer>
            <S.ButtonContainer>
                <S.LeftButton onClick={prevSlide} >
                    <ArrowIcon />
                </S.LeftButton>
                <S.RightButton onClick={nextSlide} >
                    <ArrowIcon />
                </S.RightButton>
            </S.ButtonContainer>
        </S.Container>
    );
};

export default MobileCarouselCommentsDeveloper;
