import React, { forwardRef } from 'react';
import { Controller, Control } from 'react-hook-form';
import * as S from './styles';

interface RadioButtonProps {
    name: string;
    control: Control<any>;
    label: string;
    value: string;
}

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(({ name, control, label, value }, ref) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value: selectedValue } }) => (
            <S.Container>
                <S.InputColumn>
                    <label>
                        <input
                            type="radio"
                            value={value}
                            checked={selectedValue === value}
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref} // Agora podemos usar ref aqui
                        />
                        {label}
                    </label>
                </S.InputColumn>
            </S.Container>
        )}
    />
));

RadioButton.displayName = 'RadioButton';

export { RadioButton };
