import styled, { css } from "styled-components";
import { NEUTRAL_COLORS, STATUS_COLORS } from "../../../../constants/styleConstants";
import { FONT_SIZE, FONT_WEIGTH } from "../../../../constants/fontConstants";

export const gridStyles = (size:number) => {
  switch (size) {
    case 9:
      return css`
        .grid0 {
    grid-column: 3 / span 8;
    grid-row: 1 / 2;
    min-width: 854px;
  }

  .grid1,.grid2{
    min-width: 421px;
    grid-row: 2 / 3;
  }

  .grid1 {
    grid-column: 3 / 7;

    margin-right: 24px;
  }

  .grid2 {
    grid-column: 7 / span 4;
    margin-left: 24px;
  }

  .grid3,.grid6{
    min-height: 618px;
    min-width: 516px;
    margin-right: 32px;
  }

  .grid3 {
    grid-column: 3 / span 5;
    grid-row: 3 / 5;
  }

  .grid4{
    grid-column: 9 / span 2;
    grid-row: 3 / 4;
  }

  .grid5 {
    grid-column: 9 / span 2;
    grid-row: 4 / 5;
  }

  .grid6 {
    grid-column: 3 / span 5;
    grid-row: 5 / 7;
  }

  .grid4,.grid5,.grid7,.grid8{
    margin-right: 88px;
    min-width: 324px;
    height: 301px;
  }

  .grid7 {
    grid-column: 9 / span 2;
    grid-row: 5 / 6;
  }

  .grid8 {
    grid-column: 9 / span 2;
    grid-row: 6 / 7;
  }
      `;
    case 8:
      return css`
    .grid0,.grid1{
    min-width: 324px;
    height: 301px;
    grid-column: 3/ span 3;
    margin-right: 16px;
  }

  .grid0 {
    grid-row: 1 / 2;
  }

  .grid1 {
    grid-row: 2 / 3;
  }

  .grid2{
    grid-column: 6 / span 5;
    grid-row: 1 / 3;
    min-height: 618px;
    min-width: 516px;
    margin-left: 28px;
  }

  .grid3{
    min-height: 618px;
    min-width: 516px;
    margin-right: 32px;
  }

  .grid3 {
    grid-column: 3 / span 5;
    grid-row: 3 / 5;
  }

  .grid4{
    grid-column: 9 / span 2;
    grid-row: 3 / 4;
  }

  .grid5 {
    grid-column: 9 / span 2;
    grid-row: 4 / 5;
  }


  .grid4,.grid5{
    margin-right: 88px;
    min-width: 324px;
    height: 301px;
  }

  .grid6,.grid7{
    min-width: 421px;
    grid-row: 5 / 6;
    height: 410px;
  }

  .grid6 {
    grid-column: 3 / 7;
    margin-right: 24px;
  }

  .grid7 {
    grid-column: 7 / span 4;
    margin-left: 24px;
  }


      `;
    case 7:
      return css`
        .grid0,.grid1{
    min-width: 324px;
    height: 301px;
    grid-column: 3/ span 3;
    margin-right: 16px;
  }

  .grid0 {
    grid-row: 1 / 2;
  }

  .grid1 {
    grid-row: 2 / 3;
  }

  .grid2{
    grid-column: 6 / span 5;
    grid-row: 1 / 3;
    min-height: 618px;
    min-width: 516px;
    margin-left: 28px;
  }

  .grid3{
    min-height: 618px;
    min-width: 516px;
    margin-right: 32px;
  }

  .grid3 {
    grid-column: 3 / span 5;
    grid-row: 3 / 5;
  }

  .grid4{
    grid-column: 9 / span 2;
    grid-row: 3 / 4;
  }

  .grid5 {
    grid-column: 9 / span 2;
    grid-row: 4 / 5;
  }


  .grid4,.grid5{
    margin-right: 88px;
    min-width: 324px;
    height: 301px;
  }

  .grid6{
    grid-row: 5 / 6;
    grid-column: 3 / span 8;
    min-width: 854px;
  }
      `;
    case 6:
      return css`
        .grid0,.grid1{
    min-width: 324px;
    height: 301px;
    grid-column: 3/ span 3;
    margin-right: 16px;
  }

  .grid0 {
    grid-row: 1 / 2;
  }

  .grid1 {
    grid-row: 2 / 3;
  }

  .grid2{
    grid-column: 6 / span 5;
    grid-row: 1 / 3;
    min-height: 618px;
    min-width: 516px;
    margin-left: 28px;
  }

  .grid3,.grid4{
    min-width: 421px;
    grid-row: 3 / 4;
    height: 410px;
  }

  .grid3 {
    grid-column: 3 / 7;

    margin-right: 24px;
  }

  .grid4 {
    grid-column: 7 / span 4;
    margin-left: 24px;
  }

  .grid5{
    grid-row: 4 / 5;
    grid-column: 3 / span 8;
    min-width: 854px;
  }
      `;
    case 5:
      return css`
          .grid0,.grid1{
    min-width: 324px;
    height: 301px;
    grid-column: 3/ span 3;
    margin-right: 16px;
  }

  .grid0 {
    grid-row: 1 / 2;
  }

  .grid1 {
    grid-row: 2 / 3;
  }

  .grid2{
    grid-column: 6 / span 5;
    grid-row: 1 / 3;
    min-height: 618px;
    min-width: 516px;
    margin-left: 28px;
  }

  .grid3,.grid4{
    min-width: 421px;
    grid-row: 3 / 4;
    height: 410px;
  }

  .grid3 {
    grid-column: 3 / 7;

    margin-right: 24px;
  }

  .grid4 {
    grid-column: 7 / span 4;
    margin-left: 24px;
  }
      `;
    case 4:
      return css`
          .grid0,.grid1{
    min-width: 324px;
    height: 301px;
    grid-column: 3/ span 3;
    margin-right: 16px;
  }

  .grid0 {
    grid-row: 1 / 2;
  }

  .grid1 {
    grid-row: 2 / 3;
  }

  .grid2{
    grid-column: 6 / span 5;
    grid-row: 1 / 3;
    min-height: 618px;
    min-width: 516px;
    margin-left: 28px;
  }

  .grid3 {
    grid-row: 3 / 4;
    grid-column: 3 / span 8;
    min-width: 854px;
  }
      `;
    case 3:
      return css`
          .grid0,.grid1{
    min-width: 324px;
    height: 301px;
    grid-column: 3/ span 3;
    margin-right: 16px;
  }

  .grid0 {
    grid-row: 1 / 2;
  }

  .grid1 {
    grid-row: 2 / 3;
  }

  .grid2{
    grid-column: 6 / span 5;
    grid-row: 1 / 3;
    min-height: 618px;
    min-width: 516px;
    margin-left: 28px;
  }
      `;
    case 2:
      return css`
          .grid0,.grid1{
    min-width: 421px;
    height: 410px;
  }

  .grid0 {
    grid-column: 3 / 7;
    margin-right: 24px;
  }

  .grid1 {
    grid-column: 7 / span 4;
    margin-left: 24px;
  }
      `;
    case 1:
      return css`
          .grid0 {
    grid-column: 3 / span 8;
    grid-row: 1 / 2;
    min-width: 854px;
  }
      `;
    default:
      return css``;
  }
};

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    position: relative;

    width:1206px;

    margin-top: 118px;
    margin-bottom: 16px;

    &::-webkit-scrollbar-track{
      margin-top: 130px ;
    }
`

export const Title = styled.h1`
    font-size: ${FONT_SIZE.LARGE};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140% ;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    align-items: flex-end;
`;

export const GuideContainer = styled.div`
    display: flex;

    width: 100%;

    justify-content: flex-start;
    align-items: center;

    gap: 8px;
`

export const GuideTextPass = styled.p`
    font-size: ${FONT_SIZE.TINY};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.DARK_GRAY};
    &:nth-child(1){
        cursor: pointer;
    }
`;

export const GuideTextActual = styled(GuideTextPass)`
    color: ${NEUTRAL_COLORS.DARK};
`;

export const FavButton = styled.button<{active:boolean, visualizer?:boolean}>`
    display: flex;

    align-items: center;
    justify-content: center;

    width: 135px;
    height: 40px;

    gap:16px;

    padding: 8px 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    svg{
        height: ${props => props.active ? '26px' : '24px'};
       path{
        stroke: ${props => props.active ?  'transparent': props.visualizer ? NEUTRAL_COLORS.GRAY : NEUTRAL_COLORS.HIGH_DARK  };
        fill: ${props => props.active ? STATUS_COLORS.ERROR : 'transparent'}

    };
       }

`;

export const GridImages = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;

    gap: 8px;

    border-radius: 16px;

     & > img:nth-child(1) {
        grid-row: 1 / 3;
        width: 635px;
        height: 386px;
        border-top-left-radius:  16px;
        border-bottom-left-radius:  16px;
     }

     & > img:nth-child(n+2):nth-child(-n+5) {
        max-width: 100%;
        max-height: 189px;
     }

     & > img:nth-child(3){
        border-top-right-radius: 16px;
     }

     & > img:nth-child(5){
        border-bottom-right-radius: 16px;
     }
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    `

export const ButtonGallery = styled.button`
    display: flex;

    align-items: center;
    justify-content: center;

    position: absolute;

    right: calc(0% + 16px);
    bottom: calc(0% + 16px);

    padding: 8px 16px;

    gap: 16px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    max-height: 40px;

    cursor: pointer;

    img{
            width: 24px;
            height: 24px;

    }

    p{
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.LARGE};
        line-height: 140%;
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        white-space: nowrap;
    }
`;

export const GridGallery = styled.div<{size:number}>`
  ${({size}) => size !==  0 ?  css`
  display: grid;
  grid-template-columns: repeat(12, 71px);

  gap: 16px 32px;

  place-content: center start;
  place-items: start center;

  width: 100%;

  & > img {
    max-height: 410px;
  }

  & > span {
    max-height: 410px;
  }
  ` : css`
  display: none;
  `}

  ${({size}) => gridStyles(size)}
`;

