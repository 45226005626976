import { Footer } from "components/Footer"
import { HeaderAlt } from "components/HeaderAlt"
import { Inputs } from "components/Input"
import { useState } from "react"

export const HelpCenter = () => {

    const [search, setSearch] = useState('')

    return (
        <div>
            <HeaderAlt/>
        <Inputs.Search placeholder="Pesquisar" width={'100%'} htmlFor="search" value={search} onChange={(e) => setSearch(e.currentTarget.value)} />
            <Footer/>
        </div>
    )
}