import { zodResolver } from "@hookform/resolvers/zod"
import { AxiosError } from "axios"
import useAlert from "components/Notification"
import { useSessionStorage } from "context/sessionStorage"
import { useCheckin } from "pages/Checkin/hook/useCheckin"
import { useAdditionalInfoSchema } from "pages/Checkin/schemas/AdditionalInfo"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import fetchAxios from "services/http"
import { z } from "zod"

export const useAdditionalInfo = () => {

    const { t } = useTranslation()

    const { accompanying, checkinDetails, arrivelAndDepartureHours, address, guestCheckinData } = useCheckin()

    const [hasAnswerReasonTrip, setHasAnswerReasonTrip] = useState<boolean>(false)
    const [hasAnswerHowToFindYogha, setHasAnswerHowToFindYogha] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const { Alert, showAlert } = useAlert({ active: false, title: '', type: "SUCCESS" })

    const { saveToSessionStorage, removeFromSessionStorage } = useSessionStorage()


    const navigate = useNavigate();

    const schema = useAdditionalInfoSchema();

    type FormData = z.infer<typeof schema>;

    const { handleSubmit, formState: { errors }, setValue, control, trigger, watch } = useForm<FormData>({
        resolver: zodResolver(schema),
        defaultValues: {
            accept: false
        }
    });

    const pathnameActual: string = window.location.pathname.endsWith('/') ? window.location.pathname.slice(0, -1) : window.location.pathname.slice(0, window.location.pathname.lastIndexOf('/'));

    const arrayOptionReasonForTravel = [
        { value: t('checkin.additionalInformation.forms.select.options.business'), label: t('checkin.additionalInformation.forms.select.options.business') },
        { value: t('checkin.additionalInformation.forms.select.options.tourism'), label: t('checkin.additionalInformation.forms.select.options.tourism') },
        { value: t('checkin.additionalInformation.forms.select.options.studies'), label: t('checkin.additionalInformation.forms.select.options.studies') },
        { value: t('checkin.additionalInformation.forms.select.options.other'), label: t('checkin.additionalInformation.forms.select.options.other') },
    ]

    const arrayOptionHowFindYogha = [
        { value: t('checkin.additionalInformation.forms.select.options.airbnb'), label: t('checkin.additionalInformation.forms.select.options.airbnb') },
        { value: t('checkin.additionalInformation.forms.select.options.google'), label: t('checkin.additionalInformation.forms.select.options.google') },
        { value: t('checkin.additionalInformation.forms.select.options.referals'), label: t('checkin.additionalInformation.forms.select.options.referals') },
        { value: t('checkin.additionalInformation.forms.select.options.socialNetworks'), label: t('checkin.additionalInformation.forms.select.options.socialNetworks') },
        { value: t('checkin.additionalInformation.forms.select.options.other'), label: t('checkin.additionalInformation.forms.select.options.other') },
    ]

    const translateAnwser = (anwser: string) => {
        switch (anwser) {
            case 'Business':
                return 'Negócios'
            case 'Tourism':
                return 'Turismo'
            case 'Studies':
                return 'Estudo'
            case 'Other':
                return 'Outro'
            case 'Airbnb':
                return 'Portal Airbnb'
            case 'Referals':
                return 'Indicação'
            case 'Social Networks':
                return 'Redes sociais'
            case 'Google':
                return 'Google'
            default:
                return anwser
        }
    }

    const onSubmit = async (data: FormData) => {
        saveToSessionStorage('additional-info', data)

        setLoading(true)
        try {
            const res = await fetchAxios.put(`/checkin/update/${checkinDetails.checkinId}`, {
                city_origin: data.originCity,
                booking_id: checkinDetails.booking_id,
                question_yogha: translateAnwser(data.howToFindYogha.value),
                question_reason_trip: translateAnwser(data.reasonToTrip.value),
                question_curitiba: data.knownTheCity,
                arrival_time: arrivelAndDepartureHours.arrival_time,
                checkout_time: arrivelAndDepartureHours.checkout_time,
                other_yogha_question: data.howToFindYoghaAnswer ?? '',
                other_reason_trip_question: data.reasonToTripAnswer ?? '',
                ...address,
                ...guestCheckinData
            })
            let guestId;

            if (guestCheckinData.method === 'create') {
                guestId = res.data.user.id;
            } else {
                guestId = guestCheckinData.customer_id !== null && guestCheckinData.customer_id !== undefined ? guestCheckinData.customer_id : res.data.user.id;
            }

            if (accompanying.length > 0) {
                try {
                    const res = await fetchAxios.post(`/checkin/escorts`, {
                        data: accompanying
                    })
                } catch (error) {
                   console.error(error)
                }
            }
            navigate(`${pathnameActual}/successful`,{ replace: true })
        } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Erro ao cadastrar") {
                removeFromSessionStorage('checkin-details')
                removeFromSessionStorage('personal-info')
                removeFromSessionStorage('accompanying')
                removeFromSessionStorage('address')
                removeFromSessionStorage('arrival-and-departure-time')
                removeFromSessionStorage('additional-info')
                showAlert({ active: true, title: t('checkin.expiredCheckin'), type: "ERROR" })
                setTimeout(() => {
                    navigate(`${pathnameActual}/`, { replace: true })
                }, 3000);
            }
                if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data && axiosError.response.data.message === "Checkin já foi finalizado") {
                    removeFromSessionStorage('checkin-details')
                    removeFromSessionStorage('personal-info')
                    removeFromSessionStorage('accompanying')
                    removeFromSessionStorage('address')
                    removeFromSessionStorage('arrival-and-departure-time')
                    removeFromSessionStorage('additional-info')
                    showAlert({ active: true, title: t('checkin.hasFinished'), type: "ERROR" })
                    setTimeout(() => {
                        navigate(`${pathnameActual}/`,{ replace: true })
                    }, 3000);
                }
        } finally {
            setLoading(false)
        }
    }

    const handleBackPage = () => {
        navigate(`${pathnameActual}/arrival-and-departure`)
    }


    const watchReasonToTrip = watch('reasonToTrip');
    const watchHowToFindYogha = watch('howToFindYogha');

    useEffect(() => {
        if (watchReasonToTrip?.value === 'Other' || watchReasonToTrip?.value === 'Outro') {
            setHasAnswerReasonTrip(true)
        } else {
            setHasAnswerReasonTrip(false)
        }
        if (watchHowToFindYogha?.value === 'Other' || watchHowToFindYogha?.value === 'Outro') {
            setHasAnswerHowToFindYogha(true)
        } else {
            setHasAnswerHowToFindYogha(false)
        }
    }, [watchReasonToTrip, watchHowToFindYogha])

    return {
        onSubmit,
        handleBackPage,
        hasAnswerReasonTrip,
        hasAnswerHowToFindYogha,
        arrayOptionReasonForTravel,
        arrayOptionHowFindYogha,
        control,
        errors,
        Alert,
        handleSubmit,
        setValue,
        trigger,
        loading
    }
}