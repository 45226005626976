import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'context/sessionStorage';
import { useAnyProvider } from 'hooks/Context';
import { useEffect, useRef, useState } from 'react';

const logo = require('assets/image/logo.png');
const icon = require('assets/svg/checkin/ChangeLanguageIcon.svg').default;
const brazilFlag = require('assets/image/checkin/BrazilFlag.png');
const usaFlag = require('assets/image/checkin/UsaFlag.png');


export const CheckinHeader = () => {
    const [openModal, setOpenModal] = useState(false)
    const [changeLanguageModal, setChangeLanguageModal] = useState(false)
    const navigate = useNavigate()
    const { setDate } = useAnyProvider();
    const { i18n, t } = useTranslation();
    const { removeFromSessionStorage } = useSessionStorage()

    const modalRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            setOpenModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const { saveToSessionStorage } = useSessionStorage()

    const changeLanguage = (language: string) => {
        setChangeLanguageModal((changeLanguageModal) => !changeLanguageModal)
        if (i18n.language !== language)
            i18n.changeLanguage(language);
        saveToSessionStorage('language', language)
    };

    useEffect(() => {
        setOpenModal(false)
    }, [i18n.language, changeLanguageModal])


    const backToHome = () => {
        setDate([])
        removeFromSessionStorage('dateHeader')
        navigate('/')
    }

    return (
        <S.Header>
            <S.LogoContainer onClick={backToHome}>
                <img src={logo} alt="" />
            </S.LogoContainer>
            <S.ChangeLanguageButton onClick={() => setOpenModal(true)}>
                <img src={icon} alt="" />
                {openModal &&
                    <S.ChangeLanguageModal ref={modalRef} >
                        <S.LanguageContainer onClick={() => {
                            changeLanguage('en')
                        }}>
                            <img src={usaFlag} alt="" />
                            <p>{t('checkin.english')}</p>
                        </S.LanguageContainer>
                        <S.LanguageContainer onClick={() => changeLanguage('pt')}>
                            <img src={brazilFlag} alt="" /> <p>{t('checkin.portuguese')}</p>
                        </S.LanguageContainer>
                    </S.ChangeLanguageModal>}
            </S.ChangeLanguageButton>
        </S.Header>
    )
}