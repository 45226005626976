import styled from "styled-components";
import { FONT, FONT_SIZE, } from "../../constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "../../constants/styleConstants";
import { Link } from "react-router-dom";
import { IInput, NewInputProps } from "components/Input/types";
import ReactInputMask from "react-input-mask";
import { IMaskInput } from "react-imask";

type InputProps = Omit<IInput, "htmlFor" | "erroText" | "title" | "inputType">

export const InputContainer = styled.div<Pick<NewInputProps , 'errorMessages' | 'showPassword'|'width' | 'heightInput' |'disabled'>>`
    display: grid;
    grid-template-columns: 1fr;

    position: relative;

    align-items: start;

    justify-content: center;

    white-space: pre-wrap;

    label{
        ${({theme}) => theme === 'dark' && `
            color: ${NEUTRAL_COLORS.WHITE};
        `};
        ${FONT.BODY.BODY_SMALL[500]}
        margin-bottom: 8px;
    }

    input[type="checkbox"]{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        cursor: pointer;

        min-width: 24px;
        min-height: 24px;

        border-radius: 8px;
        border: 2px solid ${NEUTRAL_COLORS.GRAY};
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            font-family: "Font Awesome 5 Free";
            content: "\f00c";
            font-weight: 700;
            font-size: ${FONT_SIZE.TINY};
            color: ${NEUTRAL_COLORS.WHITE};
            display: none;
        };
        &:hover{
            background: ${NEUTRAL_COLORS.LIGHT_GRAY};
        }
        &:checked{
            background: ${PRIMARY_COLORS.MEDIUM};
            border: 2px solid transparent;
        }
        &:checked::after{
            display: block;
        }
    }
    input{
        border: 1px solid ${props => props.errorMessages === true ? STATUS_COLORS.ERROR : props.disabled ? STATUS_COLORS.DISABLED :  NEUTRAL_COLORS.GRAY};
    line-height: 140%;
    border-radius: 10px;
    font-size: ${FONT_SIZE.TINY};
    padding-left: 1rem;
    padding-right: ${({showPassword}) => showPassword && '5.8rem'};
    width: ${({width, showPassword}) =>  width && showPassword === true ? `calc(${width} - 16px - 5.8rem) ` : width ? `calc(${width} - 16px) `: '302px'};
    height: ${props => props.heightInput !== undefined ? props.heightInput : 52  }px;
    color: ${props => props.disabled  ? STATUS_COLORS.DISABLED : NEUTRAL_COLORS.HIGH_DARK };

    ::placeholder {
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }

    :focus{
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        outline: none;
        border: 1px solid ${PRIMARY_COLORS.MEDIUM};
        ::placeholder {
            color: ${NEUTRAL_COLORS.HIGH_DARK};
        }
    }

    :disabled{
        color: ${STATUS_COLORS.DISABLED};
        border: 1px solid ${STATUS_COLORS.DISABLED};
    }

    ${({theme}) => theme === 'dark' && `
        background-color: transparent;
        color: ${NEUTRAL_COLORS.WHITE};
    `}
    }
`;



export const TextArea = styled.textarea`
    width: calc(100% - 16px);
    resize: none;
    padding: 8px;
    line-height: 1.4;
    border-radius: 10px;
    font-size: ${FONT_SIZE.TINY};
    height: 140px;
    ::placeholder {
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }

    :focus{
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        outline: none;
        border: 1px solid ${PRIMARY_COLORS.MEDIUM};
        ::placeholder {
            color: ${NEUTRAL_COLORS.HIGH_DARK};
        }
    }

    :disabled{
        color: ${STATUS_COLORS.DISABLED};
        border: 1px solid ${STATUS_COLORS.DISABLED};
    }


`

export const ShowPassword = styled.div`
    display: flex;

    position: absolute;

    right: 14px;
    top: 44px;

    align-items: center;
    justify-content: center;

    padding: 0 8px;

    cursor: pointer;
    p{
        ${FONT.BODY.BODY_SMALL[600]};
        color: ${PRIMARY_COLORS.MEDIUM};
        :hover{
            color: ${PRIMARY_COLORS.DARK};
        }
    }
`;


export const ErrorContainer = styled.div<Pick<IInput, 'errorMessages'>  >`
    display: flex;

    align-items: flex-start;
    justify-content: flex-start;

    margin-top: 4px;
    gap:4px;
`;

export const ErrorText = styled.p<Pick<IInput, 'errorLinkText'>>`
    color: ${STATUS_COLORS.ERROR};
    ${({errorLinkText}) => errorLinkText ? FONT.CAPTION.CAPTION_SEMI_REGULAR : FONT.BODY.BODY_SMALL[400]};
`;

    export const Row = styled.div`
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    justify-content: flex-start;

    gap: 16px;

    label{
        ${FONT.BODY.BODY_MEDIUM[400]};
    }
    input[type="checkbox"]{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        cursor: pointer;

        min-width: 24px;
        min-height: 24px;

        border-radius: 8px;
        border: 2px solid ${NEUTRAL_COLORS.GRAY};
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            font-family: "Font Awesome 5 Free";
            content: "\f00c";
            font-weight: 700;
            font-size: ${FONT_SIZE.TINY};
            color: ${NEUTRAL_COLORS.WHITE};
            display: none;
        };
        &:hover{
            background: ${NEUTRAL_COLORS.LIGHT_GRAY};
        }
        &:checked{
            background: ${PRIMARY_COLORS.MEDIUM};
            border: 2px solid transparent;
        }
        &:checked::after{
            display: block;
        }
    }
    `;

export const Highlight = styled(Link)`
    text-decoration: none;
    ${FONT.BODY.BODY_MEDIUM[400]};
    color: ${PRIMARY_COLORS.MEDIUM};
    &:hover{
        color: ${PRIMARY_COLORS.DARK};
    }
`;

export const ErrorLink = styled(Link)`
    ${FONT.CAPTION.CAPTION_SEMI_BOLD};
    color: ${STATUS_COLORS.ERROR};
`;

