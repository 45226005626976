import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

export const SelfieValidationContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    h1 {
        ${FONT.HEADLINES.H5}
    }
    padding-bottom: 48px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        padding-bottom: 0px;
    }

`;

export const ContentSelfieValidation = styled.div`
    display: grid;
    grid-template-columns: repeat(2,fit-content(100%));

    gap: 32px;
    margin-top: 32px;
    margin-bottom: 64px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        width: 100%;
        gap: 16px;
    }
`;

export const WarningMessage = styled.div`
    display: flex;
    gap: 8px;

    align-items: center;


    max-width: 800px;

    padding: 16px;

    border-radius: 10px;
    border: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};

    p{
        ${FONT.BODY.BODY_SMALL[400]}
    }

    margin-top: 32px;
`;

export const RowCheckSelfie = styled.div`
    display: flex;
    gap: 8px;

    align-items: center;

    img {
        width: 24px;
        height: 24px;
    }

    p {
        ${FONT.BODY.BODY_SMALL[400]}
    }
`;

export const ColumnCheckSelfie = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;
    margin-top: 36px;

    max-width: 469px;

    @media (max-width: 768px) {
        margin-top: 4px;
    }
`;

export const InputFileSelfie = styled.div`
    display: flex;
    gap: 8px;

    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 332px;
    height: 251px;

    border-radius: 10px;
    border: 2px dashed${PRIMARY_COLORS.MEDIUM};
    background: #FFF7F2;

    img {
        width: 24px;
        height: 24px;
    }

    p {
        ${FONT.BODY.BODY_SMALL[400]}
    }

    cursor: pointer;

    @media (max-width: 768px) {
        width: calc(100%);
    }
`;

export const SelfiContainer = styled.div`
    display: flex;
    position: relative;

    align-items: center;
    justify-content: center;

    width: 332px;
    height: 251px;

    border-radius: 10px;
    border: 2px solid ${NEUTRAL_COLORS.LIGHT_GRAY};

    img {
        width: 188px;
        height: 188px;
        object-fit: cover;
    }
`;

export const DeleteButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    border-radius: 50%;
    background-color: white;

    position: absolute;
    top: 12px;
    right: 12px;
    padding: 10px;

    max-width: 44px;
    max-height: 44px;

    img{
        width: 24px;
        height: 24px;
    }

    cursor: pointer;
`;

export const ContainerInputFile = styled.div`
    display: flex;
    flex-direction: column;

    gap: 16px;

    strong {
        ${FONT.BODY.BODY_MEDIUM[600]}
    }
`;