import * as S from './styles'

export const LoadingMobile = () => {

    const loadingImage = require('assets/image/payment/Loading.png')

    return (
        <S.Container>
            <S.LoadingImage src={loadingImage} />
        </S.Container>
    )

}