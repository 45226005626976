import { type } from 'os';
import { useEffect, useState, useRef } from 'react';

type MobileNavigationProps = {
    isLoadingOrIsFetching: boolean,
    setVisibleNavigation: any,
}

export const
    useMobileNavigation = ({ isLoadingOrIsFetching, setVisibleNavigation }: MobileNavigationProps) => {
        const [scrollElement, setScrollElement] = useState<Element | null>(null);
        const [canToggleVisibility, setCanToggleVisibility] = useState(true);
        const lastScrollPosition = useRef(0);  // Utilizando useRef aqui

        const elementLoaded = (element: Element) => {
            setScrollElement(element);
        };

        useEffect(() => {
            const handleScroll = () => {
                const newScrollPosition = scrollElement ? scrollElement.scrollTop : window.scrollY;

                if (newScrollPosition <= 0) {
                    // Se o usuário está no topo, defina como true
                    setVisibleNavigation(true);
                    return;
                } else if (newScrollPosition >= 1) {
                    setVisibleNavigation(false);
                } else {
                    setVisibleNavigation(false);
                }

                if (!canToggleVisibility) {
                    // Não mude a visibilidade se não puder
                    return;
                }



                // Bloqueie a mudança de visibilidade por 1,5 segundos
                setCanToggleVisibility(false);
                setTimeout(() => setCanToggleVisibility(true), 1500);

                lastScrollPosition.current = newScrollPosition;  // Atualizando o valor aqui
            };

            if (scrollElement) {
                scrollElement.addEventListener("scroll", handleScroll);
            } else {
                window.addEventListener("scroll", handleScroll);
            }

            return () => {
                if (scrollElement) {
                    scrollElement.removeEventListener("scroll", handleScroll);
                } else {
                    window.removeEventListener("scroll", handleScroll);
                }
            };
        }, [isLoadingOrIsFetching, scrollElement, canToggleVisibility]);

        return {
            elementLoaded
        };
    };
