import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled from "styled-components";

type VisibleContainerProps = {
    visible: boolean;
}

export const VerifyDocumentContainer = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: 100%;


    h1{
        ${FONT.HEADLINES.H5}
        margin-bottom: 16px;
    }
    h4{
        max-width: 512px;
        width: 100%;
        ${FONT.BODY.BODY_SMALL[400]};
        margin-bottom: 40px;
    }

    padding-bottom: 48px;

    @media (max-width: 768px) {
        width: calc(100% - 42px);
        h4{
            max-width: 100%;
        }
        h1{
            text-align: center;
        }
        padding: 20px;
        padding-bottom: 0;
    }
`;

export const ExplainContainer = styled.div<VisibleContainerProps>`
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;

    align-items: start;
    justify-content: center;

    width: 100%;
    max-width: calc(649px - 64px);
    padding: 32px;

    margin-bottom: 136px;

    gap: 28px;

    strong{
        ${FONT.BODY.BODY_SMALL[500]};
    }
    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }
    small{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};

    }

    @media (max-width: 768px) {
        width: calc(100% - 20px);
        margin-bottom: 48px;
    }
`;

export const ExplainContent = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    gap: 8px;

    &:nth-child(3){
        position: relative;
        ::before{
            content: '';
            left: 10px;
            top: -24px;
            position: absolute;
            width: 2px;
            height: 18px;
            background-color: ${PRIMARY_COLORS.MEDIUM};
        }
    }
    img{
        width: 24px;
        height: 24px;
    }
`;

export const GuideContent = styled.div<VisibleContainerProps>`
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;

    align-items: center;
    justify-content: center;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }

    img{
        margin-top: 40px;
        margin-bottom: 32px;
    }

    ul{
        max-width: 556px;
        width: 100%;
        li{
            ${FONT.BODY.BODY_SMALL[400]};
        }
        padding-left: 32px;
    }

    margin-bottom: 48px;

    @media (max-width: 768px) {
        margin-bottom: 48px;
    }
`;

export const ColumnInputFiles = styled.div`
    display: flex;

    gap: 32px;

    margin-bottom: 84px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const ContainerInputFiles = styled.div`
    display: flex;

    flex-direction: column;

    gap: 16px;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }

    @media (max-width: 768px) {
        width: calc(100% - 40px);
    }

`;

export const SendFilesContainer = styled.div<VisibleContainerProps>`
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;

    align-items: center;
    justify-content: center;

    gap: 40px;



    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }

    @media (max-width: 768px) {
        align-items: start;
        width: 100%;
    }
`;