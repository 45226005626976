import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export const useArrivalAndDepartureSchema = () => {
    const { t } = useTranslation();

    const isValidHour = (data: string) => {
      if (data.length !== 4 ) return false;
      const hour = parseInt(data.substring(0, 2));
      const minute = parseInt(data.substring(2, 4));
      return hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59;
    };

    const schema = z.object({
      arrivalHour: z.string({ required_error: t('checkin.arrivalAndDepartureTime.forms.errors.checkinTime') })
        .nonempty({
          message: t('checkin.arrivalAndDepartureTime.forms.errors.checkinTime'),
        })
        .refine(isValidHour, {
          message: t('checkin.arrivalAndDepartureTime.forms.errors.invalidHour'),
        }),
      departureHour: z.string({ required_error: t('checkin.arrivalAndDepartureTime.forms.errors.checkoutTime') })
        .nonempty({
          message: t('checkin.arrivalAndDepartureTime.forms.errors.checkoutTime'),
        })
        .refine(isValidHour, {
          message: t('checkin.arrivalAndDepartureTime.forms.errors.invalidHour'),
        }),
      accept: z.boolean({ required_error: t('checkin.arrivalAndDepartureTime.forms.errors.accept') })
    }).superRefine((data,context) => {

    }
    );


    return schema;
  };