import { Button } from "components/Button"
import * as S from "./styles"
import { Link } from "react-router-dom"

const cleaningTheHouse = require('assets/svg/CleaningTheHouse.svg').default

export const Maintenance = () => {
    return (
        <S.Container>
            <S.Content>
                <S.MainSection>
                    <img src={cleaningTheHouse} alt="" />
                    <S.TextSection>
                        <h1>
                            Estamos arrumando a casa por aqui!
                            <br />
                            Mas não se preocupe, pois logo estaremos de volta ;)
                        </h1>
                    </S.TextSection>
                </S.MainSection>
                <S.FooterContainerText>
                    <span>Precisa de ajuda ou deseja fazer uma reserva?
                        Entre em contato com a nossa equipe</span>
                    <S.ButtonContainer>
                        <Link style={{
                            textDecoration: 'none'
                        }} to={'https://wa.me/5541996941949'}>
                            <Button onClickButton={() => { }} size="large" widthSize={'100%'} title="Fale com a nossa equipe" type="primary" />
                        </Link>
                    </S.ButtonContainer>
                </S.FooterContainerText>

            </S.Content>
        </S.Container>
    )
}