import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns';
import * as S from './styles'
import { ptBR } from 'date-fns/locale';
import { ReservesProps } from 'pages/SpaceUser/pages/Reservations';
import { Button } from 'components/Button';

export const Reserve: React.FC<ReservesProps> = ({ ...item }) => {

    const navigate = useNavigate()

    const userIcon = require('../../../assets/svg/spaceUser/User.svg').default;

    const GadgetType = (type: ReservesProps['type']): string => {
        switch (type) {
            case 'UNPAID':
                return 'Aguardando pagamento'
            case 'CONFIRMADA':
                return 'Concluído'
            case 'CANCELLED':
                return 'Reserva cancelada'
            case "RECEIVED":
                return 'Reservado'
            case "PAID":
                return 'Reservado'
            default:
                return 'Tipo de reserva inválida'
        }
    }

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const formatDate = (dateStr: string): string => {
        const dateObj = parseISO(dateStr);
        const formattedDate = format(dateObj, "dd MMM yyyy", { locale: ptBR });
        const parts = formattedDate.split(' ');
        parts[1] = capitalizeFirstLetter(parts[1]);
        return parts.join(' ');
    };

    return (
        <S.CardReserve key={item.id} onClick={() => navigate(`/minha-conta/reserva/${item.id}`,)}>
            <img src={item.image} alt="" />
            <S.ContentCard>
                <S.RowCard>
                    <S.Title>Local:</S.Title>
                    <S.Subtitle>{item.address.street}, {item.address.area} - {item.address.town}, {item.address.state}</S.Subtitle>
                </S.RowCard>
                <S.RowCard>
                    <S.Title>Data:</S.Title>
                    <div>
                        <S.Subtitle>{formatDate(item.initialDate)} - {formatDate(item.finalDate)}</S.Subtitle></div>
                </S.RowCard>
            </S.ContentCard>
            <S.Gadget type={item.type}><p>{GadgetType(item.type)}</p></S.Gadget>
            <S.Guests>
                <img src={userIcon} alt="" />
                <S.TotalGuests>
                    <p>{item.guests}</p>
                </S.TotalGuests>
            </S.Guests>
            <S.ButtonContainer>
                <Button onClickButton={() => { }} size='medium' title='Ver detalhes da reserva' type='secondary' />
            </S.ButtonContainer>
        </S.CardReserve>
    );
}