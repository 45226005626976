import { ReactNode, useState } from "react"
import * as S from "./styles"
import { NewInputProps } from "components/Input/types";
import { IMaskInput } from 'react-imask';


export const errorIconInput = require('assets/svg/error/WarningError.svg').default;

export const NewInput = ({ htmlFor, disabled = false, type, placeholder, title, errorText, dataList, readOnly = false, heightInput = undefined, width, showPassword, errorLinkText, linkError, onClick, theme, mask, children }: NewInputProps & {
    mask: string | ((string | RegExp)[] & string); htmlFor: string,
    title: string, children: ReactNode
}) => {

    const errorMessages = errorText ? true : false;

    const [inputType, setInputType] = useState(type);

    const changeType = () => {
        setInputType(prevType => prevType === 'password' ? 'text' : 'password');
    };

    return (
        <>
            <S.InputContainer errorMessages={!!errorText} id={htmlFor} width={width} theme={theme}  >
                {title !== null && <label htmlFor={htmlFor}>{title}</label>}
                {children}

                {errorMessages === true && <S.ErrorContainer errorMessages={errorMessages}> <img src={errorIconInput} alt="" /><S.ErrorText errorLinkText={errorLinkText} >{errorText} {errorLinkText && <S.ErrorLink to={linkError ? linkError : ''} >{errorLinkText}</S.ErrorLink>}</S.ErrorText>  </S.ErrorContainer>}
                {showPassword && <S.ShowPassword onClick={changeType}><p>{inputType === 'text' ? 'Ocultar' : 'Mostrar'}</p></S.ShowPassword>}
            </S.InputContainer>
        </>
    )
}
