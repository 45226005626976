import { createGlobalStyle } from 'styled-components';
import { NEUTRAL_COLORS, PRIMARY_COLORS } from '../constants/styleConstants';

const searchIcon = require('../assets/svg/input/MagnifyingGlass.svg').default;

const GlobalStyle = createGlobalStyle`
    body {
        color: black;
        margin: 0;
        padding: 0;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        overflow: hidden;
        line-height: 140%;
        letter-spacing: 0%;
    }
    * {
          /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;

  }

  *::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  @media (max-width: 768px) {
    .ant-picker-panels{
      display: flex;
      flex-direction: column;
      gap: 0px !important;
    }

    .ant-picker-dropdown{
      width: 100vw !important;
      transform: scale(0.8);
      left: 0px !important;
      top: 20px !important;
    }
  }

  @media only screen
    and (min-device-width: 375px)
    and (max-device-width: 1440px) {
      .ant-picker-panels {
      display: flex;
      flex-direction: column;
      max-height: 80dvh !important;
      overflow-y: scroll !important;
      }
      .ant-picker-panels > *:last-child button.ant-picker-header-next-btn {
      visibility: visible !important;
    }
  }


  *::-webkit-scrollbar-thumb {
    background-color: ${NEUTRAL_COLORS.GRAY};
    border-radius: 999px;
    border: 6px solid rgba(0,0,0,0);

  }
        margin: 0;
        padding: 0;
        font-family: Poppins;

    }
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  margin-top: 0px !important;
  background-color: ${PRIMARY_COLORS.MEDIUM} !important;
  }

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  margin-top: 0px !important;
  background-color: ${PRIMARY_COLORS.MEDIUM} !important;

}

.ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border-color: transparent !important;

}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: ${PRIMARY_COLORS.LIGHTEST} !important;
}

.ant-btn-primary {
background-color: ${PRIMARY_COLORS.MEDIUM} !important;
border-color: ${PRIMARY_COLORS.MEDIUM} !important;
}

.ant-picker-active-bar{
  background:${PRIMARY_COLORS.MEDIUM} !important;
}

.ant-picker-focused {
     border-color: none;
    border-inline-end-width: 1px;
}

.ant-picker .ant-picker-input >input {
  ::placeholder {
    color: ${NEUTRAL_COLORS.DARK_GRAY};

  }
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner{
  border-radius: 8px;
}

.ant-picker:hover, .ant-picker-focused{
  border-color: ${NEUTRAL_COLORS.DARK_GRAY};
  border:1px solid ${NEUTRAL_COLORS.DARK_GRAY};
}

.ant-picker-dropdown .ant-picker-content th{
  color: ${NEUTRAL_COLORS.DARK_GRAY} !important;
 }

.ant-picker-dropdown .ant-picker-cell {
    padding: 4px 0px !important;
}

.productPage{
  .ant-picker-panel-container{
  margin-left: -422px !important;
  @media (max-width: 768px) {
    margin-left: 16px !important;
  }
}
}

.containerProduct{
  border: none !important;
  border-bottom-right-radius:0px !important;
  border-bottom-left-radius: 0px !important;
  &:hover {
    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius:8px !important;
    border-bottom-right-radius:0px !important;
    border-bottom-left-radius: 0px !important;
  }
}


.ant-picker-dropdown{
  line-height: 2.1 !important;
}

.ant-picker-dropdown .ant-picker-decade-panel, .ant-picker-dropdown .ant-picker-year-panel, .ant-picker-dropdown .ant-picker-quarter-panel, .ant-picker-dropdown .ant-picker-month-panel, .ant-picker-dropdown .ant-picker-week-panel, .ant-picker-dropdown .ant-picker-date-panel, .ant-picker-dropdown .ant-picker-time-panel {
    display: flex;
    flex-direction: column;
    width: 400px !important;
    @media (max-width: 768px) {
      width: 100% !important;
    }
}

.ant-picker-dropdown .ant-picker-header{
  height: 35px;
 border-bottom: none;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body{
  position: relative;
  &::before{
    position: absolute;
    content: '';
    display: block;
    width: 97%;
    height: 1px;
    background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};
    margin-top: 34px;


  }
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels .ant-picker-panel {
  border: none
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content {
    width: 100% !important;
    tbody{
    /* tr [class*="ant-picker-cell"]:not(.ant-picker-cell-disabled):hover {
      background-color: ${PRIMARY_COLORS.LIGHTEST} !important;
      border-radius: 8px !important;
      color: ${NEUTRAL_COLORS.WHITE} !important;
      font-size: 18px ;
    } */
    td{
    }
    }
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner{
    display: flex;
    /* position: relative; */
    /* z-index: 2; */
    /* display: inline-block; */
    /* min-width: 24px; */
    height: 44px;
    line-height: 22px;
    /* border-radius: 4px; */
    /* transition: background 0.2s,border 0.2s; */
    font-size: 14px !important;
    font-weight: 500 !important;
    align-items: center;
    justify-content: center;
}

.ant-picker-dropdown .ant-picker-cell::before {
    height: 44px !important;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner{
    min-width: 44px !important;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
    font-weight: 500 !important;
    font-size: 14px !important;
}

.ant-picker-dropdown .ant-picker-header-view {
    line-height: 40px;
    font-size: 16px;
}


.ant-picker-dropdown .ant-picker-panel-container{
  padding: 10px !important;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 8px 8px;
}

.ant-picker-dropdown .ant-picker-header-view button:hover {
    color: ${PRIMARY_COLORS.MEDIUM} !important;
}

[class^="ant-picker"] [class^="ant-picker"], [class*=" ant-picker"] [class^="ant-picker"], [class^="ant-picker"] [class*=" ant-picker"], [class*=" ant-picker"] [class*=" ant-picker"] {
    box-sizing: border-box;
    gap: 10px !important;
    padding-top: 1px !important;
    padding-left:3.2px !important;
    padding-right:3.2px !important;
    padding-bottom: 0px !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
  background-color: ${PRIMARY_COLORS.MEDIUM} !important;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  visibility: hidden;
}

.ant-picker-clear{
  visibility: hidden;
}

.ant-picker:hover, .ant-picker-focused {
    border: 1px solid rgb(255, 122, 42);
  &:nth-child(1){
    .ant-picker-active-bar{

  }

}
}

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
    margin-left: 20px ;
    width: 20px;

}

.ant-picker-range .ant-picker-range-separator {
    align-items: center;
    padding: 0 8px;
    margin-right: 16px;
    line-height: 1;
    position: relative;

    &::after{
      position: absolute;
      content: '';
      display: block;

      border: 1px solid ${NEUTRAL_COLORS.GRAY};
      border-left: 0;

      height: calc(100% + 25px);

      top: -12px;
      left: calc(100% + 8px) ;

    }
}


.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{background:#e6f4ff;}.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start::before{inset-inline-start:50%;}.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end::before{inset-inline-end:50%;}.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{position:absolute;top:50%;z-index:0;height:24px;border-top:none !important;border-bottom:none !important;transform:translateY(-50%);transition:all 0.3s;content:"";}

tr>.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,tr>.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after{
  inset-inline-end:6px;
  border-inline-end:1px dashed transparent;
  border-start-end-radius:1px;
  border-end-end-radius:1px;}

  .ant-picker-dropdown .ant-picker-cell-disabled{color:rgba(0, 0, 0, 0.25);pointer-events:none;}

tr>.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,tr>.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after{inset-inline-start:6px;border-inline-start:1px dashed transparent;border-start-start-radius:1px;border-end-start-radius:1px;}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
 padding-top:-4px !important;
}

.ant-picker-focused{
  border-color:transparent;
  box-shadow:0 0 0 0px transparent;
  border-inline-end-width:0px;outline:0;}

  .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start::before{inset-inline-start:50%;}.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end::before{inset-inline-end:50%;}.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{position:absolute;top:50%;z-index:0;height:0px;border-top:0;border-bottom:0;transform:translateY(-50%);transition:all 60s;content:"";}

//-------------------------------------------------------//

// MAP LEAFLET

.leaflet-popup-close-button{
  width: 35px;
  padding-right: 12px;
  padding-top: 10px;
    span{
      font-size: 40px;
      display: flex;
      color: ${NEUTRAL_COLORS.HIGH_DARK};
      font-weight: 200;

    }
}

.leaflet-touch .leaflet-bar{
  /* display: flex; */
}

.leaflet-control-zoom-in,.leaflet-control-zoom-out{
 font: 400 24px 'Lucida Console', Monaco, monospace;
 padding: 0px 2px;

}

.leaflet-touch .leaflet-bar{
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid ${NEUTRAL_COLORS.GRAY};
  box-shadow:  1px 2px 4px rgba(0,0,0,0.25);
}

.leaflet-touch .leaflet-bar a:first-child {

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.leaflet-bar a {
   color: ${NEUTRAL_COLORS.HIGH_DARK};
   &:hover{
  background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};}

}

 .leaflet-touch .leaflet-control-zoom-out{
  font-size:26px
}

.leaflet-touch .leaflet-bar a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 32px;
    font-weight: 300;
    .leaflet-control-zoom-out{
      font-size: 60px !important;
    }
}

.leaflet-bar a{
  border-bottom:none;
}

.leaflet-control-zoom-out{
      font-size: 42px !important;
      font-weight: 200;
    }
.leaflet-right .leaflet-control{
  margin-right: 32px;
}

.leaflet-top .leaflet-control {
    margin-top: 16px;
}

.react-tel-input .flag-dropdown.open{
  border-radius: 0px !important;
  background-color: transparent !important;
  :focus-within{
    border-color: ${PRIMARY_COLORS.MEDIUM};
  }
}

.react-tel-input .country-list .search-emoji{
  position: absolute !important;
  content: url(${searchIcon}) !important;

  left: 24px;


}

.react-tel-input .country-list{
    display: flex;
    flex-direction: column;

    row-gap: 8px;

    padding-left: 8px;

    width: calc(415px);
    max-height: 300px;
    :has(.no-entries-message){
      max-height: 130px;
      overflow: hidden;
    }
    left: 0;
    padding-bottom: 8px !important;
    border-radius: 8px !important;
    }

    .react-tel-input .country-list .country-name{
      padding-left: 6px;
    }
    .react-tel-input .country-list .country{
      padding: 8px 9px !important;
    }

    //Credit Card

  .rccs__card--american-express rccs__card__background {
  background: linear-gradient(25deg,#F5BE31, #FF7A2A) !important ;
}

.rccs__card--dankort .rccs__card__background {
  background: linear-gradient(25deg,#F5BE31, #FF7A2A) !important;
}

.rccs__card--diners-club .rccs__card__background {
  background: linear-gradient(25deg, #F5BE31, #FF7A2A) !important;
}

.rccs__card--discover .rccs__card__background {
  background: linear-gradient(25deg, #F5BE31, #FF7A2A) !important;
}

.rccs__card--elo .rccs__card__background {
  background: linear-gradient(25deg, #F5BE31, #FF7A2A) !important;
}

.rccs__card--hipercard .rccs__card__background {
  background: linear-gradient(25deg, #F5BE31, #FF7A2A) !important;
}

.rccs__card--maestro .rccs__card__background, .rccs__card--mastercard .rccs__card__background {
  background: linear-gradient(25deg,#F5BE31, #FF7A2A) !important;
}

.rccs__card--visa .rccs__card__background, .rccs__card--visa-electron .rccs__card__background {
  background: linear-gradient(25deg, #F5BE31, #FF7A2A) !important;
}

.rccs__card--unknown > div {
  background: linear-gradient(25deg,#F5BE31, #FF7A2A);
  box-shadow: none;
}

.rccs__card--jcb .rccs__card__background {
  background: linear-gradient(25deg,#F5BE31, #FF7A2A) !important;
}

.rccs__card--front, .rccs__card--back {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background: linear-gradient(25deg,#F5BE31, #FF7A2A) !important;
  box-shadow: none !important;
}

.rccs__card--flipped {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.rccs__card--flipped .rccs__card--front {
  z-index: 10;
}
.rccs__card--flipped .rccs__card--back {
  z-index: 20;
}

.leaflet-pane {
    z-index: 100;
}
.leaflet-top, .leaflet-bottom {

    z-index: 120;
}
`
export default GlobalStyle;
