import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled, { css } from "styled-components";

const tableImage = require("assets/image/flexhousing/BackgroundTable.png");

const mainImage = require('assets/image/flexhousing/MainImage.png')
const MainImage768 = require('assets/image/flexhousing/MainImage768px.png')
const MainImage1728x950 = require('assets/image/flexhousing/MainImage1728x950.png')
const MainImage1920x1080 = require('assets/image/flexhousing/MainImage1920x1080.png')
const MainImage2560x1080 = require('assets/image/flexhousing/MainImage2560x1080.png')

export const Container = styled.main`
    display: flex;

    flex-direction: column;

    align-items: center;

    width: 100%;

    height: 100vh;

    overflow: auto;

    gap: 140px;

`;

export const InitialSectionContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    width: 100%;

    gap: 80px;

    margin-top: -140px;
    @media (max-width: 768px) {
        h3{
            ${FONT.BODY.BODY_LARGE[600]};
            text-align: center;
        }
    }
`;

export const InitialSection = styled.div`
    display: flex;
    min-height: 85dvh;
    width: 100%;
    background: url(${mainImage}) no-repeat center;
    background-size: cover;
    background-repeat: space;

    @media (max-width: 768px) {
        background-image: url(${MainImage768});
        min-height: 370px;
        width: 100%;
    }
    @media (min-width: 1366px) {
      background-image: url(${MainImage1728x950});
    }
    @media (min-width: 1920px) {
        background-image: url(${MainImage1920x1080});
    }
    @media (min-width: 2560px) {
        background-image: url(${MainImage2560x1080});
    }
`;

export const InitialColumn = styled.div`
    display: flex;

    flex-direction: column;


    margin-top: 220px;
    margin-left: 80px;

    max-width: 463px;
    color: ${NEUTRAL_COLORS.WHITE};
    h2{
        ${FONT.HEADLINES.H2};

    }
    p{
        ${FONT.BODY.BODY_LARGE[400]};
    }

    gap: 16px;

    @media (max-width: 768px) {
        margin-left: 0px;

        align-items: center;
        text-align: left;

        padding-left: 20px;
        padding-right: 60px;
        margin-top: 185px;
        h2{
            ${FONT.HEADLINES.H5}
        }
        p{
            ${FONT.BODY.BODY_SMALL[400]};
        }
    }

    @media (min-width: 1366px) {
        margin-left: 6%;
    }
    @media (min-width: 1920px) {
        margin-left: 19%;
    }
    @media (min-width: 2560px) {
        margin-left: 20%;
    }
`;

export const InitialCardGroup = styled.div`
    display: grid;

    grid-template-columns: repeat(3, 1fr);

    gap:32px;

    @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);

    place-content: center;
    place-items: center;
    }
`;

export const HeadingTitle = styled.h3`
    ${FONT.HEADLINES.H3};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Highligh = styled.span`
    color: ${PRIMARY_COLORS.MEDIUM};
`;

export const YoghaServicesContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 75px;

    margin-top: 64px;

    @media (max-width: 768px) {
        margin-top: -48px;
    }
`;

export const YoghaServicesHeader = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
        max-width: 561px;
        text-align: center;
    }

    @media (max-width: 768px) {

        width: calc(100% - 40px);

        text-align: center;

        gap:16px;

        h3{
            ${FONT.BODY.BODY_LARGE[600]};
        }

        p{
        ${FONT.BODY.BODY_SMALL[400]}
        text-align: center;
    }

    margin-bottom: -32px;

    }
`;

export const CardYogharServiceGroup = styled.div`
    display: flex;

    gap: 32px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


`;

export const CardAttributesContainer = styled.div`
    display: flex;

    flex-direction: column;

    width: calc(382px - 64px);

    gap: 16px;

    padding: 32px;

    align-items: flex-start;

    border-radius: 16px;

    background: ${NEUTRAL_COLORS.WHITE};

    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.10);

    strong{
        ${FONT.BODY.BODY_LARGE[600]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }

    @media (max-width: 768px) {
        width: calc(100% - 104px);
    }
    `;

export const CardYogharServiceContainer = styled.div`
    display: flex;

    flex-direction: column;

    width: 381px;

    gap: 16px;

    strong{
        ${FONT.BODY.BODY_LARGE[600]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }

    @media (max-width: 768px) {
        width: calc(100% - 40px);

        img{
            border-radius: 16px;
        }
        p{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }
    }

    `;

export const CardYogharServiceColumn = styled.div`
    display: flex;

    flex-direction: column;

    width: 100%;

    gap: 8px;

    p{
        padding-right: 90px;
    }

    @media (max-width: 768px) {

        p{
            padding-right: 0px;
        }
    }
    `;

export const AmenitiesSectionContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 64px;

    h3{
        max-width: 825px;
        ${FONT.HEADLINES.H4}
        text-align: center;
    }

    @media (max-width: 768px) {

        width: calc(100% - 40px);

        h3{
        ${FONT.BODY.BODY_LARGE[600]}
        text-align: center;
        margin-bottom: -32px;
    }

        margin-top: -80px;
    }
`;

export const AmenitiesContainer = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;
    justify-items: center;

    padding: 16px;
    padding-bottom: 32px;
    padding-top: 32px;

    gap: 24px;

    width: calc(277px - 32px);
    height: calc(149px - 64px);

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    border-radius: 8px;

    background: ${NEUTRAL_COLORS.WHITE};

    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.10);


    @media (max-width: 768px) {
        max-width: calc(160px - 32px);
        width: 100%;
        height: calc(148px - 48px);
        padding-top: 16px;
        text-align: center;
    }
    `;

export const CardAmenitiesGroup = styled.div`
    display: grid;

    grid-template-columns: repeat(3, 1fr);

    gap: 24px;

    @media (max-width: 768px) {

    grid-template-columns: repeat(2, 1fr);

    }
`;

export const GadgetServicesSectionContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 80px;

    @media (max-width: 768px) {
        h3{
            width: calc(100% - 40px);
            ${FONT.BODY.BODY_LARGE[600]};
            margin-bottom: -48px;
        }
        text-align: center;

    }
`;

export const GadgetServicesGroup = styled.div`
    display: grid;

    grid-template-columns: repeat(3, 1fr);

    gap: 36px;

    @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);

    }
`;

export const GadgetServicesContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 278px;
    max-height: 198px;

    align-items: center;

    padding: 32px;

    gap: 16px;

    div{
        display: flex;

        align-items: center;
        justify-content: center;

        border-radius: 8px;

        background-color: ${PRIMARY_COLORS.MEDIUM};

        width: 72px;
        height: 72px;
    }

    strong{
        ${FONT.BODY.BODY_LARGE[600]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
        text-align: center;
        max-width: 235px;
    }

    border-radius: 8px;
    box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.10);

    @media (max-width: 768px) {
        max-width: calc(278px - 32px);
        width: 100%;
    }
`;

export const AppSuiteSectionContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    position: relative;

    padding-top: 168px;
    padding-inline: 82px;

    @media (max-width: 768px) {
        padding-inline: 0px;
        padding-top: 120px;
    }
`;

export const AppSuiteBackground = styled.img`
@media (max-width: 768px) {

}

`;

export const YoghaDesingSectionContainer = styled.section`
    display: grid;

    grid-template-columns: repeat(2, fit-content(100%));

    gap: 32px;

    img{
        border-radius: 16px;
    }

    padding-block: 32px;

    @media (max-width: 768px) {
    width: calc(100% - 40px);
    grid-template-columns: repeat(1, fit-content(100%));
    place-content: center;
    place-items: center;
    margin-block: -80px;
        img{
                width: 100%;
                height: 211px;
                object-fit: cover;
            }
    }
`;

export const YoghaDesingSectionContainerColumn = styled.div`
    display: flex;

    flex-direction: column;

    gap: 32px;
`;

export const ButtonContainer = styled.div`
    width: 278px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;
export const YoghaDesingSectionText = styled.div`
    display: flex;

    flex-direction: column;

    gap: 16px;

    max-width: 425px;

    h4{
    ${FONT.HEADLINES.H4};
    }


    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};

        max-width: 394px;
    }

    margin-top:30px;

    @media (max-width: 768px) {
        max-width: unset;

        align-items: center;
        justify-content: center;

        margin-top: -16px;

        h4{
            width: 100%;
            ${FONT.BODY.BODY_LARGE[600]};
            text-align: left;
        }
    }

    `;

export const CommentsSectionContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 48px;

    h3{
        max-width: 588px;
        text-align: center;
    }

    @media (max-width: 768px) {
        width: calc(100%);
        div{
            width: calc(100% - 5px);
        }
        margin-top: -48px;
    }
`;

export const AppSuiteContent = styled.div`
    display: flex;

    gap: 55px;

    position: absolute;

    bottom: 0;
    left: 131px;

    @media (max-width: 768px) {
        flex-direction: column;

        left: 0px;
        img{
            object-fit: contain;
            align-self: center;
        }
    }
`;

export const AppSuiteColumnText = styled.div`
    display: flex;

    flex-direction: column;

    gap: 16px;

    h3{
        color: ${NEUTRAL_COLORS.WHITE}
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[400]}
        color: ${NEUTRAL_COLORS.WHITE};
        max-width: 589px;
    }

    @media (max-width: 768px) {
        width: calc(100% - 32px);
        h3{
            ${FONT.BODY.BODY_LARGE[600]};
        }
        p{
            white-space: normal;
            max-width: 100%;
        }
    }
`;

export const AppSuiteCardGroup = styled.div`
    display: grid;

    grid-template-columns: repeat(2, fit-content(100%));

    gap: 16px;

    @media (max-width: 768px) {
    grid-template-columns: repeat(1, calc(100% - 64px));
    width: 100%;
    place-content: center;
    place-items: center;
    }
`

export const AppSuiteColumnRight = styled.div`
    display: flex;

    flex-direction: column;

    gap: 40px;

    justify-content: end;
    margin-bottom: 64px;

    @media (max-width: 768px) {
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`;

export const InfoCardContainer = styled.div`
    display: flex;

    padding: 16px;

    width: calc(278px - 32px);

    gap: 10px;

    div{
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: ${PRIMARY_COLORS.MEDIUM};

        width: 40px;
        height: 40px;

        border-radius: 8px;
    }
    p{
        ${FONT.BODY.BODY_MEDIUM[500]};
        color: ${NEUTRAL_COLORS.WHITE};
    }

    align-items: center;

    border-radius: 8px;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(39.5px);

    @media (max-width: 768px) {
    width: 100%;

    }
`;

export const BenefitsSectionContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 80px;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;

        gap: 32px;

        h3{
            ${FONT.BODY.BODY_LARGE[600]};
        }
        margin-top: -64px;
    }

`;

export const BenefitsTableGroup = styled.div`
    display: grid;

    grid-template-columns: repeat(3, 1fr);

    gap: 32px;

    @media (max-width: 768px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    place-content: center;
    place-items: center;
    }
`;

export const BenefitsTableContainer = styled.div<{variant:boolean}>`
    display: flex;

    flex-direction: column;

    align-items: center;

    width: 380px;
    height: 434px;

    gap: 32px;

    background-color: #faf8f8;

    border-radius: 16px;

    padding: 32px 0px;

    div{
        display: flex;
        flex-direction: column;

        max-width: 290px;
        gap: 8px;
    }

    span{
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        font-size: 28px;
        font-weight: 700;
        line-height: 110%;
    }

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
    }

    li{
        img{

        }
        display: flex;
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.DARK};
        text-decoration: none;
        gap: 12px;
    }

    ul{
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 295px;
        align-items: flex-start;
    }

    ${({variant}) => variant && css`
        background-image: url(${tableImage});
        background-repeat: no-repeat;
        background-size: cover;
        span{
            color: ${NEUTRAL_COLORS.WHITE};
        }
        p{
            color: ${NEUTRAL_COLORS.WHITE};
        }
        li{
            color: ${NEUTRAL_COLORS.WHITE};
        }
    `}

    @media (max-width: 768px) {
        width: calc(100% - 72px);
        height: unset;

        align-self: center;

        padding-left: 32px;

        ul{
            width: 100%;
            padding-left: 16px;
        }

        span{
            ${FONT.HEADLINES.H5};
            font-weight: 600 !important;
        }

        li,p{
            ${FONT.BODY.BODY_SMALL[400]};
        }

    }
`;

export const ReserveNowContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    position: relative;

    max-width: 1200px;
    width: 100%;
    max-height: 737px;
    height: 100%;

    img{
        min-width: 100%;
        object-fit: cover;
        border-radius: 16px;
    }

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        justify-content: center;
        max-width: unset;
        max-height: unset;
        margin-top: -48px;
        margin-bottom: -48px;
    }
`;

export const ReserveHeading = styled.div`
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 16px;

    position: absolute;
    top: 412px;
    left: 142px;

    h3{
        ${FONT.HEADLINES.H3};
        color: ${NEUTRAL_COLORS.WHITE};
        max-width: 912px;
        text-align: center;
    }

    .button{
        width: 378px;
    }

    @media (max-width: 768px) {
       position: unset;

        h3{
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        ${FONT.BODY.BODY_LARGE[600]};
        margin-top: 16px;
        margin-bottom: 8px;
        }

       .button{
        width: 100%;
    }
    }
`;

export const CarouselContainer = styled.div`
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% + 80px);
        height: 370px;
    }
`;

export const CaroselSectionContainer = styled.section`
    display: flex;

    flex-direction: column;

    align-items: center;

    gap: 40px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);

        h3{
            ${FONT.BODY.BODY_LARGE[600]};
        }
        margin-top: -48px;
    }
`;

export const CaroselWithNavContainer = styled.div`
    display: flex;

    flex-direction: column;
    align-items: center;

    gap: 24px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
    }
`;