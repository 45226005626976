import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, STATUS_COLORS } from "constants/styleConstants";
import styled from "styled-components";


export const ContainerPage = styled.div`
    display:flex;
    flex-direction:column;

    width: 100%;
    max-height: 100lvh;
    height: 100lvh;
    overflow-y: auto;

`;
export const Container = styled.div`
    display:flex;
    flex-direction: column;

    align-items: center;

    margin-top: 32px;

    @media (width < 768px) {
        flex-direction: column;
        margin-top: 0px;
    }
`;

export const Content = styled.div`
    display:flex;
    flex-direction: column;

    max-width: 548px;
    width: 100%;


`;

export const Header = styled.div`
    display:flex;

    gap: 16px;

    justify-content: center;
    align-items: center;

    width: 548px;
    height: 96px;

    background-color: ${STATUS_COLORS.ERROR};
    border-radius: 6px;

    h1{
        color: ${NEUTRAL_COLORS.WHITE};
        ${FONT.BODY.BODY_LARGE[600]};
    }

    margin-bottom: 40px;

    @media (width < 768px) {
        width: calc(100% - 48px);
        padding: 0px 24px;
        border-radius: 0px;
        margin-bottom: 32px;

        h1{
        color: ${NEUTRAL_COLORS.WHITE};
        ${FONT.BODY.BODY_MEDIUM[600]};
    }
    }
`;

export const ContainerInformation = styled.div`
    display: flex;
    flex-direction: column;

    text-align: center;

    gap:16px;

    p{
        ${FONT.BODY.BODY_MEDIUM[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    ol{
        padding-left: 20px;
        text-align: justify !important;
        li{
            ${FONT.BODY.BODY_MEDIUM[400]};
            text-align: justify !important;
        }
    }

    margin-bottom: 40px;

    @media (max-width: 768px) {
        width: calc(100% - 40px);
        align-self: center;

        p{
        ${FONT.BODY.BODY_SMALL[400]};
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    ol{
        padding-left: 20px;
        text-align: justify !important;
        li{
            ${FONT.BODY.BODY_SMALL[400]};
            text-align: justify !important;
        }
    }
    }
`;

export const ButtonContainer = styled.div`
    display:flex;

    gap:24px;

    justify-content: center;

    margin-bottom: 64px;

    @media (max-width: 768px) {
      flex-direction: column;

      width: calc(100% - 40px);
        align-self: center;
    }
`;